import {
	closestCenter,
	DndContext,
	DragOverEvent,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { Box } from '@mui/material';
import { UserSkill } from 'middleware-types';
import { SortableSkill } from './sortable-skill';

interface ReorderSkillsProps {
	skills: UserSkill[];
	setSkills: React.Dispatch<React.SetStateAction<UserSkill[]>>;
	userId: string;
}

export const ReorderSkills = ({ skills, setSkills, userId }: ReorderSkillsProps) => {
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	const handleDragOver = (event: DragOverEvent) => {
		const { active, over } = event;

		if (!over) return;
		if (active.id === over.id) return;
		setSkills((skills) => {
			const oldIndex = skills.findIndex((skill) => skill.id === active.id);
			const newIndex = skills.findIndex((skill) => skill.id === over.id);
			return arrayMove(skills, oldIndex, newIndex);
		});
	};

	return (
		<DndContext
			sensors={sensors}
			collisionDetection={closestCenter}
			onDragOver={handleDragOver}>
			<SortableContext items={skills} strategy={() => null}>
				<Box px={2.5} py={1.75} sx={{ columnCount: 3, columnGap: 4.5 }}>
					{skills.map((skill, index) => (
						<SortableSkill key={skill.id} skill={skill} userId={userId} index={index} />
					))}
				</Box>
			</SortableContext>
		</DndContext>
	);
};
