import { ChevronRightOutlined, SettingsOutlined } from '@mui/icons-material';
import { Box, Card, CardActionArea, Stack, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { Permission } from 'utils/permissions';
import { useSiteUser } from 'utils/useSiteUser';

/**
 * ProfileAdministration - Profile administration navigation.
 *
 * @param {{userId : string}} props
 * @return {*}
 */
export const ProfileAdministration = () => {
	const { hasPermission } = useSiteUser();
	const params = useParams<{ handle: string }>();

	if (!hasPermission(Permission.SocialUser_Account_R)) return <></>;

	return (
		<Card>
			<CardActionArea component={Link} to={`/${params.handle}/account`}>
				<Stack px={2.5} py={1.5} direction="row" alignItems="center" spacing={3}>
					<SettingsOutlined sx={{ color: 'neutral.500' }} />
					<Typography variant="body1">View Account Settings</Typography>
					<Box flex={1} />
					<ChevronRightOutlined />
				</Stack>
			</CardActionArea>
		</Card>
	);
};
