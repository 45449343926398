import { useQuery, gql } from '@apollo/client';
import { Query } from 'middleware-types';
import { Permission } from 'utils/permissions';
import { useSiteUser } from 'utils/useSiteUser';

export const useLegacyDatabasesQuery = () => {
	const { hasPermission } = useSiteUser();
	const hasSystemsReadPermission = hasPermission(Permission.Site_LegSys_R);

	const { data, error, loading } = useQuery<Pick<Query, 'legacyDatabases'>>(
		gql`
			query legacyDatabases {
				legacyDatabases {
					name
					systemId
					systemUrl
				}
			}
		`,
		{
			fetchPolicy: 'cache-first',
			onError: () => {
				console.log(error);
			},
			skip: !hasSystemsReadPermission,
		}
	);

	return {
		systems: data?.legacyDatabases || [],
		error,
		loading,
	};
};
