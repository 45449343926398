import { Box, Button, Stack } from '@mui/material';
import { AssumeIdentityBanner } from 'components/pages/assume-identity-banner';
import { UpdateBanner } from 'components/pages/update-banner';
import { ReactNode } from 'react';
import { useIsMobile } from 'utils/useScreenSize';
import { PrimaryNavDesktop } from './primary-nav-desktop';
import { PrimaryNavMobile } from './primary-nav-mobile';
import { useAnonymousSession } from 'utils/session';
import { LightShell } from '../shell';

export const PrimaryNav = ({ children }: { children: ReactNode }) => {
	const { anonymous } = useAnonymousSession();
	const isMobile = useIsMobile();

	if (anonymous) {
		return (
			<LightShell
				right={
					<Stack direction="row" spacing={1}>
						<Button variant="outlined" href="auth/sign-up">
							Sign Up
						</Button>
						<Button variant="contained" color="primary" href="auth/login">
							Log In
						</Button>
					</Stack>
				}>
				{children}
			</LightShell>
		);
	}

	return (
		<Stack height="100vh" width="100vw" flex={1}>
			<UpdateBanner />
			<AssumeIdentityBanner />
			{isMobile ? <PrimaryNavMobile /> : <PrimaryNavDesktop />}
			<Box display="flex" flexDirection="column" flexGrow={1} overflow="auto">
				{children}
			</Box>
		</Stack>
	);
};
