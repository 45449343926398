import { useEffect, useRef, useState } from 'react';

export const useSkipUntilIntersecting = <T extends HTMLElement = HTMLElement>() => {
	const [skip, setSkip] = useState(true);
	const ref = useRef<T>(null);

	useEffect(() => {
		// if we've already set skip to false, we don't need this observer anymore, so we return early
		if (skip === false) return;
		const observer = new IntersectionObserver((entries) => {
			if (entries[0].isIntersecting) setSkip(false);
		});
		if (ref.current) observer.observe(ref.current);
		return () => {
			if (ref.current) observer.unobserve(ref.current);
		};
	}, [skip]);

	return { ref, skip };
};
