import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	Divider,
	FormControlLabel,
	IconButton,
	InputAdornment,
	MenuItem,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import { CheckboxField, DatePickerField, SelectField, TextField } from 'components/ui/fields';
import { format } from 'date-fns';
import { Formik, FormikErrors, useFormikContext } from 'formik';
import { AdHocBillingItem, AdHocBillingItemUpdate } from 'middleware-types';
import { useValidation } from 'utils/useValidation';
import { formatUSD, zoneDateOnly } from 'utils/utils';
import { useBillingProducts } from '../hooks/use-billing-products';
import { useCreateAdHocItem } from '../hooks/use-create-ad-hoc-item';
import { useUpdateAdHocItem } from '../hooks/use-update-ad-hoc-item';

interface AdHocItemFormValues extends Omit<AdHocBillingItemUpdate, 'quantity' | 'unitPrice'> {
	quantity: number | '';
	unitPrice: number | '';
	customPrice: boolean;
}

interface AddUpdateLineItemModalProps {
	item?: AdHocBillingItem;
	onClose: () => void;
}

export const AddUpdateLineItemModal = ({ item, onClose }: AddUpdateLineItemModalProps) => {
	const addItem = useCreateAdHocItem();
	const updateItem = useUpdateAdHocItem();

	const initialValues: AdHocItemFormValues = {
		serviceDate: item?.serviceDate ? zoneDateOnly(item.serviceDate) : null,
		description: item?.description ?? '',
		quantity: item?.quantity ?? '',
		recurring: item?.recurring ?? false,
		stripePriceId: item?.stripePriceId ?? '',
		stripeProductId: item?.stripeProductId ?? '',
		unitPrice:
			item?.unitPrice === null || item?.unitPrice === undefined ? '' : item.unitPrice / 100,
		customPrice: Boolean(item?.unitPrice),
	};

	const onSubmit = async (values: AdHocItemFormValues) => {
		const {
			serviceDate,
			description,
			quantity,
			recurring,
			stripePriceId,
			stripeProductId,
			unitPrice,
			customPrice,
		} = values;
		if (quantity === '') return;

		const request: AdHocBillingItemUpdate = {
			serviceDate: !recurring && serviceDate ? format(serviceDate, 'yyyy-MM-dd') : undefined,
			description,
			quantity,
			recurring,
			stripePriceId: customPrice ? undefined : stripePriceId,
			stripeProductId,
			unitPrice: !customPrice || unitPrice === '' ? undefined : unitPrice * 100,
		};

		let success = false;
		if (item) success = await updateItem(item.id, request);
		else success = await addItem(request);
		if (success) onClose();
	};

	const validate = (values: AdHocItemFormValues) => {
		const errors: FormikErrors<AdHocItemFormValues> = {};
		if (values.customPrice) {
			if (!values.unitPrice) errors.unitPrice = 'Required Field';
		} else {
			if (!values.stripePriceId) errors.stripePriceId = 'Required Field';
		}
		if (!values.recurring) {
			if (!values.serviceDate) errors.serviceDate = 'Required Field';
		}
		return errors;
	};

	const validation = useValidation('AdHocBillingItemUpdate');

	return (
		<Formik<AdHocItemFormValues>
			initialValues={initialValues}
			onSubmit={onSubmit}
			validate={validate}
			validationSchema={validation.schema}>
			{({ submitForm, isSubmitting, isValid, dirty }) => (
				<Stack>
					<Stack
						px={2.5}
						py={1.5}
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						spacing={1}>
						<Typography variant="h3">
							{item ? 'Update Ad-Hoc Line Item' : 'Add Ad-Hoc Line Item'}
						</Typography>
						<IconButton onClick={onClose}>
							<Close />
						</IconButton>
					</Stack>
					<Divider />
					<FormContent />
					<Divider />
					<Stack direction="row" justifyContent="flex-end" px={2} py={1.5} spacing={1.5}>
						<Button size="large" variant="outlined" onClick={onClose}>
							Cancel
						</Button>
						<LoadingButton
							size="large"
							variant="contained"
							color="primary"
							loading={isSubmitting}
							disabled={!isValid || !dirty}
							onClick={submitForm}>
							Save
						</LoadingButton>
					</Stack>
				</Stack>
			)}
		</Formik>
	);
};

const FormContent = () => {
	// get the product and pricing options
	const { products, loading } = useBillingProducts();
	const sortedProducts = [...products].sort((a, b) => a.name.localeCompare(b.name));

	// clear the price field whenever a new product is selected
	const { values, setFieldValue } = useFormikContext<AdHocItemFormValues>();
	const onProductChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
		const stripeProductId = e.target.value;
		setFieldValue('stripeProductId', stripeProductId);
		setFieldValue('stripePriceId', '');
	};

	// get the price options
	const pricingOptions =
		products.find((p) => p.productId === values.stripeProductId)?.prices ?? [];
	const sortedPrices = [...pricingOptions].sort((a, b) =>
		(a.description ?? '').localeCompare(b.description ?? '')
	);

	return (
		<Stack p={2.5} spacing={2}>
			{loading ? (
				<Skeleton variant="rounded" height={40} />
			) : (
				<SelectField
					name="stripeProductId"
					label="Product"
					onChange={onProductChange}
					required>
					{sortedProducts.map((product) => (
						<MenuItem key={product.productId} value={product.productId}>
							{product.name}
						</MenuItem>
					))}
				</SelectField>
			)}
			<TextField
				name="description"
				label="Invoice Description"
				helperText="If no description is provided, the product name will be used"
			/>
			<TextField name="quantity" label="Quantity" type="number" required />
			<Box>
				<FormControlLabel
					control={<CheckboxField name="customPrice" />}
					label="Custom price"
				/>
				{values.customPrice ? (
					<TextField
						name="unitPrice"
						label="Price"
						type="number"
						required
						InputProps={{
							startAdornment: <InputAdornment position="start">$</InputAdornment>,
						}}
					/>
				) : (
					<SelectField name="stripePriceId" label="Price" required>
						{pricingOptions.length > 0 ? (
							sortedPrices.map((price) => (
								<MenuItem key={price.priceId} value={price.priceId}>
									{price.description} ({formatUSD(price.price)})
								</MenuItem>
							))
						) : (
							<MenuItem disabled>Select a product to see pricing options.</MenuItem>
						)}
					</SelectField>
				)}
			</Box>
			<Box>
				<FormControlLabel control={<CheckboxField name="recurring" />} label="Recurring" />
				<DatePickerField
					name="serviceDate"
					label="Date of Service"
					disabled={values.recurring}
					required={!values.recurring}
				/>
			</Box>
		</Stack>
	);
};
