import { AddOutlined, ManageSearchOutlined } from '@mui/icons-material';
import {
	Box,
	Chip,
	CircularProgress,
	FormControlLabel,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	Paper,
	Skeleton,
	Stack,
	Switch,
	Tooltip,
	Typography,
} from '@mui/material';
import { InvitationForm } from 'components/pages/site/users/invitation';
import EmergencyContact from 'components/pages/user/account/emergency-contact/emergency-contact';
import { LocationInformation } from 'components/pages/user/account/location-info';
import { PersonalInfo } from 'components/pages/user/account/personal/personal-info';
import { SiteUserInformation } from 'components/pages/user/account/siteuser';
import { UserEmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import { InfiniteScroll } from 'components/ui/infinite-scroll';
import { ConfirmationModalContent } from 'components/ui/modals/confirmation-modal-content';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { PageContent, PageTitle } from 'components/ui/page';
import { Search } from 'components/ui/search';
import { Emblem, SiteUserInvitationRequest, SortDirection } from 'middleware-types';
import React, { useState } from 'react';
import { Permission } from 'utils/permissions';
import { useSiteUser } from 'utils/useSiteUser';
import { useSiteUserEmblemsQuery } from './hooks';

/**
 * SiteUsers contains the entire site users page.
 *
 * @returns
 */
export const SiteUsersPage: React.FC = () => {
	const { hasPermission } = useSiteUser();
	const [searchFor, setSearchFor] = useState('');
	const [selected, setSelectedUser] = useState<Emblem | undefined>(undefined);
	const [showDeactivated, setShowDeactivated] = useState(false);

	const [inviteModalOpen, setInviteModalOpen] = useState(false);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const [invitedUsername, setInvitedUsername] = useState('');
	const [invitedEmail, setInvitedEmail] = useState('');

	const onSuccessfulInvite = (values: SiteUserInvitationRequest) => {
		setInvitedUsername(`${values.firstName} ${values.lastName}`);
		setInvitedEmail(values.invitedEmailAddress);
		setInviteModalOpen(false);
		setConfirmModalOpen(true);
	};

	const handleClick = (user: Emblem | undefined) => {
		setSelectedUser(user);
	};

	const handleStatusFilterChange = (event) => {
		setShowDeactivated(event.target.checked ? true : false);
	};

	const { siteUserEmblems, loading, loadMore, canLoadMore, isRefetching } =
		useSiteUserEmblemsQuery(25, '', SortDirection.Ascending, searchFor);

	// Filter out inactive users if the switch is off.
	const filteredUsers = siteUserEmblems.filter((u) => {
		if (showDeactivated) return true;
		return u.deactivated == false;
	});

	return (
		<>
			<PageTitle title="Site Users" />
			<PageContent>
				<Stack direction="row" spacing={4} height="100%">
					<Stack spacing={2} width={350}>
						<Stack direction="row" justifyContent="space-between" alignItems="center">
							<Typography variant="h2">Site Users</Typography>
							<Stack direction="row" alignItems="center" spacing={0.5}>
								<FormControlLabel
									labelPlacement="start"
									control={
										<Switch
											checked={showDeactivated}
											onChange={handleStatusFilterChange}
										/>
									}
									label={<Typography variant="body1">Show Inactive</Typography>}
								/>
								{hasPermission(Permission.Site_Inv_C) && (
									<Tooltip title="Invite Site User">
										<IconButton
											edge="end"
											size="small"
											onClick={() => setInviteModalOpen(true)}>
											<AddOutlined />
										</IconButton>
									</Tooltip>
								)}
							</Stack>
						</Stack>
						<Search onChange={(str) => setSearchFor(str)} placeholder="Search" />
						<List component={Stack} spacing={2} overflow="auto" sx={{ px: '3px' }}>
							{!loading ? (
								filteredUsers.length === 0 ? (
									<Typography variant="body1">
										No results matched the search criteria. Try again.
									</Typography>
								) : (
									<InfiniteScroll
										loadMore={loadMore}
										threshold={10}
										canLoadMore={canLoadMore}>
										<List disablePadding component={Stack} spacing={1}>
											{filteredUsers.map((emblem, i) => (
												<SiteUserListPaneItem
													key={i}
													onClick={handleClick}
													selected={selected?.id === emblem.id}
													emblem={emblem}
												/>
											))}
											{isRefetching && (
												<ListItem>
													<Stack width="100%" alignItems="center" py={1}>
														<CircularProgress size={30} />
													</Stack>
												</ListItem>
											)}
										</List>
									</InfiniteScroll>
								)
							) : (
								<SkeletonList />
							)}
						</List>
					</Stack>
					<Box flex={1} overflow="auto">
						{selected && selected.id && selected.extraData?.siteUserId ? (
							// Using key here forces the component to drop when the id changes.
							// ! Do not remove.
							<SiteUserAccountPane
								key={selected.id}
								userId={selected.id}
								siteUserId={selected.extraData?.siteUserId}
							/>
						) : (
							<Stack
								height="100%"
								component={Paper}
								spacing={1}
								justifyContent="center"
								alignItems="center">
								<EmptyStateAvatar
									icon={<ManageSearchOutlined />}
									avatarProps={{ bgcolor: 'primary.50' }}
									iconProps={{ color: 'primary.500' }}
								/>
								<Typography variant="h5">
									Select a site user on the left to view their account
									information.
								</Typography>
							</Stack>
						)}
					</Box>
				</Stack>
			</PageContent>
			{/** invite user modal */}
			<ModalOrDrawer open={inviteModalOpen}>
				<InvitationForm
					onClose={() => setInviteModalOpen(false)}
					onSuccessfulInvite={onSuccessfulInvite}
				/>
			</ModalOrDrawer>
			{/** invite confirmation modal */}
			<ModalOrDrawer open={confirmModalOpen}>
				<ConfirmationModalContent
					graphic={
						<Box
							component="img"
							src="/img/InviteConfirm.svg"
							height={120}
							width={120}
						/>
					}
					title="Invite Site User"
					primaryText={`Invitation Sent to ${invitedUsername}`}
					secondaryText={`The invite was sent to ${invitedEmail}.`}
					confirmText="Invite Another User"
					cancelText="Close"
					onSubmit={() => setInviteModalOpen(true)}
					onClose={() => setConfirmModalOpen(false)}
				/>
			</ModalOrDrawer>
		</>
	);
};

/**
 * SiteUserAccountPane - This just lists off all the account settings for a
 * provided user Id and site user Id.
 *
 * @param {{ userId: string; siteUserId: string }} { userId, siteUserId }
 */
const SiteUserAccountPane = ({ userId, siteUserId }: { userId: string; siteUserId: string }) => (
	<Stack spacing={2}>
		<PersonalInfo userId={userId} />
		<SiteUserInformation siteUserId={siteUserId} />
		<LocationInformation userId={userId} />
		<EmergencyContact userId={userId} />
	</Stack>
);

/**
 *  Site user row props.
 */
type SiteUserRowProps = {
	selected: boolean;
	emblem: Emblem;
	onClick: (user: Emblem) => void;
};

/**
 * Returns a Site User List Pane Item.
 * @param props
 * @returns
 */
export const SiteUserListPaneItem = (props: SiteUserRowProps) => {
	if (!props.emblem.id) return <></>;

	const inactive = props.emblem.deactivated === true;

	return (
		<ListItem component={Paper} disablePadding>
			<ListItemButton
				onClick={() => {
					return props.onClick(props.emblem);
				}}
				selected={props.selected}>
				<ListItemAvatar>
					<UserEmblemAvatar id={props.emblem.id} />
				</ListItemAvatar>
				<ListItemText
					primary={`${props.emblem.displayName}`}
					secondary={props.emblem.extraData?.siteUserRole}
				/>
				<Box pl={2}>
					<Chip
						size="small"
						label={inactive ? 'Inactive' : 'Active'}
						color={inactive ? 'error' : 'primary'}
					/>
				</Box>
			</ListItemButton>
		</ListItem>
	);
};

/**
 * Returns skeleton list to display while loading results.
 * @returns
 */
const SkeletonList = (): React.JSX.Element => (
	<>
		<Skeleton variant="rectangular" animation="wave" height="4rem" />
		<Skeleton variant="rectangular" animation="wave" height="4rem" />
		<Skeleton variant="rectangular" animation="wave" height="4rem" />
		<Skeleton variant="rectangular" animation="wave" height="4rem" />
	</>
);
