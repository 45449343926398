import {
	AccountTreeOutlined,
	DeleteOutlined,
	EditOutlined,
	MoreHorizOutlined,
} from '@mui/icons-material';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
} from '@mui/material';
import { useOrgId } from 'components/pages/org/outlet';
import { ORGROLES, useOrgRoleDeleteMutation, useOrgRolesQuery } from 'components/pages/org/roles';
import { ConfirmationModalContent } from 'components/ui/modals/confirmation-modal-content';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { PageContent, PageTitle } from 'components/ui/page';
import SortableColumnCell from 'components/ui/sortable-column-cell';
import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PageError } from 'utils/errors';
import { Permission } from 'utils/permissions';
import { DEFAULTPAGESIZES } from 'utils/theme';
import { useAssociateUser } from 'utils/useAssociateUser';
import { useSiteUser } from 'utils/useSiteUser';

type OrgRoleRowProps = {
	id: string;
	orgId: string;
	isAdminRole: boolean;
	name: string;
	category: string;
	associatedContacts: number;
};

/**
 * OrgRoleRow - A single row on the organization roles table.
 *
 * @param {OrgRoleRowProps} props
 * @returns
 */
const OrgRoleRow = ({
	id,
	orgId,
	isAdminRole,
	name,
	category,
	associatedContacts,
}: OrgRoleRowProps) => {
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const { hasPermission: hasAssociateUserPermission } = useAssociateUser(orgId);
	const { hasPermission: hasSiteUserPermission } = useSiteUser();

	const [contextMenuOpen, setContextMenuOpen] = useState(false);
	const buttonRef = useRef<HTMLButtonElement>(null);

	const navigate = useNavigate();

	const { deleteRole } = useOrgRoleDeleteMutation(orgId, {
		refetchQueries: [ORGROLES],
		awaitRefetchQueries: true,
	});

	const onContextMenu = (e) => {
		e.preventDefault();
		setContextMenuOpen(true);
	};

	return (
		<>
			<TableRow onContextMenu={onContextMenu}>
				<TableCell size="small">
					<AccountTreeOutlined fontSize="small" />
				</TableCell>
				<TableCell>{name}</TableCell>
				<TableCell>{category}</TableCell>
				<TableCell>{associatedContacts}</TableCell>
				<TableCell size="small">
					<IconButton onClick={() => setContextMenuOpen(true)} ref={buttonRef}>
						<MoreHorizOutlined />
					</IconButton>
					<Menu
						anchorEl={buttonRef.current}
						open={contextMenuOpen}
						onClose={() => setContextMenuOpen(false)}
						onClick={() => setContextMenuOpen(false)}>
						<MenuItem onClick={() => navigate(`${id}`, { replace: true })}>
							<ListItemIcon>
								<EditOutlined />
							</ListItemIcon>
							<ListItemText>Manage Role</ListItemText>
						</MenuItem>
						{/* Material menu's have a hacky way of ref-ing the first child, this breaks when using a wrapping HOC. Instead, just use the context */}
						{(hasAssociateUserPermission(Permission.Org_Assoc_Roles_D) ||
							hasSiteUserPermission(Permission.Site_OrgRoles_D)) && (
							<MenuItem
								disabled={associatedContacts > 0 || isAdminRole}
								onClick={() => setDeleteModalOpen(true)}>
								<ListItemIcon>
									<DeleteOutlined />
								</ListItemIcon>
								<ListItemText>Delete Role</ListItemText>
							</MenuItem>
						)}
					</Menu>
				</TableCell>
			</TableRow>
			{/** delete modal */}
			<ModalOrDrawer open={deleteModalOpen}>
				<ConfirmationModalContent
					variant="destructive"
					title="Delete Role"
					primaryText="Are you sure?"
					secondaryText={`Do you really want to delete the ${name} role? This process cannot be undone.`}
					onSubmit={async () => await deleteRole(id)}
					onClose={() => setDeleteModalOpen(false)}
					confirmText="Delete Role"
				/>
			</ModalOrDrawer>
		</>
	);
};

/**
 * OrgRoles contains the entire organization roles page.
 *
 * @returns
 */
export const OrgRolesPage = () => {
	const orgId = useOrgId();

	const [page, setPage] = useState(0);
	const [isAsc, setIsAsc] = useState<boolean>(true);
	const [sortBy, setSortBy] = useState<string>('Name');
	const [pageSize, setPageSize] = useState<number>(DEFAULTPAGESIZES[0]);
	const { hasPermission: hasAssociateUserPermission } = useAssociateUser(orgId);
	const { hasPermission: hasSiteUserPermission } = useSiteUser();

	const canCreate =
		hasAssociateUserPermission(Permission.Org_Assoc_Roles_C) ||
		hasSiteUserPermission(Permission.Site_OrgRoles_C);

	const { totalCount, roles, loading, refetch, error } = useOrgRolesQuery(
		orgId,
		pageSize,
		page,
		sortBy,
		isAsc ? 'Ascending' : 'Descending'
	);

	if (error) throw PageError;

	/**
	 * On page changed.
	 *
	 * @param {number} p
	 */
	const handlePageChange = (_: unknown, p: number): void => {
		setPage(p);
	};

	/**
	 * On page size changed.
	 *
	 * @param {React.ChangeEvent<{ value: unknown; }>} e
	 */
	const handlePageSizeChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	): void => {
		const value = parseInt(e.target.value);
		setPageSize(value);
		setPage(0);
		refetch();
	};

	/**
	 * Handle column sorting pressed.
	 *
	 * @param {string} dataKey
	 */
	const handleSortClick = (dataKey: string): void => {
		if (dataKey === sortBy) {
			setIsAsc(!isAsc);
		} else {
			setSortBy(dataKey);
		}
	};

	return (
		<>
			<PageTitle title="Organization Roles" />
			<PageContent>
				<Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
					<CardHeader
						title="Organization Roles"
						action={
							canCreate && (
								<Button
									component={Link}
									to={`create`}
									variant="contained"
									color="primary">
									Create Role
								</Button>
							)
						}
					/>
					<CardContent
						sx={{
							flex: '1 1 auto',
							overflow: 'hidden',
						}}>
						<TableContainer sx={{ maxHeight: '100%' }}>
							<Table stickyHeader>
								<TableHead>
									<TableRow>
										<TableCell size="small" style={{ width: '4%' }}></TableCell>
										<SortableColumnCell
											style={{ width: '20%' }}
											column="Name"
											onSort={handleSortClick}
											active={'Name' === sortBy}
											direction={isAsc ? 'asc' : 'desc'}>
											Role Name
										</SortableColumnCell>
										<SortableColumnCell
											style={{ width: '20%' }}
											column="Category.Name"
											onSort={handleSortClick}
											active={'Category.Name' === sortBy}
											direction={isAsc ? 'asc' : 'desc'}>
											Category
										</SortableColumnCell>
										<SortableColumnCell
											style={{ width: '20%' }}
											column="associateCount"
											onSort={handleSortClick}
											active={'associateCount' === sortBy}
											direction={isAsc ? 'asc' : 'desc'}>
											Number of Associates
										</SortableColumnCell>
										<TableCell
											size="small"
											style={{ width: '10%' }}></TableCell>
									</TableRow>
								</TableHead>
								{loading ? (
									<SkeletonRows rows={pageSize} />
								) : (
									<TableBody>
										{roles.map((row, i) => (
											<OrgRoleRow key={i} orgId={orgId} {...row} />
										))}
									</TableBody>
								)}
							</Table>
						</TableContainer>
					</CardContent>
					<CardActions disableSpacing sx={{ justifyContent: 'flex-end' }}>
						<TablePagination
							component={Stack}
							direction="row"
							justifyContent="flex-end"
							alignItems="center"
							count={totalCount}
							page={page}
							rowsPerPage={pageSize}
							onPageChange={handlePageChange}
							onRowsPerPageChange={handlePageSizeChange}
						/>
					</CardActions>
				</Card>
			</PageContent>
		</>
	);
};

type SkeletonProps = {
	rows: number;
};

// Will generate however many placeholders depending on page size
// Actual number of rows may be fewer
const SkeletonRows = (props: SkeletonProps): React.JSX.Element => (
	<TableBody>
		{Array(props.rows)
			.fill(null)
			.map((_, i) => {
				return (
					<TableRow key={i}>
						<TableCell colSpan={5}>
							<Skeleton animation="wave" height={'2em'} />
						</TableCell>
					</TableRow>
				);
			})}
	</TableBody>
);
