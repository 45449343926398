import { EditOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
	IconButton,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import { Alert } from 'components/ui/alert';
import { ConfirmationModalContent } from 'components/ui/modals/confirmation-modal-content';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { Formik, FormikProps } from 'formik';
import { useState } from 'react';
import { useCancellablePromise } from 'utils/utils';
import PersonalInfoEdit from './personal-info-edit';
import usePersonalInfoForm, {
	PersonalInformationValues,
	useCanEditPersonalInformation,
} from './personal-info-hooks';
import PersonalInfoRead from './personal-info-read';

export type PersonalInfoProps = { userId: string };

/**
 * PersonalInfo is the account settings personal information card.
 *
 * @param {*} { userId }
 * @return {*}
 */
export const PersonalInfo = ({ userId }: PersonalInfoProps): React.JSX.Element => {
	const {
		isEditing,
		initialValues,
		onSubmit,
		onEdit,
		onCancel,
		account,
		loading,
		error,
		validationSchema,
	} = usePersonalInfoForm(userId);
	const { canEdit } = useCanEditPersonalInformation(account);
	const { cancellablePromise } = useCancellablePromise();
	const handleSubmit = (values: PersonalInformationValues) => {
		return cancellablePromise(onSubmit(values)).promise;
	};

	const [changeHandleModalOpen, setChangeHandleModalOpen] = useState(false);

	return (
		<Formik<PersonalInformationValues>
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
			enableReinitialize>
			{(props: FormikProps<PersonalInformationValues>) => (
				<Card>
					<CardHeader
						title={
							<Typography variant="h2" height="2rem">
								Personal Information
							</Typography>
						}
						action={
							!isEditing ? (
								<>
									{canEdit && (
										<IconButton
											edge="end"
											onClick={onEdit}
											data-test="edit-btn">
											<EditOutlined />
										</IconButton>
									)}
								</>
							) : (
								<Stack direction="row" spacing={1}>
									<Button
										variant="outlined"
										onClick={() => {
											props.resetForm();
											onCancel();
										}}
										data-test="cancel-btn">
										Cancel
									</Button>
									<LoadingButton
										disabled={
											!props.isValid || !props.dirty || props.isSubmitting
										}
										color="primary"
										onClick={() => {
											if (props.values.handle !== initialValues.handle) {
												setChangeHandleModalOpen(true);
											} else {
												props.submitForm();
											}
										}}
										loading={props.isSubmitting}
										variant="contained"
										data-test="save-btn">
										Save
									</LoadingButton>
								</Stack>
							)
						}
					/>
					<Divider />
					<CardContent>
						<Stack pt={1} spacing={1.5}>
							{isEditing && <Alert error={error} />}
							{loading || !account ? (
								<PersonalInfoSkeleton />
							) : isEditing ? (
								<PersonalInfoEdit account={account} />
							) : (
								<PersonalInfoRead account={account} />
							)}
						</Stack>
					</CardContent>
					{/** change handle modal */}
					<ModalOrDrawer open={changeHandleModalOpen}>
						<ConfirmationModalContent
							variant="destructive"
							primaryText="Change Handle?"
							secondaryText="Links to this handle on external sites will not automatically update."
							confirmText="Continue Anyways"
							onSubmit={props.submitForm}
							onClose={() => setChangeHandleModalOpen(false)}
						/>
					</ModalOrDrawer>
				</Card>
			)}
		</Formik>
	);
};
/**
 * PersonalInfoSkeleton - Loading Skeleton for personal information.
 *
 * @return {*}
 */
const PersonalInfoSkeleton = () => (
	<Grid container alignItems="stretch">
		<Grid item sm={4} md={4}>
			<Skeleton height="10rem" width="10rem" sx={{ margin: 'auto' }} variant="circular" />
		</Grid>
		<Grid item sm={8} md={8}>
			<Stack>
				<Skeleton height="2.5rem" width="50%" />
				<Skeleton height="2.5rem" width="50%" />
				<Skeleton height="2.5rem" width="50%" />
				<Skeleton height="2.5rem" width="50%" />
			</Stack>
		</Grid>
	</Grid>
);
