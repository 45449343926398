import {
	AddOutlined,
	DeleteOutlined,
	DraftsOutlined,
	EditOutlined,
	InboxOutlined,
	InfoOutlined,
	MoreHorizRounded,
	SettingsOutlined,
	StarOutline,
	TaskAltOutlined,
} from '@mui/icons-material';
import {
	Alert,
	AlertTitle,
	Autocomplete,
	Avatar,
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Checkbox,
	Chip,
	CircularProgress,
	Divider,
	Grid,
	IconButton,
	Link,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Paper,
	Stack,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Tabs,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { Loading } from 'components/ui/loading';
// import { NavList, NavListItem } from 'components/ui/NavList';
import { PageContent, PageTitle } from 'components/ui/page';
import { useToast } from 'components/ui/toast';
import * as React from 'react';
import { useState } from 'react';

import { UserEmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { ConfirmationModalContent } from 'components/ui/modals/confirmation-modal-content';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { NavList, NavListItem } from 'components/ui/nav-list';
import { useSession } from 'utils/session';
/**
 * Currently this is the homepage.
 */
export const ThemePage: React.FC = () => {
	return (
		<PageContent>
			<PageTitle title="Site Theme Test" />
			<Typography variant="h1" gutterBottom>
				Developer Theme Test
			</Typography>
			<Stack spacing={2}>
				<Card>
					<CardHeader title="Typography" />
					<CardContent>
						<Card variant="outlined">
							<CardContent>
								<Stack>
									<Typography variant="h1">Heading 1 - 1.75 rem</Typography>
									<Typography variant="h2">Heading 2 - 1.5 rem</Typography>
									<Typography variant="h3">Heading 3 - 1.25 rem</Typography>
									<Typography variant="h4">Heading 4 - 1.125 rem</Typography>
									<Typography variant="h5">Heading 5 - 1 rem</Typography>
									<Typography variant="h6">Heading 6 - 0.875 rem</Typography>
									<Typography variant="subtitle1">Subtitle 1 - 1 rem</Typography>
									<Typography variant="subtitle2">
										Subtitle 2 - 0.875 rem
									</Typography>
									<Typography variant="body1">Body 1 - 1 rem</Typography>
									<Typography variant="body2">Body 2 - 0.875 rem</Typography>
									<Typography variant="button">
										Button Text - 0.875 rem
									</Typography>
									<Typography variant="caption">
										Caption Text - 0.75 rem
									</Typography>
									<Typography variant="overline">
										Overline Text - 0.75 rem
									</Typography>
									<Link variant="body1">Link 1 - 1 rem</Link>
									<Link variant="body2">Link 2 - 0.875 rem</Link>
								</Stack>
							</CardContent>
						</Card>
					</CardContent>
				</Card>
				<Grid container alignItems="center">
					<Grid item xs={12}>
						<Card>
							<CardHeader
								avatar={<Avatar>AV</Avatar>}
								title="Header - h2"
								subheader="Subheader - subtitle2"
								action={
									<IconButton edge="end">
										<AddOutlined />
									</IconButton>
								}
							/>
							<CardContent>
								<Box width="100%" bgcolor="neutral.200" textAlign="center">
									Card Content - body1
								</Box>
							</CardContent>
							<CardActions>
								<IconButton>
									<EditOutlined />
								</IconButton>
								<IconButton>
									<DeleteOutlined />
								</IconButton>
								<IconButton>
									<StarOutline />
								</IconButton>
							</CardActions>
						</Card>
					</Grid>
				</Grid>
				<Card>
					<CardHeader title="Fields" />
					<CardContent>
						<Grid container spacing={2} alignItems="center">
							<Grid item xs={12} sm={2}>
								<TextField label="TextField" placeholder="TextField" />
							</Grid>
							<Grid item xs={12} sm={2}>
								<TextField
									label="SelectField"
									placeholder="SelectField"
									select
									fullWidth>
									<MenuItem value="" />
									{['Bear', 'Dog', 'Cat', 'Bird', 'Rabbit'].map((l) => (
										<MenuItem key={l} value={l}>
											{l}
										</MenuItem>
									))}
								</TextField>
							</Grid>
							<Grid item xs={12} sm={3}>
								<Checkbox />
								<Checkbox indeterminate />
								<Checkbox checked disabled />
								<Checkbox disabled />
							</Grid>
							<Grid item xs={12} sm={4}>
								<Autocomplete
									multiple
									options={['Bear', 'Dog', 'Cat', 'Bird', 'Rabbit']}
									disableClearable
									filterSelectedOptions
									renderInput={(params) => (
										<TextField
											{...params}
											label="Autocomplete Chips"
											variant="outlined"
										/>
									)}
									openOnFocus
									renderTags={(value, getTagProps) =>
										value.map((option, index) => {
											// separate out the key to satisfy the linter
											const { key, ...tagProps } = getTagProps({ index });
											return (
												<Chip
													key={key}
													variant="outlined"
													label={option}
													{...tagProps}
												/>
											);
										})
									}
								/>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
				<Card>
					<CardHeader title="Buttons" />
					<CardContent>
						<Grid container spacing={2} alignItems="center">
							<Grid item>
								<Button>Button</Button>
							</Grid>
							<Grid item>
								<Button variant="outlined">Outlined</Button>
							</Grid>
							<Grid item>
								<Button variant="contained" color="primary">
									Primary
								</Button>
							</Grid>
							<Grid item>
								<Button variant="contained" color="secondary">
									Secondary
								</Button>
							</Grid>
							<Grid item>
								<IconButton>
									<EditOutlined />
								</IconButton>
							</Grid>
							<Grid item>
								<IconButton>
									<EditOutlined fontSize="small" />
								</IconButton>
							</Grid>
							<Grid item>
								<Link href="#">Hyperlink</Link>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
				<ComponentsSection />
				<Grid container spacing={1} sx={{ marginLeft: '-8px !important' }}>
					<Grid item xs={12} sm={6} md={3}>
						<Typography variant="h3" gutterBottom>
							Side Navigation
						</Typography>
						<NavList title="Nav Title">
							<NavListItem text="First Page" to={`#`} />
							<NavListItem text="Second Page" to={`#page2`} />
						</NavList>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="h3" gutterBottom>
							Selectable List
						</Typography>
						<List component={Stack} spacing={2}>
							<ListItem component={Paper} disablePadding>
								<ListItemButton>
									<ListItemAvatar>
										<Avatar src="http://placekitten.com/300/200"></Avatar>
									</ListItemAvatar>
									<ListItemText
										primary="Primary Text"
										secondary="Secondary text"
									/>
								</ListItemButton>
							</ListItem>
							<ListItem component={Paper} disablePadding>
								<ListItemButton selected>
									<ListItemAvatar>
										<Avatar src="http://placekitten.com/300/200"></Avatar>
									</ListItemAvatar>
									<ListItemText
										primary="Primary Text"
										secondary="Secondary text"
									/>
								</ListItemButton>
							</ListItem>
							<ListItem component={Paper} disablePadding>
								<ListItemButton>
									<ListItemAvatar>
										<Avatar src="http://placekitten.com/300/200"></Avatar>
									</ListItemAvatar>
									<ListItemText
										primary="Primary Text"
										secondary="Secondary text"
									/>
								</ListItemButton>
							</ListItem>
						</List>
					</Grid>
					<Grid item container xs={12} sm={6} md={9} spacing={1}>
						<Grid item xs={12}>
							<Typography variant="h3" gutterBottom>
								Elevations
							</Typography>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Paper
								variant="outlined"
								sx={{ padding: '1rem', height: '100px', width: '100%' }}>
								Elevation 0 (outlined variant)
							</Paper>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Paper
								elevation={1}
								sx={{ padding: '1rem', height: '100px', width: '100%' }}>
								Elevation 1
							</Paper>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Paper
								elevation={2}
								sx={{ padding: '1rem', height: '100px', width: '100%' }}>
								Elevation 2
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</Stack>
		</PageContent>
	);
};

const ComponentsSection = () => {
	const { user } = useSession();
	const toast = useToast();
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const [selectedTab, setSelectedTab] = useState<string>('Profile');
	const rows = [
		{
			name: 'Frozen yoghurt',
			calories: 159,
			fat: 6.0,
			carbs: 24,
			protein: 4.0,
		},
		{
			name: 'Ice cream sandwich',
			calories: 237,
			fat: 9.0,
			carbs: 37,
			protein: 4.3,
		},
		{ name: 'Eclair', calories: 262, fat: 16.0, carbs: 24, protein: 6.0 },
		{ name: 'Cupcake', calories: 305, fat: 3.7, carbs: 67, protein: 4.3 },
		{ name: 'Gingerbread', calories: 356, fat: 16.0, carbs: 49, protein: 3.9 },
	];
	return (
		<Card>
			<CardHeader title="Components" />
			<CardContent>
				<Typography variant="h3" gutterBottom>
					Simple
				</Typography>
				<Grid container spacing={2} alignItems="flex-start">
					<Grid item xs={3} sm="auto">
						<Tooltip title="Tooltip Text" arrow>
							<IconButton>
								<InfoOutlined fontSize="small" />
							</IconButton>
						</Tooltip>
					</Grid>
					<Grid item xs={3} sm="auto">
						<IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
							<MoreHorizRounded />
						</IconButton>
						<Menu
							anchorEl={anchorEl}
							open={Boolean(anchorEl)}
							onClose={() => setAnchorEl(null)}>
							<MenuItem>
								<ListItemIcon>
									<SettingsOutlined />
								</ListItemIcon>
								<ListItemText>Settings</ListItemText>
							</MenuItem>
							<MenuItem>
								<ListItemIcon>
									<EditOutlined />
								</ListItemIcon>
								<ListItemText>Edit</ListItemText>
							</MenuItem>
							<MenuItem>
								<ListItemIcon>
									<DeleteOutlined />
								</ListItemIcon>
								<ListItemText>Delete</ListItemText>
							</MenuItem>
						</Menu>
					</Grid>
					<Grid item sm="auto">
						<UserEmblemAvatar id={user.userId} />
					</Grid>
					<Grid item sm="auto">
						<Chip label="Default" />
					</Grid>
					<Grid item sm="auto">
						<Chip icon={<SettingsOutlined />} label="Default with icon" />
					</Grid>
					<Grid item sm="auto">
						<Chip size="small" label="Small" />
					</Grid>
					<Grid item sm="auto">
						<Chip size="small" icon={<SettingsOutlined />} label="Small with icon" />
					</Grid>
					<Grid item sm="auto">
						<Chip
							size="small"
							color="primary"
							icon={<SettingsOutlined />}
							label="Primary"
						/>
					</Grid>
					<Grid item sm="auto">
						<Chip
							size="small"
							color="error"
							icon={<SettingsOutlined />}
							label="Error"
						/>
					</Grid>
					<Grid item sm="auto">
						<Chip
							size="small"
							color="warning"
							icon={<SettingsOutlined />}
							label="Warning"
						/>
					</Grid>
					<Grid item sm="auto">
						<Tabs
							indicatorColor="primary"
							value={selectedTab}
							onChange={(e, v) => setSelectedTab(v)}>
							<Tab label="Profile" value="Profile" />
							<Tab label="Activity" value="Activity" />
							<Tab label="Tab 3" value="Tab3" />
						</Tabs>
					</Grid>

					<Grid item sm="auto">
						<CircularProgress />
					</Grid>
					<Grid item sm="auto">
						<Loading />
					</Grid>

					<Grid item xs={12}>
						<Typography variant="h3" gutterBottom>
							Toast
						</Typography>
						<Stack direction="row" spacing={1}>
							<Button
								variant="outlined"
								color="info"
								onClick={() =>
									toast.push('The quick brown fox jumped over the lazy dog.', {
										variant: 'info',
									})
								}>
								Information
							</Button>
							<Button
								variant="outlined"
								color="success"
								onClick={() =>
									toast.push('The quick brown fox jumped over the lazy dog.', {
										variant: 'success',
									})
								}>
								Success
							</Button>
							<Button
								variant="outlined"
								color="warning"
								onClick={() =>
									toast.push('The quick brown fox jumped over the lazy dog.', {
										variant: 'warning',
									})
								}>
								Warning
							</Button>
							<Button
								variant="outlined"
								color="error"
								onClick={() =>
									toast.push('The quick brown fox jumped over the lazy dog.', {
										variant: 'error',
									})
								}>
								Error
							</Button>
							<Button variant="outlined" onClick={() => setConfirmModalOpen(true)}>
								Confirmation
							</Button>
						</Stack>
					</Grid>

					<Grid item xs={12}>
						<Typography variant="h3" gutterBottom>
							Alerts
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Stack spacing={1}>
							<Typography variant="h4">Base</Typography>
							<Alert severity="error">
								The quick brown fox jumped over the lazy dog. - body2
							</Alert>
							<Alert severity="info">
								The quick brown fox jumped over the lazy dog. - body2
							</Alert>
							<Alert icon={<TaskAltOutlined />} severity="success">
								The quick brown fox jumped over the lazy dog. - body2
							</Alert>
							<Alert severity="warning">
								The quick brown fox jumped over the lazy dog. - body2
							</Alert>
						</Stack>
					</Grid>
					<Grid item xs={4}>
						<Stack spacing={1}>
							<Typography variant="h4">Outlined</Typography>
							<Alert variant="outlined" severity="error">
								This is an error alert — check it out! - body2
							</Alert>
							<Alert variant="outlined" severity="info">
								This is an info alert — check it out! - body2
							</Alert>
							<Alert icon={<TaskAltOutlined />} variant="outlined" severity="success">
								This is a success alert — check it out! - body2
							</Alert>
							<Alert variant="outlined" severity="warning">
								This is a warning alert — check it out! - body2
							</Alert>
						</Stack>
					</Grid>
					<Grid item xs={4}>
						<Stack spacing={1}>
							<Typography variant="h4" gutterBottom>
								Alert Titles
							</Typography>
							<Alert severity="error">
								<AlertTitle>This is an alert title - h5</AlertTitle>
								The quick brown fox jumped over the lazy dog. - body2
							</Alert>
							<Alert severity="info">
								<AlertTitle>This is an alert title - h5</AlertTitle>
								The quick brown fox jumped over the lazy dog. - body2
							</Alert>
							<Alert icon={<TaskAltOutlined />} severity="success">
								<AlertTitle>This is an alert title - h5</AlertTitle>
								The quick brown fox jumped over the lazy dog. - body2
							</Alert>
							<Alert severity="warning">
								<AlertTitle>This is an alert title - h5</AlertTitle>
								The quick brown fox jumped over the lazy dog. - body2
							</Alert>
						</Stack>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h3" gutterBottom>
							Tables
						</Typography>
						<TableContainer>
							<Table stickyHeader>
								<TableHead>
									<TableRow>
										<TableCell>Dessert (100g serving)</TableCell>
										<TableCell align="right">Calories</TableCell>
										<TableCell align="right">Fat&nbsp;(g)</TableCell>
										<TableCell align="right">Carbs&nbsp;(g)</TableCell>
										<TableCell align="right">Protein&nbsp;(g)</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{rows.map((row) => (
										<TableRow key={row.name}>
											<TableCell component="th" scope="row">
												{row.name}
											</TableCell>
											<TableCell align="right">{row.calories}</TableCell>
											<TableCell align="right">{row.fat}</TableCell>
											<TableCell align="right">{row.carbs}</TableCell>
											<TableCell align="right">{row.protein}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							component={Stack}
							direction="row"
							justifyContent="flex-end"
							alignItems="center"
							count={1}
							page={0}
							rowsPerPage={25}
							onPageChange={() => console.log('page')}
							onRowsPerPageChange={() => console.log('rows per page change')}
						/>
					</Grid>
				</Grid>
				<Grid item container xs={12} spacing={1}>
					<Grid item xs={12}>
						<Typography variant="h3" gutterBottom>
							Lists
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={2}>
						<Typography variant="h4" gutterBottom>
							Basic
						</Typography>
						<List>
							<ListItem disablePadding>
								<ListItemButton>
									<ListItemText primary="Primary Text - body1" />
								</ListItemButton>
							</ListItem>
							<ListItem disablePadding>
								<ListItemButton>
									<ListItemText primary="Primary Text - body1" />
								</ListItemButton>
							</ListItem>
							<ListItem disablePadding>
								<ListItemButton>
									<ListItemText primary="Primary Text - body1" />
								</ListItemButton>
							</ListItem>
						</List>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="h4" gutterBottom>
							Secondary Text
						</Typography>
						<List>
							<ListItem disablePadding>
								<ListItemButton>
									<ListItemIcon>
										<InboxOutlined />
									</ListItemIcon>
									<ListItemText
										primary="Primary Text - h4"
										secondary="Secondary text - subtitle1"
									/>
								</ListItemButton>
							</ListItem>
							<ListItem disablePadding>
								<ListItemButton>
									<ListItemIcon>
										<DraftsOutlined />
									</ListItemIcon>
									<ListItemText
										primary="Primary Text - h4"
										secondary="Secondary text - subtitle1"
									/>
								</ListItemButton>
							</ListItem>
							<ListItem disablePadding>
								<ListItemButton>
									<ListItemIcon>
										<SettingsOutlined />
									</ListItemIcon>
									<ListItemText
										primary="Primary Text - h4"
										secondary="Secondary text - subtitle1"
									/>
								</ListItemButton>
							</ListItem>
						</List>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={5}>
						<Typography variant="h4" gutterBottom>
							Avatars and Actions
						</Typography>
						<List>
							<ListItem
								disablePadding
								secondaryAction={
									<Button variant="contained" color="primary">
										Button
									</Button>
								}>
								<ListItemAvatar>
									<Avatar src="http://placekitten.com/300/200"></Avatar>
								</ListItemAvatar>
								<ListItemText
									primary="Primary Text - h4"
									secondary="Secondary text - subtitle1"
								/>
							</ListItem>
							<Divider />
							<ListItem disablePadding>
								<ListItemAvatar>
									<Avatar src="http://placekitten.com/320/200"></Avatar>
								</ListItemAvatar>
								<ListItemText
									primary="Primary Text - h4"
									secondary="Secondary text - subtitle1"
								/>
							</ListItem>
							<ListItem
								disablePadding
								secondaryAction={
									<IconButton edge="end">
										<MoreHorizRounded />
									</IconButton>
								}>
								<ListItemAvatar>
									<Avatar src="http://placekitten.com/330/200"></Avatar>
								</ListItemAvatar>
								<ListItemText
									primary="Primary Text - h4"
									secondary="Secondary text - subtitle1"
								/>
							</ListItem>
							<ListItem
								disablePadding
								secondaryAction={
									<IconButton edge="end">
										<DeleteOutlined />
									</IconButton>
								}>
								<ListItemAvatar>
									<Avatar src="http://placekitten.com/310/200"></Avatar>
								</ListItemAvatar>
								<ListItemText
									primary="Primary Text - h4"
									secondaryTypographyProps={{
										component: 'div',
									}}
									secondary={
										<Stack>
											<span>Secondary Text Line 1 - subtitle1</span>
											<span>Secondary Text Line 2 - subtitle1</span>
										</Stack>
									}
								/>
							</ListItem>
						</List>
					</Grid>
				</Grid>
			</CardContent>
			{/** confirmation modal */}
			<ModalOrDrawer open={confirmModalOpen}>
				<ConfirmationModalContent
					variant="activate"
					title="Confirmation Modal"
					primaryText="Are you sure?"
					secondaryText="The quick brown fox jumped over the lazy dog."
					onSubmit={async () => {
						return new Promise((resolve) => setTimeout(resolve, 5000, true));
					}}
					onClose={() => setConfirmModalOpen(false)}
				/>
			</ModalOrDrawer>
		</Card>
	);
};
