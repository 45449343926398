import { InfoOutlined, OpenInNew } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Card, Divider, Skeleton, Stack, Switch, Tooltip, Typography } from '@mui/material';
import { BillingInfoResponse } from 'middleware-types';
import { Permission } from 'utils/permissions';
import { useIsSiteUser } from 'utils/useIsSiteUser';
import { useSiteUser } from 'utils/useSiteUser';
import { formatUSD } from 'utils/utils';
import { useBillingDashboardUrl } from './hooks/use-billing-dashboard-url';
import { useBillingPortalUrl } from './hooks/use-billing-portal-url';
import { useOrgBillingOptions } from './hooks/use-org-billing-options';
import { useUpdateOrgBillingOptions } from './hooks/use-update-org-billing-options';

interface PlanInformationCardProps {
	billingInfo: BillingInfoResponse;
}

export const PlanInformationCard = ({ billingInfo }: PlanInformationCardProps) => {
	const isSiteUser = useIsSiteUser();
	const { onClick: onPortalClick, loading: portalUrlLoading } = useBillingPortalUrl();
	const { onClick: onDashboardClick, loading: dashboardUrlLoading } = useBillingDashboardUrl();

	const {
		planInformation,
		canGoToPortal,
		inheritedFromRootOrganization: isChildOrg,
	} = billingInfo;

	return (
		<Card>
			<Box px={2.5} py={2}>
				<Typography variant="h2">Plan Information</Typography>
			</Box>
			<Divider />
			<Box p={2.5}>
				<Stack
					direction={{ xs: 'column', sm: 'row' }}
					alignItems={{ xs: 'stretch', sm: 'flex-end' }}
					justifyContent="space-between"
					spacing={2}>
					{isChildOrg ? (
						<Typography
							variant="body1"
							color="GrayText"
							alignSelf={{ xs: 'flex-start', sm: 'center' }}>
							This organization&apos;s plan must be managed through its parent
							organization.
						</Typography>
					) : planInformation ? (
						<Stack spacing={0.5}>
							<Typography variant="h3">{planInformation.planName}</Typography>
							<Typography variant="body1">
								{formatUSD(planInformation.planPrice)}/
								{planInformation.planInterval}
							</Typography>
						</Stack>
					) : (
						<Typography
							variant="body1"
							color="GrayText"
							alignSelf={{ xs: 'flex-start', sm: 'center' }}>
							No plan information
						</Typography>
					)}
					<Stack direction={{ xs: 'column', sm: 'row' }} spacing={1.5}>
						{canGoToPortal && (
							<LoadingButton
								variant="outlined"
								startIcon={<OpenInNew />}
								loading={portalUrlLoading}
								onClick={() => onPortalClick()}>
								Manage Payment & Invoices
							</LoadingButton>
						)}
						{isSiteUser && (
							<LoadingButton
								variant="outlined"
								startIcon={<OpenInNew />}
								loading={dashboardUrlLoading}
								onClick={() => onDashboardClick()}>
								Customer Dashboard
							</LoadingButton>
						)}
					</Stack>
				</Stack>
			</Box>
			{isSiteUser && !isChildOrg && <DraftInvoicingSection />}
		</Card>
	);
};

const DraftInvoicingSection = () => {
	const { options, loading: queryLoading } = useOrgBillingOptions();
	const { updateOrgBillingOptions, loading: updateLoading } = useUpdateOrgBillingOptions();
	const loading = queryLoading || updateLoading;

	const { hasPermission } = useSiteUser();
	const canUpdate = hasPermission(Permission.Site_OrgBilling_U);

	if (!options) {
		return (
			<>
				<Divider />
				<Box p={1.5}>
					<Skeleton variant="text" width={100} />
				</Box>
			</>
		);
	}

	return (
		<>
			<Divider />
			<Stack px={1.5} py={1.5} spacing={1}>
				<Stack direction="row" alignItems="center" spacing={1.5}>
					<Switch
						checked={options.notBillable}
						disabled={loading || !canUpdate}
						onChange={(_e, checked) =>
							updateOrgBillingOptions({ ...options, notBillable: checked })
						}
					/>
					<Typography variant="body1">Not billable</Typography>
				</Stack>
				<Stack direction="row" alignItems="center" spacing={1.5}>
					<Switch
						checked={options.createInvoicesAsDrafts}
						disabled={loading || !canUpdate || options.notBillable}
						onChange={(_e, checked) =>
							updateOrgBillingOptions({ ...options, createInvoicesAsDrafts: checked })
						}
					/>
					<Typography variant="body1">Create monthly invoices as drafts</Typography>
					<Tooltip title="When automatic draft creation is off, customers will automatically be invoiced and charged through Stripe.">
						<InfoOutlined sx={{ color: 'neutral.500' }} />
					</Tooltip>
				</Stack>
				<Stack direction="row" alignItems="center" spacing={1.5}>
					<Switch
						checked={options.billChildrenForUsage}
						disabled={loading || !canUpdate || options.notBillable}
						onChange={(_e, checked) =>
							updateOrgBillingOptions({ ...options, billChildrenForUsage: checked })
						}
					/>
					<Typography variant="body1">Bill children for usage</Typography>
				</Stack>
				<Stack direction="row" alignItems="center" spacing={1.5}>
					<Switch
						checked={options.chargeAutomatically}
						disabled={loading || !canUpdate || options.notBillable}
						onChange={(_e, checked) =>
							updateOrgBillingOptions({ ...options, chargeAutomatically: checked })
						}
					/>
					<Typography variant="body1">Charge automatically</Typography>
				</Stack>
			</Stack>
		</>
	);
};
