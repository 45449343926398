import { gql, useQuery } from '@apollo/client';
import { Query } from 'middleware-types';
import { PageError } from 'utils/errors';
import { useAnonymousSession, useSession } from './session';
import { Permission } from './permissions';

export const ALL_USER_ORG_PERMISSIONS = gql`
	query allUserOrgPermissions {
		allUserOrgPermissions {
			organizationId
			isOrganizationAdmin
			permissions
		}
	}
`;

/**
 * useAssociateUser() gets the user permissions for an associate.
 * @returns useAssociateUser() hook.
 */
export const useAssociateUser = (
	organizationId: string | undefined
): {
	loading: boolean;
	hasPermission: (permission: string | string[], options?: { all?: boolean }) => boolean;
} => {
	// Used for permissions while outside of an organization (i.e. connection requests);
	const { user } = useAnonymousSession();
	const { data, loading, error } = useQuery<Pick<Query, 'allUserOrgPermissions'>>(
		ALL_USER_ORG_PERMISSIONS,
		{
			fetchPolicy: 'cache-and-network',
			skip: !organizationId || !user || !!user.siteUserId,
		}
	);

	const hasPermission = (permission: string | string[], options?: { all?: boolean }): boolean => {
		// passing an empty array for permissions will always succeed
		if (Array.isArray(permission) && permission.length === 0) return true;
		// fail if the permissions data is not present
		if (!data?.allUserOrgPermissions) return false;
		const currentOrg = data.allUserOrgPermissions.find(
			(x) => x.organizationId === organizationId
		);
		if (currentOrg === undefined) return false;
		// succeed if we are checking for membership
		if (permission === Permission.Org_Member) return true;
		// succeed if the current user is an org admin
		if (currentOrg.isOrganizationAdmin) return true;
		// check the rest of the permissions
		const userPermissions = currentOrg.permissions ?? [];
		if (Array.isArray(permission)) {
			return options?.all
				? permission.every((p) => userPermissions.includes(p))
				: permission.some((p) => userPermissions.includes(p));
		}
		return userPermissions.includes(permission);
	};

	if (error) throw new PageError(error);
	return {
		loading: loading || !data,
		hasPermission,
	};
};
