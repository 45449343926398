import { Close, ErrorOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Divider, IconButton, Stack, Typography } from '@mui/material';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import { FileInstanceInformation } from 'middleware-types';
import { useDeleteFiles } from '../hooks/files/use-delete-file';

interface DeleteFileModalProps {
	files: FileInstanceInformation[];
	onClose: () => void;
	onSubmit?: () => void;
}

export const DeleteFilesModal = ({ files, onClose, onSubmit }: DeleteFileModalProps) => {
	const { deleteFiles, loading } = useDeleteFiles();
	const onClick = () => {
		deleteFiles(
			files.map((f) => f.fileId),
			files[0].folder?.id
		).then((success) => {
			onSubmit && onSubmit();
			if (!success) return;
			onClose();
		});
	};

	return (
		<Stack>
			<Stack
				px={2}
				py={1}
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				spacing={1}>
				<Typography variant="h3">Are you sure?</Typography>
				<IconButton onClick={onClose}>
					<Close />
				</IconButton>
			</Stack>
			<Divider />
			<Stack alignItems="center" textAlign="center" px={10} py={3}>
				<EmptyStateAvatar
					avatarProps={{ bgcolor: 'error.50' }}
					iconProps={{ color: 'error.500' }}
					icon={<ErrorOutlined />}
				/>
				<Typography variant="h1" marginTop={2} sx={{ wordBreak: 'break-word' }}>
					Delete {files.length > 1 ? `${files.length} files` : files[0]?.fileName}?
				</Typography>
				<Typography variant="body1" sx={{ marginTop: '4px' }}>
					{files.length > 1 ? 'These files' : 'This file'} will be deleted from your
					documents. This action cannot be undone.
				</Typography>
			</Stack>
			<Divider />
			<Stack direction="row" justifyContent="flex-end" px={2} py={1.5} spacing={1.5}>
				<Button size="large" variant="outlined" onClick={onClose}>
					Cancel
				</Button>
				<LoadingButton
					size="large"
					variant="contained"
					color="error"
					loading={loading}
					onClick={onClick}>
					Delete
				</LoadingButton>
			</Stack>
		</Stack>
	);
};
