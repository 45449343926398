import { DeleteOutlined, MarkEmailReadOutlined, MoreHoriz } from '@mui/icons-material';
import {
	Badge,
	Box,
	Divider,
	IconButton,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Stack,
	Typography,
} from '@mui/material';
import { formatDistance } from 'date-fns';
import { Notification, NotificationState, NotificationType } from 'middleware-types';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
	useNotificationDismissByGroupingMutation,
	useNotificationDismissByIdMutation,
	useNotificationMarkReadByGrouping,
	useNotificationMarkReadByIdMutation,
} from 'utils/useNotifications';
import { NotificationEmblem } from './notification-emblem';

interface NotificationListItemProps {
	notification: Notification;
	onClose: () => void;
}

export const NotificationListItem = ({ notification, onClose }: NotificationListItemProps) => {
	const buttonRef = useRef<HTMLButtonElement>(null);
	const [contextMenuOpen, setContextMenuOpen] = useState(false);

	const onContextMenu = (e) => {
		e.preventDefault();
		setContextMenuOpen(true);
	};

	const { dismissNotificationById } = useNotificationDismissByIdMutation(notification.id);
	const { dismissNotificationByGrouping } = useNotificationDismissByGroupingMutation(
		notification.relatedEntityId,
		notification.type,
		notification.displayText
	);
	const { readNotificationById } = useNotificationMarkReadByIdMutation(notification.id);
	const { readNotificationByGrouping } = useNotificationMarkReadByGrouping(
		notification.relatedEntityId,
		notification.type,
		notification.displayText,
		notification.id
	);

	const isRead = notification.state === NotificationState.Read;

	const readNotification = () => {
		// If the notification represents multiple unread NotifiedUsers, use mark read by related entity
		if (notification.unreadCount > 1) {
			readNotificationByGrouping();
		} else if (!isRead) {
			readNotificationById();
		}
	};

	const handleRead = () => {
		readNotification();
		onClose();
	};

	const isSystemAnnouncement = notification.type === NotificationType.SystemNotification;
	const highlighted =
		[
			NotificationType.SystemNotification,
			NotificationType.AssociateNotification,
			NotificationType.BillingUpdate,
		].includes(notification.type) && !isRead;

	return (
		<>
			<ListItem disableGutters disablePadding onContextMenu={(e) => onContextMenu(e)}>
				<ListItemButton
					onClick={handleRead}
					component={Link}
					target={notification.associatedUrl ? '_blank' : undefined}
					sx={{
						borderLeft: '6px solid',
						borderColor: highlighted ? 'primary.main' : 'transparent',
						bgcolor: highlighted ? 'primary.light' : undefined,
						paddingLeft: 1.25,
					}}
					to={
						notification.associatedUrl ??
						(isSystemAnnouncement
							? '#'
							: `app/redirect/${notification.relatedEntityType}/${notification.relatedEntityId}`)
					}>
					<Stack width="100%" direction="row" alignItems="center" spacing={2}>
						<Box
							height={8}
							width={8}
							borderRadius="50%"
							bgcolor={isRead ? 'transparent' : 'primary.main'}
						/>
						<NotificationEmblem notification={notification} highlighted={highlighted} />
						<Box flex={1}>
							<Typography variant="h6" color={isRead ? 'neutral.600' : 'primary'}>
								{notification.displayText}
							</Typography>
							<Typography variant="subtitle2">
								{formatDistance(new Date(notification.createdUtc), new Date(), {
									addSuffix: true,
								})}
							</Typography>
						</Box>
						{notification.readCount + notification.unreadCount > 1 && (
							<Stack direction="row" spacing={3}>
								<Badge
									sx={{
										'& .MuiBadge-badge': {
											color: 'black',
											backgroundColor: 'neutral.100',
										},
									}}
									badgeContent={
										notification.readCount >= 1
											? notification.readCount
											: undefined
									}></Badge>
								<Badge
									sx={{
										'& .MuiBadge-badge': {
											color: 'white',
											backgroundColor: 'primary.main',
										},
									}}
									badgeContent={
										notification.unreadCount >= 1
											? notification.unreadCount
											: undefined
									}></Badge>
							</Stack>
						)}
						<IconButton
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
								setContextMenuOpen(true);
							}}
							onMouseDown={(e) => e.stopPropagation()}
							ref={buttonRef}>
							<MoreHoriz />
						</IconButton>
					</Stack>
				</ListItemButton>
			</ListItem>
			<Divider />
			{/** Menu */}
			<Menu
				open={contextMenuOpen}
				anchorEl={buttonRef.current}
				onClose={() => setContextMenuOpen(false)}
				onClick={() => setContextMenuOpen(false)}>
				{!isRead && (
					<MenuItem
						onClick={() => {
							readNotification();
							setContextMenuOpen(false);
						}}>
						<ListItemIcon>
							<MarkEmailReadOutlined />
						</ListItemIcon>
						<ListItemText>Mark as read</ListItemText>
					</MenuItem>
				)}
				<MenuItem
					onClick={() => {
						if (notification.readCount + notification.unreadCount > 1) {
							dismissNotificationByGrouping();
						} else {
							dismissNotificationById();
						}
						setContextMenuOpen(false);
					}}>
					<ListItemIcon>
						<DeleteOutlined />
					</ListItemIcon>
					<ListItemText>Dismiss</ListItemText>
				</MenuItem>
			</Menu>
		</>
	);
};
