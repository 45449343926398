import { Box, Stack, Typography } from '@mui/material';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import { ReactElement } from 'react';

interface ProfileCardEmptyStateProps {
	icon: ReactElement;
	primaryText: string;
	secondaryText: string;
}

export const ProfileCardEmptyState = ({
	icon,
	primaryText,
	secondaryText,
}: ProfileCardEmptyStateProps) => {
	return (
		<Stack p={5} spacing={2} alignItems="center">
			<EmptyStateAvatar icon={icon} />
			<Box textAlign="center">
				<Typography variant="h3">{primaryText}</Typography>
				<Typography variant="body1">{secondaryText}</Typography>
			</Box>
		</Stack>
	);
};
