import {
	Cancel,
	CheckCircle,
	Close,
	DownloadOutlined,
	MoreHoriz,
	RemoveRedEyeOutlined,
} from '@mui/icons-material';
import {
	Box,
	CircularProgress,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Stack,
	Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { UploadState } from 'utils/fileUtils';
import { navy } from 'utils/theme';

/** Props for the document upload spinner. */
export interface DocumentUploadSpinnerProps {
	state: UploadState;
	progress: number;
	size?: string | number;
	showPercent?: boolean;
}

/** Props for the message attachment upload spinner. */
export interface MessageAttachmentUploadSpinnerProps extends DocumentUploadSpinnerProps {
	onAttachmentDownload: (inline: boolean) => void;
	onRemove?: () => void;
}

interface UploadDisplayBaseProps extends DocumentUploadSpinnerProps {
	pendingFragment?: React.ReactElement;
	loadingFragment?: React.ReactElement;
	successFragment?: React.ReactElement;
	errorFragment?: React.ReactElement;
}

const UploadDisplayBase = ({
	state,
	progress,
	pendingFragment,
	loadingFragment,
	successFragment,
	errorFragment,
	size,
	showPercent = false,
}: UploadDisplayBaseProps) => {
	switch (state) {
		case UploadState.Pending:
			return (
				pendingFragment ?? (
					<CircularProgress
						size={size}
						variant="determinate"
						sx={{ display: 'block', color: 'neutral.200' }}
						value={100}
					/>
				)
			);
		case UploadState.Loading: {
			if (loadingFragment) return loadingFragment;

			return (
				<Stack direction="row" alignItems="center" spacing={1}>
					{showPercent && (
						<Typography variant="subtitle2" noWrap>
							{progress.toFixed(0) + '%'}
						</Typography>
					)}
					<Box position="relative">
						<CircularProgress
							value={100}
							variant="determinate"
							size={size}
							sx={{ display: 'block', color: navy[100] }}
						/>
						<CircularProgress
							size={size}
							thickness={4}
							value={progress}
							variant={progress === 100 ? 'indeterminate' : 'determinate'}
							sx={{ display: 'block', position: 'absolute', top: 0, left: 0 }}
						/>
					</Box>
				</Stack>
			);
		}
		case UploadState.Success:
			return successFragment ?? <CheckCircle color="success" />;
		case UploadState.Error:
			return errorFragment ?? <Cancel color="error" />;
		default:
			return <Cancel color="error" />;
	}
};

/** The document upload spinner. */
export const DocumentUploadSpinner = (props: DocumentUploadSpinnerProps) => (
	<UploadDisplayBase {...props} />
);

/** The message attachment upload spinner. */
export const MessageAttachmentUploadSpinner = (props: MessageAttachmentUploadSpinnerProps) => {
	const { onAttachmentDownload: onDownload, onRemove } = props;

	const [anchorEl, setAnchorEl] = useState<Element | null>(null);
	const closeMenu = () => {
		setAnchorEl(null);
	};

	return (
		<div style={{ pointerEvents: 'auto' }}>
			<UploadDisplayBase
				{...props}
				successFragment={
					<IconButton
						onClick={(e) => {
							e.stopPropagation();
							setAnchorEl(e.currentTarget);
						}}
						onMouseDown={(e) => e.stopPropagation()}>
						<MoreHoriz />
					</IconButton>
				}
				errorFragment={
					<IconButton
						onClick={(e) => {
							e.stopPropagation();
							onRemove?.();
						}}
						onMouseDown={(e) => e.stopPropagation()}>
						<Cancel color="error" />
					</IconButton>
				}
			/>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={closeMenu}
				onClick={(e) => {
					e.stopPropagation();
					closeMenu();
				}}
				onMouseDown={(e) => e.stopPropagation()}>
				<MenuItem onClick={() => onDownload(true)}>
					<ListItemIcon>
						<RemoveRedEyeOutlined />
					</ListItemIcon>
					<ListItemText>View</ListItemText>
				</MenuItem>
				<MenuItem onClick={() => onDownload(false)}>
					<ListItemIcon>
						<DownloadOutlined />
					</ListItemIcon>
					<ListItemText>Download</ListItemText>
				</MenuItem>
				{onRemove && (
					<MenuItem onClick={onRemove}>
						<ListItemIcon>
							<Close />
						</ListItemIcon>
						<ListItemText>Remove</ListItemText>
					</MenuItem>
				)}
			</Menu>
		</div>
	);
};
