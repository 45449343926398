import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { EntitySearchType, Query, QueryHandleSearchArgs } from 'middleware-types';
import React, { useEffect } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { PageError } from 'utils/errors';
import { DelayedLoading } from './delayed-loading';
import { Box, Typography } from '@mui/material';

/**
 * Hook for looking up a user's ID by handle
 *
 * @param {string} handle
 * @return {*}
 */
const useHandleSearchQuery = (handle?: string) => {
	const { data, loading, error } = useQuery<Pick<Query, 'handleSearch'>, QueryHandleSearchArgs>(
		gql`
			query handle($handle: String!) {
				handleSearch(handle: $handle) {
					id
					entityType
				}
			}
		`,
		{
			variables: { handle: handle as string },
			skip: !handle,
			onError: (e) => console.log(JSON.stringify(e)),
		}
	);
	return {
		id: data?.handleSearch?.id,
		type: data?.handleSearch?.entityType,
		loading,
		error,
	};
};

export const UserHandleOrRedirect = () => {
	const { handle } = useParams<{ handle?: string }>();
	const { id, type, loading } = useHandleSearchQuery(handle ?? '');

	if (loading) return <DelayedLoading />;
	if (!id || !type) throw new PageError('404 Not Found');

	// Org vanity urls get redirected
	if (type === EntitySearchType.Organization) {
		return <Navigate to={`/orgs/${handle}`} replace />;
	}

	return <Outlet />;
};

export const UserHandle = ({ Component }: { Component: React.FC<{ userId: string }> }) => {
	const { loading, id } = useFindIdFromHandleType(EntitySearchType.User);

	if (loading) return <DelayedLoading />;

	return <Component userId={id!} />;
};

export const useFindIdFromHandleType = (handleType: EntitySearchType) => {
	const { handle } = useParams<{ handle?: string }>();
	const { id, type, loading } = useHandleSearchQuery(handle ?? '');

	if (loading) {
		return { loading, id };
	}

	// Not found if no ID match, or not expected type
	if (!id || !type || type !== handleType) {
		throw new PageError('404 Not Found');
	}

	return { loading, id };
};
