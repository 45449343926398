import { gql, useQuery } from '@apollo/client';
import { useOrgId } from 'components/pages/org/outlet';
import { Query, QueryBillingOrganizationOptionsGetArgs } from 'middleware-types';

export const GET_ORG_BILLING_OPTIONS = gql`
	query GetOrgBillingOptions($organizationId: String!) {
		billingOrganizationOptionsGet(organizationId: $organizationId) {
			billChildrenForUsage
			chargeAutomatically
			createInvoicesAsDrafts
			notBillable
		}
	}
`;

export const useOrgBillingOptions = () => {
	const organizationId = useOrgId();
	const { data, loading } = useQuery<
		Pick<Query, 'billingOrganizationOptionsGet'>,
		QueryBillingOrganizationOptionsGetArgs
	>(GET_ORG_BILLING_OPTIONS, { variables: { organizationId } });

	const options = data?.billingOrganizationOptionsGet;
	return { options, loading };
};
