import { useState } from 'react';

export const usePopupMenu = () => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const menuOpen = Boolean(anchorEl);
	const openMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
		setAnchorEl(e.currentTarget);
	const closeMenu = () => setAnchorEl(null);
	return { anchorEl, menuOpen, openMenu, closeMenu };
};
