import { Add } from '@mui/icons-material';
import {
	Box,
	Button,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Stack,
	Tooltip,
} from '@mui/material';
import { Flag } from 'components/ui/flag';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { useState } from 'react';
import { useCountries } from 'utils/useCountries';
import { UserProfileAspectType, usePrivacy } from 'utils/usePrivacy';
import { CanLicensingSummary } from './can-licensing-summary';
import { useAdjusterLicenseSummariesQuery } from './hooks';
import { UsaLicensingSummary } from './usa-licensing-summary';
import { AddUsaLicensingManualModal, AddUsaLicensingModal } from './usa/modal';
import { NpnModal } from './usa/find-npn-modal';
import { AddCanManualModal } from './can/modal';

/**
 * LicensingInformation is the licensing card in profile
 *
 * @param {{ userId: string }} props
 * @return {*}
 */
export const LicensingInformation = (props: { userId: string }) => {
	const [addCountryAnchorEl, setAddCountryAnchorEl] = useState<null | HTMLElement>(null);
	const { countries, countriesLookupMapById } = useCountries();
	const { summaries } = useAdjusterLicenseSummariesQuery(props.userId);
	const { hasAccess } = usePrivacy(props.userId);

	const [canModalOpen, setCanModalOpen] = useState(false);
	const [usaModalOpen, setUsaModalOpen] = useState(false);
	const [usaManualModalOpen, setUsaManualModalOpen] = useState(false);
	const [npnModalOpen, setNpnModalOpen] = useState(false);

	const addCountry = (countryCode: string) => {
		switch (countryCode) {
			case 'USA':
				setUsaModalOpen(true);
				break;
			case 'CAN':
				setCanModalOpen(true);
				break;
		}
	};

	/* Filter out countries user has already added. */
	const availableCountryLicensesToAdd = countries.filter((c) =>
		summaries.every((countryLicense) => countryLicense.countryId !== c.id)
	);

	const addButtonDisabled = availableCountryLicensesToAdd.length === 0;

	return (
		<Stack spacing={{ xs: 2, md: 3 }}>
			{summaries.map((summary) => {
				const country = countriesLookupMapById.get(summary.countryId);
				if (country?.iso3CountryCode === 'USA')
					return <UsaLicensingSummary key={summary.countryId} summary={summary} />;
				if (country?.iso3CountryCode === 'CAN')
					return <CanLicensingSummary key={summary.countryId} summary={summary} />;
				return <></>;
			})}
			{hasAccess(
				UserProfileAspectType.AlterLicensing,
				UserProfileAspectType.ImportLicensing
			) && (
				<Tooltip title={addButtonDisabled ? 'No coutries to add' : ''}>
					<Box alignSelf="center">
						<Button
							variant="contained"
							color="primary"
							startIcon={<Add />}
							disabled={addButtonDisabled}
							onClick={(e) => setAddCountryAnchorEl(e.currentTarget)}>
							Add Country
						</Button>
					</Box>
				</Tooltip>
			)}
			{/** add country menu */}
			<Menu
				anchorEl={addCountryAnchorEl}
				open={Boolean(addCountryAnchorEl)}
				onClose={() => setAddCountryAnchorEl(null)}
				onClick={() => setAddCountryAnchorEl(null)}
				anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
				transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
				{availableCountryLicensesToAdd.map((country) => (
					<MenuItem key={country.id} onClick={() => addCountry(country.iso3CountryCode)}>
						<ListItemIcon>
							<Flag code={country.iso2CountryCode} />
						</ListItemIcon>
						<ListItemText>{country.name}</ListItemText>
					</MenuItem>
				))}
			</Menu>
			{/** add candada modal */}
			<ModalOrDrawer open={canModalOpen}>
				<AddCanManualModal userId={props.userId} onClose={() => setCanModalOpen(false)} />
			</ModalOrDrawer>
			{/** add usa modal */}
			<ModalOrDrawer open={usaModalOpen}>
				<AddUsaLicensingModal
					userId={props.userId}
					openManualModal={() => setUsaManualModalOpen(true)}
					openNpnModal={() => setNpnModalOpen(true)}
					onClose={() => setUsaModalOpen(false)}
				/>
			</ModalOrDrawer>
			{/** add usa manual modal */}
			<ModalOrDrawer open={usaManualModalOpen}>
				<AddUsaLicensingManualModal
					userId={props.userId}
					onClose={() => setUsaManualModalOpen(false)}
				/>
			</ModalOrDrawer>
			{/** find npn modal */}
			<ModalOrDrawer open={npnModalOpen}>
				<NpnModal userId={props.userId} onClose={() => setNpnModalOpen(false)} />
			</ModalOrDrawer>
		</Stack>
	);
};
