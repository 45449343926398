import { BusinessOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Avatar, Stack } from '@mui/material';
import { Formik, FormikProps } from 'formik';
import { UserExperienceCompanyUpdate, UserExperiencePositionUpdate } from 'middleware-types';
import { useValidation } from 'utils/useValidation';
import {
	useAddUserProfileCompanyAndPosition,
	UserExperienceCompanyAndPositionCreation,
} from './hooks';
import { UserExperienceCompanyFields, validateCompany } from './user-experience-company-form';
import {
	UserExperiencePositionFields,
	UserExperiencePositionFormValues,
	validatePosition,
} from './user-experience-position-form';

interface UserExperienceCompanyAndPositionFormProps {
	userId: string;
	onClose: () => void;
}

type UserExperienceNewCompanyAndPositionFormValues = UserExperiencePositionFormValues &
	UserExperienceCompanyUpdate & { _?: string };

export const UserExperienceCompanyAndPositionForm = ({
	userId,
	onClose,
}: UserExperienceCompanyAndPositionFormProps) => {
	const addUserProfileCompanyAndPosition = useAddUserProfileCompanyAndPosition(userId);

	const onSubmit = async (input: UserExperienceNewCompanyAndPositionFormValues) => {
		const {
			isCurrentRole,
			startMonth,
			startYear,
			endMonth,
			endYear,
			name,
			logoUrl,
			organizationId,
			websiteUrl,
			...rest
		} = input;

		if (!startMonth || !startYear || !input.employmentType || !input.locationType) {
			return;
		}

		if (!isCurrentRole && (!endMonth || !endYear)) {
			return;
		}

		const companyRequest = {
			name,
			logoUrl,
			websiteUrl,
			organizationId,
		} as UserExperienceCompanyUpdate;

		const positionRequest = {
			...rest,
			startMonth: startMonth || undefined,
			startYear: startYear || undefined,
			endMonth: endMonth || undefined,
			endYear: endYear || undefined,
		} as UserExperiencePositionUpdate;

		const request: UserExperienceCompanyAndPositionCreation = {
			companyRequest,
			positionRequest,
		};

		const success = await addUserProfileCompanyAndPosition(request);
		if (success) onClose();
	};

	const initialValues: UserExperienceNewCompanyAndPositionFormValues = {
		name: '',
		organizationId: '',
		startMonth: '',
		startYear: '',
		endMonth: '',
		endYear: '',
		employmentType: '',
		locationType: '',
		jobTitle: '',
		location: '',
		description: '',
		isCurrentRole: false,
		websiteUrl: '',
	};

	const companyValidation = useValidation('UserExperienceCompanyUpdate');
	const positionValidation = useValidation('UserExperiencePositionUpdate');
	const schema =
		companyValidation.schema && positionValidation.schema
			? companyValidation.schema.concat(positionValidation.schema)
			: undefined;

	const validate = (values: UserExperienceNewCompanyAndPositionFormValues) => {
		const companyErrors = validateCompany(values);
		const positionErrors = validatePosition(values);
		return { ...companyErrors, ...positionErrors };
	};

	return (
		<Formik<UserExperienceNewCompanyAndPositionFormValues>
			onSubmit={onSubmit}
			initialValues={initialValues}
			validationSchema={schema}
			validate={validate}
			enableReinitialize>
			{(props: FormikProps<UserExperienceNewCompanyAndPositionFormValues>) => (
				<Stack p={2.5} direction="row" alignItems="flex-start" spacing={1.5}>
					<Avatar variant="rounded" sx={{ height: 60, width: 60, fontSize: 30 }}>
						<BusinessOutlined sx={{ fontSize: '1.25em' }} />
					</Avatar>
					<Stack flex={1} spacing={2}>
						<UserExperienceCompanyFields />
						<UserExperiencePositionFields />
						<Stack direction="row" spacing={1} justifyContent="flex-end">
							<LoadingButton
								loading={props.isSubmitting}
								variant="outlined"
								onClick={onClose}>
								Cancel
							</LoadingButton>
							<LoadingButton
								loading={props.isSubmitting}
								variant="contained"
								color="primary"
								disabled={!props.dirty || props.isSubmitting || !props.isValid}
								onClick={() => {
									props.submitForm();
								}}>
								Save
							</LoadingButton>
						</Stack>
					</Stack>
				</Stack>
			)}
		</Formik>
	);
};
