import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { Formik, FormikErrors, FormikProps } from 'formik';
import { UserExperienceCompany, UserExperienceCompanyUpdate } from 'middleware-types';
import { useValidation } from 'utils/useValidation';
import { OrgSelectionOrManualInput } from '../shared/org-selection-or-manual-input';
import { useUpdateUserProfileExperienceCompany } from './hooks';

export const validateCompany = (values: UserExperienceCompanyUpdate) => {
	const errors: FormikErrors<UserExperienceCompanyUpdate> = {};
	if (!values.organizationId && !values.name)
		errors.name = 'Company name required if pre-existing organization is not selected';
	return errors;
};

interface UserExperienceCompanyFormProps {
	userId: string;
	company: UserExperienceCompany;
	onClose: () => void;
}

export const UserExperienceCompanyForm = ({
	userId,
	company,
	onClose,
}: UserExperienceCompanyFormProps) => {
	const updateUserProfileExperienceCompany = useUpdateUserProfileExperienceCompany(userId);

	const onSubmit = async (input: UserExperienceCompanyUpdate) => {
		const request = {
			name: input.name,
			logoUrl: input.logoUrl,
			organizationId: input.organizationId,
			websiteUrl: input.logoUrl ? input.websiteUrl : '',
		} as UserExperienceCompanyUpdate;

		const success = await updateUserProfileExperienceCompany(company.id, request);
		if (success) onClose();
	};

	const initialValues: UserExperienceCompanyUpdate = {
		name: company?.name ?? '',
		logoUrl: company?.logoUrl ?? '',
		organizationId: company?.organizationId ?? '',
		websiteUrl: company?.websiteUrl ?? '',
	};

	const validation = useValidation('UserExperienceCompanyUpdate');

	return (
		<Formik<UserExperienceCompanyUpdate>
			onSubmit={onSubmit}
			initialValues={initialValues}
			validationSchema={validation.schema}
			validate={validateCompany}
			enableReinitialize>
			{(props: FormikProps<UserExperienceCompanyUpdate>) => (
				<Stack spacing={2}>
					<UserExperienceCompanyFields />
					<Stack direction="row" justifyContent="flex-end" spacing={1}>
						<LoadingButton
							loading={props.isSubmitting}
							variant="outlined"
							onClick={onClose}>
							Cancel
						</LoadingButton>
						<LoadingButton
							loading={props.isSubmitting}
							variant="contained"
							color="primary"
							disabled={!props.dirty || props.isSubmitting || !props.isValid}
							onClick={() => {
								props.submitForm();
							}}>
							Save
						</LoadingButton>
					</Stack>
				</Stack>
			)}
		</Formik>
	);
};

export const UserExperienceCompanyFields = () => {
	return (
		<OrgSelectionOrManualInput
			manualInputFieldName="name"
			manualInputFieldLabel="Company"
			searchFieldPlaceholder="statefarm.com"
			searchFieldHelperText="Your company doesn't exist on Evolve yet. Enter your company's URL to do a logo search."
		/>
	);
};
