import { Close } from '@mui/icons-material';
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import { usePreviewInvoice } from '../../hooks/use-preview-invoice';
import { InvoiceDataDisplay } from './invoice-data-display';
import { PreviewInvoiceForm } from './preview-invoice-form';

interface PreviewInvoiceModalProps {
	onClose: () => void;
}

export const PreviewInvoiceModal = ({ onClose }: PreviewInvoiceModalProps) => {
	const { fetchPreview, invoices, loading } = usePreviewInvoice();
	return (
		<Stack overflow="hidden" height="100%">
			<Stack
				px={2.5}
				py={1.5}
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				spacing={1}>
				<Typography variant="h3">Preview Invoice</Typography>
				<IconButton onClick={onClose}>
					<Close />
				</IconButton>
			</Stack>
			<Divider />
			<Stack direction="row" overflow="hidden" flex={1}>
				<PreviewInvoiceForm fetchPreview={fetchPreview} />
				<Divider orientation="vertical" flexItem />
				<Stack flex={1}>
					<InvoiceDataDisplay loading={loading} invoices={invoices} />
				</Stack>
			</Stack>
		</Stack>
	);
};
