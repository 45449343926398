import { Skeleton, Stack, Typography } from '@mui/material';
import { ChatMessageRole, UserChatFile, UserChatFootnote, UserChatMessage } from 'middleware-types';
import { useEffect, useRef, useState } from 'react';
import { ChatRequest } from './chatRequest';
import { ChatResponse, ChatResponseLoading, WelcomeMessage } from './chatResponse';
import { ClaimGridResponse } from './claimGridResponse';
import { EvoxFootnotesDrawer } from './evoxFootnotesDrawer';
import { InternalMessage } from './internalMessage';

interface ChatHistoryProps {
	messages: UserChatMessage[];
	pendingRequest?: string | null;
	pendingFiles?: UserChatFile[] | undefined;
	loading?: boolean;
	requestLoading?: boolean;
	chatHasBeenReset?: boolean;
	readOnly?: boolean;
	userId: string | undefined;
	selectedMessageId?: string;
}

type UserChatMessageExtension = {
	showBubble?: boolean;
};

export type UserChatMessageExtended = UserChatMessage & UserChatMessageExtension;

export const ChatHistory = ({
	messages,
	pendingRequest = null,
	pendingFiles,
	loading = false,
	requestLoading = false,
	chatHasBeenReset = false,
	readOnly = false,
	userId,
	selectedMessageId,
}: ChatHistoryProps) => {
	const [footnotes, setFootnotes] = useState<UserChatFootnote[] | null>(null);
	const containerRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		// don't scroll to the bottom if a message is selected,
		// since we will scroll to that message instead
		if (selectedMessageId !== undefined) return;
		containerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
	}, [messages, pendingRequest, loading, requestLoading]);

	const orderedMessages: UserChatMessage[] = [...messages].sort(
		(a, b) => a.sequence - b.sequence
	);

	const fromAssistant = [ChatMessageRole.Assistant, ChatMessageRole.FunctionResultsForUser];

	const orderedMessagesExtended: UserChatMessageExtended[] = [];

	if (orderedMessages[0]) {
		orderedMessagesExtended.push({
			...orderedMessages[0],
		});
	}

	for (let i = 1; i < orderedMessages.length; i++) {
		orderedMessagesExtended.push({
			...orderedMessages[i],
			showBubble: !(
				fromAssistant.includes(orderedMessages[i].role) &&
				fromAssistant.includes(orderedMessages[i - 1]?.role) &&
				orderedMessages[i - 1].content
			),
		});
	}

	if (loading || userId === undefined) return <ChatHistorySkeleton />;

	return (
		<Stack spacing={2} p={2} ref={containerRef}>
			{chatHasBeenReset && (
				<Typography fontSize={12} alignSelf="center">
					You started a new conversation.
				</Typography>
			)}
			<WelcomeMessage userId={userId} />
			{orderedMessagesExtended.map((message, index) => {
				switch (message.role) {
					case ChatMessageRole.User:
						return (
							<ChatRequest
								key={index}
								text={message.content}
								userId={userId}
								files={message.files}
							/>
						);
					case ChatMessageRole.Assistant:
						if (message.content) {
							return (
								<ChatResponse
									key={message.chatMessageId}
									message={message}
									readOnly={readOnly}
									selected={message.chatMessageId === selectedMessageId}
									setFootnotes={setFootnotes}
									files={message.files}
								/>
							);
						}
						return <InternalMessage key={message.chatMessageId} message={message} />;
					case ChatMessageRole.FunctionResultsForUser:
						if (message.content) {
							let content;
							try {
								content = JSON.parse(message.content);
							} catch (error) {
								content = undefined;
							}

							if (content?.ResultType == 'ClaimSearchResults') {
								const claims = content?.Result?.Claims;
								return (
									<ClaimGridResponse
										claims={claims}
										key={message.chatMessageId}
										message={message}
										readOnly={readOnly}
										selected={message.chatMessageId === selectedMessageId}
									/>
								);
							} else {
								return <></>;
							}
						}
						return <InternalMessage key={message.chatMessageId} message={message} />;
					default:
						return <InternalMessage key={message.chatMessageId} message={message} />;
				}
			})}
			{pendingRequest && requestLoading && (
				<ChatRequest text={pendingRequest} userId={userId} files={pendingFiles} />
			)}
			{requestLoading && <ChatResponseLoading />}
			<EvoxFootnotesDrawer
				open={!!footnotes}
				onClose={() => setFootnotes(null)}
				footnotes={footnotes}
			/>
		</Stack>
	);
};

const ChatHistorySkeleton = () => {
	return (
		<Stack spacing={2} p={2}>
			<Stack direction="row" spacing={1} alignItems="flex-start">
				<Skeleton variant="circular" height={48} width={48} />
				<Skeleton variant="rounded" height={100} sx={{ flex: 1 }} />
			</Stack>
			<Stack direction="row-reverse" spacing={1} alignItems="flex-start">
				<Skeleton variant="circular" height={48} width={48} />
				<Skeleton variant="rounded" height={60} sx={{ flex: 1 }} />
			</Stack>
			<Stack direction="row" spacing={1} alignItems="flex-start">
				<Skeleton variant="circular" height={48} width={48} />
				<Skeleton variant="rounded" height={140} sx={{ flex: 1 }} />
			</Stack>
		</Stack>
	);
};
