import {
	Add,
	AttachFileOutlined,
	Close,
	EditNoteOutlined,
	ForumOutlined,
	InfoOutlined,
	Menu as MenuIcon,
	PushPin,
	PushPinOutlined,
	SendOutlined,
	TextSnippetOutlined,
} from '@mui/icons-material';
import {
	Alert,
	Box,
	Divider,
	IconButton,
	Link,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { UserChatFile } from 'middleware-types';
import { useState } from 'react';
import { useSession } from 'utils/session';
import { theme } from 'utils/theme';
import { ChatHistory } from './chatHistory';
import { ConfirmNewConversationDrawer } from './confirmNewConversationDrawer';
import { CustomInstructions } from './customInstructions';
import { DeleteConversationDrawer } from './deleteConversationDrawer';
import { EvoxFileUploaderModal } from './evox-file-uploader';
import { EvoxFileChip } from './evoxFileChip';
import { EvoxFileUploadInfoMessageDrawer } from './fileUploadInfoMessageDrawer';
import { useEvox } from './hooks';
import { InfoScreen } from './infoScreen';
import { PromptClippings } from './promptClippings';
import { SavedConversations } from './savedConversation';
import { SaveDrawer } from './saveDrawer';
import { SuggestedPropmt } from './suggestedPrompt';

export interface UserChatFileWithToken extends UserChatFile {
	fileUploadToken: string;
}

export const EvoX = ({ onClose }: { onClose?: () => void }) => {
	const {
		conversationId,
		conversationName,
		setConversationName,
		messages,
		pendingRequest,
		pendingFileRequest,
		resetChat,
		sendRequest,
		requestLoading,
		getSavedConversation,
		savedConversationLoading,
		chatHasBeenReset,
		latestConversationLoading,
	} = useEvox();

	const { user } = useSession();

	const loading = requestLoading || savedConversationLoading || latestConversationLoading;
	const chatIsEmpty = messages.length === 0 && pendingRequest === null && !loading;
	const isPinned = conversationName !== null;

	const [requestText, setRequestText] = useState('');
	const [requestFiles, setRequestFiles] = useState<UserChatFileWithToken[]>([]);
	const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
	const [savedConversationsOpen, setSavedConversationsOpen] = useState(false);
	const [promptClippingsOpen, setPromptClippingsOpen] = useState(false);
	const [customInstructionsOpen, setCustomInstructionsOpen] = useState(false);
	const [saveDrawerOpen, setSaveDrawerOpen] = useState(false);
	const [deleteDrawerOpen, setDeleteDrawerOpen] = useState(false);
	const [confirmNewConversationDrawerOpen, setConfirmNewConversationDrawerOpen] = useState(false);
	const [fileUploadInfoMessageDrawerOpen, setfileUploadInfoMessageDrawerOpen] = useState(false);
	const [uploadFileModalOpen, setUploadFileModalOpen] = useState(false);

	const [infoScreenOpen, setInfoScreenOpen] = useState(() => {
		const dismissed = window.localStorage.getItem('evoxInfoDismissed');
		if (dismissed === null) return true;
		return !(JSON.parse(dismissed) as boolean);
	});

	const MAX_FILES_PER_MESSAGE = 10;

	const handleOpenEvoxFileUploader = () => {
		if (requestFiles.length < MAX_FILES_PER_MESSAGE) {
			const dismissed = window.localStorage.getItem('evoxFileUploadInfoMessageDismissed');
			if (dismissed === null || !(JSON.parse(dismissed) as boolean)) {
				setfileUploadInfoMessageDrawerOpen(true);
				return;
			}

			setUploadFileModalOpen(true);
		}
	};

	const handleCloseEvoxFileUploadInfoMessage = () => {
		setfileUploadInfoMessageDrawerOpen(false);
		setUploadFileModalOpen(true);
	};

	const resetRequest = () => {
		setRequestText('');
		setRequestFiles([]);
	};

	const handleResetChat = () => {
		resetChat();
		resetRequest();
	};

	const handleGetSavedConversation = (savedConversationId: string) => {
		if (savedConversationId === conversationId) return;

		getSavedConversation(savedConversationId).then((success) => {
			if (success) resetRequest();
		});
	};

	const submit = () => {
		const request = requestText.trim();
		if (loading || !request) return;
		resetRequest();

		sendRequest(request, requestFiles);
	};

	if (infoScreenOpen) {
		return <InfoScreen onClose={() => setInfoScreenOpen(false)} />;
	}

	if (savedConversationsOpen) {
		return (
			<SavedConversations
				getSavedConversation={handleGetSavedConversation}
				onClose={() => setSavedConversationsOpen(false)}
				setConversationName={setConversationName}
				warnAboutOverwriting={!chatIsEmpty && !isPinned}
			/>
		);
	}

	if (promptClippingsOpen) {
		return (
			<PromptClippings
				onClose={() => setPromptClippingsOpen(false)}
				setRequestText={setRequestText}
			/>
		);
	}

	if (customInstructionsOpen) {
		return <CustomInstructions onClose={() => setCustomInstructionsOpen(false)} />;
	}

	return (
		<Stack height="100%" width="100%" position="relative" id="evox-container">
			<Stack
				px={1}
				py={0.5}
				direction="row"
				alignItems="center"
				borderBottom={`1px solid ${theme.palette.divider}`}>
				<IconButton onClick={(e) => setMenuAnchor(e.currentTarget)}>
					<MenuIcon />
				</IconButton>
				<Typography variant="h4" pl={1} flex={1}>
					EVO-X
				</Typography>

				{/* TODO: if current conversation is already pinned, need to show the DeleteConversationDrawer instead of SaveDrawer */}
				{isPinned ? (
					<IconButton
						disabled={chatIsEmpty || loading}
						onClick={() => setDeleteDrawerOpen(true)}>
						<PushPin sx={{ transform: 'rotate(90deg)' }} />
					</IconButton>
				) : (
					<IconButton
						disabled={chatIsEmpty || loading}
						onClick={() => setSaveDrawerOpen(true)}>
						<PushPinOutlined />
					</IconButton>
				)}

				{/* TODO: if current conversation is NOT pinned, confirm that the user is ok with losing access to it before proceeding */}
				{isPinned ? (
					<IconButton disabled={chatIsEmpty || loading} onClick={handleResetChat}>
						<Add />
					</IconButton>
				) : (
					<IconButton
						disabled={chatIsEmpty || loading}
						onClick={() => setConfirmNewConversationDrawerOpen(true)}>
						<Add />
					</IconButton>
				)}
				{onClose !== undefined && (
					<>
						<Divider orientation="vertical" sx={{ mx: 0.5 }} />
						<IconButton onClick={onClose}>
							<Close />
						</IconButton>
					</>
				)}
			</Stack>
			<Box flex={1} overflow="auto">
				<ChatHistory
					messages={messages}
					pendingRequest={pendingRequest}
					pendingFiles={pendingFileRequest}
					loading={savedConversationLoading || latestConversationLoading}
					requestLoading={requestLoading}
					chatHasBeenReset={chatHasBeenReset}
					userId={user.userId}
				/>
			</Box>
			{chatIsEmpty && (
				<Stack spacing={1.5} p={2} alignItems="flex-end">
					<SuggestedPropmt text="How do I use EVO-X?" sendRequest={sendRequest} />
					<SuggestedPropmt
						text="Draft an email template I can send to claimants introducing myself as their case's adjuster."
						sendRequest={sendRequest}
					/>
				</Stack>
			)}
			<EvoxFileUploadInfoMessageDrawer
				open={fileUploadInfoMessageDrawerOpen}
				onClose={handleCloseEvoxFileUploadInfoMessage}
			/>
			<Stack spacing={1} p={2} borderTop={`1px solid ${theme.palette.divider}`}>
				<Stack display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
					{requestFiles.map((rf) => (
						<EvoxFileChip
							key={rf.chatFileId}
							name={rf.name}
							onDelete={() =>
								setRequestFiles((prev) =>
									prev.filter((df) => df.chatFileId != rf.chatFileId)
								)
							}
						/>
					))}
				</Stack>
				{requestFiles.length >= MAX_FILES_PER_MESSAGE && (
					<Alert icon={<InfoOutlined fontSize="inherit" color="info" />} severity="info">
						You have reached the limit of {MAX_FILES_PER_MESSAGE} files for this
						message.
					</Alert>
				)}
				<Stack direction="row" spacing={1} alignItems="center">
					<IconButton onClick={() => handleOpenEvoxFileUploader()}>
						<AttachFileOutlined />
					</IconButton>
					<TextField
						name="message"
						placeholder="Type a message here..."
						multiline
						maxRows={8}
						value={requestText}
						onChange={(e) => setRequestText(e.target.value)}
						onKeyDown={(e) => {
							if (e.key === 'Enter' && !e.shiftKey) {
								e.preventDefault();
								submit();
							}
						}}
					/>
					<IconButton disabled={!requestText || loading} onClick={submit}>
						<SendOutlined />
					</IconButton>
				</Stack>
				<Typography fontSize={12}>
					EVO-X may not always produce accurate information. Always double check any
					output.{' '}
					<Link component={'button'} onClick={() => setInfoScreenOpen(true)}>
						Click here for more information.
					</Link>
				</Typography>
			</Stack>
			{/* Menu */}
			<Menu
				open={Boolean(menuAnchor)}
				anchorEl={menuAnchor}
				onClick={() => setMenuAnchor(null)}
				onClose={() => setMenuAnchor(null)}>
				<MenuItem onClick={() => setSavedConversationsOpen(true)}>
					<ListItemIcon>
						<ForumOutlined />
					</ListItemIcon>
					<ListItemText>Pinned Conversations</ListItemText>
				</MenuItem>
				<MenuItem onClick={() => setPromptClippingsOpen(true)}>
					<ListItemIcon>
						<TextSnippetOutlined />
					</ListItemIcon>
					<ListItemText>Prompt Clippings</ListItemText>
				</MenuItem>
				<MenuItem onClick={() => setCustomInstructionsOpen(true)}>
					<ListItemIcon>
						<EditNoteOutlined />
					</ListItemIcon>
					<ListItemText>Custom Instructions</ListItemText>
				</MenuItem>
			</Menu>
			{/* Save Drawer */}
			<SaveDrawer
				open={saveDrawerOpen}
				onClose={() => {
					setSaveDrawerOpen(false);
				}}
				conversationId={conversationId!}
				setConversationName={setConversationName}
			/>
			{/* Delete Drawer */}
			<DeleteConversationDrawer
				open={deleteDrawerOpen}
				onClose={() => setDeleteDrawerOpen(false)}
				conversationId={conversationId!}
				conversationName={conversationName ?? undefined}
				setConversationName={setConversationName}
			/>
			{/* Confirm New Conversation Drawer */}
			<ConfirmNewConversationDrawer
				open={confirmNewConversationDrawerOpen}
				onSubmit={() => handleResetChat()}
				onClose={() => setConfirmNewConversationDrawerOpen(false)}
			/>
			{/** Upload files modal */}
			<ModalOrDrawer
				open={uploadFileModalOpen}
				dialogProps={{ maxWidth: 'md' }}
				drawerProps={{ sx: { zIndex: theme.zIndex.modal } }}>
				<EvoxFileUploaderModal
					userId={user.userId}
					conversationId={conversationId!}
					setRequestFiles={setRequestFiles}
					onClose={() => setUploadFileModalOpen(false)}
				/>
			</ModalOrDrawer>
		</Stack>
	);
};
