import { EditOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	Card,
	Chip,
	Divider,
	IconButton,
	Link,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import { Formik, FormikProps } from 'formik';
import { AddressText, PhoneNumberText } from 'components/ui/fields';
import { OrganizationAboutUpdate, OrganizationProfile } from 'middleware-types';
import { useState } from 'react';
import { useIsMobile } from 'utils/useScreenSize';
import { useValidation } from 'utils/useValidation';
import { useOrgProfile, useUpdateOrgAbout } from '../hooks';
import { OrgAboutForm } from './about-form';
import { employeeCountToString, industryToString } from './enum-display';

interface OrgAboutCardProps {
	orgId: string;
	canEdit: boolean;
}

export const OrgAboutCard = ({ orgId, canEdit }: OrgAboutCardProps) => {
	const { profile, loading } = useOrgProfile(orgId);
	const isMobile = useIsMobile();
	const updateOrgAbout = useUpdateOrgAbout();
	const [editing, setEditing] = useState(false);
	const validation = useValidation('OrganizationAboutUpdate');

	if (loading) return <AboutSkeleton />;
	if (!profile) return <></>;

	const onSubmit = async (values: OrganizationAboutUpdate) => {
		const phoneNumber =
			values.displayPhoneNumber &&
			values.displayPhoneNumber.countryCode &&
			values.displayPhoneNumber.number
				? values.displayPhoneNumber
				: undefined;
		const newValues: OrganizationAboutUpdate = {
			about: values.about,
			industry: values.industry,
			displayPhoneNumber: phoneNumber,
			numberOfEmployees: values.numberOfEmployees,
			websiteUrl: values.websiteUrl || undefined,
			yearFounded: values.yearFounded || undefined,
		};
		const success = await updateOrgAbout(newValues);
		if (success) setEditing(false);
	};

	const initialValues: OrganizationAboutUpdate = {
		about: profile.about ?? '',
		industry: profile.industry ?? '',
		displayPhoneNumber: profile.displayPhoneNumber ?? { number: '', countryCode: '' },
		numberOfEmployees: profile.numberOfEmployees ?? '',
		websiteUrl: profile.websiteUrl ?? '',
		yearFounded: profile.yearFounded ?? ('' as any),
	};

	const specialties = profile.businessLines?.filter((line) => line.specialty) ?? [];

	return (
		<Formik<OrganizationAboutUpdate>
			enableReinitialize
			onSubmit={onSubmit}
			validationSchema={validation.schema}
			initialValues={initialValues}>
			{({
				submitForm,
				isSubmitting,
				isValid,
				dirty,
				resetForm,
			}: FormikProps<OrganizationAboutUpdate>) => (
				<Card sx={{ borderRadius: { xs: 0, sm: 1 } }}>
					<Stack
						direction="row"
						px={{ xs: 2, sm: 2.5 }}
						py={1}
						alignItems="center"
						justifyContent="space-between">
						<Typography variant="h3">About</Typography>
						{editing ? (
							<Stack direction="row" spacing={1.5}>
								<Button variant="outlined" onClick={() => setEditing(false)}>
									Cancel
								</Button>
								<LoadingButton
									variant="contained"
									color="primary"
									onClick={submitForm}
									disabled={!isValid || !dirty}
									loading={isSubmitting}>
									Save
								</LoadingButton>
							</Stack>
						) : canEdit ? (
							<IconButton
								onClick={() => {
									resetForm();
									setEditing(true);
								}}>
								<EditOutlined />
							</IconButton>
						) : (
							<Box height={40} />
						)}
					</Stack>
					<Divider />
					{editing ? (
						<OrgAboutForm profile={profile} />
					) : (
						<Stack direction={{ xs: 'column', sm: 'row' }}>
							<Stack
								spacing={3.5}
								p={{ xs: 2, sm: 2.5 }}
								flexShrink={0}
								width={{ sm: 300 }}>
								{profile.websiteUrl && (
									<Stack spacing={1.5}>
										<Typography variant="h4">Website</Typography>
										<Typography sx={{ wordBreak: 'break-all' }}>
											<Link href={profile.websiteUrl} target="_blank">
												{profile.websiteUrl}
											</Link>
										</Typography>
									</Stack>
								)}
								{profile.primaryAddress && (
									<Stack spacing={1.5}>
										<Typography variant="h4">Primary Address</Typography>
										<Stack>
											<AddressText {...profile.primaryAddress} />
										</Stack>
									</Stack>
								)}

								{profile.displayPhoneNumber && (
									<Stack spacing={1.5}>
										<Typography variant="h4">Phone</Typography>
										<Typography variant="body1">
											<PhoneNumberText number={profile.displayPhoneNumber} />
										</Typography>
									</Stack>
								)}
								{profile.industry && (
									<Stack spacing={1.5}>
										<Typography variant="h4">Industry</Typography>
										<Typography variant="body1">
											{industryToString(profile.industry)}
										</Typography>
									</Stack>
								)}
								{profile.numberOfEmployees && (
									<Stack spacing={1.5}>
										<Typography variant="h4">Company Size</Typography>
										<Typography variant="body1">
											{employeeCountToString(profile.numberOfEmployees)}
										</Typography>
									</Stack>
								)}
								{profile.yearFounded && (
									<Stack spacing={1.5}>
										<Typography variant="h4">Founded</Typography>
										<Typography variant="body1">
											{profile.yearFounded}
										</Typography>
									</Stack>
								)}
								{specialties.length > 0 && (
									<Stack spacing={1.5}>
										<Typography variant="h4">Specialties</Typography>
										<Stack direction="row" gap={1} flexWrap="wrap">
											{specialties.map((specialty) => (
												<Chip
													key={specialty.id}
													label={specialty.line}
													size="small"
												/>
											))}
										</Stack>
									</Stack>
								)}
							</Stack>
							{profile.about && (
								<>
									{!isMobile && <Divider orientation="vertical" flexItem />}
									<Stack p={2.5} spacing={1.5}>
										<Typography variant="h4">About</Typography>
										<Typography variant="body1" whiteSpace="pre-line">
											{profile.about}
										</Typography>
									</Stack>
								</>
							)}
						</Stack>
					)}
				</Card>
			)}
		</Formik>
	);
};

const AboutSkeleton = () => {
	return (
		<Card sx={{ borderRadius: { xs: 0, sm: 1 } }}>
			<Stack
				direction="row"
				px={{ xs: 2, sm: 2.5 }}
				py={1}
				m={1}
				alignItems="center"
				justifyContent="space-between">
				<Typography variant="h3">About</Typography>
			</Stack>
			<Divider />
			<Stack p={2.5} spacing={1.5} alignItems="center">
				<Skeleton variant="text" width="98%" />
			</Stack>
		</Card>
	);
};
