import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Checkbox,
	Divider,
	FormControlLabel,
	IconButton,
	MenuItem,
	Stack,
	Typography,
} from '@mui/material';
import { useOrgId } from 'components/pages/org/outlet';
import { CheckboxField, SelectField, TextField } from 'components/ui/fields';
import { addMonths, getMonth, getYear } from 'date-fns';
import { Formik } from 'formik';
import { QueryBillingPreviewInvoiceGetArgs } from 'middleware-types';
import { useState } from 'react';
import { monthNames } from 'utils/utils';

const years = [0, 1, 2].map((offset) => getYear(new Date()) + offset);

interface PreviewInvoiceFormFields {
	month: number;
	year: number;
	generateDraftInvoice: boolean;
	// billing usage
	useCurrentMonth: boolean;
	appraisalCount: number | '';
	appraisalCountDeleted: number | '';
	claimCount: number | '';
	claimCountDeleted: number | '';
	hoverCount: number | '';
	torrentCount: number | '';
	odysseyCount: number | '';
	storageActive: number | '';
	symbilityCount: number | '';
	textMessageCount: number | '';
	userCount: number | '';
	xactCount: number | '';
	bordereau: boolean;
}

interface PreviewInvoiceFormProps {
	fetchPreview: (variables: QueryBillingPreviewInvoiceGetArgs) => Promise<void>;
}

export const PreviewInvoiceForm = ({ fetchPreview }: PreviewInvoiceFormProps) => {
	const organizationId = useOrgId();
	const [usageDataOpen, setUsageDataOpen] = useState(true);

	const nextMonth = addMonths(new Date(), 1);
	const initialValues: PreviewInvoiceFormFields = {
		month: getMonth(nextMonth) + 1,
		year: getYear(nextMonth),
		generateDraftInvoice: false,
		useCurrentMonth: true,
		appraisalCount: '',
		appraisalCountDeleted: '',
		claimCount: '',
		claimCountDeleted: '',
		hoverCount: '',
		torrentCount: '',
		odysseyCount: '',
		storageActive: '',
		symbilityCount: '',
		textMessageCount: '',
		userCount: '',
		xactCount: '',
		bordereau: false,
	};

	const onSubmit = async (values: PreviewInvoiceFormFields) => {
		const invoiceDate = `${values.year.toString().padStart(4, '0')}-${values.month
			.toString()
			.padStart(2, '0')}-01`;

		await fetchPreview({
			organizationId,
			invoiceDate,
			generateDraftInvoice: values.generateDraftInvoice,
			usage: values.useCurrentMonth
				? undefined
				: {
						appraisalCount: values.appraisalCount || 0,
						appraisalCountDeleted: values.appraisalCountDeleted || 0,
						claimCount: values.claimCount || 0,
						claimCountDeleted: values.claimCountDeleted || 0,
						hoverCount: values.hoverCount || 0,
						torrentCount: values.torrentCount || 0,
						odysseyCount: values.odysseyCount || 0,
						storageActive: values.storageActive || 0,
						symbilityCount: values.symbilityCount || 0,
						textMessageCount: values.textMessageCount || 0,
						userCount: values.userCount || 0,
						xactCount: values.xactCount || 0,
						bordereau: values.bordereau,
						storageDate: Date.now(),
				  },
		});
	};

	return (
		<Formik<PreviewInvoiceFormFields> initialValues={initialValues} onSubmit={onSubmit}>
			{({ isValid, submitForm, isSubmitting, values, setFieldValue }) => (
				<Stack width={330} overflow="hidden">
					<Stack overflow="auto" flex={1}>
						<Box p={1.5} bgcolor="neutral.50">
							<Typography variant="h5">Invoice Date</Typography>
						</Box>
						<Divider />
						<Stack p={2} spacing={2}>
							<SelectField name="month" label="Month" required>
								{monthNames.map((month, index) => (
									<MenuItem key={index} value={index + 1}>
										{month}
									</MenuItem>
								))}
							</SelectField>
							<SelectField name="year" label="Year" required>
								{years.map((year) => (
									<MenuItem key={year} value={year}>
										{year}
									</MenuItem>
								))}
							</SelectField>
							<Box>
								<FormControlLabel
									label="Create Stripe draft invoice"
									control={<CheckboxField name="generateDraftInvoice" />}
									sx={{ mt: -1 }}
								/>
							</Box>
						</Stack>
						<Divider />
						<Stack
							px={1.5}
							py={1}
							bgcolor="neutral.50"
							direction="row"
							alignItems="center"
							justifyContent="space-between">
							<Typography variant="h5">Usage Data (Optional)</Typography>
							<IconButton onClick={() => setUsageDataOpen((v) => !v)}>
								{usageDataOpen ? <ExpandLess /> : <ExpandMore />}
							</IconButton>
						</Stack>
						<Divider />
						{usageDataOpen && (
							<>
								<Box px={2} py={1}>
									<FormControlLabel
										label="Use current month to date usage"
										control={
											<Checkbox
												checked={values.useCurrentMonth}
												onChange={(_e, checked) => {
													setFieldValue('useCurrentMonth', checked);
													if (!checked) return;
													setFieldValue('appraisalCount', '');
													setFieldValue('appraisalCountDeleted', '');
													setFieldValue('claimCount', '');
													setFieldValue('claimCountDeleted', '');
													setFieldValue('hoverCount', '');
													setFieldValue('odysseyCount', '');
													setFieldValue('storageActive', '');
													setFieldValue('symbilityCount', '');
													setFieldValue('textMessageCount', '');
													setFieldValue('userCount', '');
													setFieldValue('xactCount', '');
													setFieldValue('bordereau', false);
												}}
											/>
										}
									/>
								</Box>
								<Divider />
								<Stack p={2} spacing={2}>
									<TextField
										name="appraisalCount"
										label="Appraisals"
										type="number"
										disabled={values.useCurrentMonth}
									/>
									<TextField
										name="appraisalCountDeleted"
										label="Deleted Appraisals"
										type="number"
										disabled={values.useCurrentMonth}
									/>
									<TextField
										name="claimCount"
										label="Claims"
										type="number"
										disabled={values.useCurrentMonth}
									/>
									<TextField
										name="claimCountDeleted"
										label="Deleted Claims"
										type="number"
										disabled={values.useCurrentMonth}
									/>
									<TextField
										name="hoverCount"
										label="HOVER Reports"
										type="number"
										disabled={values.useCurrentMonth}
									/>
									<TextField
										name="torrentCount"
										label="Torrent Transactions"
										type="number"
										disabled={values.useCurrentMonth}
									/>
									<TextField
										name="odysseyCount"
										label="Odyssey Transactions"
										type="number"
										disabled={values.useCurrentMonth}
									/>
									<TextField
										name="storageActive"
										label="Storage GB"
										type="number"
										disabled={values.useCurrentMonth}
									/>
									<TextField
										name="symbilityCount"
										label="Symbility Transactions"
										type="number"
										disabled={values.useCurrentMonth}
									/>
									<TextField
										name="textMessageCount"
										label="Text Messages"
										type="number"
										disabled={values.useCurrentMonth}
									/>
									<TextField
										name="userCount"
										label="Users"
										type="number"
										disabled={values.useCurrentMonth}
									/>
									<TextField
										name="xactCount"
										label="XA Transactions"
										type="number"
										disabled={values.useCurrentMonth}
									/>
									<Box>
										<FormControlLabel
											label="Bordereau"
											control={<CheckboxField name="bordereau" />}
											disabled={values.useCurrentMonth}
											sx={{ mt: -1 }}
										/>
									</Box>
								</Stack>
							</>
						)}
					</Stack>
					<Divider />
					<Box px={2} py={1.5}>
						<LoadingButton
							color="primary"
							variant="contained"
							fullWidth
							loading={isSubmitting}
							disabled={!isValid}
							onClick={submitForm}>
							Generate
						</LoadingButton>
					</Box>
				</Stack>
			)}
		</Formik>
	);
};
