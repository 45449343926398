import _ from 'lodash';
import { useCallback, useState } from 'react';

export const useMultiSelect = () => {
	// keep the whole state in one obejct so we can effectively make use of setState callbacks
	const [multiSelectState, setMultiSelectState] = useState<{
		selectedIndexes: number[];
		lastSelectedIndex: number | undefined;
	}>({ selectedIndexes: [], lastSelectedIndex: undefined });

	const setIndexSelected = useCallback(
		(index: number, e: React.MouseEvent) => {
			e.stopPropagation();
			if (e.shiftKey) {
				setMultiSelectState(({ selectedIndexes, lastSelectedIndex }) => {
					if (lastSelectedIndex === undefined)
						return { selectedIndexes, lastSelectedIndex };
					const start = Math.min(index, lastSelectedIndex);
					const end = Math.max(index, lastSelectedIndex);
					return { selectedIndexes: _.range(start, end + 1), lastSelectedIndex };
				});
			} else if (e.ctrlKey || e.metaKey) {
				setMultiSelectState(({ selectedIndexes }) => {
					if (selectedIndexes.includes(index))
						return {
							selectedIndexes: selectedIndexes.filter((i) => i !== index),
							lastSelectedIndex: index,
						};
					return {
						selectedIndexes: [...selectedIndexes, index],
						lastSelectedIndex: index,
					};
				});
			} else {
				setMultiSelectState({ selectedIndexes: [index], lastSelectedIndex: index });
			}
		},
		[setMultiSelectState]
	);

	const clearSelected = useCallback(() => {
		setMultiSelectState({ selectedIndexes: [], lastSelectedIndex: undefined });
	}, [setMultiSelectState]);

	const { selectedIndexes } = multiSelectState;

	return {
		selectedIndexes,
		setIndexSelected,
		clearSelected,
	};
};
