import {
	Info,
	InfoOutlined,
	Link,
	LinkOutlined,
	ReceiptLong,
	ReceiptLongOutlined,
} from '@mui/icons-material';
import { Container, Grid } from '@mui/material';
import { useOrgId } from 'components/pages/org/outlet';
import { NavList, NavListItem } from 'components/ui/nav-list';
import { PageContent, PageTitle } from 'components/ui/page';
import { ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Permission } from 'utils/permissions';
import { useAssociateUser } from 'utils/useAssociateUser';
import { useIsSiteUser } from 'utils/useIsSiteUser';
import { useSiteUser } from 'utils/useSiteUser';

interface NavLinkInfo {
	icon: ReactElement;
	activeIcon: ReactElement;
	text: string;
	to: string;
	socialPermissions: Permission[];
	sitePermissions: Permission[];
}

const navLinks: NavLinkInfo[] = [
	{
		icon: <InfoOutlined sx={{ color: 'neutral.500' }} />,
		activeIcon: <Info color="primary" />,
		text: 'Account Information',
		to: './information',
		socialPermissions: [Permission.Org_Account_R],
		sitePermissions: [Permission.Site_OrgAcct_R],
	},
	{
		icon: <LinkOutlined sx={{ color: 'neutral.500' }} />,
		activeIcon: <Link color="primary" />,
		text: 'Account Linking',
		to: './linking',
		socialPermissions: [Permission.Org_Account_R],
		sitePermissions: [Permission.Site_OrgAcct_R],
	},
	{
		icon: <ReceiptLongOutlined sx={{ color: 'neutral.500' }} />,
		activeIcon: <ReceiptLong color="primary" />,
		text: 'Account Billing',
		to: './billing',
		socialPermissions: [Permission.Org_Billing_R],
		sitePermissions: [Permission.Site_OrgBilling_R],
	},
];

const useOrgAccountLinks = () => {
	const orgId = useOrgId();
	const isSiteUser = useIsSiteUser();
	const { hasPermission: hasAssociateUserPermission, loading: associateUserLoading } =
		useAssociateUser(orgId);
	const { hasPermission: hasSiteUserPermission, loading: siteUserLoading } = useSiteUser();

	const loading = isSiteUser ? siteUserLoading : associateUserLoading;
	const navItems = loading
		? []
		: isSiteUser
		? navLinks.filter((link) => hasSiteUserPermission(link.sitePermissions))
		: navLinks.filter((link) => hasAssociateUserPermission(link.socialPermissions));

	return { navItems, loading };
};

export const OrgAccountRedirect = () => {
	const { navItems, loading } = useOrgAccountLinks();

	if (loading || navItems.length === 0) return <></>;
	return <Navigate to={navItems[0].to} replace />;
};

export const OrgAccountOutlet = () => {
	const { navItems } = useOrgAccountLinks();
	return (
		<>
			<PageTitle title="Account Settings" />
			<PageContent mobileNoPadding>
				<Container disableGutters>
					<Grid container spacing={{ xs: 2, sm: 4 }}>
						<Grid item xs={12} md={3}>
							<NavList title="Settings" sticky px={{ xs: 1.5, sm: 0 }}>
								{navItems.map((link) => (
									<NavListItem
										key={link.to}
										icon={link.icon}
										activeIcon={link.activeIcon}
										text={link.text}
										to={link.to}
									/>
								))}
							</NavList>
						</Grid>
						<Grid item xs={12} md={9}>
							<Outlet context={useOrgId()} />
						</Grid>
					</Grid>
				</Container>
			</PageContent>
		</>
	);
};
