import { Box, Stack, Typography } from '@mui/material';
import { useMapColors } from 'utils/useMapColors';

interface MapKeyProps {
	country: 'usa' | 'canada';
}

export const MapKey = ({ country }: MapKeyProps) => {
	const {
		defaultColor,
		activeColor,
		expiringColor,
		notRequiredColor,
		expiredColor,
		inactiveColor,
		borderColor,
	} = useMapColors();

	return (
		<Stack direction="row" rowGap={1} columnGap={2.5} flexWrap="wrap">
			<Stack direction="row" alignItems="center" spacing={0.75}>
				<Box
					height={16}
					width={16}
					bgcolor={activeColor}
					border="1px solid"
					borderColor={borderColor}
				/>
				<Typography variant="body2">Licensed</Typography>
			</Stack>
			<Stack direction="row" alignItems="center" spacing={0.75}>
				<Box
					height={16}
					width={16}
					bgcolor={expiringColor}
					border="1px solid"
					borderColor={borderColor}
				/>
				<Typography variant="body2">Expiring</Typography>
			</Stack>
			<Stack direction="row" alignItems="center" spacing={0.75}>
				<Box
					height={16}
					width={16}
					bgcolor={expiredColor}
					border="1px solid"
					borderColor={borderColor}
				/>
				<Typography variant="body2">Expired</Typography>
			</Stack>
			{country === 'usa' && (
				<Stack direction="row" alignItems="center" spacing={0.75}>
					<Box
						height={16}
						width={16}
						bgcolor={inactiveColor}
						border="1px solid"
						borderColor={borderColor}
					/>
					<Typography variant="body2">Inactive</Typography>
				</Stack>
			)}
			{country === 'usa' && (
				<Stack direction="row" alignItems="center" spacing={0.75}>
					<Box
						height={16}
						width={16}
						bgcolor={notRequiredColor}
						border="1px solid"
						borderColor={borderColor}
					/>
					<Typography variant="body2">Not required</Typography>
				</Stack>
			)}
			<Stack direction="row" alignItems="center" spacing={0.75}>
				<Box
					height={16}
					width={16}
					bgcolor={defaultColor}
					border="1px solid"
					borderColor={borderColor}
				/>
				<Typography variant="body2">Not licensed</Typography>
			</Stack>
		</Stack>
	);
};
