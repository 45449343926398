import { useTheme } from '@mui/material';

export const useMapColors = () => {
	const { palette } = useTheme();
	return {
		defaultColor: palette.neutral[50],
		activeColor: palette.success.main,
		expiringColor: palette.warning.dark,
		expiredColor: palette.error.main,
		inactiveColor: palette.neutral[500],
		notRequiredColor: palette.neutral[200],
		borderColor: palette.neutral[300],
	};
};
