import { DeleteOutlined, EditOutlined, MoreHoriz, SchoolOutlined } from '@mui/icons-material';
import {
	Avatar,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Stack,
	Typography,
} from '@mui/material';
import { OrgEmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { ConfirmationModalContent } from 'components/ui/modals/confirmation-modal-content';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { UserEducation } from 'middleware-types';
import { useState } from 'react';
import { usePopupMenu } from 'utils/usePopupMenu';
import { SkillsDisplay } from '../shared/skills-display';
import { EducationForm } from './education-form';
import { useDeleteEducation, useUpdateEducation } from './hooks';

const formatYears = (education: UserEducation) => {
	const { startYear, endYear } = education;
	if (startYear && endYear) {
		if (startYear === endYear) return startYear.toString();
		return `${startYear} - ${endYear}`;
	}
	if (startYear) return `Started in ${startYear}`;
	if (endYear) return `Completed in ${endYear}`;
	return '';
};

interface EducationItemProps {
	userId: string;
	education: UserEducation;
	canEdit: boolean;
}

export const EducationItem = ({ userId, education, canEdit }: EducationItemProps) => {
	const { anchorEl, menuOpen, openMenu, closeMenu } = usePopupMenu();
	const [editing, setEditing] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	const { updateEducation } = useUpdateEducation(userId, education.id);
	const { deleteEducation } = useDeleteEducation(userId, education.id);

	const displayName = education.school || education.organizationEmblem?.displayName;

	return (
		<Stack direction="row" alignItems="flex-start" p={2.5} spacing={1.5}>
			{education.organizationId ? (
				<OrgEmblemAvatar id={education.organizationId} size={60} />
			) : (
				<Avatar
					src={education.logoUrl}
					variant="rounded"
					sx={{
						'bgcolor': education.logoUrl ? 'white' : undefined,
						'height': 60,
						'width': 60,
						'fontSize': 30,
						'& img': { objectFit: 'contain' },
					}}>
					<SchoolOutlined sx={{ fontSize: '1.25em' }} />
				</Avatar>
			)}
			{editing ? (
				<EducationForm
					education={education}
					onSubmitProp={updateEducation}
					onClose={() => setEditing(false)}
				/>
			) : (
				<Stack flex={1} spacing={1.5}>
					<Stack
						direction="row"
						spacing={2}
						alignItems="flex-start"
						justifyContent="space-between">
						<Stack spacing={0.5}>
							<Typography variant="h4" lineHeight="normal">
								{displayName}
							</Typography>
							{(education.degree || education.field) && (
								<Typography variant="body1" lineHeight="normal">
									{education.degree}
									{education.degree && education.field && ' - '}
									{education.field}
								</Typography>
							)}
							{(education.startYear || education.endYear) && (
								<Typography variant="subtitle1" lineHeight="normal">
									{formatYears(education)}
								</Typography>
							)}
						</Stack>
						{canEdit && (
							<IconButton onClick={openMenu}>
								<MoreHoriz />
							</IconButton>
						)}
					</Stack>
					<SkillsDisplay skillIds={education.skillIds} />
					{education.description && (
						<Typography variant="body1" lineHeight="normal">
							{education.description}
						</Typography>
					)}
				</Stack>
			)}
			{/** menu */}
			<Menu anchorEl={anchorEl} open={menuOpen} onClose={closeMenu} onClick={closeMenu}>
				<MenuItem onClick={() => setEditing(true)}>
					<ListItemIcon>
						<EditOutlined />
					</ListItemIcon>
					<ListItemText>Edit</ListItemText>
				</MenuItem>
				<MenuItem onClick={() => setDeleteModalOpen(true)}>
					<ListItemIcon>
						<DeleteOutlined />
					</ListItemIcon>
					<ListItemText>Delete</ListItemText>
				</MenuItem>
			</Menu>
			{/** delete modal */}
			<ModalOrDrawer open={deleteModalOpen}>
				<ConfirmationModalContent
					variant="destructive"
					primaryText={`Delete ${displayName}?`}
					secondaryText="Do you really want to delete the school? This action cannot be undone"
					onSubmit={deleteEducation}
					onClose={() => setDeleteModalOpen(false)}
				/>
			</ModalOrDrawer>
		</Stack>
	);
};
