import { Box, Card, CardActionArea, Divider, Stack, Typography } from '@mui/material';
import { FileInstanceInformation } from 'middleware-types';
import { memo, useEffect, useRef, useState } from 'react';
import { ExtensionIcon, useDownloadFileInstance, useLazyDownloadUrl } from 'utils/fileUtils';
import { theme } from 'utils/theme';
import { FileMenuButton } from './file-menu-button';
import { FolderLink } from './folder-link';

interface FileGridItemProps {
	file: FileInstanceInformation;
	index: number;
	setIndexSelected: ((index: number, e: React.MouseEvent) => void) | undefined;
	selected?: boolean;
}

export const FileGridItem = memo(function FileGridItem({
	file,
	index,
	setIndexSelected,
	selected = false,
}: FileGridItemProps) {
	const [getDownloadUrl, { data, called }] = useLazyDownloadUrl({
		fetchPolicy: 'network-only',
		variables: {
			fileInstanceId: file.id,
			sasUrlOnly: true,
		},
	});

	const [imageError, setImageError] = useState(false);
	const [contextMenuOpen, setContextMenuOpen] = useState(false);

	const onContextMenu = (e) => {
		e.preventDefault();
		setContextMenuOpen(true);
	};
	const downloadFileInstance = useDownloadFileInstance();

	const isImage = file.mimeType.startsWith('image');
	const cdnUrl = file.cdnUrl;
	const sasUrl = data?.getFileInstanceDownloadUrl.downloadUrl;
	const url = cdnUrl ?? sasUrl;
	const cardRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (!isImage || url || called) return;
		const observer = new IntersectionObserver((entries) => {
			if (entries[0].isIntersecting) getDownloadUrl();
		});
		if (cardRef.current) observer.observe(cardRef.current);
		return () => {
			if (cardRef.current) observer.unobserve(cardRef.current);
		};
	}, [isImage, url, called]);

	const onClick = setIndexSelected
		? (e: React.MouseEvent) => setIndexSelected(index, e)
		: undefined;

	const handleClick = (e: React.MouseEvent) => {
		switch (e.detail) {
			case 1:
				return onClick ? onClick(e) : downloadFileInstance(file, true);
			case 2:
				return downloadFileInstance(file, true);
		}
	};

	return (
		<Card variant="outlined" ref={cardRef} sx={{ height: '100%' }}>
			<CardActionArea
				component="div"
				onClick={handleClick}
				onContextMenu={(e) => onContextMenu(e)}
				sx={{
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'stretch',
					backgroundColor: selected ? theme.palette.primary.light : undefined,
				}}>
				<Stack
					alignItems="center"
					justifyContent="center"
					p={{ xs: 1, sm: 1.5 }}
					height={{ xs: 112, sm: 196 }}
					bgcolor="neutral.50">
					{isImage && url && !imageError ? (
						<Box
							component="img"
							src={url}
							maxHeight="100%"
							maxWidth="100%"
							onError={() => setImageError(true)}
						/>
					) : (
						<ExtensionIcon
							filename={file.fileName}
							sx={{ color: 'neutral.500', fontSize: 48 }}
						/>
					)}
				</Stack>
				<Divider />
				<Stack direction="row" alignItems="center" spacing={1.5} px={2} py={0.75} flex={1}>
					<ExtensionIcon filename={file.fileName} sx={{ color: 'neutral.500' }} />
					<Stack flex={1} overflow="hidden">
						<Typography variant="body1" noWrap>
							{file.fileName}
						</Typography>
						<FolderLink folder={file.folder} />
					</Stack>
					<FileMenuButton
						file={file}
						menuOpen={contextMenuOpen}
						setMenuOpen={setContextMenuOpen}
					/>
				</Stack>
			</CardActionArea>
		</Card>
	);
});
