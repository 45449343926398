import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import { v4 as uuid4 } from 'uuid';
import {
	ChatMessageRating,
	CustomInstructionsInput,
	Mutation,
	MutationChatConversationDeleteSavedArgs,
	MutationChatConversationSaveArgs,
	MutationChatCustomInstructionsUpdateArgs,
	MutationChatMessageLeaveFeedbackArgs,
	MutationChatMessageRequestArgs,
	MutationDeletePromptClippingArgs,
	MutationPromptClippingRequestArgs,
	MutationUpdatePromptClippingArgs,
	PromptClippingRequest,
	Query,
	QueryChatConversationGetSavedArgs,
	QueryChatCustomInstructionsGetArgs,
	QueryUserLegacyAdjustmentCompaniesWithMetricsBySystemAndAcidArgs,
	SaveConversationRequest,
	UserChatMessage,
	UserChatMessageFileRequest,
} from 'middleware-types';
import { useState } from 'react';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { useSession } from 'utils/session';
import { UserChatFileWithToken } from './evox';

const CHAT_RESPONSE_FIELDS = gql`
	fragment ChatResponseFields on UserChatMessageResponse {
		chatConversationId
		chatConversationName
		chatMessages {
			chatMessageId
			dateTimeUtc
			sequence
			role
			content
			functionName
			rating
			dateTimeUtc
			files {
				chatFileId
				name
			}
			footnotes {
				chatFootnoteId
				url
				fileName
				snippet
				urlDisplayName
			}
		}
	}
`;

export const GET_LATEST = gql`
	${CHAT_RESPONSE_FIELDS}
	query GetLatest($userId: ID!) {
		chatConversationGetLatest(userId: $userId) {
			conversation {
				...ChatResponseFields
			}
		}
	}
`;

const CHAT_REQUEST = gql`
	${CHAT_RESPONSE_FIELDS}
	mutation ChatRequest($userId: ID!, $request: UserChatMessageRequest!) {
		chatMessageRequest(userId: $userId, request: $request) {
			...ChatResponseFields
		}
	}
`;

const GET_SAVED_CONVERSATION = gql`
	${CHAT_RESPONSE_FIELDS}
	query getSavedChat($userId: ID!, $conversationId: ID!) {
		chatConversationGetSaved(userId: $userId, conversationId: $conversationId) {
			...ChatResponseFields
		}
	}
`;

export const useEvox = () => {
	const { user } = useSession();
	const toast = useToast();

	const [conversationId, setConversationId] = useState<string | null>(null);
	const [conversationName, setConversationName] = useState<string | null>(null);
	const [messages, setMessages] = useState<UserChatMessage[]>([]);
	const [pendingRequest, setPendingRequest] = useState<string | null>(null);
	const [pendingFileRequest, setPendingFileRequest] = useState<
		UserChatFileWithToken[] | undefined
	>(undefined);
	const [chatHasBeenReset, setChatHasBeenReset] = useState(false);

	const { loading: latestConversationLoading } = useQuery<
		Pick<Query, 'chatConversationGetLatest'>
	>(GET_LATEST, {
		variables: {
			userId: user.userId,
		},
		onCompleted: (data) => {
			const latestConversation = data.chatConversationGetLatest.conversation;
			if (latestConversation === null || latestConversation === undefined) return;
			setConversationId(latestConversation.chatConversationId);
			setConversationName(latestConversation.chatConversationName ?? null);
			const messages = latestConversation.chatMessages ?? [];
			setMessages(messages);
		},
		fetchPolicy: 'no-cache',
	});

	const [_sendRequest, { loading: requestLoading }] = useMutation<
		Pick<Mutation, 'chatMessageRequest'>,
		MutationChatMessageRequestArgs
	>(CHAT_REQUEST);

	const [_getSavedConversation, { loading: savedConversationLoading }] = useLazyQuery<
		Pick<Query, 'chatConversationGetSaved'>,
		QueryChatConversationGetSavedArgs
	>(GET_SAVED_CONVERSATION, { fetchPolicy: 'cache-and-network' });

	const resetChat = () => {
		setConversationId(uuid4());
		setConversationName(null);
		setMessages([]);
		setPendingRequest(null);
		setPendingFileRequest(undefined);
		setChatHasBeenReset(true);
	};

	const sendRequest = (request: string, requestFiles?: UserChatFileWithToken[]) => {
		setPendingRequest(request);
		if (requestFiles) setPendingFileRequest(requestFiles);

		_sendRequest({
			variables: {
				userId: user.userId,
				request: {
					conversationId: conversationId ?? uuid4(),
					content: request,
					attachments:
						requestFiles && requestFiles.length > 0
							? requestFiles.map(
									(file) =>
										<UserChatMessageFileRequest>{
											fileId: file.chatFileId,
											fileUploadToken: file.fileUploadToken,
										}
							  )
							: undefined,
					callerTimezoneOffsetMinutes: new Date().getTimezoneOffset(),
				},
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) return;
				if (!res.data) return;
				setPendingRequest(null);
				setPendingFileRequest(undefined);
				if (conversationId === null) {
					setConversationId(res.data.chatMessageRequest.chatConversationId);
				}
				setConversationName(res.data.chatMessageRequest.chatConversationName ?? null);
				const newMessages = res.data.chatMessageRequest.chatMessages;
				if (newMessages !== undefined) {
					setMessages((currentMessages) => [...currentMessages, ...newMessages]);
				}
			})
			.catch((e) => {
				if (e.message === 'The user aborted a request.') {
					return;
				} else if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
					// Extract any user message that might have been provided with the returned error so we can
					// display a more helpful error. Else, it will display a generic error message.
					let userMessage =
						e.graphQLErrors[0]?.extensions?.response?.body?.details?.userMessage;
					if (userMessage !== undefined) {
						toast.push(userMessage, { variant: 'error' });
						return;
					}
				}

				handleNoResponse({ toast });
			});
	};

	const getSavedConversation = (conversationId: string) =>
		_getSavedConversation({ variables: { userId: user.userId, conversationId } })
			.then((res) => {
				if (res.data) {
					setChatHasBeenReset(false);
					setConversationId(res.data.chatConversationGetSaved.chatConversationId);
					setConversationName(
						res.data.chatConversationGetSaved.chatConversationName ?? null
					);
					const messages = res.data.chatConversationGetSaved.chatMessages ?? [];
					setMessages(messages);
					return true;
				}
				return false;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});

	return {
		conversationId,
		conversationName,
		setConversationName,
		messages,
		pendingRequest,
		pendingFileRequest,
		resetChat,
		sendRequest,
		requestLoading,
		getSavedConversation,
		savedConversationLoading,
		chatHasBeenReset,
		latestConversationLoading,
	};
};

const GET_SAVED_CONVERSATIONS = gql`
	query getSavedChats($userId: ID!) {
		chatConversationGetAllSaved(userId: $userId) {
			savedConversations {
				conversationId
				conversationName
			}
		}
	}
`;

export const useSavedConversations = () => {
	const { user } = useSession();
	const toast = useToast();

	const { data, loading } = useQuery<Pick<Query, 'chatConversationGetAllSaved'>>(
		GET_SAVED_CONVERSATIONS,
		{
			variables: {
				userId: user.userId,
			},
			fetchPolicy: 'no-cache',
			onError: () =>
				toast.push('Unable to load pinned conversations', {
					variant: 'error',
				}),
		}
	);
	const savedConversations = data?.chatConversationGetAllSaved.savedConversations ?? [];

	return {
		savedConversations,
		loading,
	};
};

const SAVE_CONVERSATION = gql`
	mutation saveConversation(
		$userId: ID!
		$conversationId: ID!
		$request: SaveConversationRequest!
	) {
		chatConversationSave(userId: $userId, conversationId: $conversationId, request: $request) {
			conversationId
			conversationName
		}
	}
`;

export const useSaveConversation = () => {
	const toast = useToast();
	const { user } = useSession();

	const [_saveConversation] = useMutation<
		Pick<Mutation, 'chatConversationSave'>,
		MutationChatConversationSaveArgs
	>(SAVE_CONVERSATION, {
		refetchQueries: [GET_SAVED_CONVERSATIONS],
		awaitRefetchQueries: true,
	});

	const saveConversation = async (conversationId: string, request: SaveConversationRequest) => {
		return await _saveConversation({
			variables: {
				userId: user.userId,
				conversationId,
				request,
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Successfully saved conversation.', {
					variant: 'success',
				});
				return res.data?.chatConversationSave.conversationName;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return saveConversation;
};

const DELETE_CONVERSATION = gql`
	mutation deleteConversation($userId: ID!, $conversationId: ID!) {
		chatConversationDeleteSaved(userId: $userId, conversationId: $conversationId)
	}
`;

export const useDeleteSavedConversation = () => {
	const toast = useToast();
	const { user } = useSession();

	const [_deleteConversation, { loading }] = useMutation<
		Pick<Mutation, 'chatConversationDeleteSaved'>,
		MutationChatConversationDeleteSavedArgs
	>(DELETE_CONVERSATION, {
		refetchQueries: [GET_SAVED_CONVERSATIONS],
		awaitRefetchQueries: true,
	});

	const deleteConversation = async (conversationId: string) => {
		return await _deleteConversation({
			variables: {
				userId: user.userId,
				conversationId,
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Successfully deleted conversation.', {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { deleteConversation, loading };
};

const GET_CHAT_INSTRUCTIONS = gql`
	query GetChatInstructions($userId: ID!) {
		chatCustomInstructionsGet(userId: $userId) {
			enableCustomInstructions
			about
			responseTargeting
		}
	}
`;

const UPDATE_CHAT_INSTRUCTIONS = gql`
	mutation UpdateChatInstructions($userId: ID!, $customInstructions: CustomInstructionsInput!) {
		chatCustomInstructionsUpdate(userId: $userId, customInstructions: $customInstructions)
	}
`;

export const useChatInstructions = () => {
	const { user } = useSession();
	const toast = useToast();

	const { data, loading } = useQuery<
		Pick<Query, 'chatCustomInstructionsGet'>,
		QueryChatCustomInstructionsGetArgs
	>(GET_CHAT_INSTRUCTIONS, {
		variables: {
			userId: user.userId,
		},
		onError: () =>
			toast.push('Unable to load chat instructions', {
				variant: 'error',
			}),
	});

	const [_updateChatInstructions] = useMutation<
		Pick<Mutation, 'chatCustomInstructionsUpdate'>,
		MutationChatCustomInstructionsUpdateArgs
	>(UPDATE_CHAT_INSTRUCTIONS, {
		refetchQueries: [GET_CHAT_INSTRUCTIONS],
		awaitRefetchQueries: true,
	});

	const updateChatInstructions = async (customInstructions: CustomInstructionsInput) => {
		return await _updateChatInstructions({
			variables: { userId: user.userId, customInstructions },
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Successfully updated custom chat instructions.', {
					variant: 'success',
				});
				return true;
			})
			.catch((e) => {
				console.log(JSON.stringify(e));
				handleNoResponse({ toast });
				return false;
			});
	};

	return {
		chatInstructions: data?.chatCustomInstructionsGet,
		loading,
		updateChatInstructions,
	};
};

const LEAVE_FEEDBACK = gql`
	mutation LeaveFeedback($userId: ID!, $messageId: ID!, $request: ChatMessageFeedback!) {
		chatMessageLeaveFeedback(userId: $userId, messageId: $messageId, request: $request) {
			chatMessageId
			rating
		}
	}
`;

export const useEvoxFeedback = () => {
	const { user } = useSession();

	const [_leaveFeedback] = useMutation<
		Pick<Mutation, 'chatMessageLeaveFeedback'>,
		MutationChatMessageLeaveFeedbackArgs
	>(LEAVE_FEEDBACK);

	const leaveFeedback = (messageId: string, rating: ChatMessageRating) => {
		_leaveFeedback({
			variables: {
				userId: user.userId,
				messageId: messageId,
				request: {
					rating,
				},
			},
		});
	};

	return { leaveFeedback };
};

const GET_PROMPT_CLIPPINGS = gql`
	query promptClippingsGet($userId: ID!) {
		promptClippingsGet(userId: $userId) {
			clippings {
				id
				title
				content
			}
		}
	}
`;

export const usePromptClippings = () => {
	const { user } = useSession();
	const toast = useToast();

	const { data, loading } = useQuery<Pick<Query, 'promptClippingsGet'>>(GET_PROMPT_CLIPPINGS, {
		variables: {
			userId: user.userId,
		},
		onError: () =>
			toast.push('Unable to load prompt clippings.', {
				variant: 'error',
			}),
	});

	const promptClippings = data?.promptClippingsGet.clippings ?? [];

	return {
		promptClippings,
		loading,
	};
};

const CREATE_PROMPT_CLIPPING = gql`
	mutation promptClippingRequest($userId: ID!, $request: PromptClippingRequest!) {
		promptClippingRequest(userId: $userId, request: $request) {
			id
			title
			content
		}
	}
`;

export const useCreatePromptClipping = () => {
	const { user } = useSession();
	const toast = useToast();

	const [_createPromptClipping, loading] = useMutation<
		Pick<Mutation, 'promptClippingRequest'>,
		MutationPromptClippingRequestArgs
	>(CREATE_PROMPT_CLIPPING, {
		refetchQueries: [GET_PROMPT_CLIPPINGS],
		awaitRefetchQueries: true,
	});

	const createPromptClipping = async (request: PromptClippingRequest) => {
		return await _createPromptClipping({
			variables: {
				userId: user.userId,
				request,
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Successfully saved prompt clipping.', {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return {
		createPromptClipping,
		loading,
	};
};

const UPDATE_PROMPT_CLIPPING = gql`
	mutation updatePromptClipping(
		$userId: ID!
		$request: PromptClippingRequest!
		$clippingId: ID!
	) {
		updatePromptClipping(userId: $userId, request: $request, clippingId: $clippingId) {
			id
			title
			content
		}
	}
`;

export const useUpdatePromptClipping = () => {
	const { user } = useSession();
	const toast = useToast();

	const [_createPromptClipping, loading] = useMutation<
		Pick<Mutation, 'updatePromptClipping'>,
		MutationUpdatePromptClippingArgs
	>(UPDATE_PROMPT_CLIPPING, {
		refetchQueries: [GET_PROMPT_CLIPPINGS],
		awaitRefetchQueries: true,
	});

	const updatePromptClipping = async (clippingId: string, request: PromptClippingRequest) => {
		return await _createPromptClipping({
			variables: {
				userId: user.userId,
				clippingId,
				request,
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Successfully updated prompt clipping.', {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return {
		updatePromptClipping,
		loading,
	};
};

const DELETE_CLIPPING = gql`
	mutation deletePromptClipping($userId: ID!, $clippingId: ID!) {
		deletePromptClipping(userId: $userId, clippingId: $clippingId)
	}
`;

export const useDeleteClipping = () => {
	const toast = useToast();
	const { user } = useSession();

	const [_deleteClipping, { loading }] = useMutation<
		Pick<Mutation, 'deletePromptClipping'>,
		MutationDeletePromptClippingArgs
	>(DELETE_CLIPPING, {
		refetchQueries: [GET_PROMPT_CLIPPINGS],
		awaitRefetchQueries: true,
	});

	const deleteClipping = async (clippingId: string) => {
		return await _deleteClipping({
			variables: {
				userId: user.userId,
				clippingId,
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Successfully deleted clipping.', {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { deleteClipping, loading };
};
const GET_AUTHORIZED_LEGACY_USERS_FOR_CLAIM_BY_COGNITO_ID = gql`
	query linkedUsersWithClaimAccess($systemId: Int!, $acid: Int!) {
		userLegacyAdjustmentCompaniesWithMetricsBySystemAndAcid(systemId: $systemId, acid: $acid) {
			adjustmentCompaniesWithMetrics {
				adjCoName
				adjCoLogo
				systemUrl
				dbid
				userId
				userRole
				userFirstName
				userLastName
			}
		}
	}
`;

export const useGetAuthorizedLegacyUsersForClaimByCognitoId = (systemId: number, acid: number) => {
	const toast = useToast();
	const { data, loading } = useQuery<
		Pick<Query, 'userLegacyAdjustmentCompaniesWithMetricsBySystemAndAcid'>,
		QueryUserLegacyAdjustmentCompaniesWithMetricsBySystemAndAcidArgs
	>(GET_AUTHORIZED_LEGACY_USERS_FOR_CLAIM_BY_COGNITO_ID, {
		variables: {
			systemId,
			acid,
		},
		fetchPolicy: 'network-only',
		onError: () =>
			toast.push('Unable to load linked legacy users with access to this claim', {
				variant: 'error',
			}),
	});
	const users =
		data?.userLegacyAdjustmentCompaniesWithMetricsBySystemAndAcid
			.adjustmentCompaniesWithMetrics ?? [];

	return {
		users,
		loading,
	};
};
