import { Box, FormControlLabel, Stack, Typography, TypographyProps } from '@mui/material';
import { useEmblem } from 'components/ui/emblem/hooks';
import { CheckboxField } from 'components/ui/fields';
import {
	EmblemEntityType,
	UserEducation,
	UserExperienceCompany,
	UserSkillPlaceUsed,
	UserSkillPlaceUsedCreate,
} from 'middleware-types';
import { useEducation } from '../education/hooks';
import { useUserProfileExperience } from '../user-experience/hooks';

export interface PlacesUsedFormValues {
	experience: {
		id: string;
		checked: boolean;
	}[];
	education: {
		id: string;
		checked: boolean;
	}[];
}

export const convertPlacesUsedFormValues = (
	values: PlacesUsedFormValues
): UserSkillPlaceUsedCreate[] => {
	return [
		...values.experience.filter((e) => e.checked).map((e) => ({ userExperienceId: e.id })),
		...values.education.filter((e) => e.checked).map((e) => ({ userEducationId: e.id })),
	];
};

export const usePlacesUsedInitialValues = (
	userId: string,
	placesUsed: UserSkillPlaceUsed[] = []
): PlacesUsedFormValues => {
	const { companies } = useUserProfileExperience(userId);
	const { education } = useEducation(userId);
	return {
		experience: companies.map((company) => ({
			id: company.id,
			checked: placesUsed.some((p) => p.userExperienceId === company.id),
		})),
		education: education.map((e) => ({
			id: e.id,
			checked: placesUsed.some((p) => p.userEducationId === e.id),
		})),
	};
};

interface PlacesUsedCheckboxesProps {
	userId: string;
	typographyVariant?: TypographyProps['variant'];
}

export const PlacesUsedCheckboxes = ({
	userId,
	typographyVariant = 'h4',
}: PlacesUsedCheckboxesProps) => {
	const { companies } = useUserProfileExperience(userId);
	const { education } = useEducation(userId);

	return (
		<Stack spacing={2}>
			<Stack spacing={0.5}>
				<Typography variant={typographyVariant}>Experiences</Typography>
				<Stack spacing={-0.75}>
					{companies.map((company, index) => (
						<ExperienceCheckbox key={index} index={index} company={company} />
					))}
				</Stack>
			</Stack>
			<Stack spacing={0.5}>
				<Typography variant={typographyVariant}>Education</Typography>
				<Stack spacing={-0.75}>
					{education.map((e, index) => (
						<EducationCheckbox
							key={index}
							index={index}
							education={e}
							allEducation={education}
						/>
					))}
				</Stack>
			</Stack>
		</Stack>
	);
};

interface ExperienceCheckboxProps {
	index: number;
	company: UserExperienceCompany;
}

const ExperienceCheckbox = ({ index, company }: ExperienceCheckboxProps) => {
	const orgId = company.organizationId ?? '';
	const { emblem } = useEmblem(orgId, EmblemEntityType.Organization, !orgId);

	const companyName = emblem?.displayName ?? company.name;

	return (
		<Box>
			<FormControlLabel
				label={companyName}
				control={<CheckboxField name={`experience.${index}.checked`} />}
			/>
		</Box>
	);
};

interface EducationCheckboxProps {
	index: number;
	education: UserEducation;
	allEducation: UserEducation[];
}

const EducationCheckbox = ({ index, education, allEducation }: EducationCheckboxProps) => {
	const schoolName = education.school || education.organizationEmblem?.displayName;
	const isDuplicate = allEducation.some((e) => {
		if (e.id === education.id) return false;
		return (
			(education.organizationId && e.organizationId === education.organizationId) ||
			(education.school && e.school === education.school)
		);
	});

	return (
		<Box>
			<FormControlLabel
				label={
					<>
						{schoolName}{' '}
						{isDuplicate && education.degree && (
							<Typography display="inline" color="GrayText">
								({education.degree})
							</Typography>
						)}
					</>
				}
				control={<CheckboxField name={`education.${index}.checked`} />}
			/>
		</Box>
	);
};
