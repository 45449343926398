import { Badge, IconButton } from '@mui/material';
import clsx from 'clsx';
import { EmblemEntityType } from 'middleware-types';
import { ReactNode, useState } from 'react';
import { ModalOrDrawer } from '../modals/modal-or-drawer';
import { OrgEmblemAvatar, UserEmblemAvatar } from './emblem-avatar';
import { useEmblem } from './hooks';
import { OrganizationEmblemUploaderModal } from './org-emblem-uploader';
import { AvatarOrBannerUploaderModal } from './user-emblem-uploader';

interface AvatarUploadButtonProps {
	onClick: () => void;
	variant: 'circular' | 'rectangular';
	disabled?: boolean;
	children: ReactNode;
}

const AvatarUploadButton = ({
	onClick,
	variant,
	disabled = false,
	children,
}: AvatarUploadButtonProps) => {
	return (
		<IconButton
			disabled={disabled}
			className={clsx('group', variant === 'rectangular' && 'rounded-none', '-m-2')}
			onClick={onClick}>
			<Badge
				componentsProps={{
					badge: {
						className:
							'opacity-0 transition-opacity duration-150 group-hover:opacity-100',
					},
				}}
				overlap={variant}
				badgeContent={<img src="/img/camera.svg" alt="Camera Icon" />}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
				{children}
			</Badge>
		</IconButton>
	);
};

export const UserAvatarUploadButton = ({
	id,
	disabled,
	size = 160,
}: {
	id: string;
	disabled?: boolean;
	size?: number;
}) => {
	const { emblem } = useEmblem(id, EmblemEntityType.User);
	const file = emblem?.avatarFile?.file?.currentInstance;

	const [modalOpen, setModalOpen] = useState(false);

	return (
		<>
			<AvatarUploadButton
				variant="circular"
				disabled={disabled}
				onClick={() => setModalOpen(true)}>
				<UserEmblemAvatar id={id} size={size} noDropdown />
			</AvatarUploadButton>
			<ModalOrDrawer open={modalOpen} dialogProps={{ maxWidth: 'md' }}>
				<AvatarOrBannerUploaderModal
					userId={id}
					fileInstance={file}
					type="avatar"
					onClose={() => setModalOpen(false)}
				/>
			</ModalOrDrawer>
		</>
	);
};

export const OrgAvatarUploadButton = ({
	id,
	disabled,
	size = 160,
}: {
	id: string;
	disabled?: boolean;
	size?: number;
}) => {
	const { emblem } = useEmblem(id, EmblemEntityType.Organization);
	const file = emblem?.avatarFile?.file?.currentInstance;

	const [modalOpen, setModalOpen] = useState(false);

	return (
		<>
			<AvatarUploadButton
				variant="rectangular"
				disabled={disabled}
				onClick={() => setModalOpen(true)}>
				<OrgEmblemAvatar id={id} size={size} noDropdown />
			</AvatarUploadButton>
			<ModalOrDrawer open={modalOpen} dialogProps={{ maxWidth: 'md' }}>
				<OrganizationEmblemUploaderModal
					organizationId={id}
					fileInstance={file}
					type="avatar"
					onClose={() => setModalOpen(false)}
				/>
			</ModalOrDrawer>
		</>
	);
};
