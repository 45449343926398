import { gql, useQuery } from '@apollo/client';
import { useOrgId } from 'components/pages/org/outlet';
import { Query, QueryBillingOrganizationBillingInvoicesGetArgs } from 'middleware-types';

const GET_RECENT_INVOICES = gql`
	query GetRecentInvoices($organizationId: String!, $numDays: Int!) {
		billingOrganizationBillingInvoicesGet(organizationId: $organizationId, numDays: $numDays) {
			outstandingBalance
			invoices {
				invoiceAmount
				invoiceDate
				invoiceNumber
				invoicePdfUrl
				isSubscription
				status
			}
		}
	}
`;

export const useRecentInvoices = (numDays: number) => {
	const organizationId = useOrgId();
	const { data, loading } = useQuery<
		Pick<Query, 'billingOrganizationBillingInvoicesGet'>,
		QueryBillingOrganizationBillingInvoicesGetArgs
	>(GET_RECENT_INVOICES, { variables: { organizationId, numDays } });

	const outstandingBalance = data?.billingOrganizationBillingInvoicesGet.outstandingBalance ?? 0;
	const invoices = data?.billingOrganizationBillingInvoicesGet.invoices ?? [];
	return { outstandingBalance, invoices, loading };
};
