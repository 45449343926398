import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { TextField } from 'components/ui/fields';
import { Formik, FormikErrors } from 'formik';
import { UserEducation, UserEducationUpdate } from 'middleware-types';
import { useValidation } from 'utils/useValidation';
import { EmptyStringify } from 'utils/utils';
import { OrgSelectionOrManualInput } from '../shared/org-selection-or-manual-input';

type EducationFormValues = EmptyStringify<Omit<UserEducationUpdate, 'startMonth' | 'endMonth'>>;

const hasValue = (value: any) => value || value === 0;
const validate = (values: EducationFormValues) => {
	const { organizationId, school, startYear, endYear } = values;
	const errors: FormikErrors<EducationFormValues> = {};

	if (!organizationId && !school)
		errors.school = 'School name required if pre-existing organization is not selected';

	if (hasValue(startYear) && !Number.isInteger(startYear))
		errors.startYear = 'Start Year must be an integer';
	if (hasValue(endYear) && !Number.isInteger(endYear))
		errors.endYear = 'End Year must be an integer';

	if (hasValue(startYear) && hasValue(endYear)) {
		if (startYear! > endYear!) {
			errors.startYear = 'Start Year cannot be greater than End Year';
		}
	}

	return errors;
};

interface EducationFormProps {
	education?: UserEducation;
	onSubmitProp: (update: UserEducationUpdate) => Promise<boolean>;
	onClose: () => void;
}

export const EducationForm = ({ education, onSubmitProp, onClose }: EducationFormProps) => {
	const initialValues: EducationFormValues = {
		school: education?.school ?? '',
		organizationId: education?.organizationId ?? '',
		websiteUrl: education?.websiteUrl ?? '',
		logoUrl: education?.logoUrl ?? '',
		degree: education?.degree ?? '',
		field: education?.field ?? '',
		description: education?.description ?? '',
		startYear: education?.startYear ?? '',
		endYear: education?.endYear ?? '',
	};

	const onSubmit = async (values: EducationFormValues) => {
		const update: UserEducationUpdate = {
			school: values.school,
			organizationId: values.organizationId,
			websiteUrl: values.websiteUrl,
			logoUrl: values.logoUrl || undefined,
			degree: values.degree,
			field: values.field,
			description: values.description,
			startYear: values.startYear || undefined,
			endYear: values.endYear || undefined,
		};
		await onSubmitProp(update).then((success) => {
			if (success) onClose();
		});
	};

	const validation = useValidation('UserEducationUpdate');

	return (
		<Formik<EducationFormValues>
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={validation.schema}
			validate={validate}>
			{({ submitForm, isSubmitting, isValid, dirty }) => (
				<Stack flex={1} spacing={2}>
					<OrgSelectionOrManualInput
						manualInputFieldName="school"
						manualInputFieldLabel="School"
						searchFieldHelperText="Enter the URL of your school to do a logo search."
					/>
					<TextField name="degree" label="Degree" />
					<TextField name="field" label="Field of Study" />
					<Stack direction="row" spacing={1.5}>
						<TextField name="startYear" label="Start Year" type="number" />
						<TextField name="endYear" label="End Year" type="number" />
					</Stack>
					<TextField name="description" label="Description" multiline minRows={3} />
					<Stack direction="row" justifyContent="flex-end" spacing={1}>
						<Button variant="outlined" onClick={onClose}>
							Cancel
						</Button>
						<LoadingButton
							variant="contained"
							color="primary"
							disabled={!isValid || !dirty}
							loading={isSubmitting}
							onClick={submitForm}>
							Save
						</LoadingButton>
					</Stack>
				</Stack>
			)}
		</Formik>
	);
};
