import { ArrowDownward, ArrowDropDown, ArrowUpward, Check } from '@mui/icons-material';
import { Button, ListSubheader, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { SortDirection } from 'middleware-types';
import { useState } from 'react';

export enum BillingSummariesSortColumn {
	OrganizationName = 'Organization Name',
	Status = 'Status',
	AmountBilled = 'Amount Billed',
	BalanceDue = 'Balance Due',
}

interface BillingSummariesSortDropdownProps {
	column: BillingSummariesSortColumn;
	setColumn: (value: BillingSummariesSortColumn) => void;
	direction: SortDirection;
	setDirection: (value: SortDirection) => void;
	flex?: boolean;
}

export const BillingSummariesSortDropdown = ({
	column,
	setColumn,
	direction,
	setDirection,
}: BillingSummariesSortDropdownProps) => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	return (
		<>
			<Button
				variant="outlined"
				startIcon={
					direction === SortDirection.Ascending ? <ArrowUpward /> : <ArrowDownward />
				}
				endIcon={<ArrowDropDown />}
				onClick={(e) => setAnchorEl(e.currentTarget)}
				sx={{ height: 40 }}>
				Sort by {column.toLowerCase()}
			</Button>
			{/** menu */}
			<Menu
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				MenuListProps={{
					sx: { width: 300 },
				}}>
				<ListSubheader sx={{ bgcolor: 'neutral.50', lineHeight: 1 }}>
					<Typography variant="overline">COLUMN</Typography>
				</ListSubheader>
				{Object.values(BillingSummariesSortColumn).map((option) => (
					<MenuItem key={option} onClick={() => setColumn(option)}>
						<Stack
							py={0.5}
							width="100%"
							direction="row"
							alignItems="center"
							justifyContent="space-between">
							{option}
							{option === column && <Check color="primary" sx={{ my: -1 }} />}
						</Stack>
					</MenuItem>
				))}
				<ListSubheader sx={{ bgcolor: 'neutral.50', lineHeight: 1 }}>
					<Typography variant="overline">ORDER</Typography>
				</ListSubheader>
				{Object.values(SortDirection).map((option) => (
					<MenuItem key={option} onClick={() => setDirection(option)}>
						<Stack
							py={0.5}
							width="100%"
							direction="row"
							alignItems="center"
							justifyContent="space-between">
							{option}
							{option === direction && <Check color="primary" sx={{ my: -1 }} />}
						</Stack>
					</MenuItem>
				))}
			</Menu>
		</>
	);
};
