import { Box } from '@mui/material';
import { OrgNavigation } from 'components/pages/org/org-nav';
import { DelayedLoading } from 'components/ui/delayed-loading';
import { useFindIdFromHandleType } from 'components/ui/handle';
import { SideRail } from 'components/ui/side-rail/side-rail';
import { EntitySearchType } from 'middleware-types';
import { Outlet, useOutletContext } from 'react-router-dom';
import { Permission } from 'utils/permissions';
import { useAnonymousSession } from 'utils/session';
import { useAssociateUser } from 'utils/useAssociateUser';

const OrgOutlet = () => {
	const { user } = useAnonymousSession();
	const { id, loading } = useFindIdFromHandleType(EntitySearchType.Organization);
	const { hasPermission } = useAssociateUser(id);
	const showOrgNav = hasPermission(Permission.Org_Member) || user?.siteUserId;

	if (loading) return <DelayedLoading />;

	return (
		<>
			{id !== undefined && showOrgNav && <OrgNavigation orgId={id} />}
			<Box flex={1} overflow="auto">
				<SideRail isOrgPage>
					<Outlet context={id satisfies OrgOutletContext} />
				</SideRail>
			</Box>
		</>
	);
};

export type OrgOutletContext = string;

export const useOrgId = () => useOutletContext<OrgOutletContext>();

export default OrgOutlet;
