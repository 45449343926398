import { gql, useLazyQuery } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import { Query, QueryBillingPreviewInvoiceGetArgs } from 'middleware-types';
import { useEffect } from 'react';

const GET_INVOICE_PREVIEW = gql`
	query GetInvoicePreview(
		$organizationId: String!
		$invoiceDate: Date!
		$generateDraftInvoice: Boolean!
		$usage: BillingUsage
	) {
		billingPreviewInvoiceGet(
			organizationId: $organizationId
			invoiceDate: $invoiceDate
			generateDraftInvoice: $generateDraftInvoice
			usage: $usage
		) {
			invoices {
				autoAdvance
				collectionMethod
				currency
				customer
				description
				invoiceDate
				invoiceUrl
				organizationName
				invoiceItems {
					currency
					customer
					description
					endDate
					price
					quantity
					startDate
					unitAmountDecimal
				}
			}
		}
	}
`;

export const usePreviewInvoice = () => {
	const toast = useToast();
	const [_fetchPreview, { data, loading }] = useLazyQuery<
		Pick<Query, 'billingPreviewInvoiceGet'>,
		QueryBillingPreviewInvoiceGetArgs
	>(GET_INVOICE_PREVIEW, {
		fetchPolicy: 'no-cache',
		onError: (e) => {
			console.log(JSON.stringify(e));
			toast.push('An error occurred while atempting to fetch the invoice data.', {
				variant: 'error',
			});
		},
	});

	const fetchPreview = async (variables: QueryBillingPreviewInvoiceGetArgs) => {
		await _fetchPreview({ variables });
	};

	useEffect(() => {
		console.log(data);
	}, [data]);

	const invoices = data?.billingPreviewInvoiceGet.invoices ?? [];
	return { fetchPreview, invoices, loading };
};
