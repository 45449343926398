import { useMutation } from '@apollo/client';
import {
	AccountCircleOutlined,
	DeleteOutlined,
	ForumOutlined,
	HistoryOutlined,
	MoreHorizOutlined,
	PowerSettingsNewOutlined,
	SendOutlined,
} from '@mui/icons-material';
import { Badge, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { UserEmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { DeactivateIcon, MaskIcon } from 'components/ui/icons';
import { ConfirmationModalContent } from 'components/ui/modals/confirmation-modal-content';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { useToast } from 'components/ui/toast';
import gql from 'graphql-tag';
import {
	Mutation,
	MutationUserAccountActivationUpdateArgs,
	MutationUserSiteUserInvitationDeleteArgs,
	MutationUserSiteUserInvitationResendArgs,
} from 'middleware-types';
import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Permission } from 'utils/permissions';
import { assume, useSession } from 'utils/session';
import { useSiteUser } from 'utils/useSiteUser';

/* Mutation for deleting an invitation to a site user. */
export const DELETE_INVITATION = gql`
	mutation UserSiteUserInvitationDelete($invitationId: String!) {
		userSiteUserInvitationDelete(invitationId: $invitationId)
	}
`;

/* Mutation for resending an invitation to a site user. */
export const RESEND_INVITATION = gql`
	mutation UserSiteUserInvitationResend($invitationId: String!) {
		userSiteUserInvitationResend(invitationId: $invitationId)
	}
`;

/* Mutation for activating/deactivating a user account. */
export const UPDATE_USERACCOUNT_ACTIVATION = gql`
	mutation UserAccountActivationUpdate($userId: ID!, $operation: ActivationOperation!) {
		userAccountActivationUpdate(userId: $userId, operation: $operation) {
			id
			emailAddress
			handle
			deactivated
		}
	}
`;

/**
 * Props for the context menu.
 *
 * @interface ContextMenuProps
 */
interface PeopleContextMenuProps {
	name: string;
	id: string | undefined | null;
	siteUserId?: string | undefined | null;
	siteUserInvitationId?: string | undefined | null;
	refetch: () => void;
	status: Status;
	handle: string | undefined | null;
	menuOpen: boolean;
	setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * The activation Operation (Activate / Deactivate).
 *
 * @enum {number}
 */
enum ActivationOperation {
	Deactivate = 'Deactivate',
	Activate = 'Activate',
}

/**
 * The Row Action.
 *
 * @enum {number}
 */
enum RowAction {
	DeleteInvitation,
	ActivateSiteUser,
	ActivateSocialUser,
	DeactivateSiteUser,
	DeactivateSocialUser,
	AssumeIdentity,
	ResendInvitation,
}

/**
 * The user status.
 *
 * @enum {number}
 */
enum Status {
	Invited = 'Invited',
	Active = 'Active',
	Inactive = 'Inactive',
	SocialActive = 'SocialActive',
	SocialInactive = 'SocialInactive',
}

/**
 * Renders the context menu for site user rows.
 * @param props
 * @returns
 */
export const PeopleContextMenu = ({
	id,
	name,
	siteUserId,
	siteUserInvitationId,
	handle,
	status,
	refetch,
	menuOpen,
	setMenuOpen,
}: PeopleContextMenuProps) => {
	const { user } = useSession();
	const { hasPermission } = useSiteUser();
	const toast = useToast();

	const buttonRef = useRef<HTMLButtonElement>(null);

	// resend invite
	const [resendInvitation] = useMutation<
		Pick<Mutation, 'userSiteUserInvitationResend'>,
		MutationUserSiteUserInvitationResendArgs
	>(RESEND_INVITATION, {
		onCompleted: () => refetch(),
	});

	// delete invitation
	const [_deleteInvitation] = useMutation<
		Pick<Mutation, 'userSiteUserInvitationDelete'>,
		MutationUserSiteUserInvitationDeleteArgs
	>(DELETE_INVITATION, {
		onCompleted: () => refetch(),
	});

	const deleteInvitation = async () => {
		if (!siteUserInvitationId) return false;
		return await _deleteInvitation({
			variables: { invitationId: siteUserInvitationId },
		})
			.then(() => {
				toast.push(`${name} was successfully deleted.`, {
					variant: 'success',
				});
				return true;
			})
			.catch((err) => {
				toast.push(`An error occurred. Please try again later or contact Support.`, {
					variant: 'error',
				});
				console.log(err);
				return false;
			});
	};

	// activate / deactive user
	const [_updateUserActivation] = useMutation<
		Pick<Mutation, 'userAccountActivationUpdate'>,
		MutationUserAccountActivationUpdateArgs
	>(UPDATE_USERACCOUNT_ACTIVATION, {
		onCompleted: () => refetch(),
		update: (cache) => {
			cache.evict({
				id: `Emblem:${id}`,
			});
		},
	});

	const updateUserActivation = async (operation: ActivationOperation) => {
		if (!id) return false;
		return await _updateUserActivation({
			variables: { userId: id, operation },
		})
			.then(() => {
				toast.push(
					`${name} was successfully ${
						operation === ActivationOperation.Activate ? 'activated' : 'deactivated'
					}.`,
					{
						variant: 'success',
					}
				);
				return true;
			})
			.catch((err) => {
				toast.push(`An error occurred. Please try again later or contact Support.`, {
					variant: 'error',
				});
				console.log(err);
				return false;
			});
	};

	// modal states
	const [assumeIdentityOpen, setAssumeIdentityOpen] = useState(false);
	const [deleteInvitationOpen, setDeleteInvitationOpen] = useState(false);
	const [activateDeactivateModalOpen, setActivateDeactivateModalOpen] = useState(false);

	const onAction = (action: RowAction): void => {
		switch (action) {
			case RowAction.AssumeIdentity:
				setAssumeIdentityOpen(true);
				break;
			case RowAction.ResendInvitation: {
				if (!siteUserInvitationId) break;
				resendInvitation({
					variables: { invitationId: siteUserInvitationId },
				})
					.then(() =>
						toast.push(`${name}'s Site User invitation was successfully resent.`, {
							variant: 'success',
						})
					)
					.catch((err) => {
						toast.push(
							`An error occurred. Please try again later or contact Support.`,
							{ variant: 'error' }
						);
						console.log(err);
					});
				break;
			}
			case RowAction.DeleteInvitation: {
				setDeleteInvitationOpen(true);
				break;
			}
			case RowAction.ActivateSiteUser:
			case RowAction.DeactivateSiteUser:
			case RowAction.ActivateSocialUser:
			case RowAction.DeactivateSocialUser:
				setActivateDeactivateModalOpen(true);
				break;
		}
	};

	const handleRowAction = (action: RowAction): void => {
		setMenuOpen(false);
		if (onAction) {
			onAction(action);
		}
	};

	/**
	 * The actions possible for a context menu.
	 *  @type {*} */
	const tableActions = [
		{
			status: ['Invited'],
			permissions: [Permission.Site_Inv_D],
			action: RowAction.DeleteInvitation,
			text: 'Delete Invitation',
			icon: <DeleteOutlined />,
		},
		{
			status: ['Invited'],
			permissions: [Permission.Site_Inv_C],
			action: RowAction.ResendInvitation,
			text: 'Resend Invitation',
			icon: <SendOutlined />,
		},
		{
			status: ['Active'],
			disabled: siteUserId === user.siteUserId,
			permissions: [Permission.Site_User_U],
			action: RowAction.DeactivateSiteUser,
			text: 'Deactivate User',
			icon: <DeactivateIcon />,
		},
		{
			status: ['Inactive'],
			permissions: [Permission.Site_User_U],
			action: RowAction.ActivateSiteUser,
			text: 'Activate User',
			icon: <PowerSettingsNewOutlined />,
		},
		{
			status: ['SocialActive'],
			disabled:
				id === user?.userId || user?.siteUserId === null || user?.siteUserId === undefined,
			permissions: [Permission.SocialUser_Account_U],
			action: RowAction.DeactivateSocialUser,
			text: 'Deactivate User',
			icon: <DeactivateIcon />,
		},
		{
			status: ['SocialInactive'],
			disabled:
				id === user?.userId || user?.siteUserId === null || user?.siteUserId === undefined,
			permissions: [Permission.SocialUser_Account_U],
			action: RowAction.ActivateSocialUser,
			text: 'Activate User',
			icon: <PowerSettingsNewOutlined />,
		},
		{
			status: ['SocialActive'],
			disabled:
				id === user?.userId || user?.siteUserId === null || user?.siteUserId === undefined,
			permissions: [Permission.SocialUser_Assume_R],
			action: RowAction.AssumeIdentity,
			text: 'Assume Identity',
			icon: <MaskIcon />,
		},
		{
			status: ['SocialActive'],
			permissions: [Permission.SocialUser_Profile_R],
			link: `/${handle}`,
			text: 'View Profile',
			icon: <AccountCircleOutlined />,
		},
		{
			status: ['Active', 'Inactive', 'SocialActive', 'SocialInactive'],
			link: `/app/history/${id}`,
			text: 'History',
			icon: <HistoryOutlined />,
		},
		{
			status: ['SocialActive'],
			permissions: Permission.SocialUser_EvoX_R,
			link: `/site/evox/user/${id}`,
			text: 'View EVO-X Conversations',
			icon: <ForumOutlined />,
		},
	];

	let menu = tableActions
		.filter((ta) => hasPermission(ta.permissions ?? []) && ta.status.includes(status))
		.map((ta, k) => {
			if (ta.link) {
				return (
					<MenuItem key={k} component={Link} to={ta.link}>
						<ListItemIcon>{ta.icon}</ListItemIcon>
						<ListItemText>{ta.text}</ListItemText>
					</MenuItem>
				);
			}

			return (
				<MenuItem
					key={k}
					onClick={
						ta.action !== undefined ? (): void => handleRowAction(ta.action) : undefined
					}
					disabled={ta.disabled}>
					<ListItemIcon>{ta.icon}</ListItemIcon>
					<ListItemText>{ta.text}</ListItemText>
				</MenuItem>
			);
		});

	// helper variables for the activate / deactivate modal
	const isActive = status === Status.Active || status === Status.SocialActive;
	const isSiteUser = status === Status.Active || status === Status.Inactive;

	return (
		<>
			{menu.length > 0 && (
				<IconButton
					ref={buttonRef}
					aria-label="more"
					aria-controls="long-menu"
					aria-haspopup="true"
					onClick={() => setMenuOpen(true)}>
					<MoreHorizOutlined />
				</IconButton>
			)}
			<Menu anchorEl={buttonRef.current} open={menuOpen} onClose={() => setMenuOpen(false)}>
				{menu}
			</Menu>
			{/** assume identity modal */}
			{id && (
				<ModalOrDrawer open={assumeIdentityOpen}>
					<ConfirmationModalContent
						title="Assuming Identity"
						graphic={
							<Badge
								color="warning"
								overlap="circular"
								sx={{
									'& .MuiBadge-badge': {
										height: '2.25rem',
										borderRadius: '50%',
									},
								}}
								badgeContent={
									<MaskIcon
										sx={{
											padding: '4px 0 0 4px',
										}}
									/>
								}
								anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
								<UserEmblemAvatar id={id} size={120} />
							</Badge>
						}
						primaryText={`Assume ${name}?`}
						secondaryText={`While assuming ${name}'s identity, you are responsible for all actions taken.`}
						onSubmit={() => assume(id)}
						onClose={() => setAssumeIdentityOpen(false)}
					/>
				</ModalOrDrawer>
			)}
			{/** delete site user invitation */}
			<ModalOrDrawer open={deleteInvitationOpen}>
				<ConfirmationModalContent
					variant="destructive"
					title="Delete Invitation"
					primaryText="Are you sure?"
					secondaryText={`Do you really want to delete ${name}'s invitation? This process cannot be undone.`}
					onSubmit={deleteInvitation}
					onClose={() => setDeleteInvitationOpen(false)}
				/>
			</ModalOrDrawer>
			{/** activate / deactivate modal */}
			<ModalOrDrawer open={activateDeactivateModalOpen}>
				<ConfirmationModalContent
					variant={isActive ? 'destructive' : 'activate'}
					title={`${isActive ? 'Deactivate' : 'Activate'} User`}
					primaryText="Are you sure?"
					secondaryText={`Do you really want to ${
						isActive ? 'deactivate' : 'activate'
					} ${name}'s ${isSiteUser ? 'site' : 'standard'} user account? You can ${
						isActive ? 'reactivate' : 'deactivate'
					} this user anytime.`}
					confirmText={`${isActive ? 'Deactivate' : 'Activate'} User`}
					onSubmit={async () =>
						await updateUserActivation(
							isActive ? ActivationOperation.Deactivate : ActivationOperation.Activate
						)
					}
					onClose={() => setActivateDeactivateModalOpen(false)}
				/>
			</ModalOrDrawer>
		</>
	);
};
