import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { TextField } from 'components/ui/fields';
import { ModalButtonsContainer } from 'components/ui/modals/modal-buttons-container';
import { ModalContent } from 'components/ui/modals/modal-content';
import { ModalHeader } from 'components/ui/modals/modal-header';
import { ModalContentProps } from 'components/ui/modals/modal-types';
import { Formik, FormikProps } from 'formik';
import { BusinessLine, BusinessLineUpdate } from 'middleware-types';
import { useValidation } from 'utils/useValidation';
import { useCreateLineOfBusiness, useUpdateLineOfBusiness } from '../hooks';

interface LineOfBusinessModalProps extends ModalContentProps {
	orgId: string;
	lineOfBusiness?: BusinessLine;
}

export const LineOfBusinessModal = ({
	orgId,
	lineOfBusiness,
	onClose,
}: LineOfBusinessModalProps) => {
	const createLineOfBusiness = useCreateLineOfBusiness(orgId);
	const updateLineOfBusiness = useUpdateLineOfBusiness(orgId);

	const initialValues: BusinessLineUpdate = {
		line: lineOfBusiness?.line ?? '',
		text: lineOfBusiness?.text ?? '',
		specialty: lineOfBusiness?.specialty ?? false,
	};

	const onSubmit = async (values: BusinessLineUpdate) => {
		let success = false;
		if (lineOfBusiness === undefined) {
			success = await createLineOfBusiness(values);
		} else {
			success = await updateLineOfBusiness(lineOfBusiness.id, values);
		}
		if (success) onClose();
	};

	const validation = useValidation('BusinessLineUpdate');

	return (
		<Formik<BusinessLineUpdate>
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={validation.schema}
			enableReinitialize>
			{(props: FormikProps<BusinessLineUpdate>) => (
				<>
					<ModalHeader
						title={
							lineOfBusiness === undefined
								? 'Add Line of Business'
								: 'Update Line of Business'
						}
						onClose={onClose}
					/>
					<ModalContent>
						<TextField name="line" label="Name" required />
						<TextField
							name="text"
							label="Description"
							multiline
							helperText="Limit of 200 characters"
						/>
					</ModalContent>
					<ModalButtonsContainer>
						<Button variant="outlined" onClick={onClose}>
							Close
						</Button>
						<LoadingButton
							type="submit"
							color="primary"
							variant="contained"
							loading={props.isSubmitting}
							disabled={!props.isValid || !props.dirty || props.isSubmitting}
							onClick={() => {
								props.submitForm();
							}}>
							Save
						</LoadingButton>
					</ModalButtonsContainer>
				</>
			)}
		</Formik>
	);
};
