import { CheckOutlined, Close, MarkAsUnread, WorkOutlineRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Avatar,
	Button,
	Card,
	Container,
	Divider,
	Link,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemSecondaryAction,
	ListItemText,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import { ConfirmationModalContent } from 'components/ui/modals/confirmation-modal-content';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { PageContent, PageTitle } from 'components/ui/page';
import { AssociateInvitation } from 'middleware-types';
import {
	useAcceptAssociateInvitation,
	useAssociateInvitations,
	useDeclineAssociateInvitation,
} from 'pages/app/legacy/old-linked-companies';
import { Fragment, useState } from 'react';
import { PageError } from 'utils/errors';
import { useSession } from 'utils/session';
import { theme } from 'utils/theme';
import { useIsMobile } from 'utils/useScreenSize';

export const OrgInvitationsPage = () => {
	const { user } = useSession();
	if (user?.siteUserId) {
		throw new PageError('Site users cannot become organization associates.');
	}

	return (
		<>
			<PageTitle title="Organization Invitations" />
			<PageContent p={{ xs: 0, sm: 3 }}>
				<Container disableGutters maxWidth="md" sx={{ height: '100%' }}>
					<Card
						sx={{
							height: '100%',
						}}>
						<Stack height="100%">
							<Stack spacing={1} padding={2}>
								<Typography variant="h2">Organization Invitations</Typography>
								<Typography variant="body2">
									Invitations to become an organization associate will appear
									here. Depending on the role assigned, organization associates
									can manage organization profiles and settings on Evolve.
								</Typography>
							</Stack>
							<Divider />
							<OrgInvitationDisplay />
						</Stack>
					</Card>
				</Container>
			</PageContent>
		</>
	);
};

/**
 * Display for org invitations
 */
const OrgInvitationDisplay = () => {
	const { invitations, loading } = useAssociateInvitations();

	if (loading) return <OrgInvitationDisplayLoadingSkeleton />;

	if (!invitations || invitations.length < 1)
		return (
			<Stack flex={1} alignItems="center">
				<Stack spacing={1} justifyContent="center" alignItems="center" height="100%">
					<EmptyStateAvatar icon={<MarkAsUnread />} />
					<Typography variant="h3">No organization invitations.</Typography>
					<Typography variant="h5">
						Your organization invitations will appear here.
					</Typography>
				</Stack>
			</Stack>
		);

	return (
		<List disablePadding sx={{ overflow: 'auto' }}>
			{invitations.map((inv) => (
				<Fragment key={inv.id}>
					<OrgInvitationDisplayItem inv={inv} />
					<Divider />
				</Fragment>
			))}
		</List>
	);
};

const OrgInvitationDisplayItem = ({ inv }: { inv: AssociateInvitation }) => {
	const isMobile = useIsMobile();

	return (
		<Stack>
			<ListItem disableGutters disablePadding className="p-0">
				<ListItemButton component={Link} className="w-full rounded p-4" disableRipple>
					<ListItemAvatar>
						{inv.avatarFile?.file?.currentInstance?.cdnUrl ? (
							<Avatar
								src={inv.avatarFile?.file?.currentInstance?.cdnUrl}
								variant="square"
							/>
						) : (
							<Avatar variant="square">
								<WorkOutlineRounded />
							</Avatar>
						)}
					</ListItemAvatar>
					<ListItemText
						primary={
							<Typography variant="h4" width={{ sm: '50%', md: '70%' }} noWrap>
								{inv.organizationName}
							</Typography>
						}
					/>
				</ListItemButton>
				{!isMobile && (
					<ListItemSecondaryAction className="right-4">
						<Stack direction="row" spacing={1.5}>
							<OrgInvitationButtons inv={inv} />
						</Stack>
					</ListItemSecondaryAction>
				)}
			</ListItem>
			{isMobile && (
				<Stack direction="row" spacing={1.5} pb={2} px={2}>
					<OrgInvitationButtons inv={inv} />
				</Stack>
			)}
		</Stack>
	);
};

const OrgInvitationButtons = ({ inv }: { inv: AssociateInvitation }) => {
	const { acceptInvitation, loading: acceptLoading } = useAcceptAssociateInvitation();
	const { declineInvitation } = useDeclineAssociateInvitation();

	const [declineModalOpen, setDeclineModalOpen] = useState(false);

	return (
		<>
			<LoadingButton
				color="primary"
				variant="contained"
				loading={acceptLoading}
				startIcon={<CheckOutlined />}
				onClick={() => acceptInvitation(inv)}>
				Accept
			</LoadingButton>
			<Button
				variant="outlined"
				startIcon={<Close />}
				onClick={() => setDeclineModalOpen(true)}>
				Decline
			</Button>
			{/** decline invite modal */}
			<ModalOrDrawer open={declineModalOpen}>
				<ConfirmationModalContent
					variant="destructive"
					title="Decline Invitation"
					primaryText="Are you sure?"
					secondaryText={`Do you really want to decline ${inv.organizationName}'s invitation?`}
					onSubmit={async () => await declineInvitation(inv)}
					onClose={() => setDeclineModalOpen(false)}
					confirmText="Decline"
				/>
			</ModalOrDrawer>
		</>
	);
};

const OrgInvitationDisplayLoadingSkeleton = () => {
	return (
		<List disablePadding>
			<ListItem
				disableGutters
				disablePadding
				className="p-0"
				sx={{
					borderBottom: `1px solid ${theme.palette.divider}`,
				}}>
				<Skeleton variant="rectangular" width="100%" height="4rem" />
			</ListItem>
			<ListItem
				disableGutters
				disablePadding
				className="p-0"
				sx={{
					borderBottom: `1px solid ${theme.palette.divider}`,
				}}>
				<Skeleton variant="rectangular" width="100%" height="4rem" />
			</ListItem>
			<ListItem
				disableGutters
				disablePadding
				className="p-0"
				sx={{
					borderBottom: `1px solid ${theme.palette.divider}`,
				}}>
				<Skeleton variant="rectangular" width="100%" height="4rem" />
			</ListItem>
		</List>
	);
};
