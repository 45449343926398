import { Loop } from '@mui/icons-material';
import {
	Box,
	Card,
	Chip,
	ChipProps,
	Divider,
	IconButton,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';
import { PdfFileOutlined } from 'components/ui/icons';
import { format } from 'date-fns';
import { InvoiceStatus } from 'middleware-types';
import { formatUSD, zoneDateOnly } from 'utils/utils';
import { useRecentInvoices } from './hooks/use-recent-invoices';

const NUM_DAYS = 60;

export const RecentInvoicesCard = () => {
	const { outstandingBalance, invoices, loading } = useRecentInvoices(NUM_DAYS);

	return (
		<Card>
			<Box px={2.5} py={2}>
				<Typography variant="h2">Recent Invoices (past {NUM_DAYS} days)</Typography>
			</Box>
			<Divider />
			<Box p={2.5}>
				{loading ? (
					<Skeleton variant="rounded" height={60} />
				) : (
					<Stack spacing={2}>
						<Typography>
							Outstanding balance: {formatUSD(outstandingBalance / 100)}
						</Typography>
						{invoices.length === 0 ? (
							<Box p={2} bgcolor="neutral.50" borderRadius={1}>
								<Typography variant="body1">
									This organization does not have recent invoices.
								</Typography>
							</Box>
						) : (
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Invoice Number</TableCell>
											<TableCell>Invoice Date</TableCell>
											<TableCell>Status</TableCell>
											<TableCell align="right">Invoice Amount</TableCell>
											<TableCell padding="checkbox" />
										</TableRow>
									</TableHead>
									<TableBody>
										{invoices.map((invoice, index) => (
											<TableRow key={index}>
												<TableCell>{invoice.invoiceNumber}</TableCell>
												<TableCell>
													<Stack
														direction="row"
														alignItems="center"
														gap={1}>
														{format(
															zoneDateOnly(invoice.invoiceDate),
															'MM/dd/yyyy'
														)}
														{invoice.isSubscription && (
															<Tooltip title="Subscription">
																<Loop
																	sx={{ color: 'neutral.500' }}
																/>
															</Tooltip>
														)}
													</Stack>
												</TableCell>
												<TableCell>
													<InvoiceStatusChip status={invoice.status} />
												</TableCell>
												<TableCell align="right">
													{formatUSD(invoice.invoiceAmount / 100)}
												</TableCell>
												<TableCell padding="checkbox">
													{invoice.invoicePdfUrl && (
														<Tooltip title="View PDF">
															<IconButton
																href={invoice.invoicePdfUrl}
																target="_blank">
																<PdfFileOutlined />
															</IconButton>
														</Tooltip>
													)}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						)}
					</Stack>
				)}
			</Box>
		</Card>
	);
};

const getChipColor = (status: InvoiceStatus): ChipProps['color'] => {
	switch (status) {
		case InvoiceStatus.Paid:
			return 'success';
		case InvoiceStatus.Overdue:
			return 'error';
		default:
			return 'default';
	}
};

export const InvoiceStatusChip = ({ status }: { status: InvoiceStatus }) => {
	return <Chip size="small" label={status} color={getChipColor(status)} />;
};
