import { AttachFileOutlined, SendOutlined, UploadOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, IconButton, Link, Snackbar, Stack, Tooltip, Typography } from '@mui/material';
import { useEditor } from '@tiptap/react';
import { FileDropOutline } from 'components/ui/file-drop-outline';
import { EvoxLogoIcon } from 'components/ui/icons';
import { extensions } from 'components/ui/rich-text-editor/extensions';
import { RichTextContent } from 'components/ui/rich-text-editor/rich-text-content';
import { RichTextToolbar } from 'components/ui/rich-text-editor/toolbar/rich-text-toolbar';
import { useFormikContext } from 'formik';
import { useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { getUploadProgress } from 'utils/fileUtils';
import { NewMessageFormValues } from '../conversation-column/conversation-message-box';
import { useMessageAttachments } from '../hooks/use-message-attachments';
import { ConversationEvox } from './evox/conversation-evox';
import { MessageFileChip } from './message-file-chip';

interface MessageBoxProps {
	isNewConversation?: boolean;
}

export const MessageBox = ({ isNewConversation = false }: MessageBoxProps) => {
	const sendButtonRef = useRef<HTMLButtonElement>(null);

	const [evoxOpen, setEvoxOpen] = useState(false);
	const { submitForm, isSubmitting, isValid, dirty, setFieldValue } =
		useFormikContext<NewMessageFormValues>();

	const editor = useEditor({
		extensions,
		onUpdate: ({ editor }) => {
			setFieldValue('messageText', editor.getHTML());
		},
		editorProps: {
			handleKeyDown: (_view, e) => {
				if (e.key === 'Enter' && e.ctrlKey) {
					e.preventDefault();
					sendButtonRef.current?.click();
				}
			},
		},
	});

	const { attachments, onDropAccepted, onRemove, clearAttachments } = useMessageAttachments();
	const { getRootProps, isDragActive, open } = useDropzone({
		onDropAccepted,
		noClick: true,
	});

	const onSend = async () => {
		editor?.setEditable(false);
		await submitForm();
		editor?.commands.clearContent(true);
		editor?.setEditable(true);
		clearAttachments();
	};

	if (evoxOpen)
		return (
			<ConversationEvox
				onClose={() => setEvoxOpen(false)}
				editor={editor}
				isNewConversation={isNewConversation}
			/>
		);

	return (
		<Stack spacing={1} flex={1} overflow="hidden">
			<Stack flex={1} overflow="hidden" spacing={1}>
				<RichTextToolbar editor={editor} />
				<Stack
					position="relative"
					flex={1}
					overflow="hidden"
					border="1px solid"
					borderColor="neutral.200"
					bgcolor="neutral.50"
					borderRadius={1}
					{...getRootProps()}>
					<RichTextContent editor={editor} flex={1} overflow="auto" p={1.5} />
					{editor?.isEmpty && (
						<Box
							position="absolute"
							top={0}
							width="100%"
							p={1.5}
							sx={{ pointerEvents: 'none' }}>
							<Typography variant="body2" color="GrayText" display="inline">
								Type a message here or
							</Typography>
							<EvoxLogoIcon
								fontSize="inherit"
								sx={{
									display: 'inline',
									mx: 0.75,
									mb: '-2px',
									verticalAlign: 'baseline',
								}}
							/>
							<Link
								variant="body2"
								display="inline"
								component="button"
								onClick={() => setEvoxOpen(true)}
								sx={{ pointerEvents: 'auto' }}>
								Draft a message with EVO-X
							</Link>
						</Box>
					)}
					{attachments.length > 0 && (
						<Stack
							overflow="auto"
							direction="row"
							spacing={1}
							p={1}
							borderTop="1px solid"
							borderColor="divider"
							bgcolor="background.paper"
							flexShrink={0}>
							{attachments.map((a) => (
								<MessageFileChip
									key={a.key}
									fileData={a.file}
									uploadState={a.uploadState}
									uploadProgress={getUploadProgress(a.uploadProgress)}
									submitting={isSubmitting}
									onRemove={() => onRemove(a.key)}
								/>
							))}
						</Stack>
					)}
					<Snackbar
						open={isDragActive}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
						message={
							<Stack direction="row" alignItems="center">
								<UploadOutlined sx={{ mr: 1 }} />
								Drop file(s) to attach to your message
							</Stack>
						}></Snackbar>
					{isDragActive && <FileDropOutline borderRadius={1} />}
				</Stack>
			</Stack>
			<Stack direction="row">
				<IconButton onClick={open}>
					<AttachFileOutlined />
				</IconButton>
				{/* <IconButton>
					<DrawOutlined />
				</IconButton>
				<IconButton>
					<ArticleOutlined />
				</IconButton> */}
				<IconButton onClick={() => setEvoxOpen(true)}>
					<EvoxLogoIcon />
				</IconButton>
				<Box flex={1} />
				<Tooltip
					placement="top"
					title={
						editor?.isEmpty ? 'Enter some text to send your message' : 'Ctrl + Enter'
					}>
					<span>
						<LoadingButton
							ref={sendButtonRef}
							variant="contained"
							color="primary"
							startIcon={<SendOutlined />}
							disabled={!isValid || !dirty}
							loading={isSubmitting}
							onClick={onSend}>
							Send
						</LoadingButton>
					</span>
				</Tooltip>
			</Stack>
		</Stack>
	);
};
