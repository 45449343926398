import { LockPersonOutlined, LockResetOutlined } from '@mui/icons-material';
import { CircularProgress, Grid, IconButton, Stack, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import { useGetCognitoUser, useResetMfa } from 'components/pages/user/account/security/hooks';
import { ConfirmationModalContent } from 'components/ui/modals/confirmation-modal-content';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSession } from 'utils/session';

interface MfaSectionProps {
	userId: string;
	canEdit: boolean;
}

export const MfaSection = ({ userId, canEdit }: MfaSectionProps) => {
	const navigate = useNavigate();
	const { loading, user } = useGetCognitoUser(userId);
	const { resetMfa } = useResetMfa();
	const { user: sessionUser } = useSession();

	const [modalOpen, setModalOpen] = useState(false);

	return (
		<>
			<Grid container item xs={12} alignItems="center">
				<Grid item xs={12} sm={4}>
					<Stack direction="row" spacing={{ xs: 2, sm: 3 }}>
						<LockPersonOutlined fontSize="small" />
						<Typography variant="h5">MFA</Typography>
					</Stack>
				</Grid>
				<Grid item xs>
					{user?.preferredMfaSetting === 'SMS_MFA' && <Typography>SMS</Typography>}
					{user?.preferredMfaSetting === 'SOFTWARE_TOKEN_MFA' && (
						<Typography>Authenticator</Typography>
					)}
					{!user?.preferredMfaSetting &&
						(loading ? (
							<CircularProgress style={{ height: `1rem`, width: `1rem` }} />
						) : (
							<Typography>NO MFA CONFIGURED</Typography>
						))}
				</Grid>
				{user?.preferredMfaSetting && canEdit && (
					<Grid item xs="auto">
						<IconButton onClick={() => setModalOpen(true)}>
							<LockResetOutlined />
						</IconButton>
					</Grid>
				)}
			</Grid>
			{/** reset modal */}
			<ModalOrDrawer open={modalOpen}>
				<ConfirmationModalContent
					variant="destructive"
					title="Reset MFA"
					primaryText="Are you sure?"
					secondaryText={
						sessionUser.userId == userId
							? `Do you really want to reset your MFA? Doing so will log you out. When you next log in you will be required to reconfigure your MFA.`
							: `Do you really want to reset this user's MFA? When this user logs in next they will be required to reconfigure their MFA.`
					}
					onSubmit={async () => {
						return await resetMfa(userId).then(async (res) => {
							if (!res) return false;

							// Don't sign out after MFA reset if reset was triggered
							// from an account page that isn't one's own (e.g. a site user
							// visiting a social user's account page).
							if (sessionUser.userId !== userId) return true;

							await Auth.signOut();
							navigate('/auth/login', { replace: true });
							return false;
						});
					}}
					onClose={() => setModalOpen(false)}
					confirmText="Reset MFA"
				/>
			</ModalOrDrawer>
		</>
	);
};
