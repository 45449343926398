import { EditOutlined } from '@mui/icons-material';
import { Avatar, Card, CardContent, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { useState } from 'react';
import { Permission } from 'utils/permissions';
import { theme } from 'utils/theme';
import { useSiteUser } from 'utils/useSiteUser';
import { useOrgAccountGetConversationInsurance } from './hooks';
import { ConversationInsuranceModal } from './modal';

export const ConversationInsurance = ({ orgId }: { orgId: string }) => {
	const { hasPermission: hasSiteUserPermission } = useSiteUser();
	const { conversationInsurance, loading } = useOrgAccountGetConversationInsurance(orgId);

	const [modalOpen, setModalOpen] = useState(false);

	const isLinked = conversationInsurance?.conversationInsuranceUrl;

	return (
		<Card>
			<CardContent
				sx={{
					display: 'flex',
					alignItems: 'center',
					borderBottom: `1px solid ${theme.palette.divider}`,
				}}>
				<Typography variant="h2">Conversation Insurance</Typography>
			</CardContent>
			<CardContent
				sx={{
					display: 'flex',
					alignItems: 'center',
				}}>
				<Stack
					width="100%"
					display="flex"
					flexDirection="row"
					alignItems="center"
					justifyContent="space-between">
					<Stack flexDirection="row" gap={2} alignItems="center" width="100%">
						{isLinked && (
							<Avatar
								sx={{
									'& img': { objectFit: 'contain' },
									'background': 'transparent',
								}}
								src={'/img/conversation-insurance-color-logo.svg'}></Avatar>
						)}
						{loading ? (
							<Typography width="100%" variant="h5">
								<Skeleton variant="text" width="100%" />
							</Typography>
						) : (
							<Typography
								variant="h5"
								pr={1}
								sx={{
									wordBreak: 'break-word',
								}}>
								{isLinked
									? conversationInsurance?.conversationInsuranceUrl
									: 'This organization is not linked to a Conversation Insurance Account.'}
							</Typography>
						)}
					</Stack>
					{hasSiteUserPermission(Permission.Site_OrgAcct_U) && (
						<IconButton onClick={() => setModalOpen(true)}>
							<EditOutlined />
						</IconButton>
					)}
				</Stack>
			</CardContent>
			{/** modal */}
			<ModalOrDrawer open={modalOpen}>
				<ConversationInsuranceModal
					orgId={orgId}
					url={conversationInsurance?.conversationInsuranceUrl}
					onClose={() => setModalOpen(false)}
				/>
			</ModalOrDrawer>
		</Card>
	);
};
