import { LoadingButton } from '@mui/lab';
import { Button, TextField } from '@mui/material';
import { ModalButtonsContainer } from 'components/ui/modals/modal-buttons-container';
import { ModalContent } from 'components/ui/modals/modal-content';
import { ModalHeader } from 'components/ui/modals/modal-header';
import { ModalContentProps } from 'components/ui/modals/modal-types';
import { useToast } from 'components/ui/toast';
import { Formik, FormikProps } from 'formik';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { useValidation } from 'utils/useValidation';
import {
	GET_ORGANIZATION_CONVERSATION_INSURANCE,
	useOrgAccountUpdateConversationInsurance,
} from './hooks';

interface UpdateUrlForm {
	url: string;
}

interface ConversationInsuranceModalProps extends ModalContentProps {
	orgId: string;
	url: string | undefined;
}

export const ConversationInsuranceModal = ({
	orgId,
	url,
	onClose,
}: ConversationInsuranceModalProps) => {
	const toast = useToast();
	const { updateConversationInsurance, loading } = useOrgAccountUpdateConversationInsurance();

	const initialValues = {
		url: url || '',
	};

	const successToastText = url
		? 'Conversation Insurance URL updated successfully.'
		: 'Conversation Insurance URL added successfully.';

	const handleSubmit = async (values: UpdateUrlForm) => {
		const conversationInsuranceUrl: string | undefined =
			values.url === '' ? undefined : values.url;
		await updateConversationInsurance({
			refetchQueries: [
				{
					query: GET_ORGANIZATION_CONVERSATION_INSURANCE,
					variables: { organizationId: orgId },
				},
			],
			awaitRefetchQueries: true,
			variables: {
				organizationId: orgId,
				request: {
					conversationInsuranceUrl,
				},
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return;
				}
				toast.push(successToastText, {
					variant: 'success',
				});
				onClose();
			})
			.catch(() => {
				handleNoResponse({ toast });
			});
	};

	const validation = useValidation('ConversationInsurance');

	return (
		<Formik<UpdateUrlForm>
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={validation.schema}>
			{(props: FormikProps<UpdateUrlForm>) => (
				<>
					<ModalHeader
						title={`${url ? 'Update' : 'Add'} Conversation Insurance URL`}
						onClose={onClose}
					/>
					<ModalContent>
						<TextField
							name="url"
							label="Conversation Insurance URL"
							fullWidth
							variant="outlined"
							value={props.values.url}
							onChange={props.handleChange}
							onBlur={props.handleBlur}
						/>
					</ModalContent>
					<ModalButtonsContainer>
						<Button variant="outlined" onClick={onClose}>
							Cancel
						</Button>
						<LoadingButton
							disabled={!props.dirty || !props.isValid}
							data-test="loading-btn"
							variant="contained"
							color="primary"
							onClick={props.submitForm}
							loading={loading || props.isSubmitting}>
							Update
						</LoadingButton>
					</ModalButtonsContainer>
				</>
			)}
		</Formik>
	);
};
