import {
	Add,
	ArrowBackIosNew,
	FolderOutlined,
	ManageSearchOutlined,
	UploadOutlined,
} from '@mui/icons-material';
import {
	Box,
	ButtonBase,
	Drawer,
	Fab,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Paper,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import { PageContent } from 'components/ui/page';
import { useUploadsDrawer } from 'components/ui/uploads/uploads-drawer';
import { SortDirection } from 'middleware-types';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { DocumentSearchField } from '../components/document-search-field';
import { DocumentSearchProvider } from '../components/document-search-provider';
import { DocumentsGrid } from '../components/documents-grid';
import { FileDisplay, FileDisplayToggle } from '../components/file-display-toggle';
import { FilesSortColumn, SortOptionsDropdown } from '../components/sort-options-dropdown';
import { useDocumentsContext } from '../documents-page';
import { useFolder } from '../hooks/folders/use-folder';
import { useCurrentFolderId } from '../hooks/use-current-folder-id';
import { useSortFiles } from '../hooks/use-sort-files';
import { useSortFolders } from '../hooks/use-sort-folders';
import { CreateUpdateFolderModal } from '../modals/create-update-folder-modal';
import { UploadModal } from '../modals/upload-modal';
import { DocumentsList } from './documents-list';

export const MobileDocumentsPage = () => {
	const [currentFolderId, setCurrentFolderId] = useCurrentFolderId();
	const { permissions } = useDocumentsContext();
	const { drawerOpen } = useUploadsDrawer();

	const [sortColumn, setSortColumn] = useState(FilesSortColumn.LastUpdated);
	const [sortDirection, setSortDirection] = useState(SortDirection.Descending);
	const [displayMode, setDisplayMode] = useState(FileDisplay.List);

	const [uploadDrawerOpen, setUploadDrawerOpen] = useState(false);
	const [createFolderDrawerOpen, setCreateFolderDrawerOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const {
		isSearchActive,
		isRoot,
		name,
		ancestors,
		folders,
		files,
		loading,
		searchText,
		setSearchText,
		searchValue,
	} = useFolder();
	const sortedFiles = useSortFiles(files, sortColumn, sortDirection);
	const sortedFolders = useSortFolders(folders, sortColumn, sortDirection);
	const numResults = folders.length + files.length;

	const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
	const onDropAccepted = (files: File[]) => {
		setFilesToUpload(files);
		setUploadDrawerOpen(true);
	};

	const { getInputProps, open } = useDropzone({ onDropAccepted });

	return (
		<PageContent mobileNoPadding py={0} pt={1.5}>
			<Stack height="100%" position="relative" borderRadius={0} spacing={2} component={Paper}>
				<Stack spacing={1}>
					<Stack direction="row" alignItems="center" px={1} pt={1.5} spacing={0.5}>
						{isRoot || isSearchActive ? (
							<Box height={40} width={4} />
						) : (
							<IconButton
								onClick={() =>
									setCurrentFolderId(ancestors[ancestors.length - 1]?.id)
								}>
								<ArrowBackIosNew />
							</IconButton>
						)}
						{loading ? (
							<Skeleton variant="text" width={150} />
						) : isSearchActive ? (
							<Typography variant="h2">
								{numResults} Result{numResults === 1 ? '' : 's'}
							</Typography>
						) : (
							<Typography variant="h2" noWrap>
								{name}
							</Typography>
						)}
					</Stack>
					<Stack px={2} spacing={1.5}>
						<Stack direction="row" spacing={2}>
							<SortOptionsDropdown
								column={sortColumn}
								setColumn={setSortColumn}
								direction={sortDirection}
								setDirection={setSortDirection}
								flex
							/>
							<FileDisplayToggle value={displayMode} setValue={setDisplayMode} />
						</Stack>
						<DocumentSearchField
							searchText={searchText}
							setSearchText={setSearchText}
						/>
					</Stack>
				</Stack>
				{loading ? (
					<Stack flex={1} alignItems="center" justifyContent="center">
						<Skeleton
							variant="circular"
							width="5rem"
							height="5rem"
							sx={{
								marginBottom: 1,
							}}
						/>
						<Skeleton variant="text" width={100} />
						<Skeleton variant="text" width={120} />
					</Stack>
				) : numResults === 0 ? (
					isSearchActive ? (
						<Stack flex={1} alignItems="center" justifyContent="center" spacing={2}>
							<EmptyStateAvatar icon={<ManageSearchOutlined />} />
							<Box textAlign="center">
								<Typography variant="h5">No results found</Typography>
							</Box>
						</Stack>
					) : (
						<>
							<input {...getInputProps()} />
							<ButtonBase
								onClick={open}
								component={Stack}
								flex={1}
								alignItems="center"
								justifyContent="center"
								spacing={2}>
								<EmptyStateAvatar icon={<UploadOutlined />} />
								<Box textAlign="center">
									<Typography variant="h5">Tap to upload files</Typography>
									<Typography variant="body1">
										or use the &apos;Add&apos; button below
									</Typography>
								</Box>
							</ButtonBase>
						</>
					)
				) : (
					<DocumentSearchProvider
						isSearchActive={isSearchActive}
						searchValue={searchValue}>
						<Box flex={1} px={2} overflow="auto">
							{displayMode === FileDisplay.List ? (
								<DocumentsList folders={sortedFolders} files={sortedFiles} />
							) : (
								<DocumentsGrid folders={sortedFolders} files={sortedFiles} />
							)}
							<Box height={100} />
						</Box>
					</DocumentSearchProvider>
				)}
				{!drawerOpen && permissions.update.length > 0 && (
					<Fab
						color="primary"
						sx={{ position: 'absolute', bottom: 16, right: 16 }}
						onClick={(e) => setAnchorEl(e.currentTarget)}>
						<Add fontSize="large" />
					</Fab>
				)}
			</Stack>
			{/** new menu */}
			<Menu
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				onClick={() => setAnchorEl(null)}
				anchorOrigin={{ vertical: -12, horizontal: 'right' }}
				transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				sx={{ bottom: 16 }}>
				<MenuItem onClick={open}>
					<ListItemIcon>
						<UploadOutlined />
					</ListItemIcon>
					<ListItemText>Upload Files</ListItemText>
				</MenuItem>
				<MenuItem onClick={() => setCreateFolderDrawerOpen(true)}>
					<ListItemIcon>
						<FolderOutlined />
					</ListItemIcon>
					<ListItemText>New Folder</ListItemText>
				</MenuItem>
			</Menu>
			{/** upload files drawer */}
			<Drawer open={uploadDrawerOpen} anchor="bottom">
				<UploadModal
					files={filesToUpload}
					folderId={currentFolderId}
					folderName={name}
					onClose={() => setUploadDrawerOpen(false)}
				/>
			</Drawer>
			{/** create folder modal */}
			<Drawer open={createFolderDrawerOpen} anchor="bottom">
				<CreateUpdateFolderModal onClose={() => setCreateFolderDrawerOpen(false)} />
			</Drawer>
		</PageContent>
	);
};
