import { Close, ErrorOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useEmblem } from 'components/ui/emblem/hooks';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import { EmblemEntityType } from 'middleware-types';
import { useDeleteUserProfileExperienceCompany } from './hooks';

interface UserExperienceDeleteCompanyModalProps {
	name?: string;
	organizationId?: string;
	onClose: () => void;
	userId: string;
	companyId: string;
}

export const UserExperienceDeleteCompanyModal = ({
	name,
	organizationId,
	onClose,
	userId,
	companyId,
}: UserExperienceDeleteCompanyModalProps) => {
	const deleteCompany = useDeleteUserProfileExperienceCompany(userId, companyId);

	const onSubmit = () => {
		deleteCompany().then((success) => {
			if (!success) return;
			onClose();
		});
	};

	return (
		<Stack>
			<Stack
				px={2}
				py={1}
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				spacing={1}>
				<Typography variant="h3">Are you sure?</Typography>
				<IconButton onClick={onClose}>
					<Close />
				</IconButton>
			</Stack>
			<Divider />
			<Stack alignItems="center" textAlign="center" px={10} py={3}>
				<EmptyStateAvatar
					avatarProps={{ bgcolor: 'error.50' }}
					iconProps={{ color: 'error.500' }}
					icon={<ErrorOutlined />}
				/>
				{name && (
					<Typography variant="h1" marginTop={2} sx={{ wordBreak: 'break-word' }}>
						Delete {name}?
					</Typography>
				)}
				{organizationId && (
					<UserExperienceDeleteCompanyModalNameDisplay organizationId={organizationId} />
				)}
				<Typography variant="body1" sx={{ marginTop: '4px' }}>
					This company and all of its positions will be deleted from your experience
					display. This action cannot be undone.
				</Typography>
			</Stack>
			<Divider />
			<Stack direction="row" justifyContent="flex-end" px={2} py={1.5} spacing={1.5}>
				<Button size="large" variant="outlined" onClick={onClose}>
					Cancel
				</Button>
				<LoadingButton size="large" variant="contained" color="error" onClick={onSubmit}>
					Delete
				</LoadingButton>
			</Stack>
		</Stack>
	);
};

const UserExperienceDeleteCompanyModalNameDisplay = ({
	organizationId,
}: {
	organizationId: string;
}) => {
	const { emblem } = useEmblem(organizationId, EmblemEntityType.Organization);
	return (
		<Typography variant="h1" marginTop={2} sx={{ wordBreak: 'break-word' }}>
			Delete {emblem?.displayName}?
		</Typography>
	);
};
