import { LoadingButton } from '@mui/lab';
import { FormControlLabel, MenuItem, Stack } from '@mui/material';
import { CheckboxField, SelectField, TextField } from 'components/ui/fields';
import { Formik, FormikErrors, FormikProps, useFormikContext } from 'formik';
import {
	EmploymentLocationType,
	EmploymentType,
	UserExperienceCompany,
	UserExperiencePosition,
	UserExperiencePositionUpdate,
} from 'middleware-types';
import { useValidation } from 'utils/useValidation';
import { EmptyStringify, monthNames } from 'utils/utils';
import { pascalCaseAddSpaces } from './helpers';
import {
	useAddUserProfileExperiencePositionAtExistingCompany,
	useUpdateUserProfileExperiencePosition,
} from './hooks';

const hasValue = (value: any) => value || value === 0;
export const validatePosition = (values: UserExperiencePositionFormValues) => {
	const { startMonth, startYear, endMonth, endYear, isCurrentRole } = values;
	const errors: FormikErrors<UserExperiencePositionFormValues> = {};

	if (hasValue(startYear) && !Number.isInteger(startYear))
		errors.startYear = 'Start Year must be an integer';
	if (hasValue(endYear) && !Number.isInteger(endYear))
		errors.endYear = 'End Year must be an integer';

	if (!isCurrentRole) {
		if (!endMonth) errors.endMonth = 'End Month required for a non-current role';
		if (!hasValue(endYear)) errors.endYear = 'End Year required for a non-current role';
	}

	if (startMonth && hasValue(startYear) && endMonth && hasValue(endYear)) {
		if (endYear! < startYear || (endYear === startYear && endMonth < startMonth)) {
			errors.endMonth = 'Position cannot end before it started';
			errors.endYear = 'Position cannot end before it started';
		}
	}

	return errors;
};

interface UserExperiencePositionFormProps {
	userId: string;
	company: UserExperienceCompany;
	position?: UserExperiencePosition;
	onClose: () => void;
}

export const UserExperiencePositionForm = ({
	userId,
	company,
	position,
	onClose,
}: UserExperiencePositionFormProps) => {
	const addUserProfileExperiencePositionAtExistingCompany =
		useAddUserProfileExperiencePositionAtExistingCompany(userId, company.id);

	const updateUserProfileExperiencePosition = useUpdateUserProfileExperiencePosition(
		userId,
		company.id
	);

	const onSubmit = async (input: UserExperiencePositionFormValues) => {
		let success = false;
		const { isCurrentRole, startMonth, startYear, endMonth, endYear, ...rest } = input;

		if (!startMonth || !startYear || !input.employmentType || !input.locationType) {
			return;
		}

		if (!isCurrentRole && (!endMonth || !endYear)) {
			return;
		}

		const request = {
			...rest,
			startMonth: startMonth || undefined,
			startYear: startYear || undefined,
			endMonth: endMonth || undefined,
			endYear: endYear || undefined,
		} as UserExperiencePositionUpdate;

		if (!position) {
			success = await addUserProfileExperiencePositionAtExistingCompany(request);
		} else {
			success = await updateUserProfileExperiencePosition(position.id, request);
		}

		if (success) onClose();
	};

	const initialValues: UserExperiencePositionFormValues = {
		startMonth: position?.startMonth ?? '',
		startYear: position?.startYear ?? '',
		endMonth: position?.endMonth ?? '',
		endYear: position?.endYear ?? '',
		employmentType: position?.employmentType ?? '',
		locationType: position?.locationType ?? '',
		jobTitle: position?.jobTitle ?? '',
		location: position?.location ?? '',
		description: position?.description ?? '',
		isCurrentRole: Boolean(position) && !position?.endMonth && !position?.endYear,
	};

	const validation = useValidation('UserExperiencePositionUpdate');

	return (
		<Formik<UserExperiencePositionFormValues>
			onSubmit={onSubmit}
			initialValues={initialValues}
			validationSchema={validation.schema}
			validate={validatePosition}
			enableReinitialize>
			{(props: FormikProps<UserExperiencePositionFormValues>) => (
				<Stack spacing={2}>
					<UserExperiencePositionFields />
					<Stack direction="row" spacing={1} justifyContent="flex-end">
						<LoadingButton
							loading={props.isSubmitting}
							variant="outlined"
							onClick={onClose}>
							Cancel
						</LoadingButton>
						<LoadingButton
							loading={props.isSubmitting}
							variant="contained"
							color="primary"
							disabled={!props.dirty || props.isSubmitting || !props.isValid}
							onClick={() => {
								props.submitForm();
							}}>
							Save
						</LoadingButton>
					</Stack>
				</Stack>
			)}
		</Formik>
	);
};

export interface UserExperiencePositionFormValues
	extends EmptyStringify<UserExperiencePositionUpdate> {
	isCurrentRole: boolean;
}

export const UserExperiencePositionFields = () => {
	const { values, isSubmitting, setFieldValue } =
		useFormikContext<UserExperiencePositionFormValues>();

	return (
		<Stack spacing={2}>
			<TextField name="jobTitle" label="Job Title" required placeholder="e.g. Adjuster" />
			<SelectField
				required
				name="employmentType"
				label="Employment Type"
				fullWidth
				disabled={isSubmitting}>
				{Object.values(EmploymentType).map((et, idx) => (
					<MenuItem key={idx} value={et}>
						{pascalCaseAddSpaces(et)}
					</MenuItem>
				))}
			</SelectField>
			<SelectField
				required
				name="locationType"
				label="Location Type"
				fullWidth
				disabled={isSubmitting}>
				{Object.values(EmploymentLocationType).map((lt, idx) => (
					<MenuItem key={idx} value={lt}>
						{pascalCaseAddSpaces(lt)}
					</MenuItem>
				))}
			</SelectField>
			<TextField name="location" label="Location" required placeholder="e.g. Dallas, Texas" />
			<FormControlLabel
				label="This is my current role"
				control={<CheckboxField name="isCurrentRole" />}
				onClick={async () => {
					await setFieldValue('endMonth', '', false);
					await setFieldValue('endYear', '', true);
				}}
			/>
			<Stack direction="row" spacing={1}>
				<SelectField name="startMonth" label="Start Month" required>
					{monthNames.map((month, index) => (
						<MenuItem key={index} value={index + 1}>
							{month}
						</MenuItem>
					))}
				</SelectField>
				<TextField name="startYear" label="Start Year" required type="number" />
			</Stack>
			<Stack direction="row" spacing={1}>
				<SelectField
					name="endMonth"
					label="End Month"
					disabled={values.isCurrentRole}
					required={!values.isCurrentRole}>
					{monthNames.map((month, index) => (
						<MenuItem key={index} value={index + 1}>
							{month}
						</MenuItem>
					))}
				</SelectField>
				<TextField
					name="endYear"
					label="End Year"
					disabled={values.isCurrentRole}
					required={!values.isCurrentRole}
					type="number"
				/>
			</Stack>
			<TextField
				name="description"
				label="Description"
				placeholder={`- Identified and assessed roof damage and informed clients about necessary repairs.
- Communicated with policy holders, clients, police, and necessary parties.
- Performed thorough inspection of vehicles and created appropriate estimates.`}
				multiline
				minRows={3}
				disabled={isSubmitting}
			/>
		</Stack>
	);
};
