import { gql, useMutation } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import {
	FileInformation,
	Mutation,
	MutationDeleteFileInstanceArgs,
	Query,
	QueryGetFileInstancesArgs,
} from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { useDocumentSearchContext } from '../../components/document-search-provider';
import { useDocumentsContext } from '../../documents-page';
import { updateFolderContents } from '../../utils/cache-helpers';
import { FILE_INFO_FIELDS } from '../../utils/fragments.graphql';
import { GET_FILE_INSTANCES } from './use-file-instances';

const DELETE_FILE_INSTANCE = gql`
	mutation DeleteFileInstance(
		$entityType: FoldersApiEntityType!
		$entityId: String!
		$request: FileDeleteRequest!
	) {
		deleteFileInstance(entityType: $entityType, entityId: $entityId, request: $request)
	}
`;

export const useDeleteFileInstance = () => {
	const toast = useToast();
	const { entityType, entityId } = useDocumentsContext();
	const { searchValue } = useDocumentSearchContext();

	const [_deleteFileInstance, { loading }] = useMutation<
		Pick<Mutation, 'deleteFileInstance'>,
		MutationDeleteFileInstanceArgs
	>(DELETE_FILE_INSTANCE);

	const deleteFileInstance = async (
		fileInstanceId: string,
		fileId: string,
		folderId: string | undefined
	) => {
		return await _deleteFileInstance({
			variables: { entityType, entityId, request: { ids: [fileInstanceId] } },
			update: (cache) => {
				const fileInstances = cache.readQuery<
					Pick<Query, 'getFileInstances'>,
					QueryGetFileInstancesArgs
				>({ query: GET_FILE_INSTANCES, variables: { fileId } });
				if (!fileInstances) return;
				const instances = fileInstances.getFileInstances.instances;
				const filteredInstances = instances.filter((i) => i.id !== fileInstanceId);
				const newCurrent = filteredInstances[filteredInstances.length - 1];
				if (filteredInstances.length === 0) {
					updateFolderContents(
						{ cache, folderId, entityType, entityId, searchValue },
						(existing) => ({
							...existing,
							files: existing.files?.filter((f) => f.id !== fileId),
						})
					);
				} else {
					cache.writeQuery<Pick<Query, 'getFileInstances'>, QueryGetFileInstancesArgs>({
						query: GET_FILE_INSTANCES,
						variables: { fileId },
						data: {
							getFileInstances: {
								...fileInstances.getFileInstances,
								instances: filteredInstances,
							},
						},
					});
					cache.updateFragment<FileInformation>(
						{
							id: `FileInformation:${fileId}`,
							fragment: FILE_INFO_FIELDS,
							fragmentName: 'FileInfoFields',
						},
						(data) => {
							if (!data) return;
							if (data.currentInstance?.id === newCurrent.id) return;
							return {
								...data,
								currentInstance: newCurrent,
							};
						}
					);
				}
			},
			onError: (e) => console.log(JSON.stringify(e)),
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('File version deleted successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { deleteFileInstance, loading };
};
