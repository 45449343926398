import {
	Close,
	DeleteOutlined,
	EditOutlined,
	MoreHoriz,
	Verified,
	VerifiedOutlined,
} from '@mui/icons-material';
import {
	Box,
	ButtonBase,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Stack,
	Typography,
} from '@mui/material';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import { ConfirmationModalContent } from 'components/ui/modals/confirmation-modal-content';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { UserSkill } from 'middleware-types';
import { useState } from 'react';
import { usePopupMenu } from 'utils/usePopupMenu';
import { EditPlacesUsedModal } from './edit-places-used-modal';
import { EndorsementsModal } from './endorsements-modal';
import { useDeleteSkill, useEndorseSkill } from './hooks';
import { useAnonymousSession } from 'utils/session';
import { UserEmblemAvatar } from 'components/ui/emblem/emblem-avatar';

interface SkillListItemProps {
	skill: UserSkill;
	userId: string;
	isSortable?: boolean;
	canEndorse?: boolean;
	canEdit?: boolean;
	index?: number;
}

export const SkillListItem = ({
	skill,
	userId,
	isSortable = false,
	canEndorse = false,
	canEdit = false,
	index,
}: SkillListItemProps) => {
	const { user } = useAnonymousSession();

	const { deleteSkill } = useDeleteSkill(userId);
	const { endorseSkill } = useEndorseSkill(userId, skill.id);

	const { anchorEl, menuOpen, openMenu, closeMenu } = usePopupMenu();
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [endorseModalOpen, setEndorseModalOpen] = useState(false);
	const [unendorseModalOpen, setUnendorseModalOpen] = useState(false);
	const [endorsementsModalOpen, setEndorsementsModalOpen] = useState(false);
	const [editPlacesModalOpen, setEditPlacesModalOpen] = useState(false);

	return (
		<Box py={0.75} sx={{ breakInside: 'avoid-column' }}>
			<Typography variant="body1" lineHeight="normal">
				{index !== undefined && `${index + 1}. `}
				{skill.skill.name}
				{skill.endorsementCount > 0 && (
					<>
						{' '}
						•{' '}
						<ButtonBase
							disabled={isSortable}
							onClick={() => setEndorsementsModalOpen(true)}>
							<Stack direction="row" spacing={0.25} alignItems="center" height={18}>
								<Typography variant="h5" color="primary">
									{skill.endorsementCount}
								</Typography>
								<Verified sx={{ fontSize: 16, color: 'primary.main' }} />
								{skill.endorsedByCurrentUser && user?.userId && (
									<UserEmblemAvatar id={user.userId} size={16} noDropdown />
								)}
							</Stack>
						</ButtonBase>
					</>
				)}
				{(canEndorse || canEdit) && (
					<>
						{' '}
						<IconButton
							onClick={openMenu}
							sx={{ height: 18, width: 18, borderRadius: 0.5 }}>
							<MoreHoriz sx={{ fontSize: 16 }} />
						</IconButton>
					</>
				)}
			</Typography>
			{/** menu */}
			<Menu open={menuOpen} anchorEl={anchorEl} onClose={closeMenu} onClick={closeMenu}>
				{canEndorse && !skill.endorsedByCurrentUser && (
					<MenuItem onClick={() => setEndorseModalOpen(true)}>
						<ListItemIcon>
							<VerifiedOutlined />
						</ListItemIcon>
						<ListItemText>Give Endorsement</ListItemText>
					</MenuItem>
				)}
				{canEndorse && skill.endorsedByCurrentUser && (
					<MenuItem onClick={() => setUnendorseModalOpen(true)}>
						<ListItemIcon>
							<Close />
						</ListItemIcon>
						<ListItemText>Remove Endorsement</ListItemText>
					</MenuItem>
				)}
				{canEdit && (
					<MenuItem onClick={() => setEndorsementsModalOpen(true)}>
						<ListItemIcon>
							<VerifiedOutlined />
						</ListItemIcon>
						<ListItemText>Manage Endorsements</ListItemText>
					</MenuItem>
				)}
				{canEdit && (
					<MenuItem onClick={() => setEditPlacesModalOpen(true)}>
						<ListItemIcon>
							<EditOutlined />
						</ListItemIcon>
						<ListItemText>Edit Places Used</ListItemText>
					</MenuItem>
				)}
				{canEdit && (
					<MenuItem onClick={() => setDeleteModalOpen(true)}>
						<ListItemIcon>
							<DeleteOutlined />
						</ListItemIcon>
						<ListItemText>Delete</ListItemText>
					</MenuItem>
				)}
			</Menu>
			{/** delete modal */}
			<ModalOrDrawer open={deleteModalOpen}>
				<ConfirmationModalContent
					variant="destructive"
					primaryText={`Delete "${skill.skill.name}?"`}
					secondaryText="Do you really want to delete the skill? This process cannot be undone."
					onSubmit={async () => await deleteSkill(skill.id)}
					onClose={() => setDeleteModalOpen(false)}
				/>
			</ModalOrDrawer>
			{/** endorse modal */}
			<ModalOrDrawer open={endorseModalOpen}>
				<ConfirmationModalContent
					graphic={<EmptyStateAvatar icon={<VerifiedOutlined />} />}
					primaryText="Give endorsement?"
					secondaryText={`Are you sure you want to endorse "${skill.skill.name}?"`}
					onSubmit={async () => await endorseSkill(true)}
					onClose={() => setEndorseModalOpen(false)}
				/>
			</ModalOrDrawer>
			{/** remove endorsement modal */}
			<ModalOrDrawer open={unendorseModalOpen}>
				<ConfirmationModalContent
					variant="destructive"
					primaryText="Remove endorsement?"
					secondaryText={`Are you sure you want to remove your endorsement of "${skill.skill.name}?"`}
					confirmText="Remove"
					onSubmit={async () => await endorseSkill(false)}
					onClose={() => setUnendorseModalOpen(false)}
				/>
			</ModalOrDrawer>
			{/** endorsements modal */}
			<ModalOrDrawer open={endorsementsModalOpen}>
				<EndorsementsModal
					userId={userId}
					skill={skill}
					canEdit={canEdit}
					onClose={() => setEndorsementsModalOpen(false)}
				/>
			</ModalOrDrawer>
			{/** edit places modal */}
			<ModalOrDrawer open={editPlacesModalOpen}>
				<EditPlacesUsedModal
					userId={userId}
					skill={skill}
					onClose={() => setEditPlacesModalOpen(false)}
				/>
			</ModalOrDrawer>
		</Box>
	);
};
