import {
	AccountCircleOutlined,
	ForumOutlined,
	HowToRegOutlined,
	SendOutlined,
} from '@mui/icons-material';
import { Box, Button, ButtonProps, Card, Divider, Stack, Typography } from '@mui/material';
import { useCurrentUserUserConnection } from 'components/pages/network/user/UserConnectionButton/hooks';
import { UserEmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { useEmblemBanner } from 'components/ui/emblem/hooks';
import { AccountCancelOutlined, AccountQuestionOutlined } from 'components/ui/icons';
import { ConnectionState, Emblem, EmblemEntityType } from 'middleware-types';
import { Permission } from 'utils/permissions';
import { useAnonymousSession } from 'utils/session';
import { useCanMessage } from 'utils/useCanMessage';
import { useSiteUser } from 'utils/useSiteUser';
import { useSkipUntilIntersecting } from 'utils/useSkipUntilIntersecting';
import { isDemo } from 'utils/utils';

export const TeamMemberCard = ({ member }: { member: Emblem }) => {
	const { user } = useAnonymousSession();
	const { hasPermission } = useSiteUser();

	const isSelf = user && member.id === user.userId;
	const isSiteUser = user && Boolean(user.siteUserId);
	const canViewProfile = !isSiteUser || hasPermission(Permission.SocialUser_Profile_R);

	const subtitle =
		member.extraData?.companyName && member.extraData.companyTitle
			? `${member.extraData.companyTitle} at ${member.extraData.companyName}`
			: '';

	const { ref, skip } = useSkipUntilIntersecting<HTMLDivElement>();

	const messageButtonEnabled = useCanMessage(member.handle, skip);
	const { src, onError } = useEmblemBanner(member.id, EmblemEntityType.User, skip);
	const { profile } = useCurrentUserUserConnection(member.id, skip || member.private);

	const connection = profile?.connection;
	let connectMessage = 'Not Connected';
	let ConnectIcon = AccountCancelOutlined;

	if (connection) {
		const connectState = connection.state;
		if (connectState === ConnectionState.Connected) {
			connectMessage = 'Connected';
			ConnectIcon = HowToRegOutlined;
		} else if (connectState === ConnectionState.Pending) {
			if (user && connection.targetUserId === user.userId) {
				connectMessage = 'Req. Pending';
				ConnectIcon = AccountQuestionOutlined;
			} else {
				connectMessage = 'Req. Sent';
				ConnectIcon = SendOutlined;
			}
		}
	}

	return (
		<Card ref={ref} variant="outlined" sx={{ position: 'relative' }}>
			<Box
				component="img"
				src={src}
				onError={onError}
				position="absolute"
				top={0}
				width="100%"
				height={68}
				sx={{ objectFit: 'cover' }}
			/>
			<Stack height="100%">
				<Stack
					flexGrow={1}
					position="relative"
					alignItems="center"
					spacing={1.5}
					pt="30px"
					pb={1.5}
					px={2}>
					<UserEmblemAvatar id={member.id} size={80} />
					<Box textAlign="center">
						<Typography variant="h4">{member.displayName}</Typography>
						{subtitle && <Typography variant="body1">{subtitle}</Typography>}
					</Box>
				</Stack>
				{!member.private && (
					<>
						<Divider />
						<Stack direction="row">
							{isDemo() && !isSelf && !isSiteUser && (
								<>
									<TeamMemberCardButton sx={{ pointerEvents: 'none' }}>
										<ConnectIcon />
										{connectMessage}
									</TeamMemberCardButton>
									<Divider orientation="vertical" />
								</>
							)}
							{isDemo() && !isSelf && !isSiteUser && (
								<>
									<TeamMemberCardButton
										disabled={!messageButtonEnabled}
										href={`/app/conversation-emblem-redirect/${EmblemEntityType.User}/${member.id}`}>
										<ForumOutlined />
										Message
									</TeamMemberCardButton>
									<Divider orientation="vertical" />
								</>
							)}
							<>
								<TeamMemberCardButton
									disabled={!canViewProfile}
									href={`/${member.handle}`}>
									<AccountCircleOutlined />
									View Profile
								</TeamMemberCardButton>
							</>
						</Stack>
					</>
				)}
			</Stack>
		</Card>
	);
};

const TeamMemberCardButton = ({ sx, ...rest }: ButtonProps) => {
	return (
		<Button
			{...rest}
			sx={{
				...sx,
				flexDirection: 'column',
				borderRadius: 0,
				flexGrow: 1,
				flexShrink: 1,
				flexBasis: 0,
				pt: 1.5,
				textAlign: 'center',
			}}
		/>
	);
};
