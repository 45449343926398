import { Box, Card, Divider, Stack } from '@mui/material';
import { EvoX } from 'components/pages/evoxchat/evox';
import { ReactElement, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAnonymousSession } from 'utils/session';
import { useIsMobile } from 'utils/useScreenSize';
import { SideRailButton } from './side-rail-button';
import { SideRailQuickLinks } from './side-rail-quick-links';

interface SideRailProps {
	isOrgPage?: boolean;
	children: ReactElement;
}

export const SideRail = ({ isOrgPage = false, children }: SideRailProps) => {
	const navigate = useNavigate();
	const [showEvox, setShowEvox] = useState(false);

	const isMobile = useIsMobile();
	const location = useLocation();
	const { user } = useAnonymousSession();

	const noSideRail =
		isMobile ||
		!user ||
		Boolean(user.siteUserId) ||
		location.pathname === '/' ||
		(location.pathname.startsWith('/orgs') &&
			!location.pathname.startsWith('/orgs/invitations') &&
			!isOrgPage);

	if (noSideRail) return children;

	return (
		<Stack height="100%" width="100%" flexDirection="row">
			<Stack alignSelf="stretch" position="relative" bgcolor="background.paper">
				<SideRailButton
					src="/img/evoxLogo.svg"
					tooltipText="EVO-X"
					onClick={() => setShowEvox((prev) => !prev)}
				/>
				<SideRailButton
					src="/img/FT-logo.svg"
					tooltipText="FileTrac"
					onClick={() => navigate('/app/legacy/linked-companies')}
				/>
				<SideRailButton
					src="/img/conversation-insurance-color-logo.svg"
					tooltipText="Conversation Insurance"
					href="https://app.conversation-insurance.com/share/YM1Ot7IqU_uMqIdu?utm_source=manual"
					target="_blank"
				/>
				<Divider />
				<SideRailQuickLinks />
			</Stack>
			<Divider orientation="vertical" />
			{/** evox */}
			{showEvox && (
				<Box position="relative">
					<Stack
						position="absolute"
						height="100%"
						width={{ xs: undefined, sm: '400px' }}
						zIndex={999}>
						<Card sx={{ height: '100%', borderRadius: '0' }}>
							<EvoX onClose={() => setShowEvox(false)} />
						</Card>
					</Stack>
				</Box>
			)}
			<Box display="flex" flexDirection="column" flexGrow={1} overflow="auto">
				{children}
			</Box>
		</Stack>
	);
};
