import { Stack } from '@mui/material';
import { LoginPromptButton } from './login-prompt-button';
import { ForumOutlined, PersonAddAlt } from '@mui/icons-material';

export const AnonymousSocialInteractionBar = () => {
	return (
		<Stack direction="row" spacing={1.5}>
			<LoginPromptButton variant="contained" color="primary" startIcon={<PersonAddAlt />}>
				Connect
			</LoginPromptButton>
			<LoginPromptButton variant="outlined" startIcon={<ForumOutlined />}>
				Message
			</LoginPromptButton>
		</Stack>
	);
};
