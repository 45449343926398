import {
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
} from '@mui/material';
import { DEFAULTPAGESIZES } from 'utils/theme';
import { useBillingJournalsContext } from '../billing-journals-provider';
import { BillingJournalRow } from './billing-journal-row';

interface BillingJournalsTableProps {
	includeCompany?: boolean;
}

export const BillingJournalsTable = ({ includeCompany = false }: BillingJournalsTableProps) => {
	const { journals, totalCount, loading, page, setPage, pageSize, setPageSize } =
		useBillingJournalsContext();

	return (
		<Stack flex={1} overflow="hidden">
			<Stack flex={1} overflow="hidden">
				<TableContainer>
					<Table stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell>Severity</TableCell>
								<TableCell>Timestamp</TableCell>
								{includeCompany && <TableCell>Company</TableCell>}
								<TableCell>Message</TableCell>
								<TableCell>Reference</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{loading ? (
								[...Array(3)].map((_, index) => (
									<TableRow key={index}>
										<TableCell colSpan={10}>
											<Skeleton variant="text" />
										</TableCell>
									</TableRow>
								))
							) : journals.length === 0 ? (
								<TableRow>
									<TableCell colSpan={10} align="center">
										No results
									</TableCell>
								</TableRow>
							) : (
								journals.map((journal) => (
									<BillingJournalRow
										key={journal.id}
										journal={journal}
										includeCompany={includeCompany}
									/>
								))
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Stack>
			<TablePagination
				component={Stack}
				flexShrink={0}
				count={totalCount}
				page={page}
				onPageChange={(_e, v) => setPage(v)}
				rowsPerPage={pageSize}
				onRowsPerPageChange={(e) => {
					setPage(0);
					setPageSize(parseInt(e.target.value));
				}}
				rowsPerPageOptions={DEFAULTPAGESIZES}
			/>
		</Stack>
	);
};
