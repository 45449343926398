import { Book, BookOutlined, Description, DescriptionOutlined } from '@mui/icons-material';
import { Box, Container, Stack } from '@mui/material';
import { NavList, NavListItem } from 'components/ui/nav-list';
import { PageContent, PageTitle } from 'components/ui/page';
import { Outlet } from 'react-router-dom';
import { StripeAdminCard } from './stripe-admin-card';

export const SiteBillingPage = () => {
	return (
		<>
			<PageTitle title="Billing" />
			<PageContent noScroll>
				<Container disableGutters maxWidth="xxl" sx={{ height: '100%' }}>
					<Stack spacing={2} direction="row" alignItems="flex-start" height="100%">
						<NavList title="Billing" width={250} flexShrink={0}>
							<NavListItem
								activeIcon={<Description color="primary" />}
								icon={<DescriptionOutlined sx={{ color: 'neutral.500' }} />}
								text="Billing Report"
								to=""
								end
							/>
							<NavListItem
								activeIcon={<Book color="primary" />}
								icon={<BookOutlined sx={{ color: 'neutral.500' }} />}
								text="Billing Journals"
								to="journals"
								end
							/>
						</NavList>
						<Box flex={1} alignSelf="stretch">
							<Outlet />
						</Box>
						<StripeAdminCard />
					</Stack>
				</Container>
			</PageContent>
		</>
	);
};
