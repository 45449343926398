import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { ModalButtonsContainer } from 'components/ui/modals/modal-buttons-container';
import { ModalContent } from 'components/ui/modals/modal-content';
import { ModalHeader } from 'components/ui/modals/modal-header';
import { ModalContentProps } from 'components/ui/modals/modal-types';
import { Formik } from 'formik';
import { UserSkill, UserSkillPlacesUsedUpdateRequest } from 'middleware-types';
import { usePlacesSkillUsed, useUpdateSkill } from './hooks';
import {
	convertPlacesUsedFormValues,
	PlacesUsedCheckboxes,
	PlacesUsedFormValues,
	usePlacesUsedInitialValues,
} from './places-used-checkboxes';

interface EditPlacesUsedModalProps extends ModalContentProps {
	userId: string;
	skill: UserSkill;
}

export const EditPlacesUsedModal = ({ userId, skill, onClose }: EditPlacesUsedModalProps) => {
	const { updateSkill } = useUpdateSkill(userId, skill.id);
	const { placesUsed } = usePlacesSkillUsed(userId, skill.id);

	const initialValues: PlacesUsedFormValues = usePlacesUsedInitialValues(userId, placesUsed);

	const onSubmit = async (values: PlacesUsedFormValues) => {
		const request: UserSkillPlacesUsedUpdateRequest = {
			placesUsed: convertPlacesUsedFormValues(values),
		};
		await updateSkill(request).then((success) => {
			if (success) onClose();
		});
	};

	return (
		<Formik<PlacesUsedFormValues>
			enableReinitialize
			initialValues={initialValues}
			onSubmit={onSubmit}>
			{({ submitForm, isSubmitting, dirty }) => (
				<>
					<ModalHeader
						title={`Edit Places Used (${skill.skill.name})`}
						onClose={onClose}
					/>
					<ModalContent>
						<PlacesUsedCheckboxes userId={userId} />
					</ModalContent>
					<ModalButtonsContainer>
						<Button variant="outlined" onClick={onClose}>
							Cancel
						</Button>
						<LoadingButton
							variant="contained"
							color="primary"
							disabled={!dirty}
							loading={isSubmitting}
							onClick={submitForm}>
							Save
						</LoadingButton>
					</ModalButtonsContainer>
				</>
			)}
		</Formik>
	);
};
