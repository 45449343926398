import { LoadingButton } from '@mui/lab';
import {
	AutocompleteRenderInputParams,
	Box,
	Button,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { Field, Formik, FormikErrors } from 'formik';
import { Autocomplete } from 'formik-mui';
import { Skill, UserSkill, UserSkillCreate } from 'middleware-types';
import { useAddSkill, useSkillsList } from './hooks';
import {
	convertPlacesUsedFormValues,
	PlacesUsedCheckboxes,
	PlacesUsedFormValues,
	usePlacesUsedInitialValues,
} from './places-used-checkboxes';

interface SkillFormValues extends PlacesUsedFormValues {
	skill: Skill | null;
}

interface AddSkillFormProps {
	userId: string;
	existingSkills: UserSkill[];
	onClose: () => void;
}

export const AddSkillForm = ({ userId, existingSkills, onClose }: AddSkillFormProps) => {
	const { addSkill } = useAddSkill(userId);
	const { skills } = useSkillsList();

	// filter out skills that have been previously selected
	const skillOptions = skills.filter(
		(skill) => !existingSkills.some((existing) => existing.skillId === skill.id)
	);

	const initialValues: SkillFormValues = {
		skill: null,
		...usePlacesUsedInitialValues(userId),
	};

	const onSubmit = async (values: SkillFormValues) => {
		const request: UserSkillCreate = {
			skillId: values.skill!.id,
			placesUsed: convertPlacesUsedFormValues(values),
			displayOrder: existingSkills.length
				? Math.max(...existingSkills.map((skill) => skill.displayOrder)) + 1
				: 1,
		};
		await addSkill(request).then((success) => {
			if (success) onClose();
		});
	};

	const validate = (values: SkillFormValues) => {
		const errors: FormikErrors<SkillFormValues> = {};
		if (values.skill === null) errors.skill = 'Required Field';
		return errors;
	};

	return (
		<Formik<SkillFormValues>
			enableReinitialize
			initialValues={initialValues}
			onSubmit={onSubmit}
			validate={validate}>
			{({ isSubmitting, submitForm, isValid, dirty }) => (
				<Stack p={2.5} spacing={2.5}>
					<Typography variant="h4">Add New Skill</Typography>
					<Field
						name="skill"
						component={Autocomplete}
						options={skillOptions}
						disableClearable
						getOptionLabel={(option: Skill) => option.name}
						isOptionEqualToValue={(option: Skill, value: Skill) =>
							option.id === value.id
						}
						renderInput={(props: AutocompleteRenderInputParams) => (
							<TextField {...props} required label="Skill" sx={{ m: 0 }} />
						)}
					/>
					<Stack spacing={1}>
						<Typography variant="h5">Where did you use this skill?</Typography>
						<PlacesUsedCheckboxes userId={userId} typographyVariant="h6" />
					</Stack>
					<Stack direction="row" spacing={1} justifyContent="flex-end">
						<Button variant="outlined" onClick={onClose}>
							Cancel
						</Button>
						<LoadingButton
							variant="contained"
							color="primary"
							disabled={!isValid || !dirty}
							loading={isSubmitting}
							onClick={submitForm}>
							Add
						</LoadingButton>
					</Stack>
				</Stack>
			)}
		</Formik>
	);
};
