import { Link } from '@mui/material';
import { ReactNode } from 'react';
import { useLazyDownloadUrl } from 'utils/fileUtils';

interface AssistantLinkProps {
	children: ReactNode;
	url?: string | undefined;
}

export const AssistantLink = ({ url, children }: AssistantLinkProps) => {
	const isLazyLoad = url && url.startsWith('evox.chatfile.lazy/fileinstance/');
	const fileInstanceId = isLazyLoad ? url.split('/')[2] : '';

	const [getDownloadUrl] = useLazyDownloadUrl({
		fetchPolicy: 'network-only',
		variables: {
			fileInstanceId: fileInstanceId,
			sasUrlOnly: true,
		},
	});

	const handleClick = async (e: any) => {
		e.preventDefault();
		const url = await getDownloadUrl();
		window.location.href = url.data?.getFileInstanceDownloadUrl.downloadUrl ?? '#';
	};

	return isLazyLoad ? (
		<Link
			sx={{ 'color': 'navy.500', '&:visited': { color: 'navy.500' } }}
			underline="always"
			onClick={handleClick}
			component="button">
			{children}
		</Link>
	) : (
		<Link
			sx={{ 'color': 'navy.500', '&:visited': { color: 'navy.500' } }}
			underline="always"
			target="_blank"
			href={url}>
			{children}
		</Link>
	);
};
