import { SettingsOutlined } from '@mui/icons-material';
import {
	Box,
	Button,
	Container,
	Dialog,
	Drawer,
	IconButton,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import { useOrgQuickLinks } from 'components/pages/dashboard/quickLinks/orgQuickLinkHooks';
import { QuickLinks } from 'components/pages/dashboard/quickLinks/quickLinks';
import { useOrgId } from 'components/pages/org/outlet';
import { OrgEmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { PageContent, PageTitle } from 'components/ui/page';
import { useState } from 'react';
import { Permission } from 'utils/permissions';
import { theme } from 'utils/theme';
import { useAssociateUser } from 'utils/useAssociateUser';
import { useElementSize } from 'utils/useElementSize';
import { useIsMobile } from 'utils/useScreenSize';
import { useSiteUser } from 'utils/useSiteUser';
import { OrgDashboardSettingsModal } from './editSettingsModal';
import { OrgLinkCards } from './org-link-cards';
import { useOrgDashboardSettings } from './orgDashboardSettingsHooks';

export const OrganizationDashboard = () => {
	const isMobile = useIsMobile();
	const orgId = useOrgId();

	const quickLinksMethods = useOrgQuickLinks(orgId);
	const { settings } = useOrgDashboardSettings(orgId);
	const [openDashboardSettingsModal, setOpenDashboardSettingsModal] = useState(false);

	const { hasPermission: hasAssociateUserPermission } = useAssociateUser(orgId);
	const { hasPermission: hasSiteUserPermission } = useSiteUser();
	const canEdit =
		hasAssociateUserPermission(Permission.Org_Dashboard_U) ||
		hasSiteUserPermission(Permission.Site_OrgDashboard_U);

	// keep track of the size of the header
	// this is used to set the height of the header background
	const [headerRef, { clientHeight }] = useElementSize<HTMLDivElement>();
	const headerHeight = clientHeight ?? 60;

	const textColor = settings?.primaryColor
		? theme.palette.getContrastText(settings?.primaryColor)
		: undefined;

	return (
		<Box position="relative">
			<Box
				position="absolute"
				width="100%"
				zIndex={-100}
				bgcolor={settings?.primaryColor}
				height={headerHeight + 100}
			/>
			<PageTitle title="Dashboard" />
			<PageContent>
				<Container maxWidth="lg" disableGutters>
					<Stack>
						<Stack ref={headerRef} pt={{ xs: 1, sm: 3 }} pb={{ xs: 3, sm: 4 }}>
							{isMobile ? (
								<Stack spacing={2}>
									<Stack spacing={1.5}>
										<Stack direction="row" spacing={2} alignItems="center">
											<OrgEmblemAvatar id={orgId} size={60} />
											{settings?.title ? (
												<Typography
													variant="h1"
													color={textColor}
													sx={{ wordBreak: 'break-word' }}>
													{settings.title}
												</Typography>
											) : (
												<Skeleton variant="text" width={120} />
											)}
										</Stack>
										<Typography
											color={textColor}
											sx={{ wordBreak: 'break-word' }}>
											{settings?.subTitle}
										</Typography>
									</Stack>
									{canEdit && (
										<Button
											variant="outlined"
											startIcon={<SettingsOutlined />}
											onClick={() => setOpenDashboardSettingsModal(true)}>
											Configure Dashboard Theme
										</Button>
									)}
								</Stack>
							) : (
								<Stack direction="row" spacing={3} alignItems="center">
									<OrgEmblemAvatar id={orgId} size={80} />
									<Box flex={1}>
										<Typography
											variant="h1"
											color={textColor}
											sx={{ wordBreak: 'break-word' }}>
											{settings?.title}
										</Typography>
										<Typography
											color={textColor}
											sx={{ wordBreak: 'break-word' }}>
											{settings?.subTitle}
										</Typography>
									</Box>
									{canEdit && (
										<IconButton
											onClick={() => setOpenDashboardSettingsModal(true)}>
											<SettingsOutlined
												fontSize="large"
												htmlColor={textColor}
											/>
										</IconButton>
									)}
								</Stack>
							)}
						</Stack>
						<Stack spacing={4}>
							<OrgLinkCards settings={settings} />
							<QuickLinks methods={quickLinksMethods} canEdit={canEdit} />
						</Stack>
						{isMobile ? (
							<Drawer open={openDashboardSettingsModal} anchor="bottom">
								<OrgDashboardSettingsModal
									orgId={orgId}
									onClose={() => setOpenDashboardSettingsModal(false)}
								/>
							</Drawer>
						) : (
							<Dialog open={openDashboardSettingsModal} fullWidth>
								<OrgDashboardSettingsModal
									orgId={orgId}
									onClose={() => setOpenDashboardSettingsModal(false)}
								/>
							</Dialog>
						)}
					</Stack>
				</Container>
			</PageContent>
		</Box>
	);
};

// const OrgDashboardSkeleton = () => {
// 	return (
// 		<Box position="relative">
// 			<Skeleton
// 				variant="rectangular"
// 				height={209}
// 				width="100%"
// 				sx={{ position: 'absolute', zIndex: -100 }}
// 			/>
// 			<PageTitle title="Dashboard" />
// 			<PageContent>
// 				<Container maxWidth="lg" disableGutters>
// 					<Stack spacing={{ xs: 2.5, sm: 5 }}>
// 						<Stack direction="row" spacing={3} alignItems="center" paddingTop={3}>
// 							<Skeleton
// 								height="4rem"
// 								width="4rem"
// 								sx={{ margin: 'auto' }}
// 								variant="rectangular"
// 							/>
// 							<Box flex={1}>
// 								<Skeleton variant="text" width={150} />
// 								<Skeleton variant="text" width={150} />
// 							</Box>
// 						</Stack>
// 						<Box
// 							display="grid"
// 							gridTemplateColumns={{
// 								xs: 'repeat(2, 1fr)',
// 								md: 'repeat(3, 1fr)',
// 								lg: 'repeat(4, 1fr)',
// 							}}
// 							gridAutoRows="1fr"
// 							gap={2}>
// 							{Array.from({ length: 4 }).map((_, i) => {
// 								return (
// 									<Card key={i}>
// 										<CardContent sx={{ height: '100%' }}>
// 											<Stack
// 												height="100%"
// 												alignItems="center"
// 												justifyContent="center"
// 												spacing={1}>
// 												<Icon fontSize="large" />
// 												<Skeleton
// 													variant="circular"
// 													height={50}
// 													width={50}
// 												/>
// 												<Skeleton variant="text" height={25} width={100} />
// 											</Stack>
// 										</CardContent>
// 									</Card>
// 								);
// 							})}
// 						</Box>
// 					</Stack>
// 				</Container>
// 			</PageContent>
// 		</Box>
// 	);
// };
