import { CheckCircleOutlined } from '@mui/icons-material';
import { Avatar, Chip, Stack, Typography } from '@mui/material';
import { Flag } from 'components/ui/flag';
import { AdjusterLicensingSummary } from 'middleware-types';
import { useCountries } from 'utils/useCountries';

interface LicensingSummaryDetailsProps {
	summary: AdjusterLicensingSummary;
}

export const LicensingSummaryDetails = ({ summary }: LicensingSummaryDetailsProps) => {
	const { countriesLookupMapById } = useCountries();
	const country = countriesLookupMapById.get(summary.countryId);

	return (
		<Stack direction="row" alignItems="center" spacing={2}>
			<Avatar>{country && <Flag code={country.iso2CountryCode} />}</Avatar>
			<Stack spacing={0.25}>
				<Stack direction="row" alignItems="baseline" spacing={1}>
					<Typography variant="h4">{country?.name}</Typography>
					{summary.licensingProvenance !== 'ManuallyEntered' && (
						<Chip
							color="primary"
							size="small"
							label={summary.licensingProvenance.toUpperCase()}
							icon={<CheckCircleOutlined />}
						/>
					)}
					<Typography
						sx={{ color: 'neutral.400' }}
						variant="body2"
						display="inline"
						fontStyle="italic">
						{summary.licenseHoldersName}
					</Typography>
				</Stack>
				<Typography variant="body1">
					{summary.numLicenses} Licenses • {summary.numActiveLicenses} Active •{' '}
					{summary.numExpiredLicenses} Expired • {summary.numExpiredWithin60DaysLicenses}{' '}
					Expiring Within Sixty Days
				</Typography>
			</Stack>
		</Stack>
	);
};
