import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { Stack, Typography, ButtonBase, Box } from '@mui/material';
import { useFormikContext } from 'formik';
import {
	FileAccessLevel,
	FileAccessLevelChangeRequest,
	FileInstanceCopyRequest,
} from 'middleware-types';
import {
	useDefaultAccessLevel,
	AccessLevelIcon,
	AccessLevelDescription,
} from '../components/access-level';

export const AccessLevelFields = ({
	accessLevelOptions,
	fieldName = 'accessLevel',
	multipleFiles = false,
}: {
	accessLevelOptions: {
		option: FileAccessLevel;
		disabled: boolean | undefined;
	}[];
	fieldName?: string;
	multipleFiles?: boolean;
}) => {
	const { setFieldValue, values } = useFormikContext<
		FileAccessLevelChangeRequest | FileInstanceCopyRequest
	>();
	const defaultLevel = useDefaultAccessLevel();

	return (
		<Stack p={2.5} spacing={3}>
			<Stack spacing={2}>
				<Typography variant="h5">
					What level of privacy should{' '}
					{multipleFiles ? 'these documents' : 'this document'} have? *
				</Typography>
				{accessLevelOptions.map(({ option, disabled }) => {
					const selected = values[fieldName] === option;
					const isDefault = defaultLevel == option;
					return (
						<ButtonBase
							disabled={disabled}
							key={option}
							onClick={() => setFieldValue(fieldName, option)}
							sx={{
								borderRadius: 2,
								border: '1px solid',
								bgcolor: selected ? 'primary.light' : undefined,
								borderColor: selected
									? 'primary.main'
									: disabled
									? 'neutral.100'
									: 'neutral.200',
							}}>
							<Stack
								width="100%"
								direction="row"
								alignItems="center"
								p={2}
								spacing={2}>
								{selected ? (
									<RadioButtonChecked color="primary" />
								) : (
									<RadioButtonUnchecked
										sx={{
											color: disabled ? 'neutral.300' : 'neutral.500',
										}}
									/>
								)}
								<AccessLevelIcon
									level={option}
									sx={{
										fontSize: 32,
										color: disabled ? 'neutral.300' : 'primary.main',
									}}
								/>
								<Box flex={1} textAlign="left">
									<Typography
										variant="h5"
										mb={0.5}
										color={disabled ? 'neutral.300' : undefined}>
										{option} {isDefault && '(Default)'}
									</Typography>
									<Typography
										variant="subtitle1"
										lineHeight={1.25}
										color={disabled ? 'neutral.300' : undefined}>
										<AccessLevelDescription level={option} />
									</Typography>
								</Box>
							</Stack>
						</ButtonBase>
					);
				})}
			</Stack>
		</Stack>
	);
};
