import { ListItem, ListItemButton, Stack, Typography } from '@mui/material';
import { FileInstanceInformation } from 'middleware-types';
import { memo, useState } from 'react';
import { ExtensionIcon, useDownloadFileInstance } from 'utils/fileUtils';
import { AccessLevelIcon } from '../components/access-level';
import { FileMenuButton } from '../components/file-menu-button';
import { FolderLink } from '../components/folder-link';
import { useVersionHistoryContext } from '../components/version-history-provider';

interface FileListItemProps {
	file: FileInstanceInformation;
}

export const FileListItem = memo(function FileListItem({ file }: FileListItemProps) {
	const downloadFileInstance = useDownloadFileInstance();
	const { isVersionHistory } = useVersionHistoryContext();
	const [contextMenuOpen, setContextMenuOpen] = useState(false);

	return (
		<ListItem disableGutters disablePadding>
			<ListItemButton disableGutters onClick={() => downloadFileInstance(file, true)}>
				<Stack direction="row" width="100%" alignItems="center" spacing={1.5}>
					<ExtensionIcon filename={file.fileName} sx={{ color: 'neutral.500' }} />
					<Stack flex={1} overflow="hidden">
						<Typography variant="body1" noWrap>
							{file.fileName}{' '}
							{isVersionHistory && (
								<Typography component="span" sx={{ color: 'neutral.400' }}>
									(v{file.versionNumber})
								</Typography>
							)}
						</Typography>
						<FolderLink folder={file.folder} />
					</Stack>
					<AccessLevelIcon level={file.accessLevel} sx={{ color: 'neutral.500' }} />
					<FileMenuButton
						file={file}
						menuOpen={contextMenuOpen}
						setMenuOpen={setContextMenuOpen}
					/>
				</Stack>
			</ListItemButton>
		</ListItem>
	);
});
