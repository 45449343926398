import { Box, Divider, FormControlLabel, Skeleton, Stack, Switch, Typography } from '@mui/material';
import { UserEmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { useEmblem } from 'components/ui/emblem/hooks';
import { ModalHeader } from 'components/ui/modals/modal-header';
import { ModalContentProps } from 'components/ui/modals/modal-types';
import { formatDistanceToNowStrict } from 'date-fns';
import { EmblemEntityType, UserSkill, UserSkillEndorsement } from 'middleware-types';
import { useSkillEndorsements, useUpdateSkillEndorementVisibility } from './hooks';

interface EndorsementsModalProps extends ModalContentProps {
	userId: string;
	skill: UserSkill;
	canEdit: boolean;
}

export const EndorsementsModal = ({ userId, skill, canEdit, onClose }: EndorsementsModalProps) => {
	const { endorsements, loading } = useSkillEndorsements(userId, skill.id);
	return (
		<>
			<ModalHeader
				title={`${canEdit ? 'Manage Endorsements' : 'Endorsements'} (${skill.skill.name})`}
				onClose={onClose}
			/>
			<Divider />
			{loading ? (
				<Stack p={2} spacing={2}>
					{[...Array(3)].map((_, index) => (
						<Skeleton key={index} variant="rounded" height={60} />
					))}
				</Stack>
			) : endorsements.length === 0 ? (
				<Stack p={4} alignItems="center" justifyContent="center">
					<Typography variant="subtitle1">No endorsements</Typography>
				</Stack>
			) : (
				<Stack overflow="auto">
					{endorsements.map((endorsement) => (
						<EndorsementListItem
							key={endorsement.id}
							userId={userId}
							userSkillId={skill.id}
							endorsement={endorsement}
							canEdit={canEdit}
						/>
					))}
				</Stack>
			)}
		</>
	);
};

interface EndorsementListItemProps {
	userId: string;
	userSkillId: string;
	endorsement: UserSkillEndorsement;
	canEdit: boolean;
}

const EndorsementListItem = ({
	userId,
	userSkillId,
	endorsement,
	canEdit,
}: EndorsementListItemProps) => {
	const { updateVisibility, loading } = useUpdateSkillEndorementVisibility(
		userId,
		userSkillId,
		endorsement.id
	);
	const { emblem } = useEmblem(endorsement.endorsingUserId, EmblemEntityType.User);

	const name = emblem?.displayName;
	const jobDescription = emblem?.extraData?.companyTitle
		? `${emblem.extraData.companyTitle} at ${emblem.extraData.companyName}`
		: undefined;
	const timeString = formatDistanceToNowStrict(new Date(endorsement.endorsedOnUtc), {
		addSuffix: true,
	});

	return (
		<Stack
			px={2}
			py={1.5}
			direction="row"
			alignItems="center"
			justifyContent="space-between"
			spacing={2}>
			<Stack direction="row" alignItems="flex-start" spacing={1.5}>
				<UserEmblemAvatar id={endorsement.endorsingUserId} size={48} />
				<Box>
					<Typography variant="h5">{name}</Typography>
					{jobDescription && (
						<Typography variant="subtitle2">{jobDescription}</Typography>
					)}
					<Typography variant="subtitle2">{timeString}</Typography>
				</Box>
			</Stack>
			{canEdit && (
				<FormControlLabel
					label="Show on profile"
					disabled={loading}
					control={
						<Switch
							checked={endorsement.showOnProfile}
							onChange={(_e, checked) => updateVisibility(checked)}
						/>
					}
				/>
			)}
		</Stack>
	);
};
