import { gql, useQuery } from '@apollo/client';
import { Business, Check, InfoOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Avatar,
	Button,
	Card,
	CardContent,
	IconButton,
	Skeleton,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import { useOrgId } from 'components/pages/org/outlet';
import { OrgEmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { ModalButtonsContainer } from 'components/ui/modals/modal-buttons-container';
import { ModalContent } from 'components/ui/modals/modal-content';
import { ModalHeader } from 'components/ui/modals/modal-header';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { ModalContentProps } from 'components/ui/modals/modal-types';
import { Emblem, Query, QueryLinkedLegacyAdjustmentCompanyInfoArgs } from 'middleware-types';
import { useState } from 'react';
import { Permission } from 'utils/permissions';
import { useSession } from 'utils/session';
import { theme } from 'utils/theme';
import { useAssociateUser } from 'utils/useAssociateUser';
import { ConversationInsurance } from '../conversationInsurance/conversation-insurance';
import {
	useGetAssociatesToBeSeeded,
	useGetOrganizationAncestors,
	useSeedOrgOrAncestor,
} from '../hierarchy/hooks';
import { LinkCompanyModal } from './modal';

export const OrgAccountLinkingPage = ({ orgId: propsOrgId }: { orgId?: string }) => {
	// When this component is rendered outside org outlet (site user organizations page),
	// orgId is passed explicitly as a prop. Otherwise we can fetch it with this hook,
	// like all the other org components.
	const contextOrgId = useOrgId();
	const orgId = propsOrgId ?? contextOrgId;

	return (
		<Stack spacing={4}>
			<LinkedAdjustmentCompanyCard orgId={orgId} />
			<ConversationInsurance orgId={orgId} />
		</Stack>
	);
};

export const LinkedAdjustmentCompanyCard = ({ orgId }: { orgId: string }) => {
	const { company, loading } = useLinkedLegacyAdjustmentCompanyInfo(orgId);
	const { user } = useSession();

	const [addCompanyModalOpen, setAddCompanyModalOpen] = useState(false);
	const [seedOrgModalOpen, setSeedOrgModalOpen] = useState(false);

	// If there's no company
	if (!company)
		return (
			<Card>
				<CardContent
					sx={{
						display: 'flex',
						alignItems: 'center',
						borderBottom: `1px solid ${theme.palette.divider}`,
					}}>
					<Typography variant="h2">Linked Entities</Typography>
				</CardContent>
				<CardContent
					sx={{
						display: 'flex',
						alignItems: 'center',
					}}>
					<Stack
						width="100%"
						display="flex"
						flexDirection="row"
						alignItems="center"
						justifyContent="space-between">
						{loading ? (
							<Typography width="100%" variant="h5">
								<Skeleton variant="text" width="100%" />
							</Typography>
						) : (
							<Typography variant="h5">
								This organization is not linked to any external entities.
							</Typography>
						)}
						{!loading && (
							<LoadingButton
								variant="outlined"
								startIcon={<img width="24px" src={'/img/FT-logo.svg'} />}
								onClick={() => setAddCompanyModalOpen(true)}>
								Link to FileTrac
							</LoadingButton>
						)}
					</Stack>
				</CardContent>
				{/** add company modal */}
				<ModalOrDrawer open={addCompanyModalOpen}>
					<ModalHeader
						title="Link to FileTrac"
						onClose={() => setAddCompanyModalOpen(false)}
					/>
					<LinkCompanyModal orgId={orgId} onClose={() => setAddCompanyModalOpen(false)} />
				</ModalOrDrawer>
			</Card>
		);

	// If there's a company being returned but the user doesn't have access so the data is stripped out
	if (!company.name || !company.systemUrl) {
		return (
			<Card>
				<CardContent
					sx={{
						display: 'flex',
						alignItems: 'center',
						borderBottom: `1px solid ${theme.palette.divider}`,
					}}>
					<Stack direction="row" spacing={1}>
						<img width="24px" src={'/img/FT-logo.svg'} />
						<Typography variant="h2">FileTrac</Typography>
					</Stack>
					<Tooltip
						title="This FTEvolve Organization is linked to a FileTrac Adjustment Company."
						arrow>
						<IconButton>
							<InfoOutlined fontSize="small" />
						</IconButton>
					</Tooltip>
				</CardContent>
				<CardContent
					sx={{
						display: 'flex',
						alignItems: 'center',
					}}>
					<Stack
						width="100%"
						display="flex"
						flexDirection="row"
						alignItems="center"
						justifyContent="space-between">
						<Typography variant="h5">
							This organization is linked to a FileTrac Adjustment Company
						</Typography>
					</Stack>
				</CardContent>
			</Card>
		);
	}

	// If there's a company with data
	return (
		<Card>
			<CardContent
				sx={{
					display: 'flex',
					alignItems: 'center',
					borderBottom: `1px solid ${theme.palette.divider}`,
				}}>
				<Stack direction="row" spacing={1}>
					<img width="24px" src={'/img/FT-logo.svg'} />
					<Typography variant="h2">FileTrac</Typography>
				</Stack>
				<Tooltip
					title="This FTEvolve Organization is linked to the following FileTrac Adjustment Company."
					arrow>
					<IconButton>
						<InfoOutlined fontSize="small" />
					</IconButton>
				</Tooltip>
			</CardContent>
			<CardContent
				sx={{
					display: 'flex',
					alignItems: 'center',
				}}>
				<Stack
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					width="100%">
					<Stack display="flex" flexDirection="row">
						<Avatar
							variant="square"
							sx={{
								'& img': { objectFit: 'contain' },
								'marginRight': 2,
								'background': 'transparent',
							}}
							src={
								company?.systemUrl && company?.logo
									? `${company?.systemUrl}/../images/logos/${company?.logo}`
									: undefined
							}>
							<Business />
						</Avatar>
						<Stack
							width="100%"
							display="flex"
							flexDirection="row"
							alignItems="center"
							justifyContent="space-between">
							<Typography variant="h5">{company?.name}</Typography>
						</Stack>
					</Stack>
					<Stack direction="row" alignItems="center" spacing={1}>
						<Button
							disabled={!!user.siteUserId}
							variant="contained"
							color="primary"
							onClick={() => setSeedOrgModalOpen(true)}>
							Import Users as Associates
						</Button>
						<Tooltip title="This feature will allow you to import all Evolve users linked to this FileTrac Adjustment Company as associates into either this organization or any organization in the hierarchy above, so long as you have permissions to do so within the chosen organization.">
							<InfoOutlined sx={{ color: 'neutral.500' }} />
						</Tooltip>
					</Stack>
				</Stack>
			</CardContent>
			{/** seed orgs modal */}
			<ModalOrDrawer open={seedOrgModalOpen}>
				<ModalHeader
					title="Select the Organization to Import Users Into"
					onClose={() => setSeedOrgModalOpen(false)}
				/>
				<SeedOrgOrAncestorModalContent
					orgId={orgId}
					onClose={() => setSeedOrgModalOpen(false)}
				/>
			</ModalOrDrawer>
		</Card>
	);
};

export type OrgSearchFormValues = {
	searchText: string;
};

export const useLinkedLegacyAdjustmentCompanyInfo = (orgId: string) => {
	const { data, error, loading } = useQuery<
		Pick<Query, 'linkedLegacyAdjustmentCompanyInfo'>,
		QueryLinkedLegacyAdjustmentCompanyInfoArgs
	>(
		gql`
			query linkedLegacyAdjustmentCompanyInfo($orgId: ID!) {
				linkedLegacyAdjustmentCompanyInfo(orgId: $orgId) {
					name
					acid
					logo
					systemUrl
				}
			}
		`,
		{
			variables: {
				orgId,
			},
			fetchPolicy: 'cache-and-network',
			onError: () => {
				console.log(error);
			},
		}
	);

	return {
		company: data?.linkedLegacyAdjustmentCompanyInfo,
		error,
		loading,
	};
};

interface SeedOrgOrAncestorModalContentProps extends ModalContentProps {
	orgId: string;
}

const SeedOrgOrAncestorModalContent = ({ orgId, onClose }: SeedOrgOrAncestorModalContentProps) => {
	const { data } = useGetOrganizationAncestors(orgId, true);
	const [orgToSeedEmblem, setOrgToSeedEmblem] = useState<Emblem | null | undefined>(null);
	const { seedOrgOrAncestor } = useSeedOrgOrAncestor(orgId);
	const { count } = useGetAssociatesToBeSeeded(orgId);
	const { company } = useLinkedLegacyAdjustmentCompanyInfo(orgId);
	const { hasPermission } = useAssociateUser(orgId);

	if (!data) return <></>;

	if (orgToSeedEmblem) {
		return (
			<>
				<ModalContent alignItems="center">
					<OrgEmblemAvatar id={orgToSeedEmblem.id} />
					<Typography variant="h3">{orgToSeedEmblem.displayName}</Typography>
					{count && (
						<>
							<Typography className="mb-0 text-center align-middle">
								The adjustment company
							</Typography>
							<Typography className="m-0 font-bold">{company?.name}</Typography>
							<Typography className="m-0 text-center align-middle">
								has {count} linked users.
							</Typography>
						</>
					)}
					<Typography className="p-1 text-center align-middle">
						If a linked user is already an associate of{' '}
						<span className="font-bold">{orgToSeedEmblem.displayName}</span>, they will
						not be added again and their current role will remain intact.
					</Typography>
				</ModalContent>
				<ModalButtonsContainer>
					<Button variant="outlined" onClick={() => setOrgToSeedEmblem(null)}>
						Back
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							seedOrgOrAncestor(orgToSeedEmblem.id).then(() => onClose());
						}}>
						Import Users
					</Button>
				</ModalButtonsContainer>
			</>
		);
	}

	return (
		<ModalContent>
			<Typography className="text-center" variant="body2">
				You may only import users into organizations at this organization&apos;s level of
				the hierarchy or above, and where you have permissions to update associates.
			</Typography>
			{hasPermission(Permission.Org_Assoc_U) && (
				<>
					<Typography variant="h3">Current Organization</Typography>
					<Stack display="flex" flexDirection="row" justifyContent="space-between">
						<Stack display="flex" flexDirection="row" alignItems="center">
							<OrgEmblemAvatar id={data.emblem.id} />
							<Typography variant="h3" padding={1}>
								{data?.emblem.displayName}
							</Typography>
							{data.emblem.id == data.ancestorToSeedId && (
								<Tooltip title="The current organization has most recently imported linked users into this organization. Users who accept invitations into the current organization's linked FileTrac Adjustment Company will be added automatically as an associate of this organization if they are not one already.">
									<Check />
								</Tooltip>
							)}
						</Stack>
						<Button
							variant="contained"
							color="primary"
							onClick={() => setOrgToSeedEmblem(data.emblem)}>
							Select
						</Button>
					</Stack>
				</>
			)}
			{data.ancestors && data.ancestors.length > 0 && (
				<Typography variant="h3">Organization Hierarchy</Typography>
			)}
			{data.ancestors &&
				data.ancestors.length > 0 &&
				data?.ancestors?.map((a) => {
					return (
						<Stack
							key={a.ancestorLevel}
							display="flex"
							flexDirection="row"
							justifyContent="space-between">
							<Stack display="flex" flexDirection="row" alignItems="center">
								<OrgEmblemAvatar id={a.organizationId} />
								<Typography variant="h3" padding={1}>
									{a.emblem?.displayName}
								</Typography>
								{a.organizationId == data.ancestorToSeedId && (
									<Tooltip title="The current organization has most recently imported linked users into this organization. Users who accept invitations into the current organization's linked FileTrac Adjustment Company will be added automatically as an associate of this organization if they are not one already.">
										<Check />
									</Tooltip>
								)}
							</Stack>
							<Button
								variant="contained"
								color="primary"
								onClick={() => setOrgToSeedEmblem(a?.emblem)}>
								Select
							</Button>
						</Stack>
					);
				})}
		</ModalContent>
	);
};
