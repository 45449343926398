import {
	ConversationApiEntityType,
	EmblemEntityType,
	ParticipantEntityType,
} from 'middleware-types';

// https://stackoverflow.com/questions/52866287/reactquill-validating-empty-input-value-showing-html-tags
export const removeTagsFromString = (s: string) => s.replace(/<(.|\n)*?>/g, '').trim();

export const convertEntityType = (value: EmblemEntityType) => {
	switch (value) {
		case EmblemEntityType.User:
			return ParticipantEntityType.User;
		case EmblemEntityType.Organization:
			return ParticipantEntityType.Organization;
		case EmblemEntityType.LegacyClaim:
			return ParticipantEntityType.LegacyClaim;
		case EmblemEntityType.System:
			return ParticipantEntityType.System;
		default:
			return ParticipantEntityType.Unknown;
	}
};

export const convertParticipantType = (value: ParticipantEntityType) => {
	switch (value) {
		case ParticipantEntityType.User:
			return EmblemEntityType.User;
		case ParticipantEntityType.Organization:
			return EmblemEntityType.Organization;
		case ParticipantEntityType.LegacyClaim:
			return EmblemEntityType.LegacyClaim;
		case ParticipantEntityType.System:
			return EmblemEntityType.System;
		case ParticipantEntityType.Unknown:
			return EmblemEntityType.Unknown;
	}
};

export const currentEntityStrings: Record<ConversationApiEntityType, string> = {
	Users: 'You',
	Organizations: 'This Organization',
	LegacyClaim: 'This Legacy Claim',
};
