import { AddCircle } from '@mui/icons-material';
import { Box, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { QuickLink } from 'middleware-types';
import { useState } from 'react';
import { QuickLinkCard } from './quickLinkCard';
import { AddUpdateQuickLinkModal } from './quickLinkModals';
import { QuickLinkMethods } from './userQuickLinkHooks';

export type ExtendedQuickLink = QuickLink & {
	presetIcon?: string;
	isPresetLink?: boolean;
};

interface QuickLinksProps {
	methods: QuickLinkMethods;
	canEdit: boolean;
	presetQuickLinks?: ExtendedQuickLink[];
}

export const QuickLinks = ({ methods, canEdit, presetQuickLinks }: QuickLinksProps) => {
	const { quickLinks, loading } = methods;
	const [addModalOpen, setAddModalOpen] = useState(false);

	return (
		<>
			<Stack spacing={2}>
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<Typography variant="h1">Quick Links</Typography>
					{canEdit && (
						<IconButton onClick={() => setAddModalOpen(true)}>
							<AddCircle color="primary" fontSize="large" />
						</IconButton>
					)}
				</Stack>
				{loading ? (
					<Stack spacing={2} direction="row">
						{[...Array(4)].map((_, index) => (
							<Skeleton key={index} variant="rounded" height={80} sx={{ flex: 1 }} />
						))}
					</Stack>
				) : (
					<Box
						display="grid"
						gridTemplateColumns={{
							xs: '1fr',
							sm: 'repeat(2, 1fr)',
							lg: 'repeat(3, 1fr)',
							xl: 'repeat(4, 1fr)',
						}}
						gridAutoRows="1fr"
						gap={2}>
						{presetQuickLinks &&
							presetQuickLinks.map((presetQuickLink) => (
								<QuickLinkCard
									key={presetQuickLink.id}
									methods={methods}
									quickLink={presetQuickLink}
									canEdit={canEdit}
								/>
							))}
						{quickLinks.map((quickLink) => (
							<QuickLinkCard
								key={quickLink.id}
								methods={methods}
								quickLink={quickLink}
								canEdit={canEdit}
							/>
						))}
					</Box>
				)}
			</Stack>
			{/** add quicklink modal */}
			<ModalOrDrawer open={addModalOpen}>
				<AddUpdateQuickLinkModal methods={methods} onClose={() => setAddModalOpen(false)} />
			</ModalOrDrawer>
		</>
	);
};
