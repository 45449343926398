import { Close, DeleteOutlined } from '@mui/icons-material';
import {
	Box,
	Button,
	Divider,
	FormControl,
	IconButton,
	InputLabel,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Select,
	Stack,
	Typography,
} from '@mui/material';
import { FieldArray, Formik, FormikProps } from 'formik';
import { FileAccessLevel, FoldersApiEntityType } from 'middleware-types';
import { Fragment } from 'react';
import { ExtensionIcon } from 'utils/fileUtils';
import { useIsMobile } from 'utils/useScreenSize';
import {
	AccessLevelIcon,
	AccessLevelTooltip,
	getAccessLevelOptions,
} from '../components/access-level';
import { useDocumentsContext } from '../documents-page';
import { useUploadDocuments } from '../hooks/use-upload-documents';

interface FormValues {
	files: {
		file: File;
		accessLevel: FileAccessLevel;
	}[];
}

interface UploadModalProps {
	files: File[];
	onClose: () => void;
	folderId?: string;
	folderName: string;
}

export const UploadModal = ({ files, onClose, folderId, folderName }: UploadModalProps) => {
	const isMobile = useIsMobile();
	const { entityType, entityId, entityName, permissions } = useDocumentsContext();
	const accessLevelOptions = getAccessLevelOptions(permissions.update);

	const initialValues: FormValues = {
		files: files.map((file) => ({
			file,
			accessLevel: accessLevelOptions.find((option) => !option.disabled)!.option,
		})),
	};

	const uploadDocuments = useUploadDocuments();
	const onSubmit = (values: FormValues) => {
		uploadDocuments(
			values.files.map((file) => ({
				...file,
				relatedEntityId: entityId,
				folderId: folderId,
				folderName: folderName,
			}))
		);
		onClose();
	};

	return (
		<Formik<FormValues> initialValues={initialValues} onSubmit={onSubmit}>
			{({ values, setValues, setFieldValue, submitForm }: FormikProps<FormValues>) => {
				const multipleFiles = values.files.length > 1;
				const allAccessLevelsSame = values.files.every(
					(file) => file.accessLevel === values.files[0].accessLevel
				);
				const setAllValue = allAccessLevelsSame ? values.files[0].accessLevel : '';
				const setAccessLevels = (accessLevel: FileAccessLevel) => {
					setValues({
						files: values.files.map((file) => ({ ...file, accessLevel })),
					});
				};
				return (
					<Stack overflow="hidden">
						<Stack px={2} py={1} direction="row" alignItems="center" spacing={1}>
							<Typography variant="h3">Uploads</Typography>
							<AccessLevelTooltip sx={{ color: 'neutral.500' }} />
							<Box flex={1} />
							<IconButton onClick={onClose}>
								<Close />
							</IconButton>
						</Stack>
						<Divider />
						<Stack overflow="hidden" direction={{ xs: 'column-reverse', sm: 'column' }}>
							<Stack p={2} spacing={2.5}>
								<Stack spacing={1}>
									<Typography variant="h4">
										{values.files.length} {multipleFiles ? 'files' : 'file'} to
										be uploaded
									</Typography>
									<Typography variant="body1">
										Please confirm the privacy settings for each file before
										uploading to{' '}
										<strong>
											{entityName}
											{folderId && ` (${folderName})`}
										</strong>
										.
									</Typography>
								</Stack>
								{multipleFiles && (
									<FormControl size="small">
										<InputLabel>Set all to...</InputLabel>
										<Select
											label="Set all to..."
											value={setAllValue}
											onChange={(e) =>
												setAccessLevels(e.target.value as FileAccessLevel)
											}
											renderValue={(value) => value}>
											{accessLevelOptions.map(({ option, disabled }) => (
												<MenuItem
													key={option}
													value={option}
													disabled={disabled}>
													<ListItemIcon>
														<AccessLevelIcon level={option} />
													</ListItemIcon>
													<ListItemText primary={option} />
												</MenuItem>
											))}
										</Select>
									</FormControl>
								)}
							</Stack>
							<Divider />
							<FieldArray
								name="files"
								render={(arrayHelpers) => {
									const removeFile = (index: number) => {
										if (values.files.length === 1) onClose();
										else arrayHelpers.remove(index);
									};
									return (
										<Box overflow="auto" minHeight="40vh" mb="-1px">
											{values.files.map((file, index) => (
												<Fragment key={index}>
													<Stack
														direction="row"
														alignItems="center"
														spacing={1.5}
														px={2}
														py={1.5}>
														<ExtensionIcon
															filename={file.file.name}
															sx={{ color: 'neutral.500' }}
														/>
														<Typography variant="body1" flex={1} noWrap>
															{file.file.name}
														</Typography>
														<Select
															size="small"
															fullWidth={false}
															value={file.accessLevel}
															onChange={(e) =>
																setFieldValue(
																	`files.${index}.accessLevel`,
																	e.target
																		.value as FileAccessLevel
																)
															}
															sx={{ width: { sm: 140 } }}
															renderValue={(value) =>
																isMobile ? (
																	<Box height={26}>
																		<AccessLevelIcon
																			level={value}
																			sx={{
																				color: 'neutral.500',
																			}}
																		/>
																	</Box>
																) : (
																	value
																)
															}>
															{accessLevelOptions.map(
																({ option, disabled }) => (
																	<MenuItem
																		key={option}
																		value={option}
																		disabled={disabled}>
																		<ListItemIcon>
																			<AccessLevelIcon
																				level={option}
																			/>
																		</ListItemIcon>
																		<ListItemText
																			primary={option}
																		/>
																	</MenuItem>
																)
															)}
														</Select>
														<IconButton
															onClick={() => removeFile(index)}>
															<DeleteOutlined />
														</IconButton>
													</Stack>
													<Divider />
												</Fragment>
											))}
										</Box>
									);
								}}
							/>
						</Stack>
						<Divider />
						<Stack
							direction={{ xs: 'column-reverse', sm: 'row' }}
							alignItems={{ xs: 'stretch', sm: 'center' }}
							spacing={1.5}
							px={{ xs: 1.5, sm: 2 }}
							py={1.5}>
							{entityType === FoldersApiEntityType.Claim ? (
								<Typography variant="caption" flex={1}>
									<strong>Warning:</strong> Any uploaded files will be permanently
									stored in Evolve databases.
								</Typography>
							) : (
								<Box flex={1} />
							)}
							<Button
								variant="outlined"
								onClick={onClose}
								size="large"
								sx={{ display: { xs: 'none', sm: 'block' } }}>
								Cancel
							</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={submitForm}
								size="large">
								Upload
							</Button>
						</Stack>
					</Stack>
				);
			}}
		</Formik>
	);
};
