import { gql, useLazyQuery } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import { BillingHierarchyValidationResults, Query } from 'middleware-types';
import { handleNoResponse } from 'utils/errors';

const VALIDATE_BILLING_HIERARCHIES = gql`
	query ValidateBillingHierarchies {
		billingBillingHierarchyValidationResultsGet {
			errors
		}
	}
`;

export const useBillingHierarchiesValidate = (
	onCompleted: (data: BillingHierarchyValidationResults) => void
) => {
	const toast = useToast();
	const [validate, { loading }] = useLazyQuery<
		Pick<Query, 'billingBillingHierarchyValidationResultsGet'>
	>(VALIDATE_BILLING_HIERARCHIES, {
		fetchPolicy: 'no-cache',
		onError: () => handleNoResponse({ toast }),
		onCompleted: (data) => onCompleted(data.billingBillingHierarchyValidationResultsGet),
	});

	return { validate, loading };
};
