import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import gql from 'graphql-tag';
import {
	Mutation,
	MutationUserAccountPrivacyUpdateArgs,
	MutationUserProfileUpdateArgs,
	Query,
	QueryProfileArgs,
	UserProfileUpdate,
	WorkType,
} from 'middleware-types';
import { handleNoResponse, PageError, responseHasErrors } from 'utils/errors';
import { NAME_FIELDS } from 'utils/useAccount';

/**
 * Fragment for profile fields.
 */
export const PROFILE_FIELDS = gql`
	${NAME_FIELDS}
	fragment ProfileFields on UserProfile {
		id
		name {
			...NameFields
		}
		displayLocation
		companyName
		companyTitle
		languageIds
		about
		xProfileLink
		linkedinProfileLink
		facebookProfileLink
		conversationInsuranceProfileLink
		following
		avatarFile {
			fileId
			file {
				id
				currentInstance {
					id
					cdnUrl
					fileName
					fileSize
					virusStatus
					uploadedUtc
				}
			}
		}
		displayTemporaryLocation {
			displayLocation
			startDate
			endDate
		}
		workCodes
		connection {
			id
			requestingUserId
			targetUserId
			state
		}
		private
	}
`;

export type DisplayTemporaryLocation = {
	displayLocation: string;
	startDate: Date | undefined | null;
	endDate: Date;
};

/**
 * useProfileHeaderQuery(userId) - Hook to get the user profile header section.
 *
 * @param {string} userId
 * @return {*}
 */
export const useProfileHeaderQuery = (userId: string) => {
	const { loading, data, error } = useQuery<Pick<Query, 'profile'>, QueryProfileArgs>(
		gql`
			${PROFILE_FIELDS}
			query profile($userId: ID!) {
				profile(userId: $userId) {
					...ProfileFields
				}
			}
		`,
		{
			fetchPolicy: 'cache-and-network',
			variables: {
				userId,
			},
		}
	);

	// Any errors on this modal should be thrown to be caught at the error boundary.
	if (error) {
		throw new PageError(error);
	}

	return { profile: data?.profile, loading };
};

/**
 * Mutation to update the user profile header section.
 */
export const PROFILE_HEADER_UPDATE = gql`
	mutation userProfileUpdate($userId: ID!, $update: UserProfileUpdate!) {
		userProfileUpdate(userId: $userId, update: $update) {
			id
			name {
				firstName
				lastName
				middleName
				suffix
			}
			companyName
			companyTitle
			displayLocation
			avatarFile {
				fileId
				file {
					id
				}
			}
			about
			xProfileLink
			linkedinProfileLink
			facebookProfileLink
			conversationInsuranceProfileLink
			jobFunctions
			workCodes
		}
	}
`;

/**
 * useProfileHeaderMutation(userId) - Hook to update the user profile from the header section.
 *
 * @param {string} userId
 * @return {*}
 */
export const useProfileHeaderMutation = (userId: string) => {
	const [updateProfileMut, { error }] = useMutation<
		Pick<Mutation, 'userProfileUpdate'>,
		MutationUserProfileUpdateArgs
	>(PROFILE_HEADER_UPDATE, {
		update: (cache) => {
			// Updating the profile header can change the name on account, search, and emblem.
			cache.evict({
				id: `UserAccount:${userId}`,
				fieldName: 'name',
			});
			cache.evict({
				id: `UserEmblem:${userId}`,
				fieldName: 'name',
			});
			cache.evict({
				id: `QuickSearchEntity:${userId}`,
				fieldName: 'displayName',
			});

			cache.gc();
		},
	});
	const updateProfile = async (update: UserProfileUpdate) => {
		return updateProfileMut({
			variables: {
				userId,
				update,
			},
		});
	};

	return { updateProfile, error };
};

/* Hook to get a map of work codes to work type display names
 *
 * @return {*}  {{
 * 	workTypes: Map<string, string>;
 * 	loading: boolean;
 * 	error?: ApolloError;
 * }}
 */
export const useWorkTypesQuery = (): {
	workTypes?: Map<string, string>;
	loading: boolean;
	error?: ApolloError;
} => {
	const { data, loading, error } = useQuery<Pick<Query, 'workTypes'>>(WORK_TYPES);

	return {
		workTypes: data?.workTypes.reduce((prev: Map<string, string>, current: WorkType) => {
			prev.set(current.workCode, current.displayName);
			return prev;
		}, new Map()),
		loading,
		error,
	};
};

export const WORK_TYPES = gql`
	query workTypes {
		workTypes {
			workCode
			displayName
		}
	}
`;

const UPDATE_USER_PRIVACY = gql`
	mutation UpdateUserPrivcay($userId: ID!, $isPrivate: Boolean!) {
		userAccountPrivacyUpdate(userId: $userId, isPrivate: $isPrivate)
	}
`;

export const useUpdateUserPrivacy = (userId: string) => {
	const toast = useToast();

	const [_updatePrivacy, { loading }] = useMutation<
		Pick<Mutation, 'userAccountPrivacyUpdate'>,
		MutationUserAccountPrivacyUpdateArgs
	>(UPDATE_USER_PRIVACY);

	const updatePrivacy = async (isPrivate: boolean) => {
		return await _updatePrivacy({
			variables: { userId, isPrivate },
			update: (cache) => {
				// update profile
				cache.writeFragment({
					id: `UserProfile:${userId}`,
					fragment: gql`
						fragment UserProfilePrivacy on UserProfile {
							private
						}
					`,
					data: { private: isPrivate },
				});
				// update account
				cache.writeFragment({
					id: `UserAccount:${userId}`,
					fragment: gql`
						fragment UserAccountPrivacy on UserAccount {
							private
						}
					`,
					data: { private: isPrivate },
				});
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Privacy updated successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { updatePrivacy, loading };
};
