import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Button, Typography } from '@mui/material';
import { Alert } from 'components/ui/alert';
import { useToast } from 'components/ui/toast';
import { Formik } from 'formik';
import { gql } from 'graphql-tag';
import {
	FileEntityType,
	FileInstanceInformation,
	Mutation,
	MutationUserAvatarOrBannerUpdateArgs,
	SystemFileType,
} from 'middleware-types';
import { useState } from 'react';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { FileField, FileType, FileValue } from '../fields/file';
import { ModalButtonsContainer } from '../modals/modal-buttons-container';
import { ModalContent } from '../modals/modal-content';
import { ModalHeader } from '../modals/modal-header';
import { ModalContentProps } from '../modals/modal-types';

/**
 * The FileFieldValue upload type.
 */
export interface FileFieldUploadValues extends Omit<FileValue, 'uploadToken'> {
	uploadToken: string;
}

/**
 * Hook to update the user profile picture
 */
export const useAvatarMutation = (userId: string) => {
	const [updateAvatarOrBanner, { loading, error }] = useMutation<
		Pick<Mutation, 'userAvatarOrBannerUpdate'>,
		MutationUserAvatarOrBannerUpdateArgs
	>(
		gql`
			mutation userAvatarOrBannerUpdate(
				$userId: ID!
				$avatarUploadToken: String
				$bannerUploadToken: String
			) {
				userAvatarOrBannerUpdate(
					userId: $userId
					avatarUploadToken: $avatarUploadToken
					bannerUploadToken: $bannerUploadToken
				) {
					id
					avatarFile {
						fileId
						file {
							id
							currentInstance {
								id
								cdnUrl
								fileName
								fileSize
								virusStatus
								uploadedUtc
							}
						}
					}
					bannerFile {
						fileId
						file {
							id
							currentInstance {
								id
								cdnUrl
								fileName
								fileSize
								virusStatus
								uploadedUtc
							}
						}
					}
				}
			}
		`,
		{
			update: (cache) => {
				// Updating the profile picture can change the picture on account, profile, search, and emblem.
				cache.evict({
					id: `UserAccount:${userId}`,
					fieldName: 'avatarFile',
				});
				cache.evict({
					id: `UserProfile:${userId}`,
					fieldName: 'avatarFile',
				});
				cache.evict({
					id: `UserEmblem:${userId}`,
					fieldName: 'avatarFile',
				});
				cache.evict({
					id: `QuickSearchEntity:${userId}`,
					fieldName: 'avatarFile',
				});

				cache.gc();
			},
		}
	);

	return { updateAvatarOrBanner, loading, error };
};

interface AvatarOrBannerUploaderModalProps extends ModalContentProps {
	userId: string;
	type: 'avatar' | 'banner';
	fileInstance?: FileInstanceInformation;
}

export const AvatarOrBannerUploaderModal = ({
	userId,
	type,
	fileInstance,
	onClose,
}: AvatarOrBannerUploaderModalProps) => {
	const { updateAvatarOrBanner, error } = useAvatarMutation(userId);

	const toast = useToast();
	const [isUploading, setIsUploading] = useState(false);

	return (
		<Formik<{ avatarOrBanner: FileFieldUploadValues }>
			initialValues={{
				avatarOrBanner: {
					uploadToken: '',
					id: fileInstance?.id,
					fileName: fileInstance?.fileName,
					fileSize: fileInstance?.fileSize,
					uploadedUtc: fileInstance?.uploadedUtc,
					virusStatus: fileInstance?.virusStatus,
				},
			}}
			onSubmit={(values: { avatarOrBanner: FileFieldUploadValues }) => {
				updateAvatarOrBanner({
					variables: {
						userId,
						avatarUploadToken:
							type === 'avatar' ? values.avatarOrBanner.uploadToken : undefined,
						bannerUploadToken:
							type === 'banner' ? values.avatarOrBanner.uploadToken : undefined,
					},
				})
					.then((res) => {
						if (responseHasErrors(res.errors, { toast })) {
							return false;
						}
						toast.push(
							`Profile ${
								type === 'avatar' ? 'Picture' : 'Banner'
							} updated successfully. It may take a few minutes for your picture to appear.`,
							{ variant: 'success' }
						);
						onClose();
					})
					.catch(() => handleNoResponse({ toast }));
			}}>
			{({ submitForm, isValid, dirty, isSubmitting }) => (
				<>
					<ModalHeader
						title={`Upload Profile ${type === 'avatar' ? 'Picture' : 'Banner'}`}
						onClose={onClose}
					/>
					<ModalContent>
						<Alert error={error} />
						<Typography variant="h3" className="mt-0">
							Drag & drop an image below or click to upload
						</Typography>
						<FileField
							name="avatarOrBanner"
							relatedEntityId={userId}
							relatedEntityType={FileEntityType.User}
							systemFileType={
								type === 'avatar'
									? SystemFileType.UserAvatar
									: SystemFileType.UserBanner
							}
							type={FileType.Image}
							updatesFileId={fileInstance?.fileId}
							setIsUploading={setIsUploading}
						/>
					</ModalContent>
					<ModalButtonsContainer>
						<Button variant="outlined" onClick={onClose}>
							Cancel
						</Button>
						<LoadingButton
							variant="contained"
							color="primary"
							onClick={submitForm}
							disabled={!isValid || !dirty || isSubmitting || isUploading}
							loading={isSubmitting || isUploading}>
							Save
						</LoadingButton>
					</ModalButtonsContainer>
				</>
			)}
		</Formik>
	);
};
