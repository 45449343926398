import { gql, useQuery } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import { Amplify, Auth } from 'aws-amplify';
import { Loading } from 'components/ui/loading';
import { LightShell } from 'components/ui/shell';
import { UserLogin } from 'middleware-types';
import { createContext, useContext, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { PageError } from './errors';
import { useLoginRedirect } from './loginRedirect';

Amplify.configure({
	Auth: {
		region: import.meta.env.VITE_AWS_REGION,
		userPoolId: import.meta.env.VITE_AWS_COGNITO_USERPOOLID,
		userPoolWebClientId: import.meta.env.VITE_AWS_COGNITO_CLIENTID,
		mandatorySignIn: true,
	},
});

/**
 * getAssumeIdentity() - get the saved assumed identity.
 * @returns the assumed identity saved.
 */
export const getAssumeIdentity = () => localStorage.getItem('_assumedIdentity');

/**
 * assume() - assumes the identity of the provided id.
 *
 * @param {(string | null)} id
 * @returns
 */
export const assume = (id: string | null) => {
	if (id) {
		localStorage.setItem('_assumedIdentity', id);
		window.location.href = '/';
		return;
	}

	localStorage.removeItem('_assumedIdentity');
	window.location.href = '/';
};

/**
 * Get a JWT token from the current session or federated sign in.
 * @returns the JWT token
 */
export const getJwtToken = async () => {
	return await Auth.currentSession()
		.then((session) => session.getIdToken().getJwtToken())
		.catch(() => {
			return null;
		});
};

/**
 * Get a Access token from the current session or federated sign in.
 * @returns the JWT token
 */
export const getAccessToken = async () => {
	return await Auth.currentSession()
		.then((session) => session.getAccessToken().getJwtToken())
		.catch(logout);
};

/**
 * Grabs a new Cognito Id token. Useful for when permissions change.
 * @returns a Promise containing the refreshed tokens.
 */
export async function refreshToken() {
	const user = await Auth.currentAuthenticatedUser();
	const session = await Auth.currentSession();
	return new Promise((resolve, reject) => {
		user.refreshSession(session.getRefreshToken(), (error, session) => {
			if (error) reject(error);
			resolve(session);
		});
	});
}

/**
 * Logout the user and remove the assumed identity.
 */
export const logout = async () => {
	localStorage.removeItem('_assumedIdentity');
	await Auth.signOut();
	window.location.href = '/auth/login';
};

/**
 * Redirects the user to the legacy site, and logs them into the system
 * @param {string | undefined} systemUrl
 * @param {number | undefined} userId
 */
export const postLegacyAuth = async (
	systemUrl?: string,
	userId?: number,
	evolveUserId?: string,
	redirectString?: string
) => {
	const accessToken = await getAccessToken();
	const form = document.createElement('form');
	form.method = 'POST';
	form.action = `${systemUrl}/evolveLogin.asp`;

	const userIdField = document.createElement('input');
	userIdField.type = 'hidden';
	userIdField.name = 'userId';
	userIdField.value = `${userId}`;
	form.appendChild(userIdField);

	const evolveUserIdField = document.createElement('input');
	evolveUserIdField.type = 'hidden';
	evolveUserIdField.name = 'evolveUserId';
	evolveUserIdField.value = evolveUserId as string;
	form.appendChild(evolveUserIdField);

	const accessTokenField = document.createElement('input');
	accessTokenField.type = 'hidden';
	accessTokenField.name = 'access_token';
	accessTokenField.value = accessToken as string;
	form.appendChild(accessTokenField);

	const redirectField = document.createElement('input');
	redirectField.type = 'hidden';
	redirectField.name = 'URL';
	redirectField.value = redirectString ?? '';
	form.appendChild(redirectField);

	document.body.appendChild(form);
	form.submit();
};

/**
 *
 * @returns A boolean indicating if the user is authenticated and the basic user information.
 */
type SessionContextState = {
	anonymous: boolean;
	user: UserLogin | undefined;
};

const SessionContext = createContext<SessionContextState>({ anonymous: true, user: undefined });

const allowedAnonymousRoutes = [/^\/[^/]{5,}$/, /^\/orgs\/[^/]+\/profile$/];

export const SessionProvider = ({ children }: { children: any }) => {
	const location = useLocation();
	const { setRedirectUrlTimeout } = useLoginRedirect();

	// Hacky use of state/effect but such is React
	// Could redo with Suspense when it's ready
	const [checkedJwt, setCheckedJwt] = useState(false);
	const [currentAuthenticatedUser, setCurrentAuthenticatedUser] = useState<any>();
	const { data, loading, error } = useQuery(SESSION_QUERY, {
		fetchPolicy: 'cache-first',
		skip: !checkedJwt || !currentAuthenticatedUser,
	});

	useEffect(() => {
		Auth.currentAuthenticatedUser()
			.then((user) => setCurrentAuthenticatedUser(user))
			.catch(() => {
				/** silently handle unauthenticated user */
			})
			.finally(() => setCheckedJwt(true));
	}, []);

	// loading state
	if (!checkedJwt || loading) {
		Sentry.setUser(null);
		Sentry.setExtras({});

		return (
			<LightShell>
				<Loading />
			</LightShell>
		);
	}

	// error state
	if (error) {
		if (error.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED') {
			logout();
			return (
				<LightShell>
					<Loading />
				</LightShell>
			);
		}

		Sentry.setUser(null);
		Sentry.setExtras({});

		throw new PageError(error);
	}

	// check if mfa is missing
	if (currentAuthenticatedUser && currentAuthenticatedUser.preferredMFA === 'NOMFA') {
		setRedirectUrlTimeout(location.pathname + location.search);
		return <Navigate replace to="/auth/mfa-config" />;
	}

	// check if this route is able to be accessed by an anonymous user
	if (
		!currentAuthenticatedUser &&
		!allowedAnonymousRoutes.some((route) => route.test(location.pathname))
	) {
		setRedirectUrlTimeout(location.pathname + location.search);
		return <Navigate replace to="/auth/login" />;
	}

	const user = data?.loginInformation;
	const anonymous = !user;

	if (user) {
		const { userId, displayName, emailAddress, siteUserId } = user;
		Sentry.setUser({
			email: emailAddress,
			id: userId,
			username: displayName ?? emailAddress,
			segment: siteUserId ? 'Site User' : 'User',
		});
		Sentry.setExtras({
			siteUserId: siteUserId,
			assumingIdentity: !!getAssumeIdentity(),
		});
	}

	return (
		<SessionContext.Provider value={{ user, anonymous }}>{children}</SessionContext.Provider>
	);
};

export const useAnonymousSession = (): SessionContextState =>
	useContext<SessionContextState>(SessionContext);

export const useSession = () => {
	const { user, ...rest } = useContext<SessionContextState>(SessionContext);
	if (!user) throw new Error('useSession hook was called, but no active session was found.');
	return { user, ...rest };
};

const SESSION_QUERY = gql`
	query loginInformation {
		loginInformation {
			userId
			emailAddress
			displayName
			siteUserId
			standardUserId
			registered
			siteUserInvitation {
				invitationId
				emailAddress
				firstName
				lastName
				roleId
				role {
					id
					name
				}
			}
		}
	}
`;
