import {
	AccountCircleOutlined,
	DeleteOutline,
	EditOutlined,
	MoreHorizOutlined,
	PowerSettingsNewOutlined,
} from '@mui/icons-material';
import {
	Box,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Stack,
	TableCell,
	TableRow,
	Typography,
} from '@mui/material';
import { OrgMessageButton } from 'components/pages/network/org/OrgMessageButton/OrgMessageButton';
import { UserMessageButton } from 'components/pages/network/user/UserMessageButton/UserMessageButton';
import { Status } from 'components/pages/org/associates/hooks';
import {
	DeactivateAssociateModal,
	ReactivateAssociateForm,
} from 'components/pages/org/associates/modals/activation';
import { DeleteAssociateInvitationModal } from 'components/pages/org/associates/modals/invitation';
import { UpdateAssociateForm } from 'components/pages/org/associates/modals/update';
import { UserEmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { DeactivateIcon } from 'components/ui/icons';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { AssociateOrInvitation, EmblemEntityType } from 'middleware-types';
import { useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Permission } from 'utils/permissions';
import { useSession } from 'utils/session';
import { useAssociateUser } from 'utils/useAssociateUser';
import { useSiteUser } from 'utils/useSiteUser';

/**
 *  Associate row props.
 */
type AssociateRowProps = {
	orgId: string;
	associate: AssociateOrInvitation;
};

/**
 * This defines all the colors used for the status text.
 * It is a mapping of Status -> MUI Theme color (see Theme.tsx)
 * @type {Record<Status, string>} Map of Status to MUI theme color string.
 */
const statusColor: Record<Status, string> = {
	Invited: 'neutral.200',
	Active: 'primary.dark',
	Inactive: 'error.dark',
	Declined: 'error.dark',
};

/**
 * AssociateRow - A single row on the organization associates table.
 *
 * @param {AssociateRowProps} props
 * @returns
 */
export const AssociateRow = ({ orgId, associate }: AssociateRowProps) => {
	const { user } = useSession();
	const { handle } = useParams<{
		handle: string;
	}>();
	const associateUserId = associate.userId ? associate.userId : '';

	const currentUser = user?.userId === associate.userId;
	const { hasPermission: hasAssociateUserPermission } = useAssociateUser(orgId);
	const { hasPermission: hasSiteUserPermission } = useSiteUser();
	const canUpdate =
		hasAssociateUserPermission(Permission.Org_Assoc_U) ||
		hasSiteUserPermission(Permission.Site_OrgAssoc_U);

	const canMessageAsOrg = hasAssociateUserPermission(Permission.Org_Messages_U);

	const canDelete =
		hasAssociateUserPermission(Permission.Org_Assoc_Inv_D) ||
		hasSiteUserPermission(Permission.Site_OrgAssocInv_D);

	const [contextMenuOpen, setContextMenuOpen] = useState(false);

	const [deactivateOpen, setDeactiveOpen] = useState(false);
	const [reactivateOpen, setReactivateOpen] = useState(false);
	const [deleteInvitationOpen, setDeleteInvitationOpen] = useState(false);
	const [editOpen, setEditOpen] = useState(false);

	const displayOnProfile =
		associate.status === Status.Active ? (associate.displayOnProfile ? 'Yes' : 'No') : 'N/A';

	const isUserDeactivated = associate.deactivated;

	const showUserMessageButton =
		associate.status === Status.Active &&
		user.userId !== associate.userId &&
		!isUserDeactivated;

	const showOrganizationMessageButton =
		associate.status === Status.Active && !isUserDeactivated && canMessageAsOrg;

	const contextMenuButton = useRef<HTMLButtonElement>(null);

	const onContextMenu = (e) => {
		e.preventDefault();
		setContextMenuOpen(true);
	};

	return (
		<>
			<TableRow onContextMenu={(e) => onContextMenu(e)}>
				<TableCell>
					<Stack flexDirection="row" gap={2} alignItems="center">
						{associate.userId && associate.status !== 'Invited' ? (
							<UserEmblemAvatar id={associate.userId} />
						) : (
							<Box sx={{ width: 40 }} />
						)}
						<Stack>
							<Box>{associate.externalUserDisplayName}</Box>
							<Typography variant="subtitle2">
								{associate.displayName != associate.externalUserDisplayName &&
									associate.displayName}
							</Typography>
						</Stack>
					</Stack>
				</TableCell>
				<TableCell>
					<Stack flexDirection="row" alignItems="center" gap={1}>
						{associate.status === 'Invited' && associate.emailAddress}
						{showUserMessageButton && (
							<UserMessageButton
								entityId={associateUserId}
								entityType={EmblemEntityType.User}
								handle={handle}
								alwaysEnabled
							/>
						)}
						{showOrganizationMessageButton && (
							<OrgMessageButton
								entityId={associateUserId}
								entityType={EmblemEntityType.User}
								orgHandle={handle!}
								orgId={orgId}
							/>
						)}
					</Stack>
				</TableCell>
				<TableCell>{associate.organizationRoleName}</TableCell>
				<TableCell
					sx={{
						color: statusColor[associate.status],
					}}>
					{associate.status}
				</TableCell>
				<TableCell>{displayOnProfile}</TableCell>
				<TableCell size="small">
					{associate.associateId && associate.status != 'Invited' && (
						<>
							<IconButton
								onClick={() => setContextMenuOpen(true)}
								ref={contextMenuButton}>
								<MoreHorizOutlined />
							</IconButton>
							<Menu
								anchorEl={contextMenuButton.current}
								closeAfterTransition={true}
								open={contextMenuOpen}
								onClose={() => setContextMenuOpen(false)}
								onClick={() => setContextMenuOpen(false)}>
								<MenuItem component={Link} to={`/${associate.externalUserHandle}`}>
									<ListItemIcon>
										<AccountCircleOutlined />
									</ListItemIcon>
									<ListItemText>View Profile</ListItemText>
								</MenuItem>
								{associate.status === 'Active' && canUpdate && (
									<MenuItem onClick={() => setEditOpen(true)}>
										<ListItemIcon>
											<EditOutlined />
										</ListItemIcon>
										<ListItemText>Update Associate</ListItemText>
									</MenuItem>
								)}
								{associate.status === 'Active' && !currentUser && canUpdate && (
									<MenuItem onClick={() => setDeactiveOpen(true)}>
										<ListItemIcon>
											<DeactivateIcon />
										</ListItemIcon>
										<ListItemText>Deactivate Associate</ListItemText>
									</MenuItem>
								)}
								{associate.status === 'Inactive' && !currentUser && canUpdate && (
									<MenuItem onClick={() => setReactivateOpen(true)}>
										<ListItemIcon>
											<PowerSettingsNewOutlined />
										</ListItemIcon>
										<ListItemText>Reactivate Associate</ListItemText>
									</MenuItem>
								)}
							</Menu>
						</>
					)}
					{canDelete && associate.invitationId && (
						<>
							<IconButton
								onClick={() => setContextMenuOpen(true)}
								ref={contextMenuButton}>
								<MoreHorizOutlined />
							</IconButton>
							<Menu
								anchorEl={contextMenuButton.current}
								closeAfterTransition={true}
								open={contextMenuOpen}
								onClose={() => setContextMenuOpen(false)}
								onClick={() => setContextMenuOpen(false)}>
								<MenuItem onClick={() => setDeleteInvitationOpen(true)}>
									<ListItemIcon>
										<DeleteOutline />
									</ListItemIcon>
									<ListItemText>Delete Invitation</ListItemText>
								</MenuItem>
							</Menu>
						</>
					)}
				</TableCell>
			</TableRow>
			{/** deactivate modal */}
			<ModalOrDrawer open={deactivateOpen}>
				<DeactivateAssociateModal
					orgId={orgId}
					associateId={associate.associateId!}
					displayName={associate.displayName}
					onClose={() => setDeactiveOpen(false)}
				/>
			</ModalOrDrawer>
			{/** reactivate modal */}
			<ModalOrDrawer open={reactivateOpen}>
				<ReactivateAssociateForm
					orgId={orgId}
					associate={associate}
					onClose={() => setReactivateOpen(false)}
				/>
			</ModalOrDrawer>
			{/** delete invitation modal */}
			<ModalOrDrawer open={deleteInvitationOpen}>
				<DeleteAssociateInvitationModal
					orgId={orgId}
					invitationId={associate.invitationId!}
					displayName={associate.displayName}
					onClose={() => setDeleteInvitationOpen(false)}
				/>
			</ModalOrDrawer>
			{/** edit modal */}
			<ModalOrDrawer open={editOpen}>
				<UpdateAssociateForm
					orgId={orgId}
					associate={associate}
					onClose={() => setEditOpen(false)}
				/>
			</ModalOrDrawer>
		</>
	);
};
