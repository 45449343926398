import { Card, Stack, Typography } from '@mui/material';
import { BillingJournalsFilter } from 'components/ui/billing-journals-table/billing-journals-filter';
import { BillingJournalsProvider } from 'components/ui/billing-journals-table/billing-journals-provider';
import { BillingJournalsSearch } from 'components/ui/billing-journals-table/billing-journals-search';
import { BillingJournalsTable } from 'components/ui/billing-journals-table/table/billing-journals-table';

export const SiteBillingJournalsCard = () => {
	return (
		<Card component={Stack} height="100%" p={3} pb={1} spacing={2}>
			<BillingJournalsProvider allOrgs>
				<Stack
					spacing={2}
					direction="row"
					alignItems="center"
					justifyContent="space-between">
					<Typography variant="h2">Billing Journals</Typography>
					<Stack direction="row" spacing={1.5}>
						<BillingJournalsSearch />
						<BillingJournalsFilter />
					</Stack>
				</Stack>
				<BillingJournalsTable includeCompany />
			</BillingJournalsProvider>
		</Card>
	);
};
