import { ApolloError, MutationHookOptions, useMutation, useQuery } from '@apollo/client';
import {
	AccountTreeOutlined,
	DeleteOutlined,
	EditOutlined,
	MoreHorizOutlined,
} from '@mui/icons-material';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
} from '@mui/material';
import { ConfirmationModalContent } from 'components/ui/modals/confirmation-modal-content';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { PageContent, PageTitle } from 'components/ui/page';
import SortableColumnCell from 'components/ui/sortable-column-cell';
import { useToast } from 'components/ui/toast';
import { format } from 'date-fns';
import gql from 'graphql-tag';
import {
	Mutation,
	MutationUserSiteRoleDeleteArgs,
	Query,
	QuerySiteRolesArgs,
	SortDirection,
} from 'middleware-types';
import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { Permission } from 'utils/permissions';
import { DEFAULTPAGESIZES } from 'utils/theme';
import { useSiteUser } from 'utils/useSiteUser';
import { zoneDateOnly } from 'utils/utils';

const GET_SITE_ROLES = gql`
	query siteRolesList(
		$pageSize: Float!
		$offset: Float!
		$sortedBy: String!
		$sortDirection: SortDirection!
		$searchFor: String!
	) {
		siteRoles(
			pageSize: $pageSize
			offset: $offset
			sortedBy: $sortedBy
			sortDirection: $sortDirection
			searchFor: $searchFor
		) {
			totalCount
			items {
				id
				name
				isAdminRole
				userCount
				siteRoleCategory {
					name
				}
				lastModifiedUtc
			}
		}
	}
`;

/**
 * useOrgRolesQuery() - hook to pull the provided organization's roles (paged).
 *
 * @param {string} organizationId
 * @param {number} pageSize
 * @param {number} page
 * @param {string} sortedBy
 * @param {('Ascending' | 'Descending')} sortDirection
 * @return {*}
 */
export const useSiteRolesQuery = (
	pageSize: number,
	offset: number,
	sortedBy: string,
	sortDirection: SortDirection,
	searchFor: string
) => {
	const toast = useToast();
	const { data, error, loading, refetch } = useQuery<
		Pick<Query, 'siteRoles'>,
		QuerySiteRolesArgs
	>(GET_SITE_ROLES, {
		// checks cache and fetches (important for updating queries after updating a role)
		fetchPolicy: 'cache-and-network',
		variables: {
			pageSize,
			sortedBy,
			sortDirection,
			offset,
			searchFor,
		},
		onError: () => {
			toast.push(`An error occurred. Please try again later or contact Support.`, {
				variant: 'error',
			});
			console.log(error);
		},
	});

	const totalCount = data?.siteRoles?.totalCount ?? 0;
	const roles =
		data?.siteRoles?.items?.map((r) => ({
			id: r.id,
			isAdminRole: r.isAdminRole,
			name: r.name,
			category: r.siteRoleCategory?.name,
			associatedUsers: r.userCount,
			lastModified: zoneDateOnly(r.lastModifiedUtc),
		})) ?? [];

	return { roles, totalCount, loading, error, refetch };
};

/* Mutation for deleting a site role. */
export const DELETE_SITEROLE = gql`
	mutation UserSiteRoleDelete($id: String!) {
		userSiteRoleDelete(id: $id)
	}
`;

/**
 * useDeleteSiteRoleMutation(userId) - Hook to delete a role.
 *
 * @return {*}
 */
export const useDeleteSiteRoleMutation = (
	options?: MutationHookOptions<
		Pick<Mutation, 'userSiteRoleDelete'>,
		MutationUserSiteRoleDeleteArgs
	>
): {
	deleteRole: (id: string) => Promise<boolean>;
	error: ApolloError | undefined;
	loading: boolean;
} => {
	const toast = useToast();
	const [deleteRoleMut, { error, loading }] = useMutation<
		Pick<Mutation, 'userSiteRoleDelete'>,
		MutationUserSiteRoleDeleteArgs
	>(DELETE_SITEROLE, {
		...options,
	});

	const deleteRole = async (id: string) => {
		return deleteRoleMut({
			variables: {
				id,
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push(`The role was successfully deleted.`, {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { deleteRole, error, loading };
};

interface SiteRoleRowProps {
	id: string;
	isAdminRole: boolean | undefined;
	name: string;
	category: string | undefined;
	associatedUsers: number | undefined;
	lastModified: string;
}

const SiteRoleRow = ({
	id,
	isAdminRole,
	name,
	category,
	associatedUsers,
	lastModified,
}: SiteRoleRowProps) => {
	const navigate = useNavigate();
	const { hasPermission } = useSiteUser();

	const [contextMenuOpen, setContextMenuOpen] = useState(false);

	const onContextMenu = (e) => {
		e.preventDefault();
		setContextMenuOpen(true);
	};

	const buttonRef = useRef<HTMLButtonElement>(null);

	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	const { deleteRole } = useDeleteSiteRoleMutation({
		refetchQueries: [GET_SITE_ROLES],
		awaitRefetchQueries: true,
	});

	return (
		<>
			<TableRow onContextMenu={onContextMenu}>
				<TableCell size="small">
					<AccountTreeOutlined fontSize="small" />
				</TableCell>
				<TableCell>{name}</TableCell>
				<TableCell>{category}</TableCell>
				<TableCell>{associatedUsers}</TableCell>
				<TableCell>{lastModified}</TableCell>
				<TableCell size="small">
					{hasPermission([
						Permission.Site_Role_D,
						Permission.Site_Role_U,
						Permission.Site_Role_R,
					]) && (
						<>
							<IconButton
								ref={buttonRef}
								aria-label="more"
								aria-controls="long-menu"
								aria-haspopup="true"
								onClick={() => setContextMenuOpen(true)}>
								<MoreHorizOutlined />
							</IconButton>
							<Menu
								anchorEl={buttonRef.current}
								open={contextMenuOpen}
								onClose={() => setContextMenuOpen(false)}
								onClick={() => setContextMenuOpen(false)}>
								{
									/* Material menu's have a hacky way of ref-ing the first child, 
									this breaks when using a wrapping HOC.  Instead, just use the context */
									hasPermission([
										Permission.Site_Role_U,
										Permission.Site_Role_R,
									]) && (
										<MenuItem
											onClick={() => navigate(`${id}`, { replace: true })}>
											<ListItemIcon>
												<EditOutlined />
											</ListItemIcon>
											<ListItemText>Manage Role</ListItemText>
										</MenuItem>
									)
								}
								{hasPermission(Permission.Site_Role_D) && (
									<MenuItem
										disabled={
											(associatedUsers !== undefined &&
												associatedUsers > 0) ||
											isAdminRole
										}
										onClick={() => setDeleteModalOpen(true)}>
										<ListItemIcon>
											<DeleteOutlined />
										</ListItemIcon>
										<ListItemText>Delete Role</ListItemText>
									</MenuItem>
								)}
							</Menu>
						</>
					)}
				</TableCell>
			</TableRow>
			{/** delete modal */}
			<ModalOrDrawer open={deleteModalOpen}>
				<ConfirmationModalContent
					variant="destructive"
					title="Delete Role"
					primaryText="Are you sure?"
					secondaryText={`Do you really want to delete the ${name} role? This process cannot be undone.`}
					onSubmit={async () => await deleteRole(id)}
					onClose={() => setDeleteModalOpen(false)}
					confirmText="Delete Role"
				/>
			</ModalOrDrawer>
		</>
	);
};

/**
 * SiteRoles contains the entire site roles page.
 *
 * @returns
 */
const SiteRoles: React.FC = () => {
	const [page, setPage] = useState(0);
	const [isAsc, setIsAsc] = useState<boolean>(true);
	const [sortBy, setSortBy] = useState<string>('Name');
	const [pageSize, setPageSize] = useState<number>(DEFAULTPAGESIZES[0]);

	const { roles, totalCount, loading, refetch } = useSiteRolesQuery(
		pageSize,
		page * pageSize,
		sortBy,
		isAsc ? SortDirection.Ascending : SortDirection.Descending,
		''
	);

	/**
	 * On page changed.
	 *
	 * @param {number} p
	 */
	const handlePageChange = (_: unknown, p: number): void => {
		setPage(p);
	};

	/**
	 * On page size changed.
	 *
	 * @param {React.ChangeEvent<{ value: unknown; }>} e
	 */
	const handlePageSizeChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	): void => {
		const value = parseInt(e.target.value);
		setPageSize(value);
		setPage(0);
		refetch();
	};

	/**
	 * Handle column sorting pressed.
	 *
	 * @param {string} dataKey
	 */
	const handleSortClick = (dataKey: string): void => {
		if (dataKey === sortBy) {
			setIsAsc(!isAsc);
		} else {
			setSortBy(dataKey);
		}
	};

	const { hasPermission } = useSiteUser();

	return (
		<>
			<PageTitle title="Site Roles" />
			<PageContent>
				<Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
					<CardHeader
						title="Site Roles"
						action={
							hasPermission(Permission.Site_Role_C) && (
								<Button
									component={Link}
									to="/site/roles/create"
									variant="contained"
									color="primary">
									Create Role
								</Button>
							)
						}></CardHeader>
					<CardContent
						sx={{
							flex: '1 1 auto',
							overflow: 'hidden',
						}}>
						<TableContainer sx={{ maxHeight: '100%' }}>
							<Table stickyHeader>
								<TableHead>
									<TableRow>
										<TableCell size="small" style={{ width: '4%' }}></TableCell>
										<SortableColumnCell
											style={{ width: '20%' }}
											column="Name"
											onSort={handleSortClick}
											active={'Name' === sortBy}
											direction={isAsc ? 'asc' : 'desc'}>
											Role Name
										</SortableColumnCell>
										<SortableColumnCell
											style={{ width: '20%' }}
											column="SiteRoleCategory.Name"
											onSort={handleSortClick}
											active={'SiteRoleCategory.Name' === sortBy}
											direction={isAsc ? 'asc' : 'desc'}>
											Category
										</SortableColumnCell>
										<SortableColumnCell
											style={{ width: '20%' }}
											column="UserCount"
											onSort={handleSortClick}
											active={'UserCount' === sortBy}
											direction={isAsc ? 'asc' : 'desc'}>
											Number of Users
										</SortableColumnCell>
										<SortableColumnCell
											style={{ width: '22%' }}
											column="LastModifiedUtc"
											onSort={handleSortClick}
											active={'LastModifiedUtc' === sortBy}
											direction={isAsc ? 'asc' : 'desc'}>
											Last Edited
										</SortableColumnCell>
										<TableCell
											size="small"
											style={{ width: '10%' }}></TableCell>
									</TableRow>
								</TableHead>
								{loading ? (
									<SkeletonRows rows={pageSize} />
								) : (
									<TableBody>
										{roles.map((row) => (
											<SiteRoleRow
												key={row.id}
												id={row.id}
												isAdminRole={row.isAdminRole}
												name={row.name}
												category={row.category}
												associatedUsers={row.associatedUsers}
												lastModified={format(
													row.lastModified,
													'MM/dd/yyyy h:mm aaa'
												)}
											/>
										))}
									</TableBody>
								)}
							</Table>
						</TableContainer>
					</CardContent>
					<CardActions disableSpacing sx={{ justifyContent: 'flex-end' }}>
						<TablePagination
							component={Stack}
							direction="row"
							justifyContent="flex-end"
							alignItems="center"
							count={totalCount}
							page={page}
							rowsPerPage={pageSize}
							onPageChange={handlePageChange}
							onRowsPerPageChange={handlePageSizeChange}
						/>
					</CardActions>
				</Card>
			</PageContent>
		</>
	);
};

// Will generate however many placeholders depending on page size
// Actual number of rows may be fewer
const SkeletonRows = (props: { rows: number }): React.JSX.Element => (
	<TableBody>
		{Array(props.rows)
			.fill(null)
			.map((_, i) => {
				return (
					<TableRow key={i}>
						<TableCell colSpan={6}>
							<Skeleton animation="wave" height={'2em'} />
						</TableCell>
					</TableRow>
				);
			})}
	</TableBody>
);

export default SiteRoles;
