import { DeleteOutlined, EditOutlined, MoreHoriz, Star, StarBorder } from '@mui/icons-material';
import {
	Box,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Stack,
	Typography,
} from '@mui/material';
import { useOrgId } from 'components/pages/org/outlet';
import { ConfirmationModalContent } from 'components/ui/modals/confirmation-modal-content';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { BusinessLine } from 'middleware-types';
import { useRef, useState } from 'react';
import { useDeleteLineOfBusiness, useUpdateSpecialty } from '../hooks';
import { LineOfBusinessModal } from './line-of-business-modal';

interface LineOfBusinessListItemProps {
	lineOfBusiness: BusinessLine;
	canEdit: boolean;
	canAddSpecialty: boolean;
}

export const LineOfBusinessListItem = ({
	lineOfBusiness,
	canEdit,
	canAddSpecialty,
}: LineOfBusinessListItemProps) => {
	const orgId = useOrgId();
	const { updateSpecialty, loading } = useUpdateSpecialty(lineOfBusiness);
	const { deleteLineOfBusiness } = useDeleteLineOfBusiness(orgId);

	const [contextMenuOpen, setContextMenuOpen] = useState(false);
	const menuAnchor = useRef<HTMLButtonElement>(null);

	const onContextMenu = (e) => {
		e.preventDefault();
		setContextMenuOpen(true);
	};

	const [editModalOpen, setEditModalOpen] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	return (
		<Stack
			px={{ xs: 2, sm: 2.5 }}
			py={2}
			spacing={{ xs: 1, sm: 2 }}
			direction={{ xs: 'column', sm: 'row' }}
			alignItems={{ xs: 'flex-start', sm: 'center' }}
			justifyContent="space-between"
			onContextMenu={(e) => onContextMenu(e)}>
			<Box overflow="hidden">
				<Typography variant="h5">{lineOfBusiness.line}</Typography>
				{lineOfBusiness.text && (
					<Typography variant="body1" sx={{ wordWrap: 'break-word' }}>
						{lineOfBusiness.text}
					</Typography>
				)}
			</Box>
			<Stack direction="row">
				<IconButton
					disabled={
						!canEdit || loading || (!canAddSpecialty && !lineOfBusiness.specialty)
					}
					onClick={updateSpecialty}>
					{lineOfBusiness.specialty ? <Star color="primary" /> : <StarBorder />}
				</IconButton>
				{canEdit && (
					<IconButton
						disabled={loading}
						onClick={() => setContextMenuOpen(true)}
						ref={menuAnchor}>
						<MoreHoriz />
					</IconButton>
				)}
			</Stack>
			{/** menu */}
			<Menu
				open={contextMenuOpen}
				anchorEl={menuAnchor.current}
				onClose={() => setContextMenuOpen(false)}
				onClick={() => setContextMenuOpen(false)}>
				<MenuItem onClick={() => setEditModalOpen(true)}>
					<ListItemIcon>
						<EditOutlined />
					</ListItemIcon>
					<ListItemText>Edit</ListItemText>
				</MenuItem>
				<MenuItem onClick={() => setDeleteModalOpen(true)}>
					<ListItemIcon>
						<DeleteOutlined />
					</ListItemIcon>
					<ListItemText>Remove</ListItemText>
				</MenuItem>
			</Menu>
			{/** edit modal */}
			<ModalOrDrawer open={editModalOpen}>
				<LineOfBusinessModal
					orgId={orgId}
					lineOfBusiness={lineOfBusiness}
					onClose={() => setEditModalOpen(false)}
				/>
			</ModalOrDrawer>
			{/** delete modal */}
			<ModalOrDrawer open={deleteModalOpen}>
				<ConfirmationModalContent
					variant="destructive"
					primaryText="Delete Line of Business"
					secondaryText={`Do you really want to delete ${lineOfBusiness.line}? This process cannot be undone.`}
					onSubmit={async () => await deleteLineOfBusiness(lineOfBusiness.id)}
					onClose={() => setDeleteModalOpen(false)}
				/>
			</ModalOrDrawer>
		</Stack>
	);
};
