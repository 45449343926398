import { useMutation, useQuery } from '@apollo/client';
import { PHONE_FIELDS } from 'components/ui/fields/phone';
import { useToast } from 'components/ui/toast';
import { format } from 'date-fns';
import { gql } from 'graphql-tag';
import {
	Mutation,
	MutationUserAccountActivationUpdateArgs,
	MutationUserAccountUpdateArgs,
	Query,
	QueryAccountArgs,
	UserAccount,
	UserAccountUpdate,
} from 'middleware-types';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageError, handleNoResponse, responseHasErrors } from 'utils/errors';
import { Permission } from 'utils/permissions';
import { useSession } from 'utils/session';
import { NAME_FIELDS } from 'utils/useAccount';
import { useSiteUser } from 'utils/useSiteUser';
import { useValidation } from 'utils/useValidation';
import { zoneDateOnly } from 'utils/utils';

export const usePersonalInformationQuery = (userId: string) => {
	const { data, loading, error } = useQuery<Pick<Query, 'account'>, QueryAccountArgs>(
		PERSONALINFORMATION,
		{
			fetchPolicy: 'cache-first',
			variables: {
				userId,
			},
		}
	);

	return { account: data?.account, loading, error };
};

export type PersonalInformation = Pick<
	UserAccount,
	| 'id'
	| 'siteUserId'
	| 'emailAddress'
	| 'name'
	| 'handle'
	| 'secondaryEmailAddress'
	| 'cellPhone'
	| 'homePhone'
	| 'languageIds'
	| 'birthDate'
	| 'deactivated'
	| 'avatarFile'
>;

export const PERSONALINFORMATION = gql`
	${NAME_FIELDS}
	${PHONE_FIELDS}
	query account($userId: ID!) {
		account(userId: $userId) {
			id
			siteUserId
			name {
				...NameFields
			}
			handle
			homePhone {
				...PhoneFields
			}
			cellPhone {
				...PhoneFields
			}
			emailAddress
			secondaryEmailAddress
			birthDate
			languageIds
			lastUpdatedUtc
			deactivated
			avatarFile {
				fileId
				file {
					id
					currentInstance {
						id
						cdnUrl
						fileName
						fileSize
						virusStatus
						uploadedUtc
					}
				}
			}
		}
	}
`;

/**
 * usePersonalInformationMutation - hook that updates personal information in a user account.
 *
 * @param {string} userId
 * @return {*}
 */
export const usePersonalInformationMutation = (userId: string) => {
	const toast = useToast();
	const [userAccountUpdate, { error: userAccountError, reset }] = useMutation<
		Pick<Mutation, 'userAccountUpdate'>,
		MutationUserAccountUpdateArgs
	>(UPDATE_ACCOUNT);

	const updateUserAccount = (update: UserAccountUpdate) => {
		console.log(update);
		return userAccountUpdate({
			variables: {
				userId,
				accountUpdate: update,
			},
			// Update the cache manually in order to update the name in the Site Users list
			// We dont know the parameters used to make the Site Users request so we avoid the problem by updating the name here
			update: (cache) => {
				cache.modify({
					id: `Profile:${userId}`,
					fields: {
						name: () => update.name,
					},
				});
			},
		})
			.then(async (res) => {
				// TODO: Update user profile in context rather than reloading.
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Personal Information updated successfully.', {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { updateUserAccount, error: userAccountError, reset };
};

export const UPDATE_ACCOUNT = gql`
	${NAME_FIELDS}
	${PHONE_FIELDS}
	mutation userAccountUpdate($userId: ID!, $accountUpdate: UserAccountUpdate!) {
		userAccountUpdate(userId: $userId, accountUpdate: $accountUpdate) {
			id
			lastUpdatedUtc
			emailAddress
			name {
				...NameFields
			}
			handle
			cellPhone {
				...PhoneFields
			}
			homePhone {
				...PhoneFields
			}
			secondaryEmailAddress
			birthDate
			languageIds
			deactivated
		}
	}
`;

export const UPDATE_ACCOUNT_ACTIVATION = gql`
	mutation userAccountActivationUpdate($userId: ID!, $operation: ActivationOperation!) {
		userAccountActivationUpdate(userId: $userId, operation: $operation) {
			id
		}
	}
`;

export enum ActivationOperation {
	Deactivate = 'Deactivate',
	Activate = 'Activate',
}

export type PersonalInformationValues = Omit<UserAccountUpdate, 'birthDate'> & {
	birthDate: Date | undefined | null;
};

/**
 * usePersonalInfoForm() - Hook for loading and editing the Account PersonalInformation
 * Card.
 *
 * @param {*} id
 * @return {*}
 */
const usePersonalInfoForm = (userId: string) => {
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const { account, loading, error } = usePersonalInformationQuery(userId);
	const navigate = useNavigate();
	const location = useLocation();
	if (error) throw new PageError(error);

	const {
		updateUserAccount: updateUserAccountMutation,
		error: mutationError,
		reset,
	} = usePersonalInformationMutation(userId);

	const validation = useValidation('UserAccountUpdate');

	const initialValues: PersonalInformationValues = {
		secondaryEmailAddress: account?.secondaryEmailAddress ?? '',
		name: {
			firstName: account?.name?.firstName ?? '',
			middleName: account?.name?.middleName ?? '',
			lastName: account?.name?.lastName ?? '',
			suffix: account?.name?.suffix ?? '',
		},
		handle: account?.handle ?? '',
		cellPhone: account?.cellPhone ?? {
			countryCode: '',
			number: '',
		},
		homePhone: account?.homePhone ?? {
			countryCode: '',
			number: '',
		},
		languageIds: account?.languageIds ?? [],
		birthDate: account?.birthDate ? zoneDateOnly(account?.birthDate) : null,
		avatarUploadToken: undefined,
	};

	const onSubmit = async (values: PersonalInformationValues): Promise<boolean> => {
		const update: UserAccountUpdate = {
			...values,
			secondaryEmailAddress:
				values.secondaryEmailAddress === '' ? undefined : values.secondaryEmailAddress,
			homePhone: values?.homePhone?.number ? values?.homePhone : undefined,
			birthDate: values?.birthDate ? format(values?.birthDate, 'yyyy-MM-dd') : null,
		};

		const handleChanged = update.handle !== initialValues.handle;

		return await updateUserAccountMutation(update).then((res) => {
			if (!res) return true;
			setIsEditing(false);
			// Update url if handle changes and we're at a url containing the handle
			if (account?.handle && handleChanged) {
				const fullPath = location.pathname;
				// If url doesn't contain handle (e.g. Site User tab) leave it as is
				if (fullPath.indexOf(account.handle) < 0) {
					return true;
				}
				// Get substring of path after handle (eg '/oldhandle/account' -> '/account')
				let pathEnd = fullPath.substring(
					fullPath.indexOf(account.handle) + account.handle.length
				);
				// Replace the current url with the updated handle without reloading the page
				navigate('/' + update.handle + pathEnd, { replace: true });
			}

			return true;
		});
	};

	return {
		isEditing,
		initialValues,
		onCancel: () => setIsEditing(false),
		onEdit: () => {
			reset();
			setIsEditing(true);
		},
		onSubmit,
		loading: loading || validation.loading,
		validationSchema: validation.schema,
		account: account,
		error: mutationError,
	};
};

/**
 * useCanEditPersonalInformation(account) - This hook calculates if a user can edit personal information.
 *
 * @param {(PersonalInformation | undefined)} account
 * @return {*}
 */
export const useCanEditPersonalInformation = (account: PersonalInformation | undefined) => {
	const { user } = useSession();
	const { hasPermission } = useSiteUser();

	const canEdit = (() => {
		if (!account) return false;
		if (account?.id === user.userId) return true;
		if (account?.siteUserId && hasPermission(Permission.Site_User_U)) return true;
		if (!account?.siteUserId && hasPermission(Permission.SocialUser_Account_U)) return true;
		return false;
	})();

	return { canEdit };
};

/**
 * useUpdateAccountActivationMutation(userId) - Hook that updates user account activation status.
 *
 * @param {string} userId
 * @return {*}
 */
export const useUpdateAccountActivationMutation = (userId: string) => {
	const toast = useToast();
	const [updateAccountActivationMutation, { error, loading }] = useMutation<
		Pick<Mutation, 'userAccountActivationUpdate'>,
		MutationUserAccountActivationUpdateArgs
	>(UPDATE_ACCOUNT_ACTIVATION, {
		refetchQueries: [
			{ query: PERSONALINFORMATION, variables: { userId } },
			'siteUsersOrInvitations',
		],
		awaitRefetchQueries: true,
		update: (cache) => {
			cache.evict({
				id: `Emblem:${userId}`,
			});
		},
	});

	const updateAccountActivation = async (operation: ActivationOperation) => {
		return await updateAccountActivationMutation({
			variables: { userId, operation },
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push(
					`Successfully ${operation === 'Activate' ? 'activated' : 'deactivated'} user.`,
					{
						variant: 'success',
					}
				);

				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { updateAccountActivation, loading, error };
};

export default usePersonalInfoForm;
