import {
	CardContent,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { UserEmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { PeopleSearchResult } from 'middleware-types';
import { Status } from 'pages/site/system-search/system-search';
import { PeopleContextMenu } from './people-context-menu';
import React, { useState } from 'react';

type PeopleSearchRowProps = PeopleSearchResult & {
	status: 'Active' | 'Inactive' | undefined;
	refetch: () => void;
};

export const SiteUserInvitationSystemSearchResults = ({
	rows,
}: {
	rows: PeopleSearchRowProps[];
}) => {
	return (
		<CardContent
			sx={{
				flex: '1 1 auto',
				overflow: 'hidden',
			}}>
			<TableContainer sx={{ maxHeight: '100%' }}>
				<Table stickyHeader aria-label="simple table">
					<TableHead>
						{rows.length !== 0 && <SiteUserInvitationTableHeadings />}
					</TableHead>
					<TableBody>
						{rows.map((row) => (
							<SiteUserInvitationRow {...row} key={row.userId} />
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</CardContent>
	);
};

const SiteUserInvitationRow = (props: PeopleSearchRowProps): React.JSX.Element => {
	const [contextMenuOpen, setContextMenuOpen] = useState(false);

	const onContextMenu = (e) => {
		e.preventDefault();
		setContextMenuOpen(true);
	};

	return (
		<TableRow onContextMenu={(e) => onContextMenu(e)}>
			<TableCell size="small">
				{props.userId && <UserEmblemAvatar id={props.userId} />}
			</TableCell>
			<TableCell>{props.firstName}</TableCell>
			<TableCell>{props.lastName}</TableCell>
			<TableCell>
				<div>{props.emailAddress}</div>
			</TableCell>
			<TableCell>{props.affiliation ? props.affiliation : '---'}</TableCell>
			<TableCell sx={{ color: 'lightgray.500' }}>Invited</TableCell>
			<TableCell size="small">
				<PeopleContextMenu
					refetch={props.refetch}
					name={`${props.firstName} ${props.lastName}`}
					id={props.userId}
					status={Status.Invited}
					siteUserInvitationId={props.siteUserInvitationId}
					handle={props.handle}
					menuOpen={contextMenuOpen}
					setMenuOpen={setContextMenuOpen}
				/>
			</TableCell>
		</TableRow>
	);
};

const SiteUserInvitationTableHeadings = (): React.JSX.Element => {
	return (
		<TableRow>
			<TableCell size="small" style={{ width: '4%' }}></TableCell>
			<TableCell style={{ width: '15%' }}>First Name</TableCell>
			<TableCell style={{ width: '15%' }}>Last Name</TableCell>
			<TableCell style={{ width: '21%' }}>Email</TableCell>
			<TableCell style={{ width: '20%' }}>Affiliation</TableCell>
			<TableCell style={{ width: '11%' }}>Status</TableCell>
			<TableCell size="small" style={{ width: '10%' }}></TableCell>
		</TableRow>
	);
};
