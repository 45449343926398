import { gql, useQuery } from '@apollo/client';
import { Query, QueryParticipantExactHandleSearchArgs } from 'middleware-types';
import { useAnonymousSession, useSession } from './session';

export const SINGLE_PARTICIPANT_EXACT_HANDLE_SEARCH = gql`
	query SingleParticipantExactHandleSearch($entityId: String!, $handle: String) {
		participantExactHandleSearch(entityId: $entityId, handle: $handle) {
			id
			handle
		}
	}
`;

export const useCanMessage = (handle: string | undefined, skip = false) => {
	const { user } = useAnonymousSession();
	const entityId = user?.userId;
	const { data, loading } = useQuery<
		Pick<Query, 'participantExactHandleSearch'>,
		QueryParticipantExactHandleSearchArgs
	>(SINGLE_PARTICIPANT_EXACT_HANDLE_SEARCH, {
		skip: !entityId || !handle || skip,
		fetchPolicy: 'no-cache',
		variables: {
			entityId: entityId!,
			handle,
		},
	});

	const participant = data?.participantExactHandleSearch?.[0];
	return !loading && participant && participant.handle === handle;
};
