import { ReceiptLong } from '@mui/icons-material';
import {
	Card,
	FormControlLabel,
	IconButton,
	Link,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';
import { DebouncedSearchField } from 'components/ui/debounced-search-field';
import { CheckboxField } from 'components/ui/fields';
import { FilterButton } from 'components/ui/filter-button';
import { format } from 'date-fns';
import { InvoiceStatus, OrganizationBillingSummary, SortDirection } from 'middleware-types';
import { InvoiceStatusChip } from 'pages/orgs/[handle]/account/billing/recent-invoices-card';
import { useState } from 'react';
import { formatUSD } from 'utils/utils';
import { useBillingCustomerSummaries } from '../hooks/use-billing-customer-summaries';
import {
	BillingSummariesSortColumn,
	BillingSummariesSortDropdown,
} from './billing-summaries-sort-dropdown';

interface FilterValues {
	showOpen: boolean;
	showPaid: boolean;
	showOverdue: boolean;
}

const compareSummaries = (
	a: OrganizationBillingSummary,
	b: OrganizationBillingSummary,
	column: BillingSummariesSortColumn
): number => {
	switch (column) {
		case BillingSummariesSortColumn.OrganizationName:
			return (a.organizationName ?? '').localeCompare(b.organizationName ?? '');
		case BillingSummariesSortColumn.Status:
			return a.status.localeCompare(b.status);
		case BillingSummariesSortColumn.AmountBilled:
			return a.amountBilled - b.amountBilled;
		case BillingSummariesSortColumn.BalanceDue:
			return a.balanceDue - b.balanceDue;
	}
};

export const BillingReportCard = () => {
	const { summaries, totalAmountBilled, totalBalanceDue, loading } =
		useBillingCustomerSummaries();

	const [searchText, setSearchText] = useState('');

	const [filterValues, setFilterValues] = useState<FilterValues>({
		showOpen: true,
		showPaid: true,
		showOverdue: true,
	});

	const [sortColumn, setSortColumn] = useState(BillingSummariesSortColumn.OrganizationName);
	const [sortDirection, setSortDirection] = useState(SortDirection.Ascending);

	const filtered = summaries.filter((summary) => {
		if (searchText) {
			if (!summary.organizationName) return false;
			if (!summary.organizationName.toLowerCase().includes(searchText.toLowerCase()))
				return false;
		}

		if (summary.status === InvoiceStatus.Open && !filterValues.showOpen) return false;
		if (summary.status === InvoiceStatus.Paid && !filterValues.showPaid) return false;
		if (summary.status === InvoiceStatus.Overdue && !filterValues.showOverdue) return false;

		return true;
	});

	const filteredAndSorted = filtered.sort((a, b) => {
		const result = compareSummaries(a, b, sortColumn);
		return result * (sortDirection === SortDirection.Ascending ? 1 : -1);
	});

	return (
		<Card component={Stack} height="100%" p={3} spacing={2}>
			<Typography variant="h2">Billing Report</Typography>
			<Stack
				direction="row"
				alignItems="center"
				spacing={2}
				px={2}
				py={1}
				borderRadius={1}
				border="1px solid"
				borderColor="divider"
				bgcolor="neutral.50">
				<Typography>
					<strong>{format(new Date(), 'MMMM, yyyy')}</strong>
				</Typography>
				<Typography>•</Typography>
				{loading ? (
					<Skeleton variant="text" width={100} />
				) : (
					<Typography>
						Total amount billed: <strong>{formatUSD(totalAmountBilled / 100)}</strong>
					</Typography>
				)}
				<Typography>•</Typography>
				{loading ? (
					<Skeleton variant="text" width={100} />
				) : (
					<Typography>
						Total balance due: <strong>{formatUSD(totalBalanceDue / 100)}</strong>
					</Typography>
				)}
			</Stack>
			<Stack direction="row" spacing={1.5}>
				<DebouncedSearchField
					fullWidth={false}
					setExternalValue={setSearchText}
					sx={{ flex: 1 }}
				/>
				<FilterButton initialValues={filterValues} setFilters={setFilterValues}>
					<Stack p={2}>
						<FormControlLabel
							label="Open"
							control={<CheckboxField name="showOpen" />}
						/>
						<FormControlLabel
							label="Paid"
							control={<CheckboxField name="showPaid" />}
						/>
						<FormControlLabel
							label="Overdue"
							control={<CheckboxField name="showOverdue" />}
						/>
					</Stack>
				</FilterButton>
				<BillingSummariesSortDropdown
					column={sortColumn}
					setColumn={setSortColumn}
					direction={sortDirection}
					setDirection={setSortDirection}
				/>
			</Stack>
			<TableContainer>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell>Organization Name</TableCell>
							<TableCell>Status</TableCell>
							<TableCell align="right">Amount Billed</TableCell>
							<TableCell align="right">Balance Due</TableCell>
							<TableCell padding="checkbox" />
						</TableRow>
					</TableHead>
					<TableBody>
						{loading ? (
							[...Array(3)].map((_, index) => (
								<TableRow key={index}>
									<TableCell colSpan={10}>
										<Skeleton variant="text" />
									</TableCell>
								</TableRow>
							))
						) : filteredAndSorted.length === 0 ? (
							<TableRow>
								<TableCell colSpan={10} align="center">
									No results
								</TableCell>
							</TableRow>
						) : (
							filteredAndSorted.map((summary, index) => (
								<TableRow key={index}>
									<TableCell>
										<Link
											target="_blank"
											href={summary.stripeCustomerUrl ?? '#'}>
											{summary.organizationName}
										</Link>
									</TableCell>
									<TableCell>
										<InvoiceStatusChip status={summary.status} />
									</TableCell>
									<TableCell align="right">
										{formatUSD(summary.amountBilled / 100)}
									</TableCell>
									<TableCell align="right">
										{formatUSD(summary.balanceDue / 100)}
									</TableCell>
									<TableCell padding="checkbox">
										<Tooltip title="Go to billing">
											<IconButton
												target="_blank"
												href={`/orgs/${summary.organizationHandle}/account/billing`}>
												<ReceiptLong />
											</IconButton>
										</Tooltip>
									</TableCell>
								</TableRow>
							))
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</Card>
	);
};
