import { useUploadsDrawer } from 'components/ui/uploads/uploads-drawer';
import { FileAccessLevel, FileEntityType, SystemFileType } from 'middleware-types';
import { useDocumentsContext } from '../documents-page';
import { commitFileInstance } from './files/commit-file-instance';
import { commitFile } from './files/commit-file';

interface UploadDocumentsParams {
	file: File;
	accessLevel: FileAccessLevel;
	relatedEntityId: string;
	folderId: string | undefined;
	folderName: string | undefined;
	updatesFileId?: string;
}

/**
 * This hook is a wrapper around the uploadFiles function
 * It handles some functionality specific to the documents page
 */
export const useUploadDocuments = () => {
	const { entityId, entityType, entityName } = useDocumentsContext();
	const { uploadFiles } = useUploadsDrawer();

	const uploadDocuments = (params: UploadDocumentsParams | UploadDocumentsParams[]) => {
		const documents = Array.isArray(params) ? params : [params];
		uploadFiles(
			documents.map((doc) => ({
				file: doc.file,
				relatedEntityId: doc.relatedEntityId,
				relatedEntityType: FileEntityType.Organization,
				systemFileType: SystemFileType.OrganizationCommon,
				accessLevel: doc.accessLevel,
				updatesFileId: doc.updatesFileId,
				subtitle: `${entityName}${doc.folderName ? ` (${doc.folderName})` : ''}`,
				url: `${window.location.pathname}?clearSearch=true${
					doc.folderId ? `&folderId=${doc.folderId}` : ''
				}`,
				onCompleted: doc.updatesFileId
					? async (fileUploadToken: string) =>
							await commitFileInstance({
								entityId,
								entityType,
								request: {
									fileUploadToken,
									folderId: doc.folderId,
									accessLevel: doc.accessLevel,
								},
							})
					: async (fileUploadToken: string) =>
							await commitFile({
								entityId,
								entityType,
								request: {
									fileUploadToken,
									folderId: doc.folderId,
									accessLevel: doc.accessLevel,
								},
							}),
			}))
		);
	};

	return uploadDocuments;
};
