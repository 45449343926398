import { FolderOutlined } from '@mui/icons-material';
import { ListItem, ListItemButton, Stack, Typography } from '@mui/material';
import { FolderReference } from 'middleware-types';
import { memo, useState } from 'react';
import { FolderLink } from '../components/folder-link';
import { FolderMenuButton } from '../components/folder-menu-button';
import { useCurrentFolderId } from '../hooks/use-current-folder-id';

interface FolderListItemProps {
	folder: FolderReference;
}

export const FolderListItem = memo(function FolderListItem({ folder }: FolderListItemProps) {
	const [, setCurrentFolderId] = useCurrentFolderId();
	const [contextMenuOpen, setContextMenuOpen] = useState(false);

	return (
		<ListItem disableGutters disablePadding>
			<ListItemButton disableGutters onClick={() => setCurrentFolderId(folder.id)}>
				<Stack direction="row" width="100%" alignItems="center" spacing={1.5}>
					<FolderOutlined sx={{ color: 'neutral.500' }} />
					<Stack flex={1} overflow="hidden">
						<Typography variant="body1" noWrap>
							{folder.name}
						</Typography>
						<FolderLink folder={folder.parentFolder} />
					</Stack>
					<FolderMenuButton
						folder={folder}
						menuOpen={contextMenuOpen}
						setMenuOpen={setContextMenuOpen}
					/>
				</Stack>
			</ListItemButton>
		</ListItem>
	);
});
