import { AddOutlined, SchoolOutlined } from '@mui/icons-material';
import { Box, Card, Divider, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { Fragment, useState } from 'react';
import { Permission } from 'utils/permissions';
import { useAnonymousSession } from 'utils/session';
import { useSiteUser } from 'utils/useSiteUser';
import { ProfileCardEmptyState } from '../shared/profile-card-empty-state';
import { EducationItem } from './education-item';
import { useEducation } from './hooks';
import { NewEducationForm } from './new-education-form';

interface EducationCardProps {
	userId: string;
}

export const EducationCard = ({ userId }: EducationCardProps) => {
	const { user } = useAnonymousSession();
	const { hasPermission } = useSiteUser();

	const [editing, setEditing] = useState(false);
	const canEdit =
		(user && user.userId === userId) || hasPermission(Permission.SocialUser_Profile_U);

	const { education, loading } = useEducation(userId);

	if (loading) return <Skeleton variant="rounded" height={100} />;
	if (!canEdit && education.length === 0) return <></>;

	return (
		<Card sx={{ borderRadius: { xs: 0, sm: 1 } }}>
			<Stack
				px={2.5}
				py={1}
				direction="row"
				alignItems="center"
				justifyContent="space-between">
				<Typography variant="h3">Education</Typography>
				{canEdit ? (
					<IconButton
						onClick={() => {
							setEditing(true);
						}}>
						<AddOutlined />
					</IconButton>
				) : (
					<Box height={40} />
				)}
			</Stack>
			<Divider />
			{editing && <NewEducationForm userId={userId} onClose={() => setEditing(false)} />}
			{education.map((e, index) => (
				<Fragment key={e.id}>
					{(editing || index !== 0) && <Divider />}
					<EducationItem userId={userId} education={e} canEdit={canEdit} />
				</Fragment>
			))}
			{!editing && education.length === 0 && (
				<ProfileCardEmptyState
					icon={<SchoolOutlined />}
					primaryText="No education yet."
					secondaryText="Add schools, certificate programs, or other educational experiences here."
				/>
			)}
		</Card>
	);
};
