import { gql, useMutation, useQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, TextField as MTextField, MenuItem, Skeleton } from '@mui/material';
import { Alert } from 'components/ui/alert';
import { SelectField, TextField } from 'components/ui/fields';
import { ModalButtonsContainer } from 'components/ui/modals/modal-buttons-container';
import { ModalContent } from 'components/ui/modals/modal-content';
import { ModalHeader } from 'components/ui/modals/modal-header';
import { ModalContentProps } from 'components/ui/modals/modal-types';
import { useToast } from 'components/ui/toast';
import { Formik, FormikProps } from 'formik';
import {
	Mutation,
	MutationUserSiteUserInvitationSendArgs,
	Query,
	QuerySiteRolesArgs,
	SiteUserInvitationRequest,
	SortDirection,
} from 'middleware-types';
import React from 'react';
import { PageError, handleNoResponse, responseHasErrors } from 'utils/errors';
import { useValidation } from 'utils/useValidation';

/**
 * useSiteUserInvitation - Custom hook for getting all roles, validation schema, handling submit, and loading.
 *
 * @returns {({
 * 	roles: SiteRoleItem[];
 * 	validationSchema: ValidationSchema | null;
 * 	submit: (values: MSiteUserInvitationRequest) => Promise<void>;
 * 	loading: boolean;
 * })}
 */
export const useSiteUserInvitation = () => {
	const validation = useValidation('RepresentativeUpdate');
	const toast = useToast();
	const rolesQuery = useQuery<Pick<Query, 'siteRoles'>, QuerySiteRolesArgs>(
		gql`
			query siteRolesList(
				$pageSize: Float!
				$offset: Float!
				$sortedBy: String!
				$sortDirection: SortDirection!
				$searchFor: String!
			) {
				siteRoles(
					pageSize: $pageSize
					offset: $offset
					sortedBy: $sortedBy
					sortDirection: $sortDirection
					searchFor: $searchFor
				) {
					items {
						id
						name
					}
				}
			}
		`,
		{
			variables: {
				pageSize: 100,
				offset: 0,
				sortedBy: 'name',
				sortDirection: SortDirection.Ascending,
				searchFor: '',
			},
			fetchPolicy: 'cache-and-network',
		}
	);

	const [inviteMutation, mutationState] = useMutation<
		Pick<Mutation, 'userSiteUserInvitationSend'>,
		MutationUserSiteUserInvitationSendArgs
	>(
		gql`
			mutation invitation($siteUserInvitationRequest: SiteUserInvitationRequest!) {
				userSiteUserInvitationSend(siteUserInvitationRequest: $siteUserInvitationRequest) {
					invitationId
				}
			}
		`
	);

	if (rolesQuery.error) throw new PageError('Error loading site roles');

	const onSubmit = async (values: SiteUserInvitationRequest): Promise<boolean> =>
		inviteMutation({
			variables: { siteUserInvitationRequest: values },
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Invitation sent successfully.', {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});

	return {
		validationSchema: validation.schema,
		roles: rolesQuery.data?.siteRoles?.items ?? [],
		loading: validation.loading || rolesQuery?.loading,
		error: mutationState.error,
		onSubmit,
	};
};

interface InvitationFormProps extends ModalContentProps {
	onSuccessfulInvite: (values: SiteUserInvitationRequest) => void;
}

export const InvitationForm = ({ onClose, onSuccessfulInvite }: InvitationFormProps) => {
	const { loading, roles, onSubmit, validationSchema, error } = useSiteUserInvitation();
	const submitForm = async (values: SiteUserInvitationRequest) => {
		const success = await onSubmit(values);
		if (success) onSuccessfulInvite(values);
	};

	return (
		<Formik<SiteUserInvitationRequest>
			onSubmit={submitForm}
			validationSchema={validationSchema}
			initialValues={{
				firstName: '',
				lastName: '',
				invitedEmailAddress: '',
				affiliation: '',
				roleId: '',
			}}
			enableReinitialize>
			{(props: FormikProps<SiteUserInvitationRequest>) => (
				<>
					<ModalHeader title="Invite Site User" onClose={onClose} />
					<ModalContent>
						<Box>
							{loading ? (
								<SkeletonForm />
							) : (
								<Grid container spacing={1} columnSpacing={2}>
									<Grid xs={12} md={6} item>
										<TextField
											label="First Name"
											required
											type="text"
											name="firstName"
										/>
									</Grid>
									<Grid xs={12} md={6} item>
										<TextField
											label="Last Name"
											required
											type="text"
											name="lastName"
										/>
									</Grid>
									<Grid xs={12} md={6} item>
										<TextField
											label="Email Address"
											required
											type="email"
											name="invitedEmailAddress"
										/>
									</Grid>
									<Grid xs={12} md={6} item>
										<SelectField
											name="roleId"
											label="Role"
											required
											value={props.values.roleId ?? ''}
											fullWidth>
											{roles.map((src, i) => (
												<MenuItem key={i} value={src.id}>
													{src.name !== '' ? src.name : <em>None</em>}
												</MenuItem>
											))}
										</SelectField>
									</Grid>
									<Grid xs={12} md={12} item>
										<TextField
											label="Affiliation"
											required
											type="email"
											name="affiliation"
										/>
									</Grid>
									{error && (
										<Grid xs={12} md={12} item>
											<Alert error={error} />
										</Grid>
									)}
								</Grid>
							)}
						</Box>
					</ModalContent>
					<ModalButtonsContainer>
						<Button variant="outlined" onClick={onClose}>
							Cancel
						</Button>
						<LoadingButton
							variant="contained"
							color="primary"
							onClick={props.submitForm}
							disabled={!props.isValid || !props.dirty || props.isSubmitting}
							loading={props.isSubmitting}>
							Send Invite
						</LoadingButton>
					</ModalButtonsContainer>
				</>
			)}
		</Formik>
	);
};

const SkeletonForm = (): React.JSX.Element => (
	<Grid container spacing={1} columnSpacing={2}>
		<Grid xs={12} md={6} item>
			<Skeleton animation="wave" width="100%">
				<MTextField />
			</Skeleton>
		</Grid>
		<Grid xs={12} md={6} item>
			<Skeleton animation="wave" width="100%">
				<MTextField />
			</Skeleton>
		</Grid>
		<Grid xs={12} md={6} item>
			<Skeleton animation="wave" width="100%">
				<MTextField />
			</Skeleton>
		</Grid>
		<Grid xs={12} md={6} item>
			<Skeleton animation="wave" width="100%">
				<MTextField />
			</Skeleton>
		</Grid>
		<Grid xs={12} md={12} item>
			<Skeleton animation="wave" width="100%">
				<MTextField />
			</Skeleton>
		</Grid>
	</Grid>
);
