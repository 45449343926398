import { useMutation, useQuery } from '@apollo/client';
import { ADDRESS_FIELDS } from 'components/ui/fields/address';
import { PHONE_FIELDS } from 'components/ui/fields/phone';
import { useToast } from 'components/ui/toast';
import { gql } from 'graphql-tag';
import {
	AccountEmergencyContactUpdate,
	Mutation,
	MutationUserAccountEmergencyContactDeleteArgs,
	MutationUserAccountEmergencyContactUpdateArgs,
	Query,
	QueryAccountArgs,
} from 'middleware-types';
import { useState } from 'react';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { Permission } from 'utils/permissions';
import { useSession } from 'utils/session';
import { useDefaults } from 'utils/useDefaults';
import { useSiteUser } from 'utils/useSiteUser';
import { useValidation } from 'utils/useValidation';

export const useEmergencyContactQuery = (userId: string) => {
	const { data, loading, error } = useQuery<Pick<Query, 'account'>, QueryAccountArgs>(
		EMERGENCYCONTACT,
		{
			fetchPolicy: 'cache-first',
			variables: {
				userId,
			},
		}
	);

	return { data, error, loading };
};

export const EMERGENCYCONTACT = gql`
	${PHONE_FIELDS}
	${ADDRESS_FIELDS}
	query account($userId: ID!) {
		account(userId: $userId) {
			id
			siteUserId
			emergencyContact {
				userId
				firstName
				lastName
				relationship
				cellPhone {
					...PhoneFields
				}
				otherPhone {
					...PhoneFields
				}
				email
				address {
					...AddressFields
				}
			}
		}
	}
`;

export const useEmergencyContactUpdateMutation = () => {
	const [userAccountEmergencyContactUpdate, { loading, error, reset }] = useMutation<
		Pick<Mutation, 'userAccountEmergencyContactUpdate'>,
		MutationUserAccountEmergencyContactUpdateArgs
	>(UPDATE_EMERGENCY_CONTACT);

	const updateEmergencyContact = (userId: string, update: AccountEmergencyContactUpdate) => {
		return userAccountEmergencyContactUpdate({
			refetchQueries: [{ query: EMERGENCYCONTACT, variables: { userId } }],
			awaitRefetchQueries: true,
			variables: { userId, emergencyContactUpdate: update },
		});
	};

	return { updateEmergencyContact, loading, error, reset };
};

export const useEmergencyContactDeleteMutation = () => {
	const [userAccountEmergencyContactDelete, { loading, error }] = useMutation<
		Pick<Mutation, 'userAccountEmergencyContactDelete'>,
		MutationUserAccountEmergencyContactDeleteArgs
	>(DELETE_EMERGENCY_CONTACT);

	const deleteEmergencyContact = (userId: string) => {
		return userAccountEmergencyContactDelete({
			refetchQueries: [{ query: EMERGENCYCONTACT, variables: { userId: userId } }],
			awaitRefetchQueries: true,
			variables: { userId },
		});
	};

	return { deleteEmergencyContact, loading, error };
};

export const UPDATE_EMERGENCY_CONTACT = gql`
	mutation userAccountEmergencyContactUpdate(
		$userId: ID!
		$emergencyContactUpdate: AccountEmergencyContactUpdate!
	) {
		userAccountEmergencyContactUpdate(
			userId: $userId
			emergencyContactUpdate: $emergencyContactUpdate
		) {
			userId
			firstName
			lastName
			relationship
			address {
				countryId
				address1
				address2
				municipality
				adminArea1Id
				adminArea2Id
				postalCode
				coordinate {
					latitude
					longitude
				}
			}
			email
			cellPhone {
				countryCode
				number
			}
			otherPhone {
				countryCode
				number
			}
		}
	}
`;

export const DELETE_EMERGENCY_CONTACT = gql`
	mutation userAccountEmergencyContactDelete($userId: ID!) {
		userAccountEmergencyContactDelete(userId: $userId)
	}
`;

/**
 * useEmergencyContactForm() - Hook for loading and editing EmergencyContactForm card.
 *
 * @param {*} userId
 * @return {*}
 */
const useEmergencyContactForm = (userId: string) => {
	const [isEditing, setIsEditing] = useState(false);
	const defaults = useDefaults();
	const toast = useToast();
	const { data } = useEmergencyContactQuery(userId);
	const {
		updateEmergencyContact,
		error: updateError,
		reset,
	} = useEmergencyContactUpdateMutation();
	const {
		deleteEmergencyContact,
		error: deleteError,
		loading: deleteLoading,
	} = useEmergencyContactDeleteMutation();
	const { hasPermission } = useSiteUser();
	const { user } = useSession();
	const { schema: validationSchema, loading: validationLoading } =
		useValidation('emergencycontactupdate');

	const emergencyContact = data?.account.emergencyContact;

	const canEdit = () => {
		if (userId === user.userId) return true;
		if (data?.account.siteUserId && hasPermission(Permission.Site_User_U)) return true;
		if (!data?.account.siteUserId && hasPermission(Permission.SocialUser_Account_U))
			return true;
		return false;
	};

	const onSubmit = async (values: AccountEmergencyContactUpdate) => {
		await updateEmergencyContact(userId, {
			...values,
			email: values.email === '' ? undefined : values.email,
			otherPhone: values.otherPhone?.number === '' ? undefined : values.otherPhone,
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Emergency Contact updated successfully.', {
					variant: 'success',
				});
				setIsEditing(false);
			})
			.catch(() => handleNoResponse({ toast }));
	};

	const onDelete = async () =>
		await deleteEmergencyContact(userId)
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Successfully deleted emergency contact', {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});

	const initialValues: AccountEmergencyContactUpdate = {
		firstName: emergencyContact?.firstName ?? '',
		lastName: emergencyContact?.lastName ?? '',
		relationship: emergencyContact?.relationship ?? '',
		cellPhone: {
			countryCode: emergencyContact?.cellPhone.countryCode ?? '',
			number: emergencyContact?.cellPhone.number ?? '',
		},
		otherPhone: {
			countryCode: emergencyContact?.otherPhone?.countryCode ?? '',
			number: emergencyContact?.otherPhone?.number ?? '',
		},
		email: emergencyContact?.email ?? '',
		address: emergencyContact?.address
			? {
					...emergencyContact.address,
					adminArea2Id: emergencyContact.address.adminArea2Id ?? '',
			  }
			: {
					countryId: defaults.country.id,
					address1: '',
					address2: '',
					municipality: '',
					adminArea1Id: '',
					adminArea2Id: '',
					postalCode: '',
			  },
	};

	const toggleEdit = () => {
		reset();
		setIsEditing(!isEditing);
	};

	return {
		initialValues,
		onSubmit,
		isEditing,
		toggleEdit,
		emergencyContact,
		validationSchema,
		onDelete,
		error: updateError || deleteError,
		loading: deleteLoading || validationLoading,
		canEdit: canEdit(),
	};
};

export default useEmergencyContactForm;
