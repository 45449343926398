import { AddOutlined, BusinessOutlined } from '@mui/icons-material';
import { Box, Card, Divider, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { OrgEmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import {
	EmploymentLocationType,
	EmploymentType,
	UserExperienceCompany,
	UserExperiencePosition,
} from 'middleware-types';
import { Fragment, useState } from 'react';
import { Permission } from 'utils/permissions';
import { useAnonymousSession } from 'utils/session';
import { useSiteUser } from 'utils/useSiteUser';
import { monthNames } from 'utils/utils';
import { ProfileCardEmptyState } from '../shared/profile-card-empty-state';
import {
	ExperienceCompanyDisplay,
	ImageDisplayAvatar,
	LocationAndLocationTypeDisplay,
} from './display-components';
import {
	compareStartEndDates,
	formatWorkPeriod,
	getPositionEndDate,
	getPositionStartDate,
	pascalCaseAddSpaces,
} from './helpers';
import { useUserProfileExperience } from './hooks';
import { UserExperienceCompanyAndPositionForm } from './user-experience-company-and-position-form';
import { UserExperienceCompanyContextMenu } from './user-experience-company-context.menu';
import { UserExperienceCompanyForm } from './user-experience-company-form';
import { UserExperiencePositionContextMenu } from './user-experience-position-context-menu';
import { UserExperiencePositionForm } from './user-experience-position-form';

interface UserExperienceCardProps {
	userId: string;
}

export const UserExperienceCard = ({ userId }: UserExperienceCardProps) => {
	const { user } = useAnonymousSession();
	const { hasPermission } = useSiteUser();
	const { companies, loading } = useUserProfileExperience(userId);

	const [editing, setEditing] = useState(false);
	const canEdit =
		(user && user.userId === userId) || hasPermission(Permission.SocialUser_Profile_U);

	if (loading) return <Skeleton variant="rounded" height={100} />;
	if (!canEdit && companies.length === 0) return <></>;

	return (
		<Card sx={{ borderRadius: { xs: 0, sm: 1 } }}>
			<Stack
				direction="row"
				px={2.5}
				py={1}
				alignItems="center"
				justifyContent="space-between">
				<Typography variant="h3">Experience</Typography>
				{canEdit ? (
					<IconButton
						onClick={() => {
							setEditing(true);
						}}>
						<AddOutlined />
					</IconButton>
				) : (
					<Box height={40} />
				)}
			</Stack>
			<Divider />
			{editing && (
				<UserExperienceCompanyAndPositionForm
					userId={userId}
					onClose={() => setEditing(false)}
				/>
			)}
			{companies.map((company, index) => (
				<Fragment key={company.id}>
					{(editing || index !== 0) && <Divider />}
					<CompanyListItem company={company} userId={userId} />
				</Fragment>
			))}
			{!editing && companies.length === 0 && (
				<ProfileCardEmptyState
					icon={<BusinessOutlined />}
					primaryText="No experiences yet."
					secondaryText="Add past companies and organizations you've worked at here."
				/>
			)}
		</Card>
	);
};

interface CompanyListItemProps {
	company: UserExperienceCompany;
	userId: string;
}

const CompanyListItem = ({ company, userId }: CompanyListItemProps) => {
	const [showUpdateCompanyForm, setShowUpdateCompanyForm] = useState(false);
	const [showAddPositionForm, setShowAddPositionForm] = useState(false);

	const allSameLocationType = company.positions.every(
		(p, _, array) => p.locationType === array[0].locationType
	)
		? company.positions[0].locationType
		: undefined;

	const allSameLocation = company.positions.every(
		(p, _, array) => p.location === array[0].location
	)
		? company.positions[0].location
		: undefined;

	const allSameEmploymentType = company.positions.every(
		(p, _, array) => p.employmentType === array[0].employmentType
	)
		? company.positions[0].employmentType
		: undefined;

	const sortedPositions = [...company.positions].sort((a, b) => {
		return compareStartEndDates(
			getPositionStartDate(a),
			getPositionStartDate(b),
			getPositionEndDate(a),
			getPositionEndDate(b)
		);
	});

	return (
		<Stack p={2.5} spacing={2} direction="row" alignItems="flex-start">
			{company.organizationId ? (
				<OrgEmblemAvatar id={company.organizationId} size={60} />
			) : (
				<ImageDisplayAvatar src={company.logoUrl} size={60} />
			)}
			<Stack spacing={1.5} flex={1}>
				{showUpdateCompanyForm ? (
					<UserExperienceCompanyForm
						company={company}
						userId={userId}
						onClose={() => setShowUpdateCompanyForm(false)}
					/>
				) : (
					<Stack direction="row" alignItems="flex-start" justifyContent="space-between">
						<ExperienceCompanyDisplay
							company={company}
							allSameEmploymentType={allSameEmploymentType}
							allSameLocation={allSameLocation}
							allSameLocationType={allSameLocationType}
						/>
						<UserExperienceCompanyContextMenu
							showUpdateCompanyForm={() => setShowUpdateCompanyForm(true)}
							showAddPositionForm={() => setShowAddPositionForm(true)}
							company={company}
						/>
					</Stack>
				)}
				{sortedPositions.map((p) => (
					<Fragment key={p.id}>
						<Divider />
						<PositionListItem
							position={p}
							company={company}
							userId={userId}
							allSameEmploymentType={allSameEmploymentType}
							allSameLocation={allSameLocation}
							allSameLocationType={allSameLocationType}
						/>
					</Fragment>
				))}
				{showAddPositionForm && (
					<>
						<Divider />
						<UserExperiencePositionForm
							userId={userId}
							company={company}
							onClose={() => setShowAddPositionForm(false)}
						/>
					</>
				)}
			</Stack>
		</Stack>
	);
};

interface PositionListItemProps {
	userId: string;
	company: UserExperienceCompany;
	position: UserExperiencePosition;
	allSameEmploymentType?: EmploymentType;
	allSameLocation?: string;
	allSameLocationType?: EmploymentLocationType;
}

const PositionListItem = ({
	position,
	company,
	userId,
	allSameLocation,
	allSameLocationType,
	allSameEmploymentType,
}: PositionListItemProps) => {
	const [showUpdatePositionForm, setShowUpdatePositionForm] = useState(false);

	if (showUpdatePositionForm) {
		return (
			<UserExperiencePositionForm
				userId={userId}
				company={company}
				position={position}
				onClose={() => setShowUpdatePositionForm(false)}
			/>
		);
	}

	const startDate = getPositionStartDate(position);
	const endDate = getPositionEndDate(position);
	const timePeriodString =
		`${monthNames[position.startMonth - 1]}, ${position.startYear} • ` +
		(position.endMonth && position.endYear
			? `${monthNames[position.endMonth - 1]}, ${position.endYear}`
			: 'Present') +
		` (${formatWorkPeriod(startDate, endDate)})`;

	return (
		<Stack direction="row" alignItems="flex-start" justifyContent="space-between">
			<Stack spacing={0.5}>
				<Typography variant="h4" lineHeight="normal">
					{position.jobTitle}
				</Typography>
				{!allSameEmploymentType && (
					<Typography variant="body1" lineHeight="normal">
						{pascalCaseAddSpaces(position.employmentType)}
					</Typography>
				)}
				<LocationAndLocationTypeDisplay
					location={!allSameLocation ? position.location : undefined}
					locationType={!allSameLocationType ? position.locationType : undefined}
				/>
				<Typography variant="subtitle1" lineHeight="normal">
					{timePeriodString}
				</Typography>
				<Typography variant="body1" lineHeight="normal">
					{position.description}
				</Typography>
			</Stack>
			{!showUpdatePositionForm && (
				<UserExperiencePositionContextMenu
					showUpdatePositionForm={() => setShowUpdatePositionForm(true)}
					position={position}
					company={company}
				/>
			)}
		</Stack>
	);
};
