import {
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { FileInstanceInformation, FolderReference } from 'middleware-types';
import { FileRow } from './file-row';
import { FolderRow } from './folder-row';
import { AccessLevelTooltip } from '../components/access-level';
import { useVersionHistoryContext } from '../components/version-history-provider';

interface DocumentsTableProps {
	folders: FolderReference[];
	files: FileInstanceInformation[];
	setIndexSelected?: (index: number, e: React.MouseEvent) => void;
	clearSelectedFiles?: () => void;
	selectedIndexes?: number[];
}

export const DocumentsTable = ({
	folders,
	files,
	setIndexSelected,
	clearSelectedFiles,
	selectedIndexes,
}: DocumentsTableProps) => {
	const { isVersionHistory } = useVersionHistoryContext();

	return (
		<TableContainer>
			<Table stickyHeader>
				<TableHead>
					<TableRow>
						<TableCell>Name</TableCell>
						<TableCell>{isVersionHistory ? 'Upload Date' : 'Last Updated'}</TableCell>
						<TableCell>
							{isVersionHistory ? 'Uploaded By' : 'Last Updated By'}
						</TableCell>
						<TableCell>
							<Stack direction="row" alignItems="center" gap={1}>
								Privacy
								<AccessLevelTooltip sx={{ color: 'neutral.500', fontSize: 18 }} />
							</Stack>
						</TableCell>
						<TableCell>Virus Status</TableCell>
						<TableCell align="right">File Size</TableCell>
						<TableCell />
					</TableRow>
				</TableHead>
				<TableBody>
					{folders.map((folder) => (
						<FolderRow
							key={folder.id}
							folder={folder}
							clearSelectedFiles={clearSelectedFiles}
						/>
					))}
					{files.map((file, index) => (
						<FileRow
							key={isVersionHistory ? file.id : file.fileId}
							index={index}
							file={file}
							setIndexSelected={setIndexSelected}
							selected={selectedIndexes?.includes(index)}
						/>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
