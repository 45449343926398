import { addMonths, formatDistanceStrict, isAfter, isBefore, max, min } from 'date-fns';
import { UserExperienceCompany, UserExperiencePosition } from 'middleware-types';

export const getPositionStartDate = (position: UserExperiencePosition) => {
	return new Date(position.startYear, position.startMonth - 1);
};

export const getPositionEndDate = (position: UserExperiencePosition) => {
	if (position.endYear && position.endMonth)
		return new Date(position.endYear, position.endMonth - 1);
	return undefined;
};

export const getCompanyStartDate = (company: UserExperienceCompany) => {
	const startDates = company.positions.map(getPositionStartDate);
	return min(startDates);
};

export const getCompanyEndDate = (company: UserExperienceCompany) => {
	const endDates = company.positions.map(getPositionEndDate);
	if (endDates.some((date) => date === undefined)) return undefined;
	return max(endDates as Date[]);
};

export const compareStartEndDates = (
	startDateA: Date,
	startDateB: Date,
	endDateA: Date | undefined,
	endDateB: Date | undefined
) => {
	const now = new Date();
	endDateA = endDateA ?? now;
	endDateB = endDateB ?? now;

	if (isAfter(endDateA, endDateB)) return -1;
	if (isBefore(endDateA, endDateB)) return 1;

	if (isAfter(startDateA, startDateB)) return -1;
	if (isBefore(startDateA, startDateB)) return 1;
	return 0;
};

export const formatWorkPeriod = (startDate: Date, endDate: Date | undefined) => {
	endDate = endDate ?? new Date();
	// make sure the time period is never less than a month
	endDate = max([endDate, addMonths(startDate, 1)]);
	return formatDistanceStrict(startDate, endDate);
};

// puts spaces between pascal case strings
export const pascalCaseAddSpaces = (value: string) => value.split(/(?<=[a-z^])(?=[A-Z])/).join(' ');
