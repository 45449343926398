import { gql, useMutation, useQuery } from '@apollo/client';
import { EMBLEM_FIELDS } from 'components/ui/emblem/fragments';
import { useToast } from 'components/ui/toast';
import {
	Mutation,
	MutationUserProfileEducationAddArgs,
	MutationUserProfileEducationDeleteArgs,
	MutationUserProfileEducationUpdateArgs,
	Query,
	QueryUserProfileEducationGetArgs,
	UserEducationUpdate,
} from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';

/** fragments */
const USER_EDUCATION_FIELDS = gql`
	${EMBLEM_FIELDS}
	fragment UserEducationFields on UserEducation {
		id
		school
		organizationId
		websiteUrl
		logoUrl
		degree
		field
		description
		startYear
		endYear
		startMonth
		endMonth
		skillIds
		organizationEmblem {
			...EmblemFields
		}
	}
`;

/** get education */
export const GET_EDUCATION = gql`
	${USER_EDUCATION_FIELDS}
	query GetEducation($userId: ID!) {
		userProfileEducationGet(userId: $userId) {
			education {
				...UserEducationFields
			}
		}
	}
`;

export const useEducation = (userId: string) => {
	const { data, loading } = useQuery<
		Pick<Query, 'userProfileEducationGet'>,
		QueryUserProfileEducationGetArgs
	>(GET_EDUCATION, { variables: { userId } });

	const education = data?.userProfileEducationGet.education ?? [];
	const sortedEducation = [...education].sort((a, b) => {
		const aStartYear = a.startYear ?? Number.MIN_VALUE;
		const aEndYear = a.endYear ?? Number.MAX_VALUE;
		const bStartYear = b.startYear ?? Number.MIN_VALUE;
		const bEndYear = b.endYear ?? Number.MAX_VALUE;

		if (aEndYear === bEndYear) return bStartYear - aStartYear;
		return bEndYear - aEndYear;
	});

	return { education: sortedEducation, loading };
};

/** add education */
const ADD_EDUCATION = gql`
	${USER_EDUCATION_FIELDS}
	mutation AddEducation($userId: ID!, $update: UserEducationUpdate!) {
		userProfileEducationAdd(userId: $userId, update: $update) {
			...UserEducationFields
		}
	}
`;

export const useAddEducation = (userId: string) => {
	const toast = useToast();
	const [_addEducation, { loading }] = useMutation<
		Pick<Mutation, 'userProfileEducationAdd'>,
		MutationUserProfileEducationAddArgs
	>(ADD_EDUCATION, { onError: (e) => console.log(JSON.stringify(e)) });

	const addEducation = async (update: UserEducationUpdate) => {
		return await _addEducation({
			variables: { userId, update },
			update: (cache, { data }) => {
				if (!data) return;
				cache.updateQuery<
					Pick<Query, 'userProfileEducationGet'>,
					QueryUserProfileEducationGetArgs
				>({ query: GET_EDUCATION, variables: { userId } }, (queryData) => {
					if (!queryData) return;
					return {
						userProfileEducationGet: {
							education: [
								...queryData.userProfileEducationGet.education,
								data.userProfileEducationAdd,
							],
						},
					};
				});
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Education added successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { addEducation, loading };
};

/** update education */
const UPDATE_EDUCATION = gql`
	${USER_EDUCATION_FIELDS}
	mutation UpdateEducation($userId: ID!, $userEducationId: ID!, $update: UserEducationUpdate!) {
		userProfileEducationUpdate(
			userId: $userId
			userEducationId: $userEducationId
			update: $update
		) {
			...UserEducationFields
		}
	}
`;

export const useUpdateEducation = (userId: string, userEducationId: string) => {
	const toast = useToast();
	const [_updateEducation, { loading }] = useMutation<
		Pick<Mutation, 'userProfileEducationUpdate'>,
		MutationUserProfileEducationUpdateArgs
	>(UPDATE_EDUCATION, { onError: (e) => console.log(JSON.stringify(e)) });

	const updateEducation = async (update: UserEducationUpdate) => {
		return await _updateEducation({
			variables: { userId, userEducationId, update },
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Education updated successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { updateEducation, loading };
};

/** delete education */
const DELETE_EDUCATION = gql`
	mutation DeleteEducation($userId: ID!, $userEducationId: ID!) {
		userProfileEducationDelete(userId: $userId, userEducationId: $userEducationId)
	}
`;

export const useDeleteEducation = (userId: string, userEducationId: string) => {
	const toast = useToast();
	const [_deleteEducation, { loading }] = useMutation<
		Pick<Mutation, 'userProfileEducationDelete'>,
		MutationUserProfileEducationDeleteArgs
	>(DELETE_EDUCATION, { onError: (e) => console.log(JSON.stringify(e)) });

	const deleteEducation = async () => {
		return await _deleteEducation({
			variables: { userId, userEducationId },
			update: (cache) => {
				cache.updateQuery<
					Pick<Query, 'userProfileEducationGet'>,
					QueryUserProfileEducationGetArgs
				>({ query: GET_EDUCATION, variables: { userId } }, (data) => {
					if (!data) return;
					return {
						userProfileEducationGet: {
							education: data.userProfileEducationGet.education.filter(
								(e) => e.id !== userEducationId
							),
						},
					};
				});
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Education deleted successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { deleteEducation, loading };
};
