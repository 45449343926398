import { Close, UploadOutlined } from '@mui/icons-material';
import { Box, Divider, Fab, IconButton, Stack, Typography } from '@mui/material';
import { Loading } from 'components/ui/loading';
import { useToast } from 'components/ui/toast';
import { FileInstanceInformation, SortDirection } from 'middleware-types';
import { useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { DocumentsGrid } from '../components/documents-grid';
import { FileDisplay, FileDisplayToggle } from '../components/file-display-toggle';
import { FilesSortColumn, SortOptionsDropdown } from '../components/sort-options-dropdown';
import { VersionHistoryProvider } from '../components/version-history-provider';
import { useFileInstances } from '../hooks/files/use-file-instances';
import { useSortFiles } from '../hooks/use-sort-files';
import { useUploadDocuments } from '../hooks/use-upload-documents';
import { DocumentsList } from '../mobile/documents-list';
import { useDocumentsContext } from '../documents-page';

interface MobileVersionHistoryModalProps {
	file: FileInstanceInformation;
	onClose: () => void;
}

export const MobileVersionHistoryModal = ({ file, onClose }: MobileVersionHistoryModalProps) => {
	const [sortColumn, setSortColumn] = useState(FilesSortColumn.LastUpdated);
	const [sortDirection, setSortDirection] = useState(SortDirection.Descending);
	const [displayMode, setDisplayMode] = useState(FileDisplay.List);

	const context = useDocumentsContext();
	const { files, loading } = useFileInstances(file.fileId);
	const sortedFiles = useSortFiles(files, sortColumn, sortDirection);

	const toast = useToast();

	const uploadDocuments = useUploadDocuments();
	const onDropAccepted = (files: File[]) => {
		uploadDocuments({
			file: files[0],
			relatedEntityId: context.entityId,
			accessLevel: file.accessLevel,
			updatesFileId: file.fileId,
			folderId: file.folder?.id,
			folderName: file.folder?.name,
		});
		onClose();
	};

	const onDropRejected = (rejections: FileRejection[]) => {
		toast.push(rejections[0].errors[0].message, { variant: 'error' });
	};

	const { getInputProps, open } = useDropzone({
		onDropAccepted,
		onDropRejected,
		noClick: true,
		multiple: false,
		maxFiles: 1,
		noDragEventsBubbling: true,
	});

	return (
		<VersionHistoryProvider currentInstanceId={file.id}>
			<Stack flex={1} overflow="hidden" position="relative">
				<Stack
					px={2}
					py={1}
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					spacing={1}>
					<Typography variant="h3" noWrap>
						{file.fileName} (Version History)
					</Typography>
					<IconButton onClick={onClose}>
						<Close />
					</IconButton>
				</Stack>
				<Divider />
				<Stack direction="row" p={2} spacing={2}>
					<SortOptionsDropdown
						column={sortColumn}
						setColumn={setSortColumn}
						direction={sortDirection}
						setDirection={setSortDirection}
						flex
					/>
					<FileDisplayToggle value={displayMode} setValue={setDisplayMode} />
				</Stack>
				{loading ? (
					<Stack flex={1} alignItems="center" justifyContent="center">
						<Loading />
					</Stack>
				) : (
					<Box flex={1} px={2} overflow="auto">
						{displayMode === FileDisplay.List ? (
							<DocumentsList folders={[]} files={sortedFiles} />
						) : (
							<DocumentsGrid folders={[]} files={sortedFiles} />
						)}
						<Box height={100} />
					</Box>
				)}
				{file.hasPermissionToUpdate && (
					<>
						<input {...getInputProps()} />
						<Fab
							onClick={open}
							color="primary"
							sx={{ position: 'absolute', bottom: 16, right: 16 }}>
							<UploadOutlined fontSize="large" />
						</Fab>
					</>
				)}
			</Stack>
		</VersionHistoryProvider>
	);
};
