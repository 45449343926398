import { gql, useMutation } from '@apollo/client';
import { useOrgId } from 'components/pages/org/outlet';
import { useToast } from 'components/ui/toast';
import {
	Mutation,
	MutationBillingOrganizationOptionsUpdateArgs,
	OrganizationBillingOptionsInput,
	Query,
	QueryBillingOrganizationOptionsGetArgs,
} from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { GET_ORG_BILLING_OPTIONS } from './use-org-billing-options';
import { GET_ORG_BILLING_INFO } from './use-org-billing-info';

const UPDATE_ORG_BILLING_OPTIONS = gql`
	mutation UpdateOrgBillingOptions(
		$organizationId: String!
		$options: OrganizationBillingOptionsInput!
	) {
		billingOrganizationOptionsUpdate(organizationId: $organizationId, options: $options)
	}
`;

export const useUpdateOrgBillingOptions = () => {
	const toast = useToast();
	const organizationId = useOrgId();
	const [_updateOrgBillingOptions, { loading }] = useMutation<
		Pick<Mutation, 'billingOrganizationOptionsUpdate'>,
		MutationBillingOrganizationOptionsUpdateArgs
	>(UPDATE_ORG_BILLING_OPTIONS);

	const updateOrgBillingOptions = async (options: OrganizationBillingOptionsInput) => {
		return await _updateOrgBillingOptions({
			variables: { organizationId, options },
			refetchQueries: [{ query: GET_ORG_BILLING_INFO, variables: { organizationId } }],
			update: (cache) => {
				cache.updateQuery<
					Pick<Query, 'billingOrganizationOptionsGet'>,
					QueryBillingOrganizationOptionsGetArgs
				>({ query: GET_ORG_BILLING_OPTIONS, variables: { organizationId } }, () => ({
					billingOrganizationOptionsGet: options,
				}));
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Billing options updated successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { updateOrgBillingOptions, loading };
};
