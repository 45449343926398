import {
	CloudDownloadOutlined,
	CloudUploadOutlined,
	Delete,
	InsertDriveFileOutlined,
	MoreHorizOutlined,
	SwapVertOutlined,
} from '@mui/icons-material';
import {
	Grid,
	IconButton,
	LinearProgress,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Stack,
	Typography,
} from '@mui/material';
import clsx from 'clsx';
import { format } from 'date-fns';
import { filesize } from 'filesize';
import { useField } from 'formik';
import { FileEntityType, FileInstanceInformation, SystemFileType } from 'middleware-types';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
	VirusStatusChip,
	downloadFileObject,
	getUploadProgress,
	useDownloadFileInstance,
	useUpload,
} from 'utils/fileUtils';
import { useIsMobile } from 'utils/useScreenSize';
import { useToast } from '../toast';

/**
 * The FileFieldValue type.
 */
export type FileValue = Pick<
	Partial<FileInstanceInformation>,
	'id' | 'fileName' | 'fileSize' | 'uploadedUtc' | 'virusStatus'
> & {
	uploadToken: string | undefined | null;
};

export type UploaderFieldHookProps = {
	name: string;
	relatedEntityId: string;
	relatedEntityType: FileEntityType;
	systemFileType: SystemFileType;
	setIsUploading: (isUploading: boolean) => void;
	updatesFileId?: string;
};

/**
 * Gets the uploadUrl when a file us uploaded and uploads the file to S3 blob storage.
 * @param props
 * @returns
 */

export const useUploaderField = (props: UploaderFieldHookProps) => {
	const {
		name,
		relatedEntityId,
		relatedEntityType,
		systemFileType,
		setIsUploading,
		updatesFileId,
	} = props;
	const upload = useUpload();
	const downloadFileInstance = useDownloadFileInstance();
	const [{ value }, , helpers] = useField<FileValue>(name);
	const toast = useToast();
	const [uploadProgress, setUploadProgress] = useState<number[]>(new Array(1).fill(0));
	const [file, setFile] = useState<File | undefined>(undefined);

	/**
	 * Uses the apollo client to get the uploadUrl and uploadToken for the uploaded file.
	 *
	 * @param {*} files
	 */
	const onUpload = async (files: File[], type?: FileType): Promise<void> => {
		if (files.length === 0) {
			return;
		}

		const file = files[0];
		const EVOX_MAX_FILE_SIZE_B = 512000000;
		const EVOX_MAX_IMAGE_SIZE_B = 20000000;
		// Handle special cases for EVO-X files
		if (type == FileType.Evox) {
			if (file.type.startsWith('image/') && file.size > EVOX_MAX_IMAGE_SIZE_B) {
				toast.push('EvoX images must be 20 MB or smaller.', { variant: 'error' });
				return;
			} else if (file.size > EVOX_MAX_FILE_SIZE_B) {
				toast.push('EvoX attachments must be 512 MB or smaller.', { variant: 'error' });
				return;
			}
		}

		setFile(file);
		setIsUploading(true);

		upload({
			file: file,
			relatedEntityId: relatedEntityId,
			relatedEntityType: relatedEntityType,
			systemFileType: systemFileType,
			updatesFileId: updatesFileId,
			onUploadUrlFetched: (uploadToken) => {
				helpers.setValue({
					...value,
					uploadedUtc: new Date(),
					virusStatus: undefined,
					uploadToken: uploadToken.fileUploadToken,
				});
				setUploadProgress(new Array(uploadToken.blobUploadUrls.length).fill(0));
			},
			onUploadProgress: (e, partNumber) =>
				setUploadProgress((oldValue) => {
					let newValue = [...oldValue];
					newValue[partNumber - 1] = e.progress ?? 0;
					return newValue;
				}),
			onSuccess: async () => setIsUploading(false),
			onError: (e) => {
				toast.push('An error occurred while trying to upload the file.', {
					variant: 'error',
				});
				setFile(undefined);
				setUploadProgress(new Array(1).fill(0));
				setIsUploading(false);
			},
		});
	};

	/**
	 * Uses the apollo client to get the downloadUrl of the uploaded file and downloads the url on button click.
	 *
	 * @param {*} id
	 */
	const onDownload = async (): Promise<void> => {
		if (value.id) {
			// fix this typescript hack in the future, the data for this whole component is a bit messy
			downloadFileInstance(value as any as FileInstanceInformation);
			return;
		}
		if (!file) return;
		downloadFileObject(file);
	};

	/**
	 * When the file is removed from the uploader, this sets the uploadToken to null and hides the progress bar.
	 */
	const onDelete = () => {
		setFile(undefined);
		setUploadProgress(new Array(1).fill(0));
		helpers.setValue({
			id: undefined,
			fileName: undefined,
			fileSize: undefined,
			uploadedUtc: undefined,
			virusStatus: undefined,
			uploadToken: null,
		});
	};

	return {
		onUpload,
		onDownload,
		value,
		onDelete,
		uploadProgress: getUploadProgress(uploadProgress),
		file,
	};
};

export enum FileType {
	Image = 'image',
	Document = 'document',
	Evox = 'evox',
}

type FileFieldProps = {
	name: string;
	relatedEntityId: string;
	relatedEntityType: FileEntityType;
	systemFileType: SystemFileType;
	setIsUploading: (isUploading: boolean) => void;
	type?: FileType;
	disabled?: boolean;
	required?: boolean;
	maxFiles?: number;
	allowDelete?: boolean;
	updatesFileId?: string;
};

export const FileField = (props: FileFieldProps) => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const { onUpload, onDownload, value, uploadProgress, file, onDelete } = useUploaderField({
		name: props.name,
		relatedEntityId: props.relatedEntityId,
		relatedEntityType: props.relatedEntityType,
		systemFileType: props.systemFileType,
		setIsUploading: props.setIsUploading,
		updatesFileId: props.updatesFileId,
	});

	const acceptFiles: { [key in FileType]?: { [key: string]: string[] } } = {
		[FileType.Image]: {
			'image/*': [
				'.jpg',
				'.jpeg',
				'.png',
				'.gif',
				'.bmp',
				'.tiff',
				'.ico',
				'.jfif',
				'.pjpeg',
				'.pjp',
				'.svg',
				'.webp',
				'.apng',
				'.avif',
			],
		},
		// Details of OpenAI Assistant supported types at: https://platform.openai.com/docs/assistants/tools/supported-files
		[FileType.Evox]: {
			'text/plain': ['.txt', '.md'],
			'text/html': ['.html'],
			'text/css': ['.css'],
			'text/markdown': ['.md'],
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
			'application/pdf': ['.pdf'],
			'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
			'application/csv': ['.csv'],
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
			'application/json': ['.json'],
			'application/xml': ['.xml'],
			'image/*': ['.jpg', '.jpeg', '.png', '.gif', '.webp'],
		},
	};

	const isMobile = useIsMobile();
	const toast = useToast();

	// Defaults file type and max files
	const { type, maxFiles = 1 } = props;
	const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
		onDrop: async (file) => await onUpload(file, type),
		noClick: file !== undefined || !!value.id,
		multiple: false,
		maxFiles,
		onDropRejected: (r) => {
			if (r[0].errors[0].code === 'too-many-files') {
				toast.push('Files must be uploaded one at a time', { variant: 'error' });
			}
		},
		accept: type ? acceptFiles[type] : undefined,
	});

	// Set the menu to closed in a promise allowing a then to
	// run the next method.
	const closeAnd = async () => setAnchorEl(null);

	const cannotDownload =
		value.virusStatus === undefined ||
		value.virusStatus === 'Pending' ||
		value.virusStatus === 'Infected';

	return (
		<div
			{...getRootProps({
				className: clsx(
					'bg-neutral-50 p-3 border-2 border-dashed',
					isDragActive ? 'border-green-500 text-green-500' : 'border-neutral-100',
					(file || value.id) && 'border-green-500 border-none'
				),
			})}>
			<input {...getInputProps()} />

			{file || value.id ? (
				<Grid container spacing={1} justifyContent="space-between" alignItems="center">
					<Grid item>
						<Grid container alignItems="center" spacing={1}>
							<Grid item>
								<InsertDriveFileOutlined
									onClick={!cannotDownload ? onDownload : undefined}
									fontSize="large"
									className={clsx(
										'align-middle text-neutral-900',
										!cannotDownload && 'cursor-pointer'
									)}
								/>
							</Grid>
							<Grid item>
								<span
									className={clsx(
										!cannotDownload &&
											'cursor-pointer font-medium text-green-500 underline'
									)}
									onClick={!cannotDownload ? onDownload : undefined}>
									{file ? file.name : value.fileName}
								</span>
								<div className="text-sm text-neutral-900">
									{'Uploaded on '}
									{value.uploadedUtc
										? format(new Date(value.uploadedUtc), 'MMM d, y h:mmaaa')
										: ''}
								</div>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<div>{filesize(file?.size ?? value?.fileSize ?? 0).toString()}</div>
					</Grid>
					<Grid item>
						<VirusStatusChip virusStatus={value.virusStatus} />
					</Grid>
					<Grid item>
						<IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
							<MoreHorizOutlined />
						</IconButton>
						<Menu
							open={Boolean(anchorEl)}
							anchorEl={anchorEl}
							onClose={() => setAnchorEl(null)}>
							<MenuItem
								onClick={() => closeAnd().then(onDownload)}
								disabled={cannotDownload}>
								<ListItemIcon>
									<CloudDownloadOutlined />
								</ListItemIcon>
								<ListItemText primary="Download file" />
							</MenuItem>
							<MenuItem onClick={() => closeAnd().then(open)}>
								<ListItemIcon>
									<SwapVertOutlined />
								</ListItemIcon>
								<ListItemText primary="Replace file" />
							</MenuItem>
							{props.allowDelete && (
								<MenuItem onClick={() => closeAnd().then(onDelete)}>
									<ListItemIcon>
										<Delete />
									</ListItemIcon>
									<ListItemText primary="Remove file" />
								</MenuItem>
							)}
						</Menu>
					</Grid>
				</Grid>
			) : (
				<Stack alignItems="center" textAlign="center" spacing={0.5}>
					<CloudUploadOutlined />
					<Typography lineHeight={1}>
						{isMobile
							? 'Tap to browse files.'
							: 'Drag and drop your file here, or click to browse files.'}
					</Typography>
					{props.type === FileType.Evox && (
						<>
							<Typography lineHeight={1}>
								Files must be attached one at at time. Repeat this process to attach
								multiple files.
							</Typography>
							<Typography lineHeight={1}>
								Accepted file types: .txt, docx, .pdf, .md, .pptx, .xlsx, .csv,
								.jpg, .jpeg, .png, .webp, .gif, .json, .xml, .html, .css
							</Typography>
						</>
					)}
				</Stack>
			)}
			{uploadProgress !== 0 && uploadProgress !== 100 && (
				<LinearProgress variant="determinate" value={uploadProgress} />
			)}
		</div>
	);
};
