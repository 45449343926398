import { Skeleton, Stack, Typography } from '@mui/material';
import { useEmblem } from 'components/ui/emblem/hooks';
import { ConnectionState, EmblemEntityType } from 'middleware-types';
import { useSession } from 'utils/session';
import { ComprehensiveConnectionState, ConnectionStateExtended } from '../../shared/types';
import { UserMessageButton } from '../UserMessageButton/UserMessageButton';
import { UserConnectionButton } from './UserConnectionButton';
import { useCurrentUserOrgConnection, useCurrentUserUserConnection } from './hooks';

export const UserProfileUserConnectionButtons = ({ userId }: { userId: string }) => {
	const { profile, loading } = useCurrentUserUserConnection(userId);
	if (loading || !profile) return <Skeleton variant="rounded" width={60} />;

	const { user } = useSession();
	const { emblem } = useEmblem(userId, EmblemEntityType.User);
	const connection = profile?.connection;
	let connState: ComprehensiveConnectionState =
		connection?.state ?? ConnectionStateExtended.NotConnected;

	if (connection?.state == ConnectionState.Pending && connection?.targetUserId === user.userId)
		connState = ConnectionStateExtended.IncomingRequest;

	return (
		<Stack direction="row" spacing={1.5}>
			<UserConnectionButton
				user={{
					connectionId: profile?.connection?.id,
					connectionState: connState,
					entityId: profile?.id,
					entityName: `${profile.name.firstName} ${profile.name.lastName}`,
				}}
			/>
			<UserMessageButton
				entityId={profile.id}
				entityType={EmblemEntityType.User}
				handle={emblem?.handle}
			/>
		</Stack>
	);
};

export const OrgProfileUserConnectionButtons = ({ organizationId }: { organizationId: string }) => {
	const { profile, loading } = useCurrentUserOrgConnection(organizationId);
	const { user } = useSession();
	const { emblem } = useEmblem(organizationId, EmblemEntityType.Organization);

	if (user.siteUserId) return <></>;
	if (loading || !profile) return <Skeleton variant="rounded" width={60} />;

	const connection = profile.connection;
	let connState: ComprehensiveConnectionState =
		connection?.state ?? ConnectionStateExtended.NotConnected;

	if (connection?.state == ConnectionState.Pending && connection?.targetUserId === user.userId)
		connState = ConnectionStateExtended.IncomingRequest;

	if (!emblem?.private) {
		return (
			<Stack direction="row" spacing={1.5}>
				<UserConnectionButton
					user={{
						connectionId: profile?.connection?.id,
						connectionState: connState,
						entityId: profile?.id,
						entityName: profile.displayName,
					}}
				/>
				<UserMessageButton
					entityId={profile.id}
					entityType={EmblemEntityType.Organization}
					handle={emblem?.handle}
				/>
			</Stack>
		);
	} else {
		return (
			<Typography variant="body2" textAlign="center" className="rounded bg-neutral-50 p-1">
				Private organization - connection and messaging buttons hidden
			</Typography>
		);
	}
};
