import { Close, ErrorOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Divider, IconButton, Stack, Typography } from '@mui/material';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import { useDeleteUserProfileExperiencePosition } from './hooks';

interface UserExperienceDeletePositionModalProps {
	jobTitle: string;
	onClose: () => void;
	userId: string;
	companyId: string;
	positionId: string;
}

export const UserExperienceDeletePositionModal = ({
	jobTitle,
	onClose,
	userId,
	companyId,
	positionId,
}: UserExperienceDeletePositionModalProps) => {
	const deletePosition = useDeleteUserProfileExperiencePosition(userId, companyId, positionId);

	const onSubmit = () => {
		deletePosition().then((success) => {
			if (!success) return;
			onClose();
		});
	};

	return (
		<Stack>
			<Stack
				px={2}
				py={1}
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				spacing={1}>
				<Typography variant="h3">Are you sure?</Typography>
				<IconButton onClick={onClose}>
					<Close />
				</IconButton>
			</Stack>
			<Divider />
			<Stack alignItems="center" textAlign="center" px={10} py={3}>
				<EmptyStateAvatar
					avatarProps={{ bgcolor: 'error.50' }}
					iconProps={{ color: 'error.500' }}
					icon={<ErrorOutlined />}
				/>
				<Typography variant="h1" marginTop={2} sx={{ wordBreak: 'break-word' }}>
					Delete {jobTitle}?
				</Typography>
				<Typography variant="body1" sx={{ marginTop: '4px' }}>
					This position will be deleted. If the last position in a company is deleted, the
					company itself will be deleted from your Experience display. This action cannot
					be undone.
				</Typography>
			</Stack>
			<Divider />
			<Stack direction="row" justifyContent="flex-end" px={2} py={1.5} spacing={1.5}>
				<Button size="large" variant="outlined" onClick={onClose}>
					Cancel
				</Button>
				<LoadingButton size="large" variant="contained" color="error" onClick={onSubmit}>
					Delete
				</LoadingButton>
			</Stack>
		</Stack>
	);
};
