import {
	Box,
	CardContent,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { UserEmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { PhoneNumberText } from 'components/ui/fields';
import { PeopleSearchResult } from 'middleware-types';
import { Status, statusColor } from 'pages/site/system-search/system-search';
import React, { useState } from 'react';
import { PeopleContextMenu } from './people-context-menu';

type PeopleSearchRowProps = PeopleSearchResult & {
	status: 'Active' | 'Inactive' | undefined;
	refetch: () => void;
};

export const SiteUserSystemSearchResults = ({ rows }: { rows: PeopleSearchRowProps[] }) => {
	return (
		<CardContent
			sx={{
				flex: '1 1 auto',
				overflow: 'hidden',
			}}>
			<TableContainer sx={{ maxHeight: '100%' }}>
				<Table stickyHeader aria-label="simple table">
					<TableHead>{rows.length !== 0 && <SiteUserTableHeadings />}</TableHead>
					<TableBody>
						{rows.map((row) => (
							<SiteUserSystemSearchRow {...row} key={row.userId} />
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</CardContent>
	);
};

export const SiteUserSystemSearchRow = (props: PeopleSearchRowProps): React.JSX.Element => {
	const [contextMenuOpen, setContextMenuOpen] = useState(false);

	const onContextMenu = (e) => {
		e.preventDefault();
		setContextMenuOpen(true);
	};

	return (
		<TableRow onContextMenu={(e) => onContextMenu(e)}>
			<TableCell size="small">
				{props.userId && <UserEmblemAvatar id={props.userId} />}
			</TableCell>
			<TableCell sx={{ padding: '0 16px', whiteSpace: 'nowrap' }}>
				{props.firstName}
			</TableCell>
			<TableCell sx={{ padding: '0 16px', whiteSpace: 'nowrap' }}>{props.lastName}</TableCell>
			<TableCell sx={{ padding: '0 16px', whiteSpace: 'nowrap' }}>
				<Box>{`Primary: ${props.emailAddress}`}</Box>
				<Box>
					{props.secondaryEmailAddress && `Secondary: ${props.secondaryEmailAddress}`}
				</Box>
			</TableCell>
			<TableCell sx={{ padding: '0 16px', whiteSpace: 'nowrap' }}>
				{props.cellPhone || props.homePhone ? (
					<>
						{props.cellPhone && (
							<Box>
								Cell: <PhoneNumberText number={props.cellPhone} />
							</Box>
						)}
						{props.homePhone && (
							<Box>
								Home: <PhoneNumberText number={props.homePhone} />
							</Box>
						)}
					</>
				) : (
					'---'
				)}
			</TableCell>
			<TableCell sx={{ padding: '0 16px', whiteSpace: 'nowrap' }}>
				{props.affiliation ?? '---'}
			</TableCell>
			<TableCell
				sx={{
					color: props.status ? statusColor[props.status] : 'inherit',
				}}>
				{props.status}
			</TableCell>
			<TableCell size="small">
				<PeopleContextMenu
					refetch={props.refetch}
					name={`${props.firstName} ${props.lastName}`}
					id={props.userId}
					siteUserId={props.siteUserId}
					status={props.status === 'Active' ? Status.Active : Status.Inactive}
					handle={props.handle}
					menuOpen={contextMenuOpen}
					setMenuOpen={setContextMenuOpen}
				/>
			</TableCell>
		</TableRow>
	);
};

const SiteUserTableHeadings = (): React.JSX.Element => {
	return (
		<TableRow>
			<TableCell size="small" sx={{ width: '4%' }}></TableCell>
			<TableCell sx={{ whiteSpace: 'nowrap', width: '15%' }}>First Name</TableCell>
			<TableCell sx={{ whiteSpace: 'nowrap', width: '15%' }}>Last Name</TableCell>
			<TableCell sx={{ width: '15%' }}>Email(s)</TableCell>
			<TableCell sx={{ whiteSpace: 'nowrap', width: '15%' }}>Phone Number(s)</TableCell>
			<TableCell sx={{ width: '15%' }}>Affiliation</TableCell>
			<TableCell sx={{ width: '11%' }}>Status</TableCell>
			<TableCell size="small" sx={{ width: '10%' }}></TableCell>
		</TableRow>
	);
};
