import { gql, useQuery } from '@apollo/client';
import { Query, QueryGetOrgBadgeCountsArgs, QueryGetUserBadgeCountsArgs } from 'middleware-types';
import { createContext, ReactNode, useContext } from 'react';
import { Permission } from './permissions';
import { useAnonymousSession, useSession } from './session';
import { useAssociateUser } from './useAssociateUser';

// get the badge counts for notifications, user inbound connections etc.
export const GET_USER_BADGE_COUNTS = gql`
	query GetUserBadgeCounts($userId: ID!) {
		getUserBadgeCounts(userId: $userId) {
			conversationsBadgeCount
			inboundRequestsBadgeCount
			associateInvitationsBadgeCount
			notificationsBadgeCount
		}
	}
`;

// Single hook to get badge counts for a user.
export const queryUserBadgeCounts = (userId: string) => {
	const { user } = useSession();

	const { data } = useQuery<
		Pick<Query, 'getUserBadgeCounts'> & {
			entityNetworkRequestsNotifications: Query['getUserBadgeCounts'];
		},
		QueryGetUserBadgeCountsArgs
	>(GET_USER_BADGE_COUNTS, {
		fetchPolicy: 'cache-and-network',
		pollInterval: 60000,
		skip: !!user?.siteUserId,
		variables: {
			userId,
		},
		onError: (e) => {
			// fail silently
			console.log(e);
		},
	});
	return {
		conversationsBadgeCount: data?.getUserBadgeCounts.conversationsBadgeCount,
		inboundRequestsBadgeCount: data?.getUserBadgeCounts.inboundRequestsBadgeCount,
		associateInvitationsBadgeCount: data?.getUserBadgeCounts.associateInvitationsBadgeCount,
		notificationsBadgeCount: data?.getUserBadgeCounts.notificationsBadgeCount,
	};
};

// get the badge counts for notifications, user inbound connections etc.
const GET_ORG_BADGE_COUNTS = gql`
	query GetOrgBadgeCounts(
		$organizationId: ID!
		$hasMessagesRead: Boolean!
		$hasSocialRead: Boolean!
	) {
		getOrgBadgeCounts(
			organizationId: $organizationId
			hasMessagesRead: $hasMessagesRead
			hasSocialRead: $hasSocialRead
		) {
			conversationsBadgeCount
			inboundRequestsBadgeCount
		}
	}
`;

// Single hook to get badge counts for an org.
export const useOrgBadgeCounts = (organizationId: string) => {
	const { user } = useSession();
	// Only useAssociateUser necessary, site users don't ever seen badge counts
	const { hasPermission } = useAssociateUser(organizationId);
	const hasMessagesRead = hasPermission(Permission.Org_Messages_R);
	const hasSocialRead = hasPermission(Permission.Org_Social_Network_R);

	const { data } = useQuery<
		Pick<Query, 'getOrgBadgeCounts'> & {
			entityNetworkRequestsNotifications: Query['getOrgBadgeCounts'];
		},
		QueryGetOrgBadgeCountsArgs
	>(GET_ORG_BADGE_COUNTS, {
		fetchPolicy: 'cache-and-network',
		pollInterval: 60000,
		skip: !!user.siteUserId || (!hasMessagesRead && !hasSocialRead),
		variables: {
			organizationId,
			hasMessagesRead,
			hasSocialRead,
		},
		onError: (e) => {
			// fail silently
			console.log(e);
		},
	});
	return {
		conversationsBadgeCount: data?.getOrgBadgeCounts.conversationsBadgeCount,
		inboundRequestsBadgeCount: data?.getOrgBadgeCounts.inboundRequestsBadgeCount,
	};
};

export const BADGE_COUNT_MAX = 99;

interface UserBadgeCounts {
	conversationsBadgeCount?: number;
	inboundRequestsBadgeCount?: number;
	associateInvitationsBadgeCount?: number;
	notificationsBadgeCount?: number;
}

/**
 *
 * @returns A user's badge counts.
 */
type UserBadgeCountsContextState = {
	userBadgeCounts: UserBadgeCounts;
};

const defaultBadgeCounts: UserBadgeCountsContextState = {
	userBadgeCounts: {
		conversationsBadgeCount: 0,
		inboundRequestsBadgeCount: 0,
		associateInvitationsBadgeCount: 0,
		notificationsBadgeCount: 0,
	},
};

const UserBadgeCountsContext = createContext<UserBadgeCountsContextState>(defaultBadgeCounts);

export const UserBadgeCountProvider = ({ children }: { children: ReactNode }) => {
	const { user } = useAnonymousSession();
	if (!user || user.siteUserId) {
		return (
			<UserBadgeCountsContext.Provider value={defaultBadgeCounts}>
				{children}
			</UserBadgeCountsContext.Provider>
		);
	}

	const data = queryUserBadgeCounts(user.userId);
	return (
		<UserBadgeCountsContext.Provider
			value={{
				userBadgeCounts: data,
			}}>
			{children}
		</UserBadgeCountsContext.Provider>
	);
};

export const useUserBadgeCounts = (): UserBadgeCountsContextState =>
	useContext<UserBadgeCountsContextState>(UserBadgeCountsContext);
