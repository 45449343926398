import { gql, useMutation } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import { Mutation, MutationDeleteFileArgs } from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { useDocumentsContext } from '../../documents-page';
import { updateFolderContents } from '../../utils/cache-helpers';
import { useDocumentSearchContext } from '../../components/document-search-provider';

const DELETE_FILES = gql`
	mutation DeleteFile(
		$entityType: FoldersApiEntityType!
		$entityId: String!
		$request: FileDeleteRequest!
	) {
		deleteFile(entityType: $entityType, entityId: $entityId, request: $request)
	}
`;

export const useDeleteFiles = () => {
	const toast = useToast();
	const { entityType, entityId } = useDocumentsContext();
	const { searchValue } = useDocumentSearchContext();

	const [_deleteFiles, { loading }] = useMutation<
		Pick<Mutation, 'deleteFile'>,
		MutationDeleteFileArgs
	>(DELETE_FILES);

	const deleteFiles = async (fileIds: string[], folderId: string | undefined) => {
		return await _deleteFiles({
			variables: { entityType, entityId, request: { ids: fileIds } },
			update: (cache, { data }) => {
				if (!data) return;
				updateFolderContents(
					{ cache, folderId, entityType, entityId, searchValue },
					(existing) => ({
						...existing,
						files: existing.files?.filter((f) => !fileIds.includes(f.id)),
					})
				);
			},
			onError: (e) => console.log(JSON.stringify(e)),
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				fileIds.length > 1
					? toast.push('Files deleted successfully.', { variant: 'success' })
					: toast.push('File deleted successfully.', { variant: 'success' });

				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { deleteFiles, loading };
};
