/** @type {import('tailwindcss').Config} */
import plugin from 'tailwindcss/plugin';

export default {
	content: ['./index.html', './src/**/*.{js,ts,jsx,tsx}'],
	important: '#root',
	theme: {
		fontFamily: {
			sans: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
		},
		colors: {
			transparent: 'transparent',
			current: 'currentColor',
			white: '#FFFFFF',
			navy: {
				50: '#E8F0F5',
				100: '#B7CFDF',
				200: '#95B8CF',
				300: '#6498B9',
				400: '#4684AC',
				500: '#186597',
				600: '#165C89',
				700: '#11486B',
				800: '#0D3853',
				900: '#0A2A3F',
			},
			teal: {
				50: '#ECFBFD',
				100: '#C3F2F8',
				200: '#A5ECF5',
				300: '#7CE3F1',
				400: '#63DDEE',
				500: '#3CD5EA',
				600: '#37C2D5',
				700: '#2B97A6',
				800: '#217581',
				900: '#195962',
			},
			green: {
				50: '#E7F3ED',
				100: '#B5D9C7',
				200: '#91C7AC',
				300: '#5EAD86',
				400: '#3F9D6E',
				500: '#0F854A',
				600: '#0E7943',
				700: '#0B5E35',
				800: '#084929',
				900: '#06381F',
			},
			yellow: {
				50: '#FFF8E9',
				100: '#FFEABC',
				200: '#FFDF9B',
				300: '#FFD16E',
				400: '#FFC851',
				500: '#FFBA26',
				600: '#E8A923',
				700: '#B5841B',
				800: '#8C6615',
				900: '#6B4E10',
			},
			red: {
				50: '#FCEAE8',
				100: '#F6BFB8',
				200: '#F1A095',
				300: '#EB7465',
				400: '#E75947',
				500: '#E13019',
				600: '#CD2C17',
				700: '#A02212',
				800: '#7C1A0E',
				900: '#5F140B',
			},
			neutral: {
				50: '#F5F5F6',
				100: '#E9EAEB',
				200: '#D3D4D5',
				300: '#B1B4B6',
				400: '#95999C',
				500: '#767C80',
				600: '#585F63',
				700: '#3C4449',
				800: '#1E272D',
				900: '#020c13',
			},
			black: '#000000',
		},
		maxWidth: {
			'2/5': '40%',
		},
		maxHeight: {
			'4/5': '80%',
		},
		extend: {
			fontSize: {
				0.7: '.7rem',
			},
		},
	},
	corePlugins: {
		// Remove the Tailwind CSS preflight styles so it can use Material UI's preflight instead (CssBaseline).
		preflight: false,
	},
	plugins: [
		plugin(({ matchUtilities, theme }) => {
			matchUtilities(
				{
					'animation-delay': (value) => {
						return {
							'animation-delay': value,
						};
					},
				},
				{
					values: theme('transitionDelay'),
				}
			);
		}),
	],
};
