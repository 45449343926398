import { FolderOutlined, UploadOutlined } from '@mui/icons-material';
import { Dialog, Snackbar, Stack, TableCell, TableRow } from '@mui/material';
import { FileDropOutline } from 'components/ui/file-drop-outline';
import { FolderReference } from 'middleware-types';
import { memo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FolderLink } from '../components/folder-link';
import { FolderMenuButton } from '../components/folder-menu-button';
import { useDocumentsContext } from '../documents-page';
import { useCurrentFolderId } from '../hooks/use-current-folder-id';
import { UploadModal } from '../modals/upload-modal';

interface FolderRowProps {
	folder: FolderReference;
	clearSelectedFiles?: () => void;
}

export const FolderRow = memo(function FolderRow({ folder, clearSelectedFiles }: FolderRowProps) {
	const [, setCurrentFolderId] = useCurrentFolderId();
	const { permissions } = useDocumentsContext();
	const [uploadModalOpen, setUploadModalOpen] = useState(false);
	const [contextMenuOpen, setContextMenuOpen] = useState(false);

	const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
	const onDropAccepted = (files: File[]) => {
		setFilesToUpload(files);
		setUploadModalOpen(true);
	};

	const { getRootProps, isDragActive } = useDropzone({
		onDropAccepted,
		noClick: true,
		noDragEventsBubbling: true,
		disabled: permissions.update.length === 0,
	});

	const { onClick: _onClick, ...rootProps } = getRootProps();

	const onContextMenu = (e) => {
		e.preventDefault();
		setContextMenuOpen(true);
	};

	return (
		<>
			<TableRow
				hover
				onClick={() => {
					setCurrentFolderId(folder.id);
					clearSelectedFiles && clearSelectedFiles();
				}}
				onContextMenu={(e) => onContextMenu(e)}
				sx={{ position: 'relative' }}
				{...rootProps}>
				<TableCell size="small">
					<Stack direction="row" alignItems="center" gap={1.5}>
						<FolderOutlined sx={{ color: 'neutral.500' }} />
						<Stack>
							{folder.name}
							<FolderLink folder={folder.parentFolder} />
						</Stack>
					</Stack>
				</TableCell>
				<TableCell>--</TableCell>
				<TableCell>--</TableCell>
				<TableCell>--</TableCell>
				<TableCell>--</TableCell>
				<TableCell align="right">--</TableCell>
				<TableCell padding="checkbox">
					<FolderMenuButton
						folder={folder}
						menuOpen={contextMenuOpen}
						setMenuOpen={setContextMenuOpen}
					/>
					{/** file drop outline */}
					{isDragActive && <FileDropOutline />}
					{/** snackbar */}
					<Snackbar
						open={isDragActive}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
						message={
							<Stack direction="row" alignItems="center">
								<UploadOutlined sx={{ mr: 1 }} />
								Drop file(s) to upload to &quot;{folder.name}&quot;
							</Stack>
						}
						sx={{ '&.MuiSnackbar-root': { bottom: 48 } }}
					/>
				</TableCell>
			</TableRow>
			{/** upload files modal */}
			<Dialog open={uploadModalOpen} fullWidth maxWidth="md">
				<UploadModal
					files={filesToUpload}
					folderId={folder.id}
					folderName={folder.name}
					onClose={() => setUploadModalOpen(false)}
				/>
			</Dialog>
		</>
	);
});
