import * as Icons from '@mui/icons-material';
import { EditOutlined, LocationOn } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	CardMedia,
	Chip,
	Divider,
	IconButton,
	Skeleton,
	Stack,
	Tab,
	Tabs,
	Typography,
} from '@mui/material';
import { OrgProfileUserConnectionButtons } from 'components/pages/network/user/UserConnectionButton/wrappers';
import { OrgAvatarUploadButton } from 'components/ui/emblem/avatar-upload-button';
import { OrgEmblemBanner } from 'components/ui/emblem/emblem-banner';
import { Facebook, LinkedIn, TwitterX } from 'components/ui/icons';
import { Formik, FormikProps } from 'formik';
import { useState } from 'react';
import { PageError } from 'utils/errors';
import { useAnonymousSession } from 'utils/session';
import { useIsMobile } from 'utils/useScreenSize';
import { useValidation } from 'utils/useValidation';
import { employeeCountToString, industryToString } from '../about/enum-display';
import { useOrgProfile, useUpdateOrgProfileHeader } from '../hooks';
import {
	formToOrgHeader,
	OrgHeaderFormFields,
	orgHeaderToForm,
	validateOrgHeaderForm,
} from './form-helpers';
import { OrgHeaderForm } from './header-form';
import { AnonymousSocialInteractionBar } from 'components/ui/anonymous-access/anonymous-social-interaction-bar';

interface OrgHeaderCardProps {
	orgId: string;
	canEdit: boolean;
	selectedTab: number;
	setSelectedTab: (value: number) => void;
}

export const OrgHeaderCard = ({
	orgId,
	canEdit,
	selectedTab,
	setSelectedTab,
}: OrgHeaderCardProps) => {
	const { anonymous } = useAnonymousSession();
	const { profile, loading } = useOrgProfile(orgId);

	const isMobile = useIsMobile();
	const updateOrgProfileHeader = useUpdateOrgProfileHeader();
	const [editing, setEditing] = useState(false);

	const validation = useValidation('OrganizationProfileUpdate');

	if (loading) return <OrgHeaderCardSkeleton />;
	if (!profile) {
		throw PageError;
	}

	const onSubmit = async (values: OrgHeaderFormFields) => {
		const success = await updateOrgProfileHeader(formToOrgHeader(values));
		if (success) setEditing(false);
	};

	const industry = profile.industry ? industryToString(profile.industry) : '';
	const numberOfEmployees = profile.numberOfEmployees
		? employeeCountToString(profile.numberOfEmployees)
		: '';
	const subtitleText =
		industry + (industry && numberOfEmployees ? ' · ' : '') + numberOfEmployees;

	const anyLinks =
		Boolean(profile.xProfileLink) ||
		Boolean(profile.linkedinProfileLink) ||
		Boolean(profile.facebookProfileLink) ||
		Boolean(profile.conversationInsuranceProfileLink);

	const specialties = profile.businessLines?.filter((line) => line.specialty) ?? [];

	const tabs = [
		{ label: 'About', value: 0 },
		{ label: 'Our Team', value: 1 },
		{ label: 'Lines of Business', value: 2 },
		{ label: 'Posts', value: 3 },
	];

	return (
		<Formik<OrgHeaderFormFields>
			enableReinitialize
			onSubmit={onSubmit}
			validationSchema={validation.schema}
			validate={validateOrgHeaderForm}
			initialValues={orgHeaderToForm(profile)}>
			{({
				submitForm,
				isSubmitting,
				isValid,
				dirty,
				resetForm,
			}: FormikProps<OrgHeaderFormFields>) => (
				<Card sx={{ borderRadius: { xs: 0, sm: 1 } }}>
					<OrgEmblemBanner id={profile.id} enableUpload={canEdit} />
					<Stack px={{ xs: 2, sm: 2.5 }} py={2} spacing={{ xs: 2, sm: 3 }}>
						<Stack direction="row" justifyContent="space-between">
							<Box position="relative" height={40}>
								<Box position="absolute" bottom={0} left={0}>
									<OrgAvatarUploadButton
										id={profile.id}
										size={isMobile ? 100 : undefined}
										disabled={!canEdit}
									/>
								</Box>
							</Box>
							{editing ? (
								<Stack direction="row" spacing={1.5}>
									<Button variant="outlined" onClick={() => setEditing(false)}>
										Cancel
									</Button>
									<LoadingButton
										variant="contained"
										color="primary"
										onClick={submitForm}
										disabled={!isValid || !dirty}
										loading={isSubmitting}>
										Save
									</LoadingButton>
								</Stack>
							) : (
								(anyLinks || canEdit) && (
									<Stack direction="row" spacing={1}>
										{profile.xProfileLink && (
											<IconButton href={profile.xProfileLink} target="_blank">
												<TwitterX />
											</IconButton>
										)}
										{profile.linkedinProfileLink && (
											<IconButton
												href={profile.linkedinProfileLink}
												target="_blank">
												<LinkedIn />
											</IconButton>
										)}
										{profile.facebookProfileLink && (
											<IconButton
												href={profile.facebookProfileLink}
												target="_blank">
												<Facebook />
											</IconButton>
										)}
										{profile.conversationInsuranceProfileLink && (
											<IconButton
												href={profile.conversationInsuranceProfileLink}
												target="_blank">
												<img
													src={
														'/img/conversation-insurance-color-logo.svg'
													}
													style={{ width: 24, height: 24 }}
												/>
											</IconButton>
										)}
										{canEdit && (
											<>
												{anyLinks && (
													<Divider orientation="vertical" flexItem />
												)}
												<IconButton
													onClick={() => {
														resetForm();
														setEditing(true);
													}}>
													<EditOutlined />
												</IconButton>
											</>
										)}
									</Stack>
								)
							)}
						</Stack>
						{editing ? (
							<OrgHeaderForm profile={profile} />
						) : (
							<Stack
								spacing={2}
								direction={{ xs: 'column', sm: 'row' }}
								alignItems={{ sx: 'stretch', sm: 'flex-start' }}
								justifyContent="space-between">
								<Stack spacing={1.5} overflow="hidden">
									<Box>
										<Typography variant="h2">{profile.displayName}</Typography>
										{profile.tagline && (
											<Typography
												variant="body1"
												sx={{ wordBreak: 'break-word' }}>
												{profile.tagline}
											</Typography>
										)}
										{subtitleText && (
											<Typography
												variant="body1"
												sx={{ color: 'neutral.500' }}>
												{subtitleText}
											</Typography>
										)}
									</Box>
									{profile.displayLocation && (
										<Stack direction="row" alignItems="center" spacing={0.5}>
											<LocationOn fontSize="small" color="primary" />
											<Typography variant="body1" display="inline">
												Based in{' '}
											</Typography>
											<Typography variant="h5" display="inline">
												{profile.displayLocation}
											</Typography>
										</Stack>
									)}
									{specialties.length > 0 && (
										<Stack direction="row" gap={1} flexWrap="wrap">
											{specialties.map((specialty) => (
												<Chip
													key={specialty.id}
													label={specialty.line}
													size="small"
												/>
											))}
										</Stack>
									)}
								</Stack>
								{profile.callouts && profile.callouts.length > 0 && (
									<Stack
										spacing={1.5}
										p={1.5}
										bgcolor="neutral.50"
										border="1px solid"
										borderColor="neutral.200"
										borderRadius={1}
										flexShrink={0}>
										{profile.callouts.map((callout) => {
											if (!callout.icon) return null;
											const Icon: Icons.SvgIconComponent =
												Icons[callout.icon];
											return (
												<Stack
													key={callout.id}
													direction="row"
													spacing={1}
													alignItems="center">
													<Icon htmlColor={callout.color} />
													<Typography variant="body1">
														{callout.text}
													</Typography>
												</Stack>
											);
										})}
									</Stack>
								)}
							</Stack>
						)}
						{isMobile &&
							(anonymous ? (
								<AnonymousSocialInteractionBar />
							) : (
								<OrgProfileUserConnectionButtons organizationId={profile.id} />
							))}
					</Stack>
					<Divider />
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						px={{ xs: 2, sm: 2.5 }}
						spacing={3}
						overflow="auto">
						<Tabs
							sx={{ flexShrink: 0 }}
							value={selectedTab}
							onChange={(_, newValue) => setSelectedTab(newValue)}>
							{tabs.map((tab) => (
								<Tab key={tab.value} value={tab.value} label={tab.label} />
							))}
						</Tabs>
						{!isMobile &&
							(anonymous ? (
								<AnonymousSocialInteractionBar />
							) : (
								<OrgProfileUserConnectionButtons organizationId={profile.id} />
							))}
					</Stack>
				</Card>
			)}
		</Formik>
	);
};

const OrgHeaderCardSkeleton = () => (
	<Card>
		<CardMedia
			component="img"
			alt="Profile Banner"
			image="/img/profile-banner.png"
			height="140"
		/>
		<CardHeader
			style={{ height: 'inherit' }}
			avatar={<Skeleton variant="rectangular" width="160px" height="160px" />}
		/>
		<Stack
			spacing={2}
			direction={{ xs: 'column', sm: 'row' }}
			alignItems={{ sx: 'stretch', sm: 'flex-start' }}
			justifyContent="space-between">
			<Stack spacing={2} overflow="hidden" paddingLeft={2} paddingBottom={2}>
				<Box>
					<Skeleton variant="text" height={40} width={100} />
					<Skeleton variant="text" width={200} />
				</Box>
			</Stack>
		</Stack>
		<Divider />
		<CardContent>
			<Skeleton height="2rem" width="100%" />
		</CardContent>
	</Card>
);
