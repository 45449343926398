import { useQuery } from '@apollo/client';
import { PrimaryNav } from 'components/ui/primary-nav/primary-nav';
import { SideRail } from 'components/ui/side-rail/side-rail';
import { GET_LINKED_COMPANIES } from 'pages/app/legacy/linkedcompanies/hooks';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useLoginRedirect } from 'utils/loginRedirect';
import { useAnonymousSession } from 'utils/session';

export const RootOutlet = () => {
	const { user } = useAnonymousSession();
	const { redirectUrl, setRedirectUrlTimeout } = useLoginRedirect();
	const location = useLocation();

	// make a call to the linked companies endpoint, so it's already cached when they go to the page
	useQuery(GET_LINKED_COMPANIES, { skip: !user || !user.registered || Boolean(user.siteUserId) });

	// check if user is registered
	if (user && !user.registered) {
		if (redirectUrl === null) setRedirectUrlTimeout(location.pathname + location.search);
		return <Navigate to="app/register" replace />;
	}

	// perform redirect
	if (redirectUrl !== null) {
		setRedirectUrlTimeout(null);
		return <Navigate to={redirectUrl} replace />;
	}

	return (
		<PrimaryNav>
			<SideRail>
				<Outlet />
			</SideRail>
		</PrimaryNav>
	);
};
