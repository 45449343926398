import { DeleteOutlined, EditOutlined, MoreHorizRounded } from '@mui/icons-material';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { UserExperienceCompany, UserExperiencePosition } from 'middleware-types';
import { useState } from 'react';
import { Permission } from 'utils/permissions';
import { useAnonymousSession } from 'utils/session';
import { useSiteUser } from 'utils/useSiteUser';
import { UserExperienceDeletePositionModal } from './user-experience-delete-position-modal';

/**
 * Props for the context menu.
 *
 * @interface UserExperiencePositionContextMenuProps
 */
interface UserExperiencePositionContextMenuProps {
	showUpdatePositionForm: () => void;
	position: UserExperiencePosition;
	company: UserExperienceCompany;
}

/**
 * The Row Action.
 *
 * @enum {number}
 */
enum RowAction {
	UpdatePosition,
	DeletePosition,
}

export const UserExperiencePositionContextMenu = (
	props: UserExperiencePositionContextMenuProps
): React.JSX.Element => {
	const { user } = useAnonymousSession();
	const { hasPermission } = useSiteUser();
	const [anchorEl, setAnchorEl] = useState<Element | null>(null);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void =>
		setAnchorEl(event.currentTarget);
	const [showDeletePositionModal, setShowDeletePositionModal] = useState(false);

	const onAction = (action: RowAction): void => {
		switch (action) {
			case RowAction.UpdatePosition:
				return props.showUpdatePositionForm();
			case RowAction.DeletePosition: {
				return setShowDeletePositionModal(true);
			}
		}
	};

	/**
	 * The actions possible for a context menu.
	 *  @type {*} */
	const tableActions = [
		{
			permissions: [Permission.SocialUser_Profile_U],
			action: RowAction.UpdatePosition,
			text: 'Update Position',
			icon: <EditOutlined />,
		},
		{
			permissions: [Permission.SocialUser_Profile_U],
			action: RowAction.DeletePosition,
			text: 'Delete Position',
			icon: <DeleteOutlined />,
		},
	];

	const handleRowAction = (action: RowAction): void => {
		setAnchorEl(null);
		if (onAction) {
			onAction(action);
		}
	};

	let menu = tableActions
		.filter(
			(ta) =>
				(user && !user.siteUserId && props.company.userId == user.userId) ||
				hasPermission(ta.permissions ?? [])
		)
		.map((ta, k) => {
			return (
				<MenuItem
					key={k}
					onClick={
						ta.action !== undefined ? (): void => handleRowAction(ta.action) : undefined
					}>
					<ListItemIcon>{ta.icon}</ListItemIcon>
					<ListItemText>{ta.text}</ListItemText>
				</MenuItem>
			);
		});

	return (
		<>
			{menu.length > 0 && (
				<IconButton
					aria-label="more"
					aria-controls="long-menu"
					aria-haspopup="true"
					onClick={handleClick}>
					<MoreHorizRounded />
				</IconButton>
			)}
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={(): void => setAnchorEl(null)}>
				{menu}
			</Menu>
			{props.position.jobTitle &&
				props.position.userExperienceCompanyId &&
				props.position.id && (
					<ModalOrDrawer open={showDeletePositionModal}>
						<UserExperienceDeletePositionModal
							jobTitle={props.position.jobTitle}
							onClose={() => setShowDeletePositionModal(false)}
							userId={props.company.userId}
							companyId={props.position.userExperienceCompanyId}
							positionId={props.position.id}
						/>
					</ModalOrDrawer>
				)}
		</>
	);
};
