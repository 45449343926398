import { Typography } from '@mui/material';
import { useSkillsList } from '../skills-and-endorsements/hooks';

interface SkillsDisplayProps {
	skillIds: string[];
}

export const SkillsDisplay = ({ skillIds }: SkillsDisplayProps) => {
	const { skills } = useSkillsList();
	if (skillIds.length === 0) return <></>;

	const skillNames = skillIds.map(
		(skillId) => skills.find((skill) => skill.id === skillId)?.name
	);
	const skillsString = skillNames.join(' • ');
	return (
		<Typography variant="body1" lineHeight="normal">
			<strong>Skills:</strong> {skillsString}
		</Typography>
	);
};
