import { gql, useMutation, useQuery } from '@apollo/client';
import { useOrgId } from 'components/pages/org/outlet';
import { EMBLEM_FIELDS } from 'components/ui/emblem/fragments';
import { useToast } from 'components/ui/toast';
import {
	BusinessLine,
	BusinessLineUpdate,
	Mutation,
	MutationOrganizationAboutUpdateArgs,
	MutationOrganizationBusinessLineCreateArgs,
	MutationOrganizationBusinessLineDeleteArgs,
	MutationOrganizationBusinessLineUpdateArgs,
	MutationOrganizationProfileUpdateArgs,
	OrganizationAboutUpdate,
	OrganizationProfileUpdate,
	Query,
	QueryOrganizationProfileArgs,
	QueryOrganizationTeamMembersArgs,
} from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';

/** fragments */
const BUSINESS_LINE_FIELDS = gql`
	fragment BusinessLineFields on BusinessLine {
		id
		line
		text
		specialty
	}
`;

const ORG_PROFILE_FIELDS = gql`
	${BUSINESS_LINE_FIELDS}
	fragment OrgProfileFields on OrganizationProfile {
		id
		displayName
		displayLocation
		primaryAddress {
			countryId
			address1
			address2
			municipality
			adminArea1Id
			adminArea2Id
			postalCode
		}
		about
		websiteUrl
		tagline
		xProfileLink
		linkedinProfileLink
		facebookProfileLink
		conversationInsuranceProfileLink
		industry
		numberOfEmployees
		yearFounded
		displayPhoneNumber {
			countryCode
			number
		}
		callouts {
			id
			icon
			color
			text
		}
		businessLines {
			...BusinessLineFields
		}
	}
`;

/** get profile */
const GET_ORG_PROFILE = gql`
	${ORG_PROFILE_FIELDS}
	query GetOrgProfile($organizationId: ID!) {
		organizationProfile(organizationId: $organizationId) {
			...OrgProfileFields
		}
	}
`;

export const useOrgProfile = (organizationId: string) => {
	const { data, loading, error } = useQuery<
		Pick<Query, 'organizationProfile'>,
		QueryOrganizationProfileArgs
	>(GET_ORG_PROFILE, {
		variables: { organizationId },
		onError: (e) => console.log(JSON.stringify(e)),
	});

	const profile = data?.organizationProfile;
	return { profile, loading, error };
};

/** update header info */
const UPDATE_ORG_PROFILE_HEADER = gql`
	${ORG_PROFILE_FIELDS}
	mutation UpdateOrgProfileHeader(
		$organizationId: ID!
		$organizationProfileUpdate: OrganizationProfileUpdate!
	) {
		organizationProfileUpdate(
			organizationId: $organizationId
			organizationProfileUpdate: $organizationProfileUpdate
		) {
			...OrgProfileFields
		}
	}
`;

export const useUpdateOrgProfileHeader = () => {
	const organizationId = useOrgId();
	const toast = useToast();

	const [_updateOrgProfileHeader] = useMutation<
		Pick<Mutation, 'organizationProfileUpdate'>,
		MutationOrganizationProfileUpdateArgs
	>(UPDATE_ORG_PROFILE_HEADER, { onError: (e) => console.log(JSON.stringify(e)) });

	const updateOrgProfileHeader = async (organizationProfileUpdate: OrganizationProfileUpdate) => {
		return await _updateOrgProfileHeader({
			variables: { organizationId, organizationProfileUpdate },
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Profile updated successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return updateOrgProfileHeader;
};

/** update about info */
const UPDATE_ORG_ABOUT = gql`
	${ORG_PROFILE_FIELDS}
	mutation UpdateOrgAbout(
		$organizationId: ID!
		$organizationAboutUpdate: OrganizationAboutUpdate!
	) {
		organizationAboutUpdate(
			organizationId: $organizationId
			organizationAboutUpdate: $organizationAboutUpdate
		) {
			...OrgProfileFields
		}
	}
`;

export const useUpdateOrgAbout = () => {
	const organizationId = useOrgId();
	const toast = useToast();

	const [_updateOrgAbout] = useMutation<
		Pick<Mutation, 'organizationAboutUpdate'>,
		MutationOrganizationAboutUpdateArgs
	>(UPDATE_ORG_ABOUT, { onError: (e) => console.log(JSON.stringify(e)) });

	const updateOrgAbout = async (organizationAboutUpdate: OrganizationAboutUpdate) => {
		return await _updateOrgAbout({
			variables: { organizationId, organizationAboutUpdate },
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Profile updated successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return updateOrgAbout;
};

/** create line of business */
const CREATE_LINE_OF_BUSINESS = gql`
	${ORG_PROFILE_FIELDS}
	mutation CreateLineOfBusiness($organizationId: ID!, $businessLineCreate: BusinessLineUpdate!) {
		organizationBusinessLineCreate(
			organizationId: $organizationId
			businessLineCreate: $businessLineCreate
		) {
			...OrgProfileFields
		}
	}
`;

export const useCreateLineOfBusiness = (organizationId: string) => {
	const toast = useToast();

	const [_createLineOfBusiness] = useMutation<
		Pick<Mutation, 'organizationBusinessLineCreate'>,
		MutationOrganizationBusinessLineCreateArgs
	>(CREATE_LINE_OF_BUSINESS);

	const createLineOfBusiness = async (businessLineCreate: BusinessLineUpdate) => {
		return await _createLineOfBusiness({ variables: { organizationId, businessLineCreate } })
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Line of Business created successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return createLineOfBusiness;
};

/** update line of business */
const UPDATE_LINE_OF_BUSINESS = gql`
	${BUSINESS_LINE_FIELDS}
	mutation UpdateLineOfBusiness(
		$organizationId: ID!
		$lineId: ID!
		$businessLineCreate: BusinessLineUpdate!
	) {
		organizationBusinessLineUpdate(
			organizationId: $organizationId
			lineId: $lineId
			businessLineCreate: $businessLineCreate
		) {
			...BusinessLineFields
		}
	}
`;

export const useUpdateLineOfBusiness = (organizationId: string) => {
	const toast = useToast();

	const [_updateLineOfBusiness] = useMutation<
		Pick<Mutation, 'organizationBusinessLineUpdate'>,
		MutationOrganizationBusinessLineUpdateArgs
	>(UPDATE_LINE_OF_BUSINESS);

	const updateLineOfBusiness = async (lineId: string, businessLineCreate: BusinessLineUpdate) => {
		return await _updateLineOfBusiness({
			variables: { organizationId, lineId, businessLineCreate },
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Line of Business updated successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return updateLineOfBusiness;
};

export const useUpdateSpecialty = (businessLine: BusinessLine) => {
	const toast = useToast();
	const organizationId = useOrgId();

	const [updateLineOfBusiness, { loading }] = useMutation<
		Pick<Mutation, 'organizationBusinessLineUpdate'>,
		MutationOrganizationBusinessLineUpdateArgs
	>(UPDATE_LINE_OF_BUSINESS, { onError: (e) => console.log(JSON.stringify(e)) });

	const updateSpecialty = async () => {
		return await updateLineOfBusiness({
			variables: {
				organizationId,
				lineId: businessLine.id,
				businessLineCreate: {
					line: businessLine.line,
					text: businessLine.text,
					specialty: !businessLine.specialty,
				},
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { updateSpecialty, loading };
};

/** delete line of business */
const DELETE_LINE_OF_BUSINESS = gql`
	mutation DeleteLineOfBusiness($organizationId: ID!, $lineId: ID!) {
		organizationBusinessLineDelete(organizationId: $organizationId, lineId: $lineId)
	}
`;

export const useDeleteLineOfBusiness = (organizationId: string) => {
	const toast = useToast();

	const [_deleteLineOfBusiness, { loading }] = useMutation<
		Pick<Mutation, 'organizationBusinessLineDelete'>,
		MutationOrganizationBusinessLineDeleteArgs
	>(DELETE_LINE_OF_BUSINESS);

	const deleteLineOfBusiness = async (lineId: string) => {
		return await _deleteLineOfBusiness({
			variables: { organizationId, lineId },
			update: (cache) => {
				cache.evict({ id: `BusinessLine:${lineId}` });
				cache.gc();
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Line of Business deleted successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { deleteLineOfBusiness, loading };
};

/** team */
export const GET_TEAM_MEMBERS = gql`
	${EMBLEM_FIELDS}
	query GetTeamMembers($organizationId: ID!) {
		organizationTeamMembers(organizationId: $organizationId) {
			items {
				...EmblemFields
			}
		}
	}
`;

export const useOrgTeam = (organizationId: string) => {
	const { data, loading } = useQuery<
		Pick<Query, 'organizationTeamMembers'>,
		QueryOrganizationTeamMembersArgs
	>(GET_TEAM_MEMBERS, {
		variables: { organizationId },
		onError: (e) => console.log(JSON.stringify(e)),
	});

	const teamMembers = data?.organizationTeamMembers.items ?? [];
	return { teamMembers, loading };
};
