import { OpenInNewOutlined, ReorderOutlined } from '@mui/icons-material';
import { Box, Button, Card, Dialog, Divider, Drawer, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useIsMobile } from 'utils/useScreenSize';
import { useOrgTeam } from '../hooks';
import { OrderTeamMembersModal } from './order-team-members-modal';
import { TeamMemberCard } from './team-member-card';
import { TeamMemberSkeleton } from './team-member-skeleton';

interface TeamCardProps {
	orgId: string;
	canEditTeamMembers: boolean;
	canReorderTeamMembers: boolean;
}

export const TeamCard = ({ orgId, canEditTeamMembers, canReorderTeamMembers }: TeamCardProps) => {
	const { teamMembers, loading } = useOrgTeam(orgId);
	const [orderTeamMembersModalOpen, setOrderTeamMembersModalOpen] = useState(false);
	const isMobile = useIsMobile();

	return (
		<>
			<Card sx={{ borderRadius: { xs: 0, sm: 1 } }}>
				<Stack
					direction="row"
					px={{ xs: 2, sm: 2.5 }}
					py={1}
					alignItems="center"
					justifyContent="space-between">
					<Typography variant="h3">Our Team</Typography>
					<Stack direction="row" spacing={1} height={40}>
						{canReorderTeamMembers && teamMembers.length > 1 && (
							<Button
								variant="outlined"
								startIcon={<ReorderOutlined />}
								onClick={() => setOrderTeamMembersModalOpen(true)}>
								Set Display Order
							</Button>
						)}
						{canEditTeamMembers && (
							<Button
								variant="outlined"
								startIcon={<OpenInNewOutlined />}
								href="../associates">
								Manage Associates
							</Button>
						)}
					</Stack>
				</Stack>
				<Divider />
				<Box
					p={{ xs: 2, sm: 2.5 }}
					display="grid"
					gridTemplateColumns={{
						xs: '1fr',
						md: 'repeat(2, 1fr)',
					}}
					gridAutoRows="1fr"
					gap={2.5}>
					{loading ? (
						<TeamMemberSkeleton />
					) : (
						teamMembers.map((member) => (
							<TeamMemberCard key={member.id} member={member} />
						))
					)}
				</Box>
			</Card>
			{isMobile ? (
				<Drawer open={orderTeamMembersModalOpen} anchor="bottom">
					<OrderTeamMembersModal
						onClose={() => setOrderTeamMembersModalOpen(false)}
						orgId={orgId}
					/>
				</Drawer>
			) : (
				<Dialog open={orderTeamMembersModalOpen}>
					<OrderTeamMembersModal
						onClose={() => setOrderTeamMembersModalOpen(false)}
						orgId={orgId}
					/>
				</Dialog>
			)}
		</>
	);
};
