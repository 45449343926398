import { gql, useQuery } from '@apollo/client';
import { Query } from 'middleware-types';

const GET_BILLING_CUSTOMER_SUMMARIES = gql`
	query GetBillingCustomerSummaries {
		billingCustomerSummariesGet {
			totalAmountBilled
			totalBalanceDue
			invoiceSummaries {
				organizationName
				organizationHandle
				status
				amountBilled
				balanceDue
				requiresAttention
				stripeCustomerUrl
			}
		}
	}
`;

export const useBillingCustomerSummaries = () => {
	const { data, loading } = useQuery<Pick<Query, 'billingCustomerSummariesGet'>>(
		GET_BILLING_CUSTOMER_SUMMARIES
	);

	const summaries = data?.billingCustomerSummariesGet.invoiceSummaries ?? [];
	const totalAmountBilled = data?.billingCustomerSummariesGet.totalAmountBilled ?? 0;
	const totalBalanceDue = data?.billingCustomerSummariesGet.totalBalanceDue ?? 0;
	return { summaries, totalAmountBilled, totalBalanceDue, loading };
};
