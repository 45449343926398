import { EditOutlined, PasswordOutlined } from '@mui/icons-material';
import { Grid, IconButton, Stack, Typography } from '@mui/material';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { useState } from 'react';
import { ChangePasswordModal } from './change-password-modal';

interface PasswordSectionProps {
	canEdit: boolean;
}

export const PasswordSection = ({ canEdit }: PasswordSectionProps) => {
	const [modalOpen, setModalOpen] = useState(false);
	return (
		<>
			<Grid container item xs={12} alignItems="center">
				<Grid item xs={12} sm={4}>
					<Stack direction="row" spacing={{ xs: 2, sm: 3 }}>
						<PasswordOutlined fontSize="small" />
						<Typography variant="h5">Password</Typography>
					</Stack>
				</Grid>
				<Grid item xs>
					<Typography>********</Typography>
				</Grid>
				{canEdit && (
					<Grid item xs="auto">
						<IconButton onClick={() => setModalOpen(true)}>
							<EditOutlined />
						</IconButton>
					</Grid>
				)}
			</Grid>
			{/** change password modal */}
			<ModalOrDrawer open={modalOpen}>
				<ChangePasswordModal onClose={() => setModalOpen(false)} />
			</ModalOrDrawer>
		</>
	);
};
