import { Close } from '@mui/icons-material';
import { Box, Button, ButtonProps, IconButton, Link, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLoginRedirect } from 'utils/loginRedirect';
import { ModalOrDrawer } from '../modals/modal-or-drawer';

/**
 * A button that, when clicked, shows a modal that prompts the user to log in.
 */

export const LoginPromptButton = (props: ButtonProps) => {
	const location = useLocation();
	const { setRedirectUrl } = useLoginRedirect();

	const onNavigate = () => {
		setRedirectUrl(location.pathname + location.search);
	};

	const [open, setOpen] = useState(false);

	return (
		<>
			<Button {...props} onClick={() => setOpen(true)} />
			<ModalOrDrawer open={open} dialogProps={{ maxWidth: 'xs' }}>
				<Stack px={5} py={3} spacing={2} alignItems="center" position="relative">
					<Box
						component="img"
						src="/img/evolve-logo.svg"
						alt="FTEvolve"
						height={80}
						width={80}
					/>
					<Typography variant="h2">Log into Evolve to continue.</Typography>
					<Button
						variant="contained"
						color="primary"
						fullWidth
						href="/auth/login"
						onClick={onNavigate}>
						Log In
					</Button>
					<Box>
						<Typography variant="body1" color="GrayText" display="inline">
							Don&apos;t have an account yet?{' '}
						</Typography>
						<Link display="inline" href="/auth/sign-up" onClick={onNavigate}>
							Sign up.
						</Link>
						<IconButton
							sx={{ position: 'absolute', top: 8, right: 8 }}
							onClick={() => setOpen(false)}>
							<Close />
						</IconButton>
					</Box>
				</Stack>
			</ModalOrDrawer>
		</>
	);
};
