import { Add, ListOutlined, MoreHoriz, Reorder } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	Card,
	Divider,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { Permission } from 'utils/permissions';
import { useAnonymousSession } from 'utils/session';
import { useIsSiteUser } from 'utils/useIsSiteUser';
import { usePopupMenu } from 'utils/usePopupMenu';
import { useSiteUser } from 'utils/useSiteUser';
import { ProfileCardEmptyState } from '../shared/profile-card-empty-state';
import { AddSkillForm } from './add-skill-form';
import { useReorderSkills, useUserSkills } from './hooks';
import { ReorderSkills } from './reorder-skills';
import { SkillListItem } from './skill-list-item';

interface SkillsAndEndorsementsCardProps {
	userId: string;
}

export const SkillsAndEndorsementsCard = ({ userId }: SkillsAndEndorsementsCardProps) => {
	const { skills, loading } = useUserSkills(userId);
	const { reorderSkills, loading: reorderLoading } = useReorderSkills(userId);
	const [cardState, setCardState] = useState<'view' | 'reorder' | 'add'>('view');
	const { anchorEl, menuOpen, openMenu, closeMenu } = usePopupMenu();

	const { user } = useAnonymousSession();
	const { hasPermission } = useSiteUser();
	const isSiteUser = useIsSiteUser();

	const canEdit =
		(user && user.userId === userId) || hasPermission(Permission.SocialUser_Profile_U);
	const canEndorse = user !== undefined && user.userId !== userId && !isSiteUser;

	const [newSkillsOrder, setNewSkillsOrder] = useState(skills);
	const saveNewOrder = () => {
		const displayOrder = newSkillsOrder.map((skill, index) => ({
			// displayOrder is one indexed
			displayOrder: index + 1,
			userSkillId: skill.id,
		}));
		reorderSkills(displayOrder).then((success) => {
			if (success) setCardState('view');
		});
	};

	if (loading) return <Skeleton variant="rounded" height={100} />;
	if (!canEdit && skills.length === 0) return <></>;

	return (
		<Card sx={{ borderRadius: { xs: 0, sm: 1 } }}>
			<Stack
				px={2.5}
				py={1}
				direction="row"
				alignItems="center"
				justifyContent="space-between">
				<Typography variant="h3">Skills & Endorsements</Typography>
				{cardState === 'reorder' ? (
					<Stack height={40} direction="row" alignItems="center" spacing={1}>
						<Button variant="outlined" onClick={() => setCardState('view')}>
							Cancel
						</Button>
						<LoadingButton
							variant="contained"
							color="primary"
							loading={reorderLoading}
							onClick={saveNewOrder}>
							Save
						</LoadingButton>
					</Stack>
				) : cardState === 'view' && canEdit ? (
					<IconButton onClick={openMenu}>
						<MoreHoriz />
					</IconButton>
				) : (
					<Box height={40} />
				)}
			</Stack>
			<Divider />
			{cardState === 'reorder' ? (
				<ReorderSkills
					skills={newSkillsOrder}
					setSkills={setNewSkillsOrder}
					userId={userId}
				/>
			) : skills.length > 0 ? (
				<Box px={2.5} py={1.75} sx={{ columnCount: 3, columnGap: 4.5 }}>
					{skills.map((skill) => (
						<SkillListItem
							key={skill.id}
							skill={skill}
							userId={userId}
							canEdit={canEdit}
							canEndorse={canEndorse}
						/>
					))}
				</Box>
			) : (
				<ProfileCardEmptyState
					icon={<ListOutlined />}
					primaryText="No skills yet."
					secondaryText="Add skills and receive endorsements for them here."
				/>
			)}
			{cardState === 'add' && (
				<>
					<Divider />
					<AddSkillForm
						userId={userId}
						existingSkills={skills}
						onClose={() => setCardState('view')}
					/>
				</>
			)}
			{/** menu */}
			<Menu open={menuOpen} anchorEl={anchorEl} onClose={closeMenu} onClick={closeMenu}>
				<MenuItem onClick={() => setCardState('add')}>
					<ListItemIcon>
						<Add />
					</ListItemIcon>
					<ListItemText>Add Skill</ListItemText>
				</MenuItem>
				{skills.length > 0 && (
					<MenuItem
						onClick={() => {
							setNewSkillsOrder(skills);
							setCardState('reorder');
						}}>
						<ListItemIcon>
							<Reorder />
						</ListItemIcon>
						<ListItemText>Reorder Skills</ListItemText>
					</MenuItem>
				)}
			</Menu>
		</Card>
	);
};
