import { gql, useMutation, useQuery } from '@apollo/client';
import { CheckOutlined, ClearOutlined, WorkOutlineRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Avatar,
	Box,
	Button,
	Card,
	CardActionArea,
	CardContent,
	CardHeader,
	Container,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import { AFFILIATED_ORGS } from 'components/pages/org-dropdown';
import { ModalButtonsContainer } from 'components/ui/modals/modal-buttons-container';
import { ModalContent } from 'components/ui/modals/modal-content';
import { ModalHeader } from 'components/ui/modals/modal-header';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { PageContent, PageTitle } from 'components/ui/page';
import { useToast } from 'components/ui/toast';
import {
	AssociateInvitation,
	Mutation,
	MutationOrganizationAssociateInvitationAcceptArgs,
	MutationOrganizationAssociateInvitationDeclineArgs,
	Query,
} from 'middleware-types';
import * as React from 'react';
import { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { refreshToken, useSession } from 'utils/session';
import { isDemo } from 'utils/utils';
import LinkedLegacyUserList from '../../../../components/pages/linked-legacy-user';

/**
 * Root homepage.
 */
export const OldLinkedCompanies: React.FC = () => {
	const { user } = useSession();

	if (user.siteUserId) return <Navigate to="/site" replace />;

	return (
		<>
			<PageTitle title="Old Linked Companies" />
			<PageContent>
				<Container maxWidth="sm">
					<Stack spacing={4} maxWidth="sm">
						<Card>
							<CardActionArea
								component="a"
								href="https://evolution.global"
								target="_blank">
								<CardContent>
									<Stack direction="row" spacing={2} alignItems="center">
										<Box
											height={100}
											component="img"
											src="https://uploads-ssl.webflow.com/64d3a88512e31ee09c41ef59/653ad49e139feb3ecaea5769_Untitled%20design%20(75).png"
										/>
										<Typography>
											Visit our new website for the latest news and updates in
											insurance tech and claims processing.
										</Typography>
									</Stack>
								</CardContent>
							</CardActionArea>
						</Card>
						<Card>
							<CardActionArea
								component="a"
								href="https://www.evolution.global/blogs/the-state-of-the-company"
								target="_blank">
								<CardContent>
									<Stack direction="row" spacing={2} alignItems="center">
										<Box
											height={100}
											component="img"
											src="https://uploads-ssl.webflow.com/64d3a88512e31ee09c41ef59/653ad6f35d2befc1a3d0f5c3_Untitled%20design%20(76).png"
										/>
										<Typography>
											Read our CEO&apos;s article on what&apos;s coming next
											in our journey to revolutionize insurance tech.
										</Typography>
									</Stack>
								</CardContent>
							</CardActionArea>
						</Card>
						{isDemo() && (
							<Card>
								<CardHeader title="Standard User Dashboard"></CardHeader>
								<CardContent>
									<Stack spacing={4}>
										<Button
											color="primary"
											variant="contained"
											component={Link}
											to="/orgs/new"
											sx={{ alignSelf: 'flex-start' }}>
											Create Organization
										</Button>
										<Stack spacing={1}>
											<Typography variant="h3">
												Organization Invitations
											</Typography>
											<AssociatesPlaceholder />
										</Stack>
									</Stack>
								</CardContent>
							</Card>
						)}
						<LinkedLegacyUserList />
					</Stack>
				</Container>
			</PageContent>
		</>
	);
};

const ASSOCIATE_INVITATIONS = gql`
	query associateInvitations {
		associateInvitations {
			invitations {
				id
				organizationId
				organizationName
				avatarFile {
					fileId
					file {
						id
						currentInstance {
							id
							cdnUrl
						}
					}
				}
			}
		}
	}
`;

export const useAssociateInvitations = () => {
	const toast = useToast();
	const { data, loading, error } = useQuery<Pick<Query, 'associateInvitations'>>(
		ASSOCIATE_INVITATIONS,
		{
			fetchPolicy: 'cache-and-network',
			onError: () => {
				toast.push('Unable to load invitations', {
					variant: 'error',
				});
			},
		}
	);

	return { invitations: data?.associateInvitations.invitations, loading, error };
};

const ACCEPT_ASSOCIATE_INVITATION = gql`
	mutation organizationAssociateInvitationAccept($invitationId: String!) {
		organizationAssociateInvitationAccept(invitationId: $invitationId)
	}
`;

export const useAcceptAssociateInvitation = () => {
	const toast = useToast();
	const [_acceptInvitation, { loading }] = useMutation<
		Pick<Mutation, 'organizationAssociateInvitationAccept'>,
		MutationOrganizationAssociateInvitationAcceptArgs
	>(ACCEPT_ASSOCIATE_INVITATION, {
		refetchQueries: [ASSOCIATE_INVITATIONS, AFFILIATED_ORGS],
		awaitRefetchQueries: true,
		update: (cache) => {
			cache.evict({
				id: 'ROOT_QUERY',
				fieldName: 'getUserBadgeCounts',
			});

			cache.gc();
		},
	});

	const acceptInvitation = (inv: AssociateInvitation) => {
		return _acceptInvitation({
			variables: {
				invitationId: inv.id,
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push(`Invitation accepted. Welcome to ${inv.organizationName}!`, {
					variant: 'success',
				});
				refreshToken();
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { acceptInvitation, loading };
};

const DECLINE_ASSOCIATE_INVITATION = gql`
	mutation organizationAssociateInvitationDecline($invitationId: String!) {
		organizationAssociateInvitationDecline(invitationId: $invitationId)
	}
`;

export const useDeclineAssociateInvitation = () => {
	const toast = useToast();
	const [_declineInvitation, { loading }] = useMutation<
		Pick<Mutation, 'organizationAssociateInvitationDecline'>,
		MutationOrganizationAssociateInvitationDeclineArgs
	>(DECLINE_ASSOCIATE_INVITATION, {
		refetchQueries: [ASSOCIATE_INVITATIONS],
		awaitRefetchQueries: true,
		update: (cache) => {
			cache.evict({
				id: 'ROOT_QUERY',
				fieldName: 'getUserBadgeCounts',
			});

			cache.gc();
		},
	});

	const declineInvitation = (inv: AssociateInvitation) => {
		return _declineInvitation({
			variables: {
				invitationId: inv.id,
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push(`Invitation for ${inv?.organizationName} declined.`, {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { declineInvitation, loading };
};

const AssociatesPlaceholder = () => {
	const { invitations, loading } = useAssociateInvitations();
	if (loading) return <OrgListSkeleton />;
	if (invitations && invitations.length > 0) {
		return (
			<List>
				{invitations.map((inv) => (
					<InvitationListItem key={inv.id} inv={inv} />
				))}
			</List>
		);
	}

	return <Typography variant="subtitle1">You have no pending invitations.</Typography>;
};

const InvitationListItem = ({ inv }: { inv: AssociateInvitation }) => {
	const { acceptInvitation, loading: acceptLoading } = useAcceptAssociateInvitation();
	const { declineInvitation, loading: declineLoading } = useDeclineAssociateInvitation();

	const [acceptModalOpen, setAcceptModalOpen] = useState(false);
	const [declineModalOpen, setDeclineModalOpen] = useState(false);

	return (
		<ListItem>
			<ListItemAvatar>
				<Avatar src={inv.avatarFile?.file?.currentInstance?.cdnUrl}>
					<WorkOutlineRounded />
				</Avatar>
			</ListItemAvatar>
			<ListItemText primary={inv.organizationName}></ListItemText>
			<IconButton onClick={() => setAcceptModalOpen(true)}>
				<CheckOutlined />
			</IconButton>
			<IconButton onClick={() => setDeclineModalOpen(true)}>
				<ClearOutlined />
			</IconButton>
			{/** accept modal */}
			<ModalOrDrawer open={acceptModalOpen}>
				<ModalHeader title="Accept Invitation" onClose={() => setAcceptModalOpen(false)} />
				<ModalContent>
					<Box component="img" src="/img/site-user-registration.svg" />
					<Box>
						<Typography variant="body1">
							Are you sure you want to accept {inv.organizationName}&apos;s
							invitation?
						</Typography>
						<Typography variant="body1">
							By accepting this invitation, you are sharing your profile information -
							allowing authorized Organization associates to see this info as if you
							were connected to them.
						</Typography>
					</Box>
				</ModalContent>
				<ModalButtonsContainer>
					<LoadingButton
						variant="contained"
						color="primary"
						loading={acceptLoading}
						onClick={() =>
							acceptInvitation(inv).then((success) => {
								if (success) setAcceptModalOpen(false);
							})
						}>
						Accept
					</LoadingButton>
				</ModalButtonsContainer>
			</ModalOrDrawer>
			{/** decline modal */}
			<ModalOrDrawer open={declineModalOpen}>
				<ModalHeader
					title="Decline Invitation"
					onClose={() => setDeclineModalOpen(false)}
				/>
				<ModalContent>
					<Typography variant="body1">
						Are you sure you want to decine {inv.organizationName}&apos;s invitation?
					</Typography>
				</ModalContent>
				<ModalButtonsContainer>
					<LoadingButton
						variant="contained"
						color="primary"
						loading={declineLoading}
						onClick={() =>
							declineInvitation(inv).then((success) => {
								if (success) setDeclineModalOpen(false);
							})
						}>
						Decline
					</LoadingButton>
				</ModalButtonsContainer>
			</ModalOrDrawer>
		</ListItem>
	);
};

const OrgListSkeleton = () => (
	<List>
		{Array(5)
			.fill(null)
			.map((_, i) => (
				<Skeleton key={i}>
					<ListItemButton>
						<ListItemText primary="Organization"></ListItemText>
					</ListItemButton>
				</Skeleton>
			))}
	</List>
);
