import { Avatar, Stack } from '@mui/material';
import { EducationForm } from './education-form';
import { SchoolOutlined } from '@mui/icons-material';
import { useAddEducation } from './hooks';

interface NewEducationFormProps {
	userId: string;
	onClose: () => void;
}

export const NewEducationForm = ({ userId, onClose }: NewEducationFormProps) => {
	const { addEducation } = useAddEducation(userId);
	return (
		<Stack direction="row" alignItems="flex-start" p={2.5} spacing={1.5}>
			<Avatar variant="rounded" sx={{ height: 60, width: 60, fontSize: 30 }}>
				<SchoolOutlined sx={{ fontSize: '1.25em' }} />
			</Avatar>
			<EducationForm onSubmitProp={addEducation} onClose={onClose} />
		</Stack>
	);
};
