import {
	Button,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import {
	useCognitoDevices,
	useForgetCognitoDevicesMutation,
} from 'components/pages/user/account/security/hooks';
import { ConfirmationModalContent } from 'components/ui/modals/confirmation-modal-content';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { useState } from 'react';

interface DevicesTableProps {
	userId: string;
	canEdit: boolean;
}

export const DevicesTable = ({ userId, canEdit }: DevicesTableProps) => {
	const { devices, loading } = useCognitoDevices(userId);
	const { forgetCognitoDevices } = useForgetCognitoDevicesMutation(userId);
	const [modalOpen, setModalOpen] = useState(false);

	if (loading) {
		return <Skeleton variant="rounded" height={24} />;
	}

	if (!devices || devices.length < 1) {
		return <Typography>No Devices To List</Typography>;
	}

	return (
		<>
			<TableContainer sx={{ maxHeight: '80%' }}>
				<Table stickyHeader aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell size="small" width="4%"></TableCell>
							<TableCell width="15%">Name</TableCell>
							<TableCell width="15%">Last IP Used</TableCell>
							<TableCell width="15%">Last Login Date</TableCell>
							<TableCell width="20%">Remembered</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{devices.map((device, i) => {
							return (
								<TableRow key={i}>
									<TableCell size="small" width="4%"></TableCell>
									<TableCell width="15%">{device.name}</TableCell>
									<TableCell width="15%">{device.lastIpUsed}</TableCell>
									<TableCell width="15%">
										{new Date(device.lastAuthenticatedUtc).toString()}
									</TableCell>
									<TableCell width="20%">
										{device.remembered ? 'Yes' : 'No'}
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			{canEdit && (
				<Button variant="outlined" onClick={() => setModalOpen(true)}>
					Clear List and Forget All Devices
				</Button>
			)}
			<ModalOrDrawer open={modalOpen}>
				<ConfirmationModalContent
					variant="destructive"
					title="Forget Devices"
					primaryText="Are you sure?"
					secondaryText="Do you really want to forget all devices? This process cannot be undone and will remove all stored device information."
					onSubmit={forgetCognitoDevices}
					onClose={() => setModalOpen(false)}
					confirmText="Forget Devices"
				/>
			</ModalOrDrawer>
		</>
	);
};
