import { gql, useMutation, useQuery } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import {
	Mutation,
	MutationUserProfileSkillAddArgs,
	MutationUserProfileSkillDeleteArgs,
	MutationUserProfileSkillsEndorseArgs,
	MutationUserProfileSkillsEndorsementVisibilityArgs,
	MutationUserProfileSkillSetDisplayOrderArgs,
	MutationUserProfileSkillsUpdateArgs,
	Query,
	QueryUserProfileSkillEndorsementsGetArgs,
	QueryUserProfileSkillPlacesUsedGetArgs,
	QueryUserProfileSkillsGetArgs,
	UserSkillCreate,
	UserSkillDisplayOrder,
	UserSkillPlacesUsedUpdateRequest,
} from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { GET_EDUCATION } from '../education/hooks';
import { GET_USER_EXPERIENCE } from '../user-experience/hooks';

/** fragments */
const SKILL_FIELDS = gql`
	fragment SkillFields on Skill {
		id
		name
	}
`;

const USER_SKILL_FIELDS = gql`
	${SKILL_FIELDS}
	fragment UserSkillFields on UserSkill {
		id
		skillId
		displayOrder
		endorsementCount
		endorsedByCurrentUser
		skill {
			...SkillFields
		}
	}
`;

const PLACES_SKILL_USED_FIELDS = gql`
	fragment PlacesSkillUsedFields on UserSkillPlacesUsedResponse {
		placesUsed {
			id
			userId
			userExperienceId
			# userExperienceCompany
			userEducationId
			# userEducation
		}
	}
`;

/** get list of skills */
const GET_SKILLS_LIST = gql`
	${SKILL_FIELDS}
	query GetSkillsList {
		skills {
			...SkillFields
		}
	}
`;

export const useSkillsList = () => {
	const { data, loading } = useQuery<Pick<Query, 'skills'>>(GET_SKILLS_LIST, {
		onError: (e) => console.log(JSON.stringify(e)),
	});
	return { skills: data?.skills ?? [], loading };
};

/** get user skills */
const GET_USER_SKILLS = gql`
	${USER_SKILL_FIELDS}
	query GetUserSkills($userId: ID!) {
		userProfileSkillsGet(userId: $userId) {
			skills {
				...UserSkillFields
			}
		}
	}
`;

export const useUserSkills = (userId: string) => {
	const { data, loading } = useQuery<
		Pick<Query, 'userProfileSkillsGet'>,
		QueryUserProfileSkillsGetArgs
	>(GET_USER_SKILLS, { variables: { userId } });
	const skills = data?.userProfileSkillsGet.skills ?? [];
	const orderedSkills = [...skills].sort((a, b) => a.displayOrder - b.displayOrder);
	return { skills: orderedSkills, loading };
};

/** add skill */
const ADD_SKILL = gql`
	${USER_SKILL_FIELDS}
	mutation AddSkill($userId: ID!, $request: UserSkillCreate!) {
		userProfileSkillAdd(userId: $userId, request: $request) {
			...UserSkillFields
		}
	}
`;

export const useAddSkill = (userId: string) => {
	const toast = useToast();
	const [_addSkill, { loading }] = useMutation<
		Pick<Mutation, 'userProfileSkillAdd'>,
		MutationUserProfileSkillAddArgs
	>(ADD_SKILL, {
		awaitRefetchQueries: true,
		refetchQueries: [
			{ query: GET_EDUCATION, variables: { userId } },
			{ query: GET_USER_EXPERIENCE, variables: { userId } },
		],
	});

	const addSkill = async (request: UserSkillCreate) => {
		return await _addSkill({
			variables: { userId, request },
			update: (cache, { data }) => {
				if (!data) return;
				cache.updateQuery<
					Pick<Query, 'userProfileSkillsGet'>,
					QueryUserProfileSkillsGetArgs
				>({ query: GET_USER_SKILLS, variables: { userId } }, (queryData) => {
					if (!queryData) return;
					return {
						userProfileSkillsGet: {
							skills: [
								...queryData.userProfileSkillsGet.skills,
								data.userProfileSkillAdd,
							],
						},
					};
				});
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Skill added successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { addSkill, loading };
};

/** delete skill */
const DELETE_SKILL = gql`
	mutation DeleteSkill($userId: ID!, $userSkillId: ID!) {
		userProfileSkillDelete(userId: $userId, userSkillId: $userSkillId)
	}
`;

export const useDeleteSkill = (userId: string) => {
	const toast = useToast();
	const [_deleteSkill, { loading }] = useMutation<
		Pick<Mutation, 'userProfileSkillDelete'>,
		MutationUserProfileSkillDeleteArgs
	>(DELETE_SKILL);

	const deleteSkill = async (userSkillId: string) => {
		return await _deleteSkill({
			variables: { userId, userSkillId },
			update: (cache) => {
				cache.updateQuery<
					Pick<Query, 'userProfileSkillsGet'>,
					QueryUserProfileSkillsGetArgs
				>({ query: GET_USER_SKILLS, variables: { userId } }, (data) => {
					if (!data) return;
					return {
						userProfileSkillsGet: {
							skills: data.userProfileSkillsGet.skills.filter(
								(skill) => skill.id !== userSkillId
							),
						},
					};
				});
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Skill deleted successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { deleteSkill, loading };
};

/** reorder skills */
const REORDER_SKILLS = gql`
	mutation ReorderSkills($userId: ID!, $displayOrders: [UserSkillDisplayOrder!]!) {
		userProfileSkillSetDisplayOrder(userId: $userId, displayOrders: $displayOrders)
	}
`;

export const useReorderSkills = (userId: string) => {
	const toast = useToast();
	const [_reorderSkills, { loading }] = useMutation<
		Pick<Mutation, 'userProfileSkillSetDisplayOrder'>,
		MutationUserProfileSkillSetDisplayOrderArgs
	>(REORDER_SKILLS, {
		refetchQueries: [{ query: GET_USER_SKILLS, variables: { userId } }],
		awaitRefetchQueries: true,
	});

	const reorderSkills = async (displayOrders: UserSkillDisplayOrder[]) => {
		return await _reorderSkills({
			variables: { userId, displayOrders },
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('New display order saved successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { reorderSkills, loading };
};

/** endorsement */
const ENDORSE_SKILL = gql`
	mutation EndorseSkill($userId: ID!, $userSkillId: ID!, $endorse: Boolean!) {
		userProfileSkillsEndorse(userId: $userId, userSkillId: $userSkillId, endorse: $endorse)
	}
`;

export const useEndorseSkill = (userId: string, userSkillId: string) => {
	const toast = useToast();
	const [_endorseSkill, { loading }] = useMutation<
		Pick<Mutation, 'userProfileSkillsEndorse'>,
		MutationUserProfileSkillsEndorseArgs
	>(ENDORSE_SKILL, {
		refetchQueries: [{ query: GET_USER_SKILLS, variables: { userId } }],
		awaitRefetchQueries: true,
	});

	const endorseSkill = async (endorse: boolean) => {
		return await _endorseSkill({
			variables: { userId, userSkillId, endorse },
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push(
					endorse
						? 'Endorsement added successfully.'
						: 'Endorsement removed successfully.',
					{ variant: 'success' }
				);
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { endorseSkill, loading };
};

/** get endorsements */
const GET_SKILL_ENDORSEMENTS = gql`
	query GetSkillEndorsements($userId: ID!, $userSkillId: ID!) {
		userProfileSkillEndorsementsGet(userId: $userId, userSkillId: $userSkillId) {
			endorsements {
				id
				endorsedOnUtc
				showOnProfile
				endorsingUserId
				userSkillId
			}
		}
	}
`;

export const useSkillEndorsements = (userId: string, userSkillId: string) => {
	const { data, loading } = useQuery<
		Pick<Query, 'userProfileSkillEndorsementsGet'>,
		QueryUserProfileSkillEndorsementsGetArgs
	>(GET_SKILL_ENDORSEMENTS, { variables: { userId, userSkillId } });
	return { endorsements: data?.userProfileSkillEndorsementsGet.endorsements ?? [], loading };
};

/** edit endorsement visibility */
const UPDATE_SKILL_ENDORSEMENT_VISIBVILITY = gql`
	mutation UpdateEndorsementSkillVisibility(
		$userId: ID!
		$userSkillId: ID!
		$endorsementId: String!
		$visible: Boolean!
	) {
		userProfileSkillsEndorsementVisibility(
			userId: $userId
			userSkillId: $userSkillId
			endorsementId: $endorsementId
			visible: $visible
		)
	}
`;

export const useUpdateSkillEndorementVisibility = (
	userId: string,
	userSkillId: string,
	endorsementId: string
) => {
	const toast = useToast();
	const [_updateVisibility, { loading }] = useMutation<
		Pick<Mutation, 'userProfileSkillsEndorsementVisibility'>,
		MutationUserProfileSkillsEndorsementVisibilityArgs
	>(UPDATE_SKILL_ENDORSEMENT_VISIBVILITY, {
		refetchQueries: [{ query: GET_USER_SKILLS, variables: { userId } }],
		awaitRefetchQueries: true,
	});

	const updateVisibility = async (visible: boolean) => {
		return await _updateVisibility({
			variables: { userId, userSkillId, endorsementId, visible },
			update: (cache) => {
				cache.writeFragment({
					id: `UserSkillEndorsement:${endorsementId}`,
					fragment: gql`
						fragment EndorsementVisibility on UserSkillEndorsement {
							showOnProfile
						}
					`,
					data: { showOnProfile: visible },
				});
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Endorsement visibility updated successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { updateVisibility, loading };
};

/** get places used */
const GET_PLACES_SKILL_USED = gql`
	${PLACES_SKILL_USED_FIELDS}
	query GetPlacesSkillUsed($userId: ID!, $userSkillId: ID!) {
		userProfileSkillPlacesUsedGet(userId: $userId, userSkillId: $userSkillId) {
			...PlacesSkillUsedFields
		}
	}
`;

export const usePlacesSkillUsed = (userId: string, userSkillId: string) => {
	const { data, loading } = useQuery<
		Pick<Query, 'userProfileSkillPlacesUsedGet'>,
		QueryUserProfileSkillPlacesUsedGetArgs
	>(GET_PLACES_SKILL_USED, {
		variables: { userId, userSkillId },
		onError: (e) => console.log(JSON.stringify(e)),
	});
	return { placesUsed: data?.userProfileSkillPlacesUsedGet.placesUsed ?? [], loading };
};

/** edit places used */
const UPDATE_USER_SKILL = gql`
	${PLACES_SKILL_USED_FIELDS}
	mutation UpdateUserSkill(
		$userId: ID!
		$userSkillId: ID!
		$request: UserSkillPlacesUsedUpdateRequest!
	) {
		userProfileSkillsUpdate(userId: $userId, userSkillId: $userSkillId, request: $request) {
			...PlacesSkillUsedFields
		}
	}
`;

export const useUpdateSkill = (userId: string, userSkillId: string) => {
	const toast = useToast();
	const [_updateSkill, { loading }] = useMutation<
		Pick<Mutation, 'userProfileSkillsUpdate'>,
		MutationUserProfileSkillsUpdateArgs
	>(UPDATE_USER_SKILL, {
		awaitRefetchQueries: true,
		refetchQueries: [
			{ query: GET_EDUCATION, variables: { userId } },
			{ query: GET_USER_EXPERIENCE, variables: { userId } },
		],
	});

	const updateSkill = async (request: UserSkillPlacesUsedUpdateRequest) => {
		return await _updateSkill({
			variables: { userId, userSkillId, request },
			update: (cache, { data }) => {
				if (!data) return;
				cache.writeQuery<
					Pick<Query, 'userProfileSkillPlacesUsedGet'>,
					QueryUserProfileSkillPlacesUsedGetArgs
				>({
					query: GET_PLACES_SKILL_USED,
					variables: { userId, userSkillId },
					data: { userProfileSkillPlacesUsedGet: data.userProfileSkillsUpdate },
				});
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Skill updated successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { updateSkill, loading };
};
