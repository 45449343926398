import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { Auth } from 'aws-amplify';
import {
	confirmNewPasswordValidation,
	newPasswordValidation,
	oldPasswordValidation,
} from 'components/ui/LoginYups';
import { TextField } from 'components/ui/fields';
import { ModalButtonsContainer } from 'components/ui/modals/modal-buttons-container';
import { ModalContent } from 'components/ui/modals/modal-content';
import { ModalHeader } from 'components/ui/modals/modal-header';
import { ModalContentProps } from 'components/ui/modals/modal-types';
import { useToast } from 'components/ui/toast';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';

interface ChangePasswordForm {
	oldPassword: string;
	newPassword: string;
	confirmPassword: string;
}

const initialValues: ChangePasswordForm = {
	oldPassword: '',
	newPassword: '',
	confirmPassword: '',
};

const validationSchema = Yup.object({
	oldPassword: oldPasswordValidation,
	newPassword: newPasswordValidation,
	confirmPassword: confirmNewPasswordValidation,
});

export const ChangePasswordModal = ({ onClose }: ModalContentProps) => {
	const toast = useToast();

	const handleSubmit = async (
		vals: ChangePasswordForm,
		helpers: FormikHelpers<ChangePasswordForm>
	) => {
		try {
			const user = await Auth.currentAuthenticatedUser();
			await Auth.changePassword(user, vals.oldPassword, vals.newPassword);
			onClose();
			toast.push('Password Changed Successfully', { variant: 'success' });
		} catch (e: any) {
			console.log(e);
			if (e?.code === 'NotAuthorizedException') {
				helpers.setErrors({
					oldPassword:
						e?.message === 'Incorrect username or password.'
							? 'Incorrect password'
							: e?.message,
				});
				toast.push('Incorrect Password. Please try again.', { variant: 'error' });
			} else if (e?.code === 'LimitExceededException') {
				toast.push('Attempt limit exceeded. Please try after some time.', {
					variant: 'error',
				});
			} else if (e?.code === 'InvalidPasswordException') {
				helpers.setErrors({ newPassword: e?.message });
			} else {
				toast.push('There was an issue changing the password. Please try again.', {
					variant: 'error',
				});
			}
		}
	};

	return (
		<Formik<ChangePasswordForm>
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={validationSchema}>
			{(props: FormikProps<ChangePasswordForm>) => (
				<Form>
					<ModalHeader title="Change Password" onClose={onClose} />
					<ModalContent>
						<TextField
							label="Current Password"
							required
							type="password"
							name="oldPassword"
						/>
						<TextField
							label="New Password"
							required
							type="password"
							name="newPassword"
						/>
						<TextField
							label="Confirm New Password"
							required
							type="password"
							name="confirmPassword"
						/>
					</ModalContent>
					<ModalButtonsContainer>
						<Button variant="outlined" onClick={onClose}>
							Cancel
						</Button>
						<LoadingButton
							type="submit"
							color="primary"
							disabled={!props.dirty || !props.isValid || props.isSubmitting}
							variant="contained"
							loading={props.isSubmitting}>
							Change Password
						</LoadingButton>
					</ModalButtonsContainer>
				</Form>
			)}
		</Formik>
	);
};
