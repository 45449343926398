import { CardActionArea, CardMedia } from '@mui/material';
import { EmblemEntityType } from 'middleware-types';
import { useState } from 'react';
import { ModalOrDrawer } from '../modals/modal-or-drawer';
import { useEmblemBanner } from './hooks';
import { OrganizationEmblemUploaderModal } from './org-emblem-uploader';
import { AvatarOrBannerUploaderModal } from './user-emblem-uploader';

interface EmblemBannerProps {
	src: string;
	onError: () => void;
	onClick: () => void;
	enableUpload?: boolean;
}

const EmblemBanner = ({ src, onError, onClick, enableUpload = false }: EmblemBannerProps) => {
	return (
		<CardActionArea className="group relative" onClick={onClick} disabled={!enableUpload}>
			<CardMedia
				src={src}
				onError={onError}
				component="img"
				alt="profile banner image"
				sx={{
					height: {
						xs: '6.09375rem',
						sm: '14.0625rem',
					},
				}}
			/>
			<img
				src="/img/camera.svg"
				alt="Camera Icon"
				className="absolute bottom-3 right-3 opacity-0 transition-opacity duration-150 group-hover:opacity-100"
			/>
		</CardActionArea>
	);
};

export const UserEmblemBanner = ({ id, enableUpload }: { id: string; enableUpload?: boolean }) => {
	const { src, fileInstance, onError } = useEmblemBanner(id, EmblemEntityType.User);
	const [modalOpen, setModalOpen] = useState(false);

	return (
		<>
			<EmblemBanner
				src={src}
				onError={onError}
				enableUpload={enableUpload}
				onClick={() => setModalOpen(true)}
			/>
			<ModalOrDrawer open={modalOpen} dialogProps={{ maxWidth: 'md' }}>
				<AvatarOrBannerUploaderModal
					userId={id}
					type="banner"
					fileInstance={fileInstance}
					onClose={() => setModalOpen(false)}
				/>
			</ModalOrDrawer>
		</>
	);
};

export const OrgEmblemBanner = ({ id, enableUpload }: { id: string; enableUpload?: boolean }) => {
	const { src, fileInstance, onError } = useEmblemBanner(id, EmblemEntityType.Organization);
	const [modalOpen, setModalOpen] = useState(false);

	return (
		<>
			<EmblemBanner
				src={src}
				onError={onError}
				enableUpload={enableUpload}
				onClick={() => setModalOpen(true)}
			/>
			<ModalOrDrawer open={modalOpen} dialogProps={{ maxWidth: 'md' }}>
				<OrganizationEmblemUploaderModal
					organizationId={id}
					fileInstance={fileInstance}
					type="banner"
					onClose={() => setModalOpen(false)}
				/>
			</ModalOrDrawer>
		</>
	);
};
