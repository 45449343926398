import { HomeOutlined, PinDropOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, CardContent, Collapse, Grid, Stack, Typography } from '@mui/material';
import {
	AddressField,
	ButtonGroupField,
	ButtonGroupItem,
	DatePickerField,
} from 'components/ui/fields';
import { Formik } from 'formik';
/**
 * Leaflet imports.  Leaflet requires it's css be imported at the
 * root level.  Webpack however mangles the default icon image.
 * We correct this here.
 * Reference: https://stackoverflow.com/questions/49441600/react-leaflet-marker-files-not-found
 */
import { ModalButtonsContainer } from 'components/ui/modals/modal-buttons-container';
import { ModalContent } from 'components/ui/modals/modal-content';
import { ModalHeader } from 'components/ui/modals/modal-header';
import { ModalContentProps } from 'components/ui/modals/modal-types';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/leaflet.css';
import {
	GeoAddressInput,
	Location,
	LocationAddress,
	LocationAddressUpdate,
} from 'middleware-types';
import { useRef, useState } from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { useUsStates } from 'utils/useCountries';
import { useDefaults } from 'utils/useDefaults';
import { useGeocoderLazy } from 'utils/useGeocode';
import { useValidation } from 'utils/useValidation';
import * as yup from 'yup';

/**
 * The address type for a user account.
 *
 * @export
 * @enum {number}
 */
export enum AddressType {
	Primary = 'Primary',
	Alternate = 'Alternate',
	Temporary = 'Temporary',
}

const DefaultIcon = L.icon({
	iconUrl: icon,
	shadowUrl: iconShadow,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
});
L.Marker.prototype.options.icon = DefaultIcon;

export type LocationAddressFormValues = Partial<Omit<LocationAddressUpdate, 'address'>> & {
	temporaryAddress?: boolean;
	address?: Partial<GeoAddressInput>;
} & Partial<Pick<LocationAddress, 'id' | 'parentId'>>;

/**
 * Hook for editing the Location Address form
 *
 * @param {*} id
 * @param {*} onClose
 * @return {*}
 */
export const useLocationAddressForm = (locationAddress?: LocationAddressFormValues) => {
	const { schema } = useValidation('LocationAddressUpdate');
	const defaults = useDefaults();

	const validationSchema = schema?.shape({
		fromDate: yup
			.date()
			.nullable()
			.test(
				'fromDateNotNullIfTemporary',
				'Start Date required for temporary addresses',
				function (value) {
					const { temporaryAddress } = this.parent;
					if (temporaryAddress && value == null) return false;
					return true;
				}
			)
			.test('fromDate', 'Start Date must be before End Date', function (value) {
				const { toDate } = this.parent;
				if (toDate && value && value >= toDate) {
					return false;
				}
				return true;
			})
			.typeError('Please finish inputting your date'),
		toDate: yup
			.date()
			.nullable()
			.test(
				'toDateNotNullIfTemporary',
				'End Date required for temporary addresses',
				function (value) {
					const { temporaryAddress } = this.parent;
					if (temporaryAddress && value == null) return false;
					return true;
				}
			)
			.test('toDate', 'End Date must be after Start Date', function (value) {
				const { fromDate } = this.parent;
				if (fromDate && value && value <= fromDate) {
					return false;
				}
				return true;
			})
			.typeError('Please finish inputting your date'),
	});

	const initialValues: LocationAddressFormValues = {
		addressType: locationAddress?.addressType ?? AddressType.Alternate,
		temporaryAddress: locationAddress?.fromDate ? true : false,
		fromDate: locationAddress?.fromDate ?? null,
		toDate: locationAddress?.toDate ?? null,
		address: {
			countryId: locationAddress?.address?.countryId ?? defaults.country.id,
			address1: locationAddress?.address?.address1 ?? '',
			address2: locationAddress?.address?.address2 ?? '',
			municipality: locationAddress?.address?.municipality ?? '',
			adminArea1Id: locationAddress?.address?.adminArea1Id ?? '',
			adminArea2Id: locationAddress?.address?.adminArea2Id ?? '',
			postalCode: locationAddress?.address?.postalCode ?? '',
			coordinate: locationAddress?.address?.coordinate,
		},
	};

	return { initialValues, validationSchema };
};

export type LocationAddressSubmit = (
	location: LocationAddressFormValues,
	locationId?: string
) => Promise<boolean>;

interface LocationAddressFormProps extends ModalContentProps {
	addOrUpdate: LocationAddressSubmit;
	locationAddress?: LocationAddressFormValues;
}

/**
 * Renders the form for adding or updating a new Location Address in an account settings.
 *
 * @param {LocationAddressFormProps} props
 * @return {*}
 */
export const LocationAddressForm = ({
	addOrUpdate,
	locationAddress,
	onClose,
}: LocationAddressFormProps) => {
	const { initialValues, validationSchema } = useLocationAddressForm(locationAddress);
	const { geocode, loading: geocodeLoading } = useGeocoderLazy();

	const [activePage, setActivePage] = useState<1 | 2>(1);
	const [coordinate, setCoordinate] = useState<Location>({
		latitude: locationAddress?.address?.coordinate?.latitude ?? 0,
		longitude: locationAddress?.address?.coordinate?.longitude ?? 0,
	});

	const onSubmit = async (values: LocationAddressFormValues) => {
		const {
			id: _id,
			temporaryAddress: _tempAddress,
			...update
		} = {
			...values,
			addressType: values.temporaryAddress
				? AddressType.Temporary
				: values.addressType === AddressType.Temporary
				? AddressType.Alternate
				: values.addressType,
			fromDate: values.temporaryAddress ? values.fromDate : null,
			toDate: values.temporaryAddress ? values.toDate : null,
			address: {
				...values.address,
				coordinate,
			},
		};

		await addOrUpdate(update, locationAddress?.id).then((success) => {
			if (success) onClose();
		});
	};

	return (
		<Formik<LocationAddressFormValues>
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={validationSchema}
			enableReinitialize>
			{({
				values,
				validateForm,
				isSubmitting,
				isValid,
				dirty,
				submitForm,
				setFieldValue,
				setFieldTouched,
			}) => (
				<>
					<ModalHeader
						title={`${
							locationAddress ? 'Edit Address' : 'Add New Address'
						} (${activePage}/2)`}
						onClose={onClose}
					/>
					{activePage === 1 && (
						<ModalContent>
							{locationAddress?.addressType !== AddressType.Primary && (
								<Box mb={2}>
									<Typography>Is this a temporary address?</Typography>
									<ButtonGroupField name="temporaryAddress" exclusive required>
										<ButtonGroupItem value={true}>Yes</ButtonGroupItem>
										<ButtonGroupItem
											value={false}
											onClick={() => {
												setFieldValue('fromDate', null);
												setFieldTouched('fromDate', false);
												setFieldValue('toDate', null);
												setFieldTouched('toDate', false);
											}}>
											No
										</ButtonGroupItem>
									</ButtonGroupField>
									<Collapse in={values.temporaryAddress}>
										<Grid container spacing={3}>
											<Grid item xs={12} md={6}>
												<DatePickerField
													label="Start Date"
													name="fromDate"
													required={values.temporaryAddress}
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<DatePickerField
													label="End Date"
													name="toDate"
													required={values.temporaryAddress}
												/>
											</Grid>
										</Grid>
									</Collapse>
								</Box>
							)}
							<Box>
								<AddressField name="address" required />
							</Box>
						</ModalContent>
					)}
					{activePage === 2 && (
						<ModalContent>
							<Box position="relative">
								<MapContainer
									center={[coordinate.latitude, coordinate.longitude]}
									zoom={14}
									style={{ height: '65vh', width: '100%' }}>
									<GeolocateMarker
										coordinate={coordinate}
										setCoordinate={setCoordinate}
										draggable={!isSubmitting}
									/>
									<TileLayer
										attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
										url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
									/>
								</MapContainer>
								<MapInfoDisplay
									coordinate={coordinate}
									address={values.address as GeoAddressInput}
									resetCoordinate={async () => {
										const res = await geocode(
											values.address as GeoAddressInput
										);
										if (res.data?.geocode.location) {
											setCoordinate(res.data.geocode.location);
										}
									}}
								/>
							</Box>
						</ModalContent>
					)}
					{activePage === 1 && (
						<ModalButtonsContainer>
							<Button variant="outlined" onClick={onClose}>
								Cancel
							</Button>
							<LoadingButton
								color="primary"
								variant="contained"
								disabled={!isValid}
								loading={geocodeLoading}
								onClick={async () => {
									// Don't overwrite the user's custom coordinates unless they make a change
									if (dirty) {
										const res = await geocode(
											values.address as GeoAddressInput
										);
										if (res.data?.geocode.location) {
											setCoordinate(res.data.geocode.location);
										}
									}
									setActivePage(2);
								}}>
								Next
							</LoadingButton>
						</ModalButtonsContainer>
					)}
					{activePage === 2 && (
						<ModalButtonsContainer>
							<Button variant="outlined" onClick={() => setActivePage(1)}>
								Back
							</Button>
							<LoadingButton
								variant="contained"
								color="primary"
								loading={isSubmitting}
								onClick={submitForm}>
								Save Address
							</LoadingButton>
						</ModalButtonsContainer>
					)}
				</>
			)}
		</Formik>
	);
};

type GeolocateMarkerProps = {
	coordinate: Location;
	setCoordinate: (coord: Location) => void;
	draggable: boolean;
};

/**
 * Displays the pin on the map that is draggable by the user, updates the coordinates in CoordinateSelect.
 *
 * @param {GeolocateMarkerProps} props
 * @return {*}
 */
const GeolocateMarker = (props: GeolocateMarkerProps) => {
	const markerRef = useRef<L.Marker | null>(null);

	const eventHandlers = {
		drag() {
			const currentCoords = markerRef.current?.getLatLng();
			currentCoords &&
				props.setCoordinate({
					latitude: currentCoords.lat,
					longitude: currentCoords.lng,
				});
		},
	};

	return (
		<Marker
			ref={markerRef}
			eventHandlers={eventHandlers}
			draggable={props.draggable}
			position={{
				lat: props.coordinate.latitude,
				lng: props.coordinate.longitude,
			}}
		/>
	);
};

type MapInfoDisplayProps = {
	coordinate: Location;
	address: GeoAddressInput;
	resetCoordinate: () => void;
};

/**
 * Displays info about the users location and allows them to reset the coordinates.
 *
 * @param {MapInfoDisplayProps} { coordinate, address, resetCoordinate }
 * @return {*}
 */
const MapInfoDisplay = ({ coordinate, address, resetCoordinate }: MapInfoDisplayProps) => {
	const { statesLookupMapById } = useUsStates();

	return (
		<Card
			sx={{
				width: '25%',
				minWidth: 'unset !important',
				zIndex: 999,
				position: 'absolute',
				bottom: '1.5rem',
				left: '1.5rem',
			}}>
			<CardContent>
				<Stack spacing={2}>
					<strong>
						Please confirm that the pin is placed correctly for the address you entered.
					</strong>
					<Typography variant="body1">
						You can drag the pin to reposition it, if needed.
					</Typography>
					<Stack direction="row" alignItems="center" gap={1.5}>
						<HomeOutlined />
						<span>
							{address.address1}, {address.municipality}{' '}
							{address.adminArea1Id &&
								statesLookupMapById.get(address.adminArea1Id)?.displayName}{' '}
							{address.postalCode}
						</span>
					</Stack>
					<Stack direction="row" alignItems="center" gap={1.5}>
						<PinDropOutlined />
						<span>
							{coordinate.latitude.toPrecision(5)},{' '}
							{coordinate.longitude.toPrecision(5)}
						</span>
					</Stack>
					<Button
						color="primary"
						variant="contained"
						onClick={resetCoordinate}
						sx={{ mt: '1rem' }}>
						Reset Pin Location
					</Button>
				</Stack>
			</CardContent>
		</Card>
	);
};
