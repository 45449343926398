import { Avatar, Badge } from '@mui/material';
import {
	Notification,
	NotificationState,
	NotificationType,
	RelatedEntityType,
} from 'middleware-types';
import { EmblemAvatar, OrgEmblemAvatar } from '../emblem/emblem-avatar';
import { Campaign, ReceiptLong } from '@mui/icons-material';

interface NotificationEmblemProps {
	notification: Notification;
	highlighted: boolean;
}

export const NotificationEmblem = ({ notification, highlighted }: NotificationEmblemProps) => {
	return (
		<Badge
			invisible={!highlighted}
			badgeContent="!"
			color="warning"
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			sx={{ '& .MuiBadge-badge': { color: 'black', height: 16, width: 16, minWidth: 16 } }}
			overlap={
				notification.relatedEntityType === RelatedEntityType.Organization
					? 'rectangular'
					: 'circular'
			}>
			{notification.type === NotificationType.SystemNotification ? (
				<Avatar
					sx={{
						bgcolor: highlighted ? 'primary.main' : undefined,
						color: highlighted ? 'white' : undefined,
					}}>
					<Campaign />
				</Avatar>
			) : notification.type === NotificationType.BillingUpdate ? (
				<Avatar
					sx={{
						bgcolor: highlighted ? 'primary.main' : undefined,
						color: highlighted ? 'white' : undefined,
					}}>
					<ReceiptLong />
				</Avatar>
			) : (
				<EmblemAvatar
					emblem={notification.relatedEntityEmblem}
					sx={{
						border: highlighted ? '2px solid' : undefined,
						borderColor: 'primary.main',
					}}
				/>
			)}
		</Badge>
	);
};
