export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	/** Date custom scalar type */
	Date: any;
};

export type AccountEmergencyContact = {
	__typename?: 'AccountEmergencyContact';
	address: Address;
	cellPhone: PhoneNumber;
	email?: Maybe<Scalars['String']>;
	firstName: Scalars['String'];
	lastName: Scalars['String'];
	otherPhone?: Maybe<PhoneNumber>;
	relationship: Scalars['String'];
	userId: Scalars['ID'];
};

export type AccountEmergencyContactUpdate = {
	address: AddressInput;
	cellPhone: PhoneNumberInput;
	email?: InputMaybe<Scalars['String']>;
	firstName: Scalars['String'];
	lastName: Scalars['String'];
	otherPhone?: InputMaybe<PhoneNumberInput>;
	relationship: Scalars['String'];
};

export enum ActivationOperation {
	Activate = 'Activate',
	Deactivate = 'Deactivate',
}

export enum ActivationStatus {
	Active = 'Active',
	Inactive = 'Inactive',
}

export type AdHocBillingItem = {
	__typename?: 'AdHocBillingItem';
	description?: Maybe<Scalars['String']>;
	externalOrganizationId: Scalars['String'];
	id: Scalars['ID'];
	quantity: Scalars['Float'];
	recurring: Scalars['Boolean'];
	serviceDate?: Maybe<Scalars['Date']>;
	stripePriceId?: Maybe<Scalars['String']>;
	stripeProductId: Scalars['String'];
	unitPrice?: Maybe<Scalars['Float']>;
};

export type AdHocBillingItemUpdate = {
	description?: InputMaybe<Scalars['String']>;
	quantity: Scalars['Float'];
	recurring: Scalars['Boolean'];
	serviceDate?: InputMaybe<Scalars['Date']>;
	stripePriceId?: InputMaybe<Scalars['String']>;
	stripeProductId: Scalars['String'];
	unitPrice?: InputMaybe<Scalars['Float']>;
};

export type AdHocBillingItemsResponse = {
	__typename?: 'AdHocBillingItemsResponse';
	adHocBillingItems: Array<AdHocBillingItem>;
};

export type Address = {
	__typename?: 'Address';
	address1?: Maybe<Scalars['String']>;
	address2?: Maybe<Scalars['String']>;
	adminArea1?: Maybe<AddressFieldLookup>;
	adminArea1Id?: Maybe<Scalars['String']>;
	adminArea2?: Maybe<AddressFieldLookup>;
	adminArea2Id?: Maybe<Scalars['String']>;
	coordinate?: Maybe<Location>;
	countryId: Scalars['String'];
	municipality?: Maybe<Scalars['String']>;
	postalCode?: Maybe<Scalars['String']>;
};

export type AddressFieldLookup = {
	__typename?: 'AddressFieldLookup';
	displayName?: Maybe<Scalars['String']>;
	displayOrder?: Maybe<Scalars['Int']>;
	field?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	value?: Maybe<Scalars['String']>;
};

export type AddressInput = {
	address1?: InputMaybe<Scalars['String']>;
	address2?: InputMaybe<Scalars['String']>;
	adminArea1Id?: InputMaybe<Scalars['String']>;
	adminArea2Id?: InputMaybe<Scalars['String']>;
	coordinate?: InputMaybe<LocationInput>;
	countryId: Scalars['String'];
	municipality?: InputMaybe<Scalars['String']>;
	postalCode?: InputMaybe<Scalars['String']>;
};

export type AdjusterLicensingSummaries = {
	__typename?: 'AdjusterLicensingSummaries';
	summaries: Array<AdjusterLicensingSummary>;
	userId: Scalars['ID'];
};

export type AdjusterLicensingSummary = {
	__typename?: 'AdjusterLicensingSummary';
	activeRegionIds?: Maybe<Array<Scalars['String']>>;
	adjusterLicensingText?: Maybe<Scalars['String']>;
	adjusterLicensingTextSensitive?: Maybe<Scalars['String']>;
	countryId: Scalars['String'];
	expiredRegionIds?: Maybe<Array<Scalars['String']>>;
	expiringRegionIds?: Maybe<Array<Scalars['String']>>;
	inactiveRegionIds?: Maybe<Array<Scalars['String']>>;
	licenseHoldersName?: Maybe<Scalars['String']>;
	licensingProvenance: Scalars['String'];
	numActiveLicenses: Scalars['Int'];
	numExpiredLicenses: Scalars['Int'];
	numExpiredWithin60DaysLicenses: Scalars['Int'];
	numLicenses: Scalars['Int'];
	userId: Scalars['String'];
};

export type AdjustmentCompanyInfo = {
	__typename?: 'AdjustmentCompanyInfo';
	acid: Scalars['Float'];
	address1?: Maybe<Scalars['String']>;
	address2?: Maybe<Scalars['String']>;
	city?: Maybe<Scalars['String']>;
	country?: Maybe<Scalars['String']>;
	logo?: Maybe<Scalars['String']>;
	name?: Maybe<Scalars['String']>;
	state?: Maybe<Scalars['String']>;
	systemId: Scalars['Float'];
	systemUrl: Scalars['String'];
	zipCode?: Maybe<Scalars['String']>;
};

export enum AllowableFileAccessLevels {
	Confidential = 'Confidential',
	None = 'None',
	Public = 'Public',
	Sensitive = 'Sensitive',
}

export enum AllowedTransports {
	All = 'All',
	DailyDigest = 'DailyDigest',
	Email = 'Email',
	HourlyDigest = 'HourlyDigest',
	InApp = 'InApp',
	None = 'None',
	Sms = 'Sms',
}

export type AppSettings = {
	__typename?: 'AppSettings';
	name: Scalars['String'];
	subSettings?: Maybe<Array<AppSettings>>;
	value?: Maybe<Scalars['String']>;
};

export type AssociateInvitation = {
	__typename?: 'AssociateInvitation';
	avatarFile?: Maybe<File>;
	id: Scalars['ID'];
	organizationId: Scalars['String'];
	organizationName: Scalars['String'];
};

export type AssociateInvitationRequest = {
	displayName: Scalars['String'];
	emailAddress: Scalars['String'];
	roleId: Scalars['String'];
};

export type AssociateInvitationsResponse = {
	__typename?: 'AssociateInvitationsResponse';
	invitations: Array<AssociateInvitation>;
};

export type AssociateOrInvitation = {
	__typename?: 'AssociateOrInvitation';
	associateId?: Maybe<Scalars['String']>;
	deactivated?: Maybe<Scalars['Boolean']>;
	displayName: Scalars['String'];
	displayOnProfile?: Maybe<Scalars['Boolean']>;
	emailAddress: Scalars['String'];
	externalUserDisplayName?: Maybe<Scalars['String']>;
	externalUserHandle?: Maybe<Scalars['String']>;
	invitationId?: Maybe<Scalars['String']>;
	lastUpdatedUtc: Scalars['Date'];
	organizationId: Scalars['String'];
	organizationName: Scalars['String'];
	organizationRoleId: Scalars['String'];
	organizationRoleName?: Maybe<Scalars['String']>;
	status: Scalars['String'];
	userId?: Maybe<Scalars['String']>;
};

export type AssociateUpdate = {
	displayName: Scalars['String'];
	displayOnProfile: Scalars['Boolean'];
	emailAddress: Scalars['String'];
	organizationRoleId: Scalars['String'];
};

export type AssociatesToBeSeededCountResponse = {
	__typename?: 'AssociatesToBeSeededCountResponse';
	count: Scalars['Float'];
};

export type BillingAlert = {
	__typename?: 'BillingAlert';
	description: Scalars['String'];
	severity: BillingAlertSeverity;
};

export enum BillingAlertSeverity {
	Critical = 'Critical',
	Information = 'Information',
	Warning = 'Warning',
}

export type BillingHierarchyValidationResults = {
	__typename?: 'BillingHierarchyValidationResults';
	errors: Array<Scalars['String']>;
};

export type BillingInfoResponse = {
	__typename?: 'BillingInfoResponse';
	alerts: Array<BillingAlert>;
	canGoToPortal: Scalars['Boolean'];
	inheritedFromRootOrganization: Scalars['Boolean'];
	planInformation?: Maybe<BillingPlanInformation>;
};

export type BillingPlanInformation = {
	__typename?: 'BillingPlanInformation';
	planInterval: Scalars['String'];
	planName: Scalars['String'];
	planPrice: Scalars['Float'];
};

export type BillingUsage = {
	appraisalCount: Scalars['Float'];
	appraisalCountDeleted: Scalars['Float'];
	bordereau: Scalars['Boolean'];
	claimCount: Scalars['Float'];
	claimCountDeleted: Scalars['Float'];
	hoverCount: Scalars['Float'];
	odysseyCount: Scalars['Float'];
	storageActive: Scalars['Float'];
	storageDate: Scalars['Date'];
	symbilityCount: Scalars['Float'];
	textMessageCount: Scalars['Float'];
	torrentCount: Scalars['Float'];
	userCount: Scalars['Float'];
	xactCount: Scalars['Float'];
};

export type BusinessLine = {
	__typename?: 'BusinessLine';
	id: Scalars['ID'];
	line?: Maybe<Scalars['String']>;
	specialty?: Maybe<Scalars['Boolean']>;
	text?: Maybe<Scalars['String']>;
};

export type BusinessLineUpdate = {
	line?: InputMaybe<Scalars['String']>;
	specialty?: InputMaybe<Scalars['Boolean']>;
	text?: InputMaybe<Scalars['String']>;
};

export type Callout = {
	__typename?: 'Callout';
	color?: Maybe<Scalars['String']>;
	icon?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	text?: Maybe<Scalars['String']>;
};

export type CalloutUpdate = {
	color?: InputMaybe<Scalars['String']>;
	icon?: InputMaybe<Scalars['String']>;
	text?: InputMaybe<Scalars['String']>;
};

export type CanAdjusterLicensingInput = {
	ciprNumber: Scalars['String'];
	provincesLicensing: Array<CanProvinceAdjusterLicensingInput>;
};

export type CanAdjusterLicensingOutput = {
	__typename?: 'CanAdjusterLicensingOutput';
	ciprNumber: Scalars['String'];
	provincesLicensing: Array<CanProvinceAdjusterLicensingOutput>;
};

export type CanCallerLinkResult = {
	__typename?: 'CanCallerLinkResult';
	canLink: Scalars['Boolean'];
};

export type CanLicenseTypeLookup = {
	__typename?: 'CanLicenseTypeLookup';
	id: Scalars['String'];
	name: Scalars['String'];
	provinceId: Scalars['String'];
};

export type CanProvinceAdjusterLicenseInput = {
	adjusterAlias?: InputMaybe<Scalars['String']>;
	designation: Scalars['String'];
	expirationDate?: InputMaybe<Scalars['Date']>;
	licenseTypeId: Scalars['String'];
	number: Scalars['String'];
	originalIssueDate?: InputMaybe<Scalars['Date']>;
	supervisingEntity?: InputMaybe<Scalars['String']>;
};

export type CanProvinceAdjusterLicenseOutput = {
	__typename?: 'CanProvinceAdjusterLicenseOutput';
	adjusterAlias?: Maybe<Scalars['String']>;
	designation: Scalars['String'];
	expirationDate?: Maybe<Scalars['Date']>;
	licenseTypeId: Scalars['String'];
	number: Scalars['String'];
	originalIssueDate?: Maybe<Scalars['Date']>;
	supervisingEntity?: Maybe<Scalars['String']>;
};

export type CanProvinceAdjusterLicensingInput = {
	provinceAddressFieldLookupId: Scalars['String'];
	provinceLicenses: Array<CanProvinceAdjusterLicenseInput>;
};

export type CanProvinceAdjusterLicensingOutput = {
	__typename?: 'CanProvinceAdjusterLicensingOutput';
	provinceAddressFieldLookupId: Scalars['String'];
	provinceLicenses: Array<CanProvinceAdjusterLicenseOutput>;
};

export type ChangeFileFolderRequest = {
	fileIds: Array<Scalars['String']>;
	folderId?: InputMaybe<Scalars['String']>;
};

export type ChangedValue = {
	__typename?: 'ChangedValue';
	changeType: Scalars['String'];
	entityId?: Maybe<Scalars['String']>;
	fieldName: Scalars['String'];
	newValue?: Maybe<Scalars['String']>;
	oldValue?: Maybe<Scalars['String']>;
};

/** The source of an EVO-X conversation. */
export enum ChatConversationSource {
	EvolveOrganization = 'EvolveOrganization',
	EvolveUser = 'EvolveUser',
	FileTracLegacy = 'FileTracLegacy',
}

/** The type of an EVO-X conversation. */
export enum ChatConversationType {
	ClaimNotesSummary = 'ClaimNotesSummary',
	GeneralChat = 'GeneralChat',
	MessagingDraft = 'MessagingDraft',
}

/** Request object for when loading a file for use in a chat conversation. */
export type ChatFileRequest = {
	/** The conversation ID. */
	conversationId: Scalars['ID'];
	/** The file service upload token of the file to be imported for chat. */
	fileUploadToken: Scalars['String'];
};

/** Represents feedback for a chat message. */
export type ChatMessageFeedback = {
	/** The feedback comments. */
	comments?: InputMaybe<Scalars['String']>;
	/** The feedback rating. */
	rating: Scalars['String'];
};

/** Indicates the rating of a chat message. */
export enum ChatMessageRating {
	None = 'None',
	ThumbsDown = 'ThumbsDown',
	ThumbsUp = 'ThumbsUp',
}

/** Indicates the role of the chat message creator. */
export enum ChatMessageRole {
	Assistant = 'Assistant',
	FunctionResultsForAi = 'FunctionResultsForAi',
	FunctionResultsForUser = 'FunctionResultsForUser',
	System = 'System',
	User = 'User',
}

/** Claim note details. */
export type ClaimNote = {
	/** The datetime of the message. */
	dateTimeUtc: Scalars['Date'];
	/** The note text. */
	text?: InputMaybe<Scalars['String']>;
	/** The name of the note author. */
	user?: InputMaybe<Scalars['String']>;
};

/** Claim details for a claim on which we are summarizing claim notes. */
export type ClaimNotesSummaryClaimDetails = {
	/** The claim number. */
	claimNumber?: InputMaybe<Scalars['String']>;
	/** The status of the claim. */
	claimStatus?: InputMaybe<Scalars['String']>;
	/** The name of the client company (carrier). */
	clientCompany?: InputMaybe<Scalars['String']>;
	/** The name of the insured. */
	insuredName?: InputMaybe<Scalars['String']>;
	/** The loss date of the claim. */
	lossDate?: InputMaybe<Scalars['Date']>;
	/** The address of the loss (concatenates address, city, state, zip). */
	lossLocation?: InputMaybe<Scalars['String']>;
	/** The type of loss. */
	lossType?: InputMaybe<Scalars['String']>;
	/** The policy number. */
	policyNumber?: InputMaybe<Scalars['String']>;
};

/** Claim notes summary request. */
export type ClaimNotesSummaryRequest = {
	/** The chat conversation ID, when continuing an existing conversation. */
	chatConversationId?: InputMaybe<Scalars['ID']>;
	/** The details of the claim for which we are summarizing claim notes. */
	claimDetails?: InputMaybe<ClaimNotesSummaryClaimDetails>;
	/** An array of claim notes. */
	claimNotes?: InputMaybe<Array<ClaimNote>>;
	/** A serialized json string containing any metadata the legacy caller wants to include (e.g. acid, system, claimid, filter params, etc.) For forensic purposes only. */
	metadata?: InputMaybe<Scalars['String']>;
	/** The prompt from the user (i.e. during a follow-up conversation about a generated summary). */
	prompt?: InputMaybe<Scalars['String']>;
	/** The ID for the Evolve user associated with this request. */
	userId: Scalars['ID'];
};

/** Claim notes summary response. */
export type ClaimNotesSummaryResponse = {
	__typename?: 'ClaimNotesSummaryResponse';
	/** The ID of the chat conversation. */
	chatConversationId: Scalars['ID'];
	/** The generated reply. */
	message?: Maybe<Scalars['String']>;
};

export enum ClaimSummaryRoleType {
	Group = 'Group',
	User = 'User',
}

export enum ClaimSummarySubset {
	DueNext3 = 'DueNext3',
	Overdue = 'Overdue',
	RecLast3 = 'RecLast3',
}

export type CognitoDevice = {
	__typename?: 'CognitoDevice';
	createdAtUtc: Scalars['Date'];
	lastAuthenticatedUtc: Scalars['Date'];
	lastIpUsed: Scalars['String'];
	name: Scalars['String'];
	remembered: Scalars['Boolean'];
};

export type CognitoDevicesResponse = {
	__typename?: 'CognitoDevicesResponse';
	cognitoDevices?: Maybe<Array<CognitoDevice>>;
};

export type CognitoUserResponse = {
	__typename?: 'CognitoUserResponse';
	preferredMfaSetting?: Maybe<Scalars['String']>;
};

export type Connection = {
	__typename?: 'Connection';
	id: Scalars['ID'];
	requestingOrganizationEmblem?: Maybe<Emblem>;
	requestingOrganizationId?: Maybe<Scalars['String']>;
	requestingUserEmblem?: Maybe<Emblem>;
	requestingUserId?: Maybe<Scalars['String']>;
	state: ConnectionState;
	stateChangedDateTimeUtc: Scalars['Date'];
	targetOrganizationEmblem?: Maybe<Emblem>;
	targetOrganizationId?: Maybe<Scalars['String']>;
	targetUserEmblem?: Maybe<Emblem>;
	targetUserId?: Maybe<Scalars['String']>;
};

export enum ConnectionPageType {
	MyConnections = 'MY_CONNECTIONS',
	Requests = 'REQUESTS',
	RequestsISent = 'REQUESTS_I_SENT',
}

export enum ConnectionSortBy {
	OtherDisplayName = 'OtherDisplayName',
	RequestingDisplayName = 'RequestingDisplayName',
	State = 'State',
	StateChangedDateTimeUtc = 'StateChangedDateTimeUtc',
	TargetDisplayName = 'TargetDisplayName',
}

export enum ConnectionState {
	Connected = 'Connected',
	Ignored = 'Ignored',
	Pending = 'Pending',
}

export type Conversation = {
	__typename?: 'Conversation';
	creatorEmblem: Emblem;
	creatorEntityId: Scalars['String'];
	creatorEntityType: ParticipantEntityType;
	id: Scalars['ID'];
	messages: Array<Message>;
	participants: Array<ConversationParticipant>;
	subject: Scalars['String'];
};

export type ConversationAndSummary = {
	__typename?: 'ConversationAndSummary';
	conversation: Conversation;
	conversationSummary: ConversationSummary;
};

export enum ConversationApiEntityType {
	LegacyClaim = 'LegacyClaim',
	Organizations = 'Organizations',
	Users = 'Users',
}

export type ConversationInsuranceInput = {
	conversationInsuranceUrl?: InputMaybe<Scalars['String']>;
};

export type ConversationInsuranceResponse = {
	__typename?: 'ConversationInsuranceResponse';
	conversationInsuranceUrl?: Maybe<Scalars['String']>;
};

export type ConversationParticipant = {
	__typename?: 'ConversationParticipant';
	entityId: Scalars['String'];
	entityType: ParticipantEntityType;
	isCurrentParticipant: Scalars['Boolean'];
	participantLabels: Array<ParticipantLabel>;
};

export type ConversationSummariesResponse = {
	__typename?: 'ConversationSummariesResponse';
	conversationSummaries: Array<ConversationSummary>;
	labelId?: Maybe<Scalars['ID']>;
	totalCount: Scalars['Int'];
};

export type ConversationSummary = {
	__typename?: 'ConversationSummary';
	conversationId: Scalars['ID'];
	emblemToDisplay?: Maybe<Emblem>;
	entityIdToDisplay: Scalars['String'];
	entityTypeToDisplay: ParticipantEntityType;
	lastMessageDateTimeUtc: Scalars['Date'];
	lastMessageIsFromCurrentEntity: Scalars['Boolean'];
	lastMessagePreview?: Maybe<Scalars['String']>;
	participantsCount: Scalars['Int'];
	subject: Scalars['String'];
	unreadMessageCount: Scalars['Int'];
};

/** The type of an EVO-X conversation. */
export enum ConversationTypeFilter {
	ClaimNotesSummary = 'ClaimNotesSummary',
	GeneralChat = 'GeneralChat',
	MessagingDraft = 'MessagingDraft',
}

export type Country = {
	__typename?: 'Country';
	address1Label?: Maybe<Scalars['String']>;
	address1Visibility: Scalars['String'];
	address2Label?: Maybe<Scalars['String']>;
	address2Visibility: Scalars['String'];
	addressFieldLookups?: Maybe<Array<AddressFieldLookup>>;
	adminArea1Label?: Maybe<Scalars['String']>;
	adminArea1Visibility: Scalars['String'];
	adminArea2Label?: Maybe<Scalars['String']>;
	adminArea2Visibility: Scalars['String'];
	driversLicenseJurisdictionLevel?: Maybe<Scalars['String']>;
	id: Scalars['String'];
	iso2CountryCode: Scalars['String'];
	iso3CountryCode: Scalars['String'];
	municipalityLabel?: Maybe<Scalars['String']>;
	municipalityVisibility: Scalars['String'];
	name: Scalars['String'];
	nationalIdentificationNumberLabel?: Maybe<Scalars['String']>;
	nationalIdentificationNumberRegex?: Maybe<Scalars['String']>;
	phoneNumberFormat?: Maybe<Scalars['String']>;
	phonePrefix: Scalars['String'];
	postalCodeLabel?: Maybe<Scalars['String']>;
	postalCodeRegex?: Maybe<Scalars['String']>;
	postalCodeVisibility: Scalars['String'];
};

export type Coverages = {
	__typename?: 'Coverages';
	countryId: Scalars['String'];
	policyCategories: Array<PolicyCategory>;
};

/** Request object for updating a users custom instructions. */
export type CustomInstructions = {
	__typename?: 'CustomInstructions';
	/** Sets the About text for the user. */
	about?: Maybe<Scalars['String']>;
	/** Indicates whether this CustomInstructions is enabled. */
	enableCustomInstructions: Scalars['Boolean'];
	/** Sets the response targeting text for the user. */
	responseTargeting?: Maybe<Scalars['String']>;
};

/** Request object for updating a users custom instructions. */
export type CustomInstructionsInput = {
	/** Sets the About text for the user. */
	about?: InputMaybe<Scalars['String']>;
	/** Indicates whether this CustomInstructions is enabled. */
	enableCustomInstructions: Scalars['Boolean'];
	/** Sets the response targeting text for the user. */
	responseTargeting?: InputMaybe<Scalars['String']>;
};

export type DisplayTemporaryLocation = {
	__typename?: 'DisplayTemporaryLocation';
	displayLocation: Scalars['String'];
	endDate: Scalars['Date'];
	startDate?: Maybe<Scalars['Date']>;
};

export type Emblem = {
	__typename?: 'Emblem';
	avatarFile?: Maybe<File>;
	bannerFile?: Maybe<File>;
	deactivated?: Maybe<Scalars['Boolean']>;
	displayName: Scalars['String'];
	entityType: EmblemEntityType;
	extraData?: Maybe<UserExtraData>;
	handle?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	private?: Maybe<Scalars['Boolean']>;
};

export enum EmblemEntityType {
	Conversation = 'Conversation',
	LegacyClaim = 'LegacyClaim',
	Organization = 'Organization',
	System = 'System',
	Unknown = 'Unknown',
	User = 'User',
}

export type EmblemPagingResponse = {
	__typename?: 'EmblemPagingResponse';
	items: Array<Emblem>;
	totalCount: Scalars['Int'];
};

export enum EmployeeCount {
	Employees0To1 = 'Employees0To1',
	Employees2To10 = 'Employees2To10',
	Employees11To50 = 'Employees11To50',
	Employees51To200 = 'Employees51To200',
	Employees201To500 = 'Employees201To500',
	Employees501To1000 = 'Employees501To1000',
	Employees1001To5000 = 'Employees1001To5000',
	Employees5001To10000 = 'Employees5001To10000',
	EmployeesMoreThan10000 = 'EmployeesMoreThan10000',
}

export enum EmploymentLocationType {
	Hybrid = 'Hybrid',
	OnSite = 'OnSite',
	Remote = 'Remote',
}

export enum EmploymentType {
	Apprenticeship = 'Apprenticeship',
	Contract = 'Contract',
	Freelance = 'Freelance',
	FullTime = 'FullTime',
	Internship = 'Internship',
	PartTime = 'PartTime',
	Seasonal = 'Seasonal',
	SelfEmployed = 'SelfEmployed',
}

export type EntitySearch = {
	__typename?: 'EntitySearch';
	entityType: Scalars['String'];
	handle?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
};

export enum EntitySearchMode {
	All = 'All',
	Organization = 'Organization',
	User = 'User',
}

export enum EntitySearchType {
	Organization = 'Organization',
	User = 'User',
}

export type Environment = {
	__typename?: 'Environment';
	environmentName: Scalars['String'];
};

export type File = {
	__typename?: 'File';
	file?: Maybe<FileInformation>;
	fileId?: Maybe<Scalars['ID']>;
};

export enum FileAccessLevel {
	Confidential = 'Confidential',
	Public = 'Public',
	Sensitive = 'Sensitive',
}

export type FileAccessLevelChangeRequest = {
	ids: Array<Scalars['String']>;
	newAccessLevel: Scalars['String'];
};

export type FileDeleteRequest = {
	ids: Array<Scalars['String']>;
};

export type FileDownloadInformation = {
	__typename?: 'FileDownloadInformation';
	downloadUrl: Scalars['String'];
	expiresUtc?: Maybe<Scalars['Date']>;
	fileInstanceId: Scalars['ID'];
};

export enum FileEntityType {
	Evox = 'Evox',
	Message = 'Message',
	None = 'None',
	Organization = 'Organization',
	User = 'User',
}

export type FileInformation = {
	__typename?: 'FileInformation';
	currentInstance?: Maybe<FileInstanceInformation>;
	deleted: Scalars['Boolean'];
	deletedByUserId?: Maybe<Scalars['String']>;
	folder?: Maybe<FolderReference>;
	id: Scalars['ID'];
	instanceCount: Scalars['Int'];
	instances?: Maybe<Array<FileInstanceInformation>>;
	relatedEntityId: Scalars['String'];
	relatedEntityType: FileEntityType;
};

export type FileInstanceCopyRequest = {
	accessLevel: Scalars['String'];
	fileName: Scalars['String'];
	folderId?: InputMaybe<Scalars['ID']>;
};

export type FileInstanceInformation = {
	__typename?: 'FileInstanceInformation';
	accessLevel: FileAccessLevel;
	accessLevelImmutable: Scalars['Boolean'];
	/** @deprecated Used to set allowedAccessLevelChangesList */
	allowedAccessLevelChanges: Scalars['String'];
	allowedAccessLevelChangesList?: Maybe<Array<AllowableFileAccessLevels>>;
	asciiFileName: Scalars['String'];
	cdnUrl?: Maybe<Scalars['String']>;
	deleted: Scalars['Boolean'];
	deletedByUserId?: Maybe<Scalars['String']>;
	extension?: Maybe<Scalars['String']>;
	fileId: Scalars['String'];
	fileName: Scalars['String'];
	fileSize: Scalars['Float'];
	folder?: Maybe<FolderReference>;
	hasPermissionToDelete: Scalars['Boolean'];
	hasPermissionToDownload: Scalars['Boolean'];
	hasPermissionToUpdate: Scalars['Boolean'];
	id: Scalars['ID'];
	mimeType: Scalars['String'];
	uploadedBy?: Maybe<Emblem>;
	uploadedUtc: Scalars['Date'];
	userId: Scalars['String'];
	versionNumber: Scalars['Int'];
	virusStatus: VirusStatus;
	virusStatusRawResult?: Maybe<Scalars['String']>;
	virusStatusUpdatedUtc?: Maybe<Scalars['Date']>;
};

export type FileInstanceResponse = {
	__typename?: 'FileInstanceResponse';
	fileId: Scalars['ID'];
	instances: Array<FileInstanceInformation>;
};

export type FileRenameRequest = {
	fileName: Scalars['String'];
};

export type FileSetCurrentInstanceRequest = {
	fileInstanceId: Scalars['ID'];
};

export type FileUploadCommitRequest = {
	accessLevel?: InputMaybe<Scalars['String']>;
	fileUploadToken: Scalars['String'];
	folderId?: InputMaybe<Scalars['ID']>;
};

export type FileUploadRequest = {
	fileName: Scalars['String'];
	fileSize: Scalars['Float'];
	relatedEntityId: Scalars['String'];
	relatedEntityType: FileEntityType;
	requestedAccessLevel?: InputMaybe<Scalars['String']>;
	systemFileType: SystemFileType;
	updatesFileId?: InputMaybe<Scalars['String']>;
};

export type FileUploadResponse = {
	__typename?: 'FileUploadResponse';
	blobUploadUrls: Array<Scalars['String']>;
	expiresUtc: Scalars['Date'];
	fileUploadToken: Scalars['String'];
	multipartUploadId?: Maybe<Scalars['String']>;
	uploadId: Scalars['ID'];
};

export type FolderContents = {
	__typename?: 'FolderContents';
	files?: Maybe<Array<FileInformation>>;
	folders?: Maybe<Array<FolderReference>>;
};

export type FolderInformation = {
	__typename?: 'FolderInformation';
	contents: FolderContents;
	folderAncestors?: Maybe<Array<FolderReference>>;
	id: Scalars['ID'];
	itemsCount?: Maybe<Scalars['Int']>;
	name: Scalars['String'];
	relatedEntityId: Scalars['String'];
};

export type FolderReference = {
	__typename?: 'FolderReference';
	id: Scalars['ID'];
	name: Scalars['String'];
	parentFolder?: Maybe<FolderReference>;
};

export type FolderReferencesList = {
	__typename?: 'FolderReferencesList';
	folderReferences?: Maybe<Array<FolderReference>>;
};

export enum FolderSearchType {
	Any = 'Any',
	Files = 'Files',
	Folders = 'Folders',
}

export enum FoldersApiEntityType {
	Claim = 'Claim',
	Organization = 'Organization',
	User = 'User',
}

export type Follow = {
	__typename?: 'Follow';
	followedOrganizationEmblem?: Maybe<Emblem>;
	followedOrganizationId?: Maybe<Scalars['String']>;
	followedUserEmblem?: Maybe<Emblem>;
	followedUserId?: Maybe<Scalars['String']>;
	followingUserEmblem?: Maybe<Emblem>;
	followingUserId: Scalars['String'];
	id: Scalars['ID'];
};

export type GeoAddress = {
	__typename?: 'GeoAddress';
	address1?: Maybe<Scalars['String']>;
	address2?: Maybe<Scalars['String']>;
	adminArea1?: Maybe<AddressFieldLookup>;
	adminArea1Id?: Maybe<Scalars['String']>;
	adminArea2?: Maybe<AddressFieldLookup>;
	adminArea2Id?: Maybe<Scalars['String']>;
	coordinate: Location;
	countryId: Scalars['String'];
	municipality?: Maybe<Scalars['String']>;
	postalCode?: Maybe<Scalars['String']>;
};

export type GeoAddressInput = {
	address1?: InputMaybe<Scalars['String']>;
	address2?: InputMaybe<Scalars['String']>;
	adminArea1Id?: InputMaybe<Scalars['String']>;
	adminArea2Id?: InputMaybe<Scalars['String']>;
	coordinate: LocationInput;
	countryId: Scalars['String'];
	municipality?: InputMaybe<Scalars['String']>;
	postalCode?: InputMaybe<Scalars['String']>;
};

export type GeocodeResponse = {
	__typename?: 'GeocodeResponse';
	location: Location;
	viewport: Viewport;
};

export type GetProductsResponse = {
	__typename?: 'GetProductsResponse';
	products: Array<StripeProductInfo>;
};

export type HistoryLog = {
	__typename?: 'HistoryLog';
	changeUtc: Scalars['Date'];
	changes: Array<ChangedValue>;
	historyLogId: Scalars['String'];
	isAssumedIdentity: Scalars['Boolean'];
	serviceId?: Maybe<Scalars['String']>;
	serviceName?: Maybe<Scalars['String']>;
	siteUserId?: Maybe<Scalars['String']>;
	siteUserName?: Maybe<Scalars['String']>;
	userId?: Maybe<Scalars['String']>;
	userName?: Maybe<Scalars['String']>;
};

export type HistoryLogResponse = {
	__typename?: 'HistoryLogResponse';
	entityCurrentValue: Scalars['String'];
	entityId: Scalars['String'];
	entityName: Scalars['String'];
	historyLogs: Array<HistoryLog>;
	parent?: Maybe<ParentEntity>;
};

export enum Industry {
	Accounting = 'Accounting',
	Banking = 'Banking',
	BuildingMaterials = 'BuildingMaterials',
	CivicAndSocialOrganization = 'CivicAndSocialOrganization',
	CommercialRealEstate = 'CommercialRealEstate',
	ComputerAndNetworkSecurity = 'ComputerAndNetworkSecurity',
	ComputerSoftware = 'ComputerSoftware',
	Construction = 'Construction',
	ConsumerServices = 'ConsumerServices',
	ELearning = 'ELearning',
	EnvironmentalServices = 'EnvironmentalServices',
	EventsServices = 'EventsServices',
	FinancialServices = 'FinancialServices',
	GovernmentAdministration = 'GovernmentAdministration',
	InformationTechnologyAndServices = 'InformationTechnologyAndServices',
	Insurance = 'Insurance',
	LawPractice = 'LawPractice',
	MarketingAndAdvertising = 'MarketingAndAdvertising',
	MechanicalOrIndustrialEngineering = 'MechanicalOrIndustrialEngineering',
	NonProfitOrganizationManagement = 'NonProfitOrganizationManagement',
	ProfessionalTrainingAndCoaching = 'ProfessionalTrainingAndCoaching',
	PublicRelationsAndCommunications = 'PublicRelationsAndCommunications',
	RealEstate = 'RealEstate',
	SecurityAndInvestigations = 'SecurityAndInvestigations',
}

export type Invoice = {
	__typename?: 'Invoice';
	invoiceAmount: Scalars['Float'];
	invoiceDate: Scalars['Date'];
	invoiceNumber?: Maybe<Scalars['String']>;
	invoicePdfUrl?: Maybe<Scalars['String']>;
	isSubscription: Scalars['Boolean'];
	status: InvoiceStatus;
};

export type InvoiceData = {
	__typename?: 'InvoiceData';
	autoAdvance: Scalars['Boolean'];
	collectionMethod?: Maybe<Scalars['String']>;
	currency?: Maybe<Scalars['String']>;
	customer: Scalars['String'];
	description?: Maybe<Scalars['String']>;
	invoiceDate: Scalars['Date'];
	invoiceItems: Array<InvoiceItemData>;
	invoiceUrl?: Maybe<Scalars['String']>;
	organizationName: Scalars['String'];
};

export type InvoiceDataResponse = {
	__typename?: 'InvoiceDataResponse';
	invoices: Array<InvoiceData>;
};

export type InvoiceItemData = {
	__typename?: 'InvoiceItemData';
	currency?: Maybe<Scalars['String']>;
	customer: Scalars['String'];
	description: Scalars['String'];
	endDate?: Maybe<Scalars['Date']>;
	price?: Maybe<Scalars['String']>;
	quantity?: Maybe<Scalars['Float']>;
	startDate?: Maybe<Scalars['Date']>;
	unitAmountDecimal?: Maybe<Scalars['Float']>;
};

export type InvoiceResponse = {
	__typename?: 'InvoiceResponse';
	invoices: Array<Invoice>;
	outstandingBalance: Scalars['Float'];
};

export enum InvoiceStatus {
	Deleted = 'Deleted',
	Draft = 'Draft',
	Open = 'Open',
	Overdue = 'Overdue',
	Paid = 'Paid',
	Uncollectible = 'Uncollectible',
	Unknown = 'Unknown',
	Void = 'Void',
}

export type IsLinkedResponse = {
	__typename?: 'IsLinkedResponse';
	isLinked: Scalars['Boolean'];
};

export enum JobFunctions {
	CarrierRepresentative = 'CarrierRepresentative',
	Employee = 'Employee',
	InsuranceAdjuster = 'InsuranceAdjuster',
	InternalAuditor = 'InternalAuditor',
	InvitedGuest = 'InvitedGuest',
	None = 'None',
	PropertyInspector = 'PropertyInspector',
	ServiceProvider = 'ServiceProvider',
	TechnicalUser = 'TechnicalUser',
}

export type JobIndustry = {
	__typename?: 'JobIndustry';
	id: Scalars['String'];
	name: Scalars['String'];
};

export type JobSpecialty = {
	__typename?: 'JobSpecialty';
	id: Scalars['String'];
	name: Scalars['String'];
};

export type JobType = {
	__typename?: 'JobType';
	id: Scalars['String'];
	name: Scalars['String'];
	workableCoverages: Array<Scalars['String']>;
};

export enum JournalEntityType {
	DataTransformation = 'DataTransformation',
	Invoicing = 'Invoicing',
	QuickBooks = 'QuickBooks',
}

export type JournalEntry = {
	__typename?: 'JournalEntry';
	dateTimeUtc: Scalars['Date'];
	id: Scalars['String'];
	journalEntityId: Scalars['String'];
	journalEntityName?: Maybe<Scalars['String']>;
	journalEntityType: JournalEntityType;
	message?: Maybe<Scalars['String']>;
	reference?: Maybe<Scalars['String']>;
	severity: JournalSeverity;
};

export type JournalSearchRequest = {
	entityId?: InputMaybe<Scalars['String']>;
	offset: Scalars['Float'];
	pageSize: Scalars['Float'];
	searchFor?: InputMaybe<Scalars['String']>;
	severity?: InputMaybe<JournalSeverity>;
	sortDirection: ListSortDirection;
	sortedBy?: InputMaybe<Scalars['String']>;
};

export enum JournalSeverity {
	Error = 'Error',
	Information = 'Information',
	Verbose = 'Verbose',
	Warning = 'Warning',
}

export type KeyValuePair = {
	__typename?: 'KeyValuePair';
	key: Scalars['String'];
	value?: Maybe<Scalars['String']>;
};

export type KeyValuePairInput = {
	key: Scalars['String'];
	value?: InputMaybe<Scalars['String']>;
};

export type Label = {
	__typename?: 'Label';
	colorName?: Maybe<Scalars['String']>;
	entityId: Scalars['String'];
	id: Scalars['ID'];
	name: Scalars['String'];
	parentLabelId?: Maybe<Scalars['String']>;
	type: LabelType;
};

export type LabelCreateRequest = {
	colorName: Scalars['String'];
	name: Scalars['String'];
	parentLabelId?: InputMaybe<Scalars['String']>;
};

export type LabelEditRequest = {
	colorName: Scalars['String'];
	name: Scalars['String'];
};

export enum LabelType {
	All = 'All',
	Custom = 'Custom',
	Inbox = 'Inbox',
	Trash = 'Trash',
}

export type LabelsResponse = {
	__typename?: 'LabelsResponse';
	labels: Array<Label>;
};

export type Language = {
	__typename?: 'Language';
	code?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	name?: Maybe<Scalars['String']>;
};

/** Response object for getting a user's latest conversation. */
export type LatestChatConversationResponse = {
	__typename?: 'LatestChatConversationResponse';
	/** The conversation. */
	conversation?: Maybe<UserChatMessageResponse>;
};

export type LegacyClaimSummariesResult = {
	__typename?: 'LegacyClaimSummariesResult';
	claimSummaries: Array<LegacyClaimSummary>;
};

export type LegacyClaimSummary = {
	__typename?: 'LegacyClaimSummary';
	claimFileId?: Maybe<Scalars['String']>;
	claimId: Scalars['Int'];
	company?: Maybe<Scalars['String']>;
	companyFileId?: Maybe<Scalars['String']>;
	dueDate?: Maybe<Scalars['Date']>;
	insuredCompany?: Maybe<Scalars['String']>;
	insuredFirstName?: Maybe<Scalars['String']>;
	insuredLastName?: Maybe<Scalars['String']>;
	lossCity?: Maybe<Scalars['String']>;
	lossState?: Maybe<Scalars['String']>;
	receivedDate?: Maybe<Scalars['Date']>;
};

export type LegacyClientCompany = {
	__typename?: 'LegacyClientCompany';
	companyCity?: Maybe<Scalars['String']>;
	companyID: Scalars['Int'];
	companyName?: Maybe<Scalars['String']>;
	companyState?: Maybe<Scalars['String']>;
};

export type LegacyDatabase = {
	__typename?: 'LegacyDatabase';
	name: Scalars['String'];
	systemId: Scalars['String'];
	systemUrl: Scalars['String'];
};

export type LegacyInvitationInfoResponse = {
	__typename?: 'LegacyInvitationInfoResponse';
	adjCoLogo: Scalars['String'];
	adjCoName: Scalars['String'];
	systemUrl: Scalars['String'];
	userName: Scalars['String'];
};

export type LegacyUserAdjustmentCompanies = {
	__typename?: 'LegacyUserAdjustmentCompanies';
	adjustmentCompanies?: Maybe<Array<LegacyUserAdjustmentCompany>>;
};

export type LegacyUserAdjustmentCompaniesWithMetrics = {
	__typename?: 'LegacyUserAdjustmentCompaniesWithMetrics';
	adjustmentCompaniesWithMetrics?: Maybe<Array<LegacyUserAdjustmentCompanyWithMetrics>>;
};

export type LegacyUserAdjustmentCompany = {
	__typename?: 'LegacyUserAdjustmentCompany';
	acid: Scalars['Int'];
	adjCoCity?: Maybe<Scalars['String']>;
	adjCoLogo?: Maybe<Scalars['String']>;
	adjCoName?: Maybe<Scalars['String']>;
	adjCoState?: Maybe<Scalars['String']>;
	clientCompany?: Maybe<LegacyClientCompany>;
	dbid: Scalars['Int'];
	systemUrl?: Maybe<Scalars['String']>;
	userEmail?: Maybe<Scalars['String']>;
	userFirstName?: Maybe<Scalars['String']>;
	userId: Scalars['Int'];
	userLastName?: Maybe<Scalars['String']>;
	userRole?: Maybe<Scalars['String']>;
};

export type LegacyUserAdjustmentCompanyWithMetrics = {
	__typename?: 'LegacyUserAdjustmentCompanyWithMetrics';
	acid: Scalars['Int'];
	adjCoCity?: Maybe<Scalars['String']>;
	adjCoLogo?: Maybe<Scalars['String']>;
	adjCoName?: Maybe<Scalars['String']>;
	adjCoState?: Maybe<Scalars['String']>;
	clientCompany?: Maybe<LegacyClientCompany>;
	companyClaimCount?: Maybe<Scalars['Float']>;
	companyImpendingClaimCount?: Maybe<Scalars['Float']>;
	companyOverdueClaimCount?: Maybe<Scalars['Float']>;
	companyRecentlyReceivedClaimCount?: Maybe<Scalars['Float']>;
	dbid: Scalars['Int'];
	systemUrl?: Maybe<Scalars['String']>;
	userClaimCount?: Maybe<Scalars['Float']>;
	userEmail?: Maybe<Scalars['String']>;
	userFirstName?: Maybe<Scalars['String']>;
	userId: Scalars['Int'];
	userImpendingClaimCount?: Maybe<Scalars['Float']>;
	userLastName?: Maybe<Scalars['String']>;
	userOverdueClaimCount?: Maybe<Scalars['Float']>;
	userRecentlyReceivedClaimCount?: Maybe<Scalars['Float']>;
	userRole?: Maybe<Scalars['String']>;
};

export type LinkedAdjustmentCompanyInfo = {
	__typename?: 'LinkedAdjustmentCompanyInfo';
	acid: Scalars['Float'];
	logo?: Maybe<Scalars['String']>;
	name?: Maybe<Scalars['String']>;
	systemUrl?: Maybe<Scalars['String']>;
};

export type ListResponseOfConversationSummary = {
	__typename?: 'ListResponseOfConversationSummary';
	items: Array<ConversationSummary>;
};

export type ListResponseOfEmblem = {
	__typename?: 'ListResponseOfEmblem';
	items: Array<Emblem>;
};

export enum ListSortDirection {
	Ascending = 'Ascending',
	Descending = 'Descending',
}

export type Location = {
	__typename?: 'Location';
	latitude: Scalars['Float'];
	longitude: Scalars['Float'];
};

export type LocationAddress = {
	__typename?: 'LocationAddress';
	address: GeoAddress;
	addressType: Scalars['String'];
	fromDate?: Maybe<Scalars['Date']>;
	id: Scalars['ID'];
	parentId: Scalars['String'];
	toDate?: Maybe<Scalars['Date']>;
};

export type LocationAddressUpdate = {
	address: GeoAddressInput;
	addressType: Scalars['String'];
	fromDate?: InputMaybe<Scalars['Date']>;
	toDate?: InputMaybe<Scalars['Date']>;
};

export type LocationInput = {
	latitude: Scalars['Float'];
	longitude: Scalars['Float'];
};

export type LossType = {
	__typename?: 'LossType';
	code: Scalars['String'];
	coverageId: Scalars['String'];
	id: Scalars['String'];
	name: Scalars['String'];
};

export type Message = {
	__typename?: 'Message';
	attachmentFileIds: Array<Scalars['String']>;
	attachments: Array<FileInformation>;
	createdUtc: Scalars['Date'];
	creatorEntityId: Scalars['String'];
	creatorEntityType: ParticipantEntityType;
	id: Scalars['ID'];
	messageParticipants: Array<MessageParticipant>;
	messageText: Scalars['String'];
	notificationId?: Maybe<Scalars['String']>;
};

export type MessageParticipant = {
	__typename?: 'MessageParticipant';
	entityId: Scalars['String'];
	entityType: ParticipantEntityType;
	firstReadUtc?: Maybe<Scalars['Date']>;
	id: Scalars['ID'];
};

/** Messaging draft request. */
export type MessagingDraftRequest = {
	/** The chat conversation ID, when continuing an existing conversation. Pass null to start a new conversation. */
	chatConversationId?: InputMaybe<Scalars['ID']>;
	/** The ID for the conversation into which this draft will be used. If provided, conversation history will be included in requests to AI. Pass null to NOT include history. */
	commsConversationId?: InputMaybe<Scalars['ID']>;
	/** The (optional) initial text of the conversation. Should only be provided when starting a new conversation. */
	initialText?: InputMaybe<Scalars['String']>;
	/** The user's prompt, i.e. 'Tell them I can't make it tomorrow.' */
	prompt: Scalars['String'];
};

/** Messaging draft response. */
export type MessagingDraftResponse = {
	__typename?: 'MessagingDraftResponse';
	/** The ID of the chat conversation. */
	chatConversationId: Scalars['ID'];
	/** The generated draft text. */
	draftText?: Maybe<Scalars['String']>;
};

export type Mutation = {
	__typename?: 'Mutation';
	billingAdHocBillingItemAdd: AdHocBillingItem;
	billingAdHocBillingItemDelete: Scalars['Boolean'];
	billingAdHocBillingItemUpdate: AdHocBillingItem;
	billingOrganizationOptionsUpdate: Scalars['Boolean'];
	/** Deletes a saved chat conversation. */
	chatConversationDeleteSaved: Scalars['Boolean'];
	/** Saves a chat conversation so it can be reloaded and resumed in the future. */
	chatConversationSave: SavedConversation;
	/** Updates a users custom instructions. */
	chatCustomInstructionsUpdate: Scalars['Boolean'];
	/** Prepares a file for use within a chat conversation. */
	chatFileRequest: UserChatFile;
	/** Adds or updates user feedback on a chat message. */
	chatMessageLeaveFeedback: UserChatMessage;
	/** Adds a user message to a new or existing chat conversation. */
	chatMessageRequest: UserChatMessageResponse;
	/** Generates a summary of the provided set of claim notes. */
	claimNotesSummaryRequest: ClaimNotesSummaryResponse;
	cognitoUserGlobalSignOut: Scalars['Boolean'];
	commitFile: FileInformation;
	conversationAdd: ConversationAndSummary;
	conversationAddParticipant: ConversationAndSummary;
	conversationEmptyTrash: Scalars['Boolean'];
	conversationLabelApply: ConversationAndSummary;
	conversationLabelArchive: ConversationAndSummary;
	conversationLabelCreate: Label;
	conversationLabelDelete: Scalars['Boolean'];
	conversationLabelRemove: ConversationAndSummary;
	conversationLabelUpdate: Label;
	conversationMarkRead: ConversationAndSummary;
	conversationMessageAdd: ConversationAndSummary;
	conversationRemoveParticipant: ConversationAndSummary;
	conversationUpdate: ConversationAndSummary;
	deleteFile: Scalars['Boolean'];
	deleteFileInstance: Scalars['Boolean'];
	deleteFiles: Scalars['Boolean'];
	/** Deletes a prompt clipping. */
	deletePromptClipping: Scalars['Boolean'];
	deleteRelationship: Scalars['Boolean'];
	fileAccessLevelChange: Array<FileInformation>;
	fileFolderChange: Array<FileInformation>;
	fileInstanceAccessLevelChange: Array<FileInstanceInformation>;
	fileInstanceCopy: FileInformation;
	fileRename: FileInformation;
	fileSetCurrentInstance: FileInformation;
	fileVirusStatusUpdate: Scalars['Boolean'];
	folderCreate: FolderInformation;
	folderDelete: Scalars['Boolean'];
	folderUpdate: FolderInformation;
	forgetCognitoDevices: Scalars['Boolean'];
	legacyAdjustmentCompanyCreateAndLink: OrganizationCreationResponse;
	legacyAdjustmentCompanyLink: Scalars['Boolean'];
	legacyInvitationAccept: Scalars['Boolean'];
	/** Generates a new messaging draft based on the given conversation. */
	messagingDraftRequest: MessagingDraftResponse;
	notificationDismissAll: Scalars['Boolean'];
	notificationDismissByGrouping: Scalars['Boolean'];
	notificationDismissById: Scalars['Boolean'];
	notificationMarkAllRead: Scalars['Boolean'];
	notificationMarkReadByGrouping: Scalars['Boolean'];
	notificationMarkReadById: Scalars['Boolean'];
	notificationSettingsUpdate: Scalars['Boolean'];
	orgAccountQuickLinkCreate: QuickLink;
	orgAccountQuickLinkDelete: Scalars['Boolean'];
	orgAccountQuickLinkUpdate: QuickLink;
	organizationAboutUpdate: OrganizationProfile;
	organizationAccountActivate: Scalars['Boolean'];
	organizationAccountDeactivate: Scalars['Boolean'];
	organizationAccountLocationAddressAddOrUpdate: LocationAddress;
	organizationAccountLocationAddressDelete: Scalars['Boolean'];
	organizationAccountUpdate: OrganizationAccount;
	organizationAccountUpdateConversationInsurance: Scalars['Boolean'];
	organizationAssociateDeactivate: Scalars['Boolean'];
	organizationAssociateInvitationAccept: Scalars['Boolean'];
	organizationAssociateInvitationDecline: Scalars['Boolean'];
	organizationAssociateInvitationDelete: Scalars['Boolean'];
	organizationAssociateInvitationSend: Scalars['Boolean'];
	organizationAssociateReactivate: Scalars['Boolean'];
	organizationAssociateUpdate: Scalars['Boolean'];
	organizationAvatarOrBannerUpdate: Emblem;
	organizationBusinessLineCreate: OrganizationProfile;
	organizationBusinessLineDelete: Scalars['Boolean'];
	organizationBusinessLineUpdate: BusinessLine;
	organizationCommitFile: FileInformation;
	organizationCreate: OrganizationCreationResponse;
	organizationCreateAssociateNotification: Scalars['Boolean'];
	organizationDeleteFileInstance: Scalars['Boolean'];
	organizationDeleteFiles: Scalars['Boolean'];
	organizationProfileUpdate: OrganizationProfile;
	organizationRepresentativeDelete: Scalars['Boolean'];
	organizationRepresentativeUpsert: Representative;
	organizationRoleCreate: OrganizationRole;
	organizationRoleDelete: Scalars['Boolean'];
	organizationRoleUpdate: OrganizationRole;
	organizationSetPrivate: Scalars['Boolean'];
	patchOrgConfigurationSettings: OrgDashboardConfigurationResponse;
	patchUserDashboardConfigurationSettings: UserDashboardConfigurationResponse;
	/** Creates a new prompt clipping. */
	promptClippingRequest: PromptClippingResponse;
	resetMfa: Scalars['Boolean'];
	seedOrgOrAncestor: Scalars['Boolean'];
	setChild: Scalars['Boolean'];
	socialOrganizationAcceptConnection: Connection;
	socialOrganizationCancelConnectionRequest: Scalars['Boolean'];
	socialOrganizationDisconnect: Scalars['Boolean'];
	socialOrganizationIgnoreConnection: Scalars['Boolean'];
	socialOrganizationRequestConnection: Connection;
	socialUserAcceptConnection: Connection;
	socialUserCancelConnectionRequest: Scalars['Boolean'];
	socialUserDisconnect: Scalars['Boolean'];
	socialUserFollow: Scalars['Boolean'];
	socialUserIgnoreConnection: Scalars['Boolean'];
	socialUserRequestConnection: Connection;
	socialUserUnfollow: Scalars['Boolean'];
	storeCognitoDevice: Scalars['Boolean'];
	updateOrganizationTeamMembers: Scalars['Boolean'];
	/** Updates a prompt clipping. */
	updatePromptClipping: PromptClippingResponse;
	userAccountActivationUpdate: UserAccount;
	userAccountEmergencyContactDelete: Scalars['Boolean'];
	userAccountEmergencyContactUpdate: AccountEmergencyContact;
	userAccountLocationAddressAddOrUpdate: LocationAddress;
	userAccountLocationAddressDelete: Scalars['Boolean'];
	userAccountPrivacyUpdate: Scalars['Boolean'];
	userAccountQuickLinkCreate: QuickLink;
	userAccountQuickLinkDelete: Scalars['Boolean'];
	userAccountQuickLinkUpdate: QuickLink;
	userAccountUpdate: UserAccount;
	userAvatarOrBannerUpdate: Emblem;
	userProfileCanAdjusterLicensingDelete: Scalars['Boolean'];
	userProfileCanAdjusterLicensingSetManual: AdjusterLicensingSummary;
	userProfileEducationAdd: UserEducation;
	userProfileEducationDelete: Scalars['Boolean'];
	userProfileEducationUpdate: UserEducation;
	userProfileExperienceCompanyAndPositionCreate: UserExperienceCompany;
	userProfileExperienceCompanyDelete: Scalars['Boolean'];
	userProfileExperienceCompanyUpdate: UserExperienceCompany;
	userProfileExperiencePositionCreate: UserExperiencePosition;
	userProfileExperiencePositionDelete: Scalars['Boolean'];
	userProfileExperiencePositionUpdate: UserExperiencePosition;
	userProfileSkillAdd: UserSkill;
	userProfileSkillDelete: Scalars['Boolean'];
	userProfileSkillSetDisplayOrder: Scalars['Boolean'];
	userProfileSkillsEndorse: Scalars['Boolean'];
	userProfileSkillsEndorsementVisibility: Scalars['Boolean'];
	userProfileSkillsUpdate: UserSkillPlacesUsedResponse;
	userProfileUpdate: UserProfile;
	userProfileUsaAdjusterLicensingDelete: Scalars['Boolean'];
	userProfileUsaAdjusterLicensingSetManual: AdjusterLicensingSummary;
	userProfileUsaAdjusterLicensingSetNipr: AdjusterLicensingSummary;
	userRegistration: UserAccount;
	userSiteRoleAdd: SiteRole;
	userSiteRoleDelete: Scalars['Boolean'];
	userSiteRoleUpdate: SiteRole;
	userSiteUserCreateSystemNotification: Scalars['Boolean'];
	userSiteUserCreateTestData: Scalars['Boolean'];
	userSiteUserInvitationDelete: Scalars['Boolean'];
	userSiteUserInvitationResend: Scalars['Boolean'];
	userSiteUserInvitationSend: SiteUserInvitation;
	userSiteUserUpdate: SiteUser;
};

export type MutationBillingAdHocBillingItemAddArgs = {
	organizationId: Scalars['String'];
	update: AdHocBillingItemUpdate;
};

export type MutationBillingAdHocBillingItemDeleteArgs = {
	billingItemId: Scalars['String'];
	organizationId: Scalars['String'];
};

export type MutationBillingAdHocBillingItemUpdateArgs = {
	billingItemId: Scalars['String'];
	organizationId: Scalars['String'];
	update: AdHocBillingItemUpdate;
};

export type MutationBillingOrganizationOptionsUpdateArgs = {
	options: OrganizationBillingOptionsInput;
	organizationId: Scalars['String'];
};

export type MutationChatConversationDeleteSavedArgs = {
	conversationId: Scalars['ID'];
	userId: Scalars['ID'];
};

export type MutationChatConversationSaveArgs = {
	conversationId: Scalars['ID'];
	request: SaveConversationRequest;
	userId: Scalars['ID'];
};

export type MutationChatCustomInstructionsUpdateArgs = {
	customInstructions: CustomInstructionsInput;
	userId: Scalars['ID'];
};

export type MutationChatFileRequestArgs = {
	request: ChatFileRequest;
	userId: Scalars['ID'];
};

export type MutationChatMessageLeaveFeedbackArgs = {
	messageId: Scalars['ID'];
	request: ChatMessageFeedback;
	userId: Scalars['ID'];
};

export type MutationChatMessageRequestArgs = {
	request: UserChatMessageRequest;
	userId: Scalars['ID'];
};

export type MutationClaimNotesSummaryRequestArgs = {
	request: ClaimNotesSummaryRequest;
};

export type MutationCognitoUserGlobalSignOutArgs = {
	userId: Scalars['ID'];
};

export type MutationCommitFileArgs = {
	entityId: Scalars['String'];
	entityType: FoldersApiEntityType;
	request: FileUploadCommitRequest;
};

export type MutationConversationAddArgs = {
	entityId: Scalars['String'];
	entityType: ConversationApiEntityType;
	metadata?: InputMaybe<Array<KeyValuePairInput>>;
	request: NewConversationRequest;
};

export type MutationConversationAddParticipantArgs = {
	conversationId: Scalars['String'];
	entityId: Scalars['String'];
	entityType: ConversationApiEntityType;
	metadata?: InputMaybe<Array<KeyValuePairInput>>;
	request: NewParticipantOptions;
};

export type MutationConversationEmptyTrashArgs = {
	entityId: Scalars['String'];
	entityType: ConversationApiEntityType;
	metadata?: InputMaybe<Array<KeyValuePairInput>>;
};

export type MutationConversationLabelApplyArgs = {
	conversationId: Scalars['String'];
	entityId: Scalars['String'];
	entityType: ConversationApiEntityType;
	labelId: Scalars['String'];
	metadata?: InputMaybe<Array<KeyValuePairInput>>;
};

export type MutationConversationLabelArchiveArgs = {
	conversationId: Scalars['String'];
	entityId: Scalars['String'];
	entityType: ConversationApiEntityType;
	metadata?: InputMaybe<Array<KeyValuePairInput>>;
};

export type MutationConversationLabelCreateArgs = {
	entityId: Scalars['String'];
	entityType: ConversationApiEntityType;
	labelRequest: LabelCreateRequest;
	metadata?: InputMaybe<Array<KeyValuePairInput>>;
};

export type MutationConversationLabelDeleteArgs = {
	entityId: Scalars['String'];
	entityType: ConversationApiEntityType;
	labelId: Scalars['String'];
	metadata?: InputMaybe<Array<KeyValuePairInput>>;
};

export type MutationConversationLabelRemoveArgs = {
	conversationId: Scalars['String'];
	entityId: Scalars['String'];
	entityType: ConversationApiEntityType;
	labelId: Scalars['String'];
	metadata?: InputMaybe<Array<KeyValuePairInput>>;
};

export type MutationConversationLabelUpdateArgs = {
	entityId: Scalars['String'];
	entityType: ConversationApiEntityType;
	labelId: Scalars['String'];
	labelRequest: LabelEditRequest;
	metadata?: InputMaybe<Array<KeyValuePairInput>>;
};

export type MutationConversationMarkReadArgs = {
	conversationId: Scalars['String'];
	entityId: Scalars['String'];
	entityType: ConversationApiEntityType;
	metadata?: InputMaybe<Array<KeyValuePairInput>>;
};

export type MutationConversationMessageAddArgs = {
	conversationId: Scalars['String'];
	entityId: Scalars['String'];
	entityType: ConversationApiEntityType;
	metadata?: InputMaybe<Array<KeyValuePairInput>>;
	request: NewMessageRequest;
};

export type MutationConversationRemoveParticipantArgs = {
	conversationId: Scalars['String'];
	entityId: Scalars['String'];
	entityType: ConversationApiEntityType;
	metadata?: InputMaybe<Array<KeyValuePairInput>>;
	request: Participant;
};

export type MutationConversationUpdateArgs = {
	conversationId: Scalars['String'];
	entityId: Scalars['String'];
	entityType: ConversationApiEntityType;
	metadata?: InputMaybe<Array<KeyValuePairInput>>;
	request: UpdateConversationRequest;
};

export type MutationDeleteFileArgs = {
	entityId: Scalars['String'];
	entityType: FoldersApiEntityType;
	request: FileDeleteRequest;
};

export type MutationDeleteFileInstanceArgs = {
	entityId: Scalars['String'];
	entityType: FoldersApiEntityType;
	request: FileDeleteRequest;
};

export type MutationDeleteFilesArgs = {
	entityId: Scalars['String'];
	entityType: FoldersApiEntityType;
	request: FileDeleteRequest;
};

export type MutationDeletePromptClippingArgs = {
	clippingId: Scalars['ID'];
	userId: Scalars['ID'];
};

export type MutationDeleteRelationshipArgs = {
	childOrgId: Scalars['ID'];
	parentOrgId: Scalars['ID'];
};

export type MutationFileAccessLevelChangeArgs = {
	request: FileAccessLevelChangeRequest;
};

export type MutationFileFolderChangeArgs = {
	request: ChangeFileFolderRequest;
};

export type MutationFileInstanceAccessLevelChangeArgs = {
	request: FileAccessLevelChangeRequest;
};

export type MutationFileInstanceCopyArgs = {
	fileInstanceId: Scalars['String'];
	request: FileInstanceCopyRequest;
};

export type MutationFileRenameArgs = {
	fileId: Scalars['String'];
	request: FileRenameRequest;
};

export type MutationFileSetCurrentInstanceArgs = {
	fileId: Scalars['String'];
	request: FileSetCurrentInstanceRequest;
};

export type MutationFileVirusStatusUpdateArgs = {
	result: VirusScanResult;
};

export type MutationFolderCreateArgs = {
	entityId: Scalars['String'];
	entityType: FoldersApiEntityType;
	request: UpsertFolderRequest;
};

export type MutationFolderDeleteArgs = {
	entityId: Scalars['String'];
	entityType: FoldersApiEntityType;
	folderId: Scalars['String'];
};

export type MutationFolderUpdateArgs = {
	entityId: Scalars['String'];
	entityType: FoldersApiEntityType;
	folderId: Scalars['String'];
	request: UpsertFolderRequest;
};

export type MutationForgetCognitoDevicesArgs = {
	userId: Scalars['ID'];
};

export type MutationLegacyAdjustmentCompanyCreateAndLinkArgs = {
	request: OrganizationCreateAndLinkRequest;
};

export type MutationLegacyAdjustmentCompanyLinkArgs = {
	orgId: Scalars['ID'];
	request: OrganizationLinkRequest;
};

export type MutationLegacyInvitationAcceptArgs = {
	invitationId: Scalars['ID'];
};

export type MutationMessagingDraftRequestArgs = {
	entityId: Scalars['String'];
	entityType: ConversationApiEntityType;
	request: MessagingDraftRequest;
};

export type MutationNotificationDismissAllArgs = {
	types?: InputMaybe<Array<NotificationType>>;
};

export type MutationNotificationDismissByGroupingArgs = {
	inAppText: Scalars['String'];
	notificationType: NotificationType;
	relatedEntityId: Scalars['ID'];
};

export type MutationNotificationDismissByIdArgs = {
	notificationId: Scalars['ID'];
};

export type MutationNotificationMarkAllReadArgs = {
	types?: InputMaybe<Array<NotificationType>>;
};

export type MutationNotificationMarkReadByGroupingArgs = {
	inAppText: Scalars['String'];
	notificationType: NotificationType;
	relatedEntityId: Scalars['ID'];
};

export type MutationNotificationMarkReadByIdArgs = {
	notificationId: Scalars['ID'];
};

export type MutationNotificationSettingsUpdateArgs = {
	update: NotificationSettingsInput;
	userId: Scalars['ID'];
};

export type MutationOrgAccountQuickLinkCreateArgs = {
	orgId: Scalars['ID'];
	request: QuickLinkCreate;
};

export type MutationOrgAccountQuickLinkDeleteArgs = {
	orgId: Scalars['ID'];
	quickLinkId: Scalars['ID'];
};

export type MutationOrgAccountQuickLinkUpdateArgs = {
	orgId: Scalars['ID'];
	quickLinkId: Scalars['ID'];
	request: QuickLinkPatch;
};

export type MutationOrganizationAboutUpdateArgs = {
	organizationAboutUpdate: OrganizationAboutUpdate;
	organizationId: Scalars['ID'];
};

export type MutationOrganizationAccountActivateArgs = {
	organizationId: Scalars['ID'];
};

export type MutationOrganizationAccountDeactivateArgs = {
	organizationId: Scalars['ID'];
};

export type MutationOrganizationAccountLocationAddressAddOrUpdateArgs = {
	locationAddressId?: InputMaybe<Scalars['ID']>;
	organizationId: Scalars['ID'];
	update: LocationAddressUpdate;
};

export type MutationOrganizationAccountLocationAddressDeleteArgs = {
	locationAddressId: Scalars['ID'];
	organizationId: Scalars['ID'];
};

export type MutationOrganizationAccountUpdateArgs = {
	organizationAccount: OrganizationAccountUpdate;
	organizationId: Scalars['ID'];
};

export type MutationOrganizationAccountUpdateConversationInsuranceArgs = {
	organizationId: Scalars['ID'];
	request: ConversationInsuranceInput;
};

export type MutationOrganizationAssociateDeactivateArgs = {
	associateId: Scalars['ID'];
	organizationId: Scalars['ID'];
};

export type MutationOrganizationAssociateInvitationAcceptArgs = {
	invitationId: Scalars['String'];
};

export type MutationOrganizationAssociateInvitationDeclineArgs = {
	invitationId: Scalars['String'];
};

export type MutationOrganizationAssociateInvitationDeleteArgs = {
	invitationId: Scalars['ID'];
	organizationId: Scalars['ID'];
};

export type MutationOrganizationAssociateInvitationSendArgs = {
	inviteRequest: AssociateInvitationRequest;
	organizationId: Scalars['ID'];
};

export type MutationOrganizationAssociateReactivateArgs = {
	associateId: Scalars['ID'];
	organizationId: Scalars['ID'];
	organizationRoleId: Scalars['ID'];
};

export type MutationOrganizationAssociateUpdateArgs = {
	associateId: Scalars['ID'];
	organizationId: Scalars['ID'];
	update: AssociateUpdate;
};

export type MutationOrganizationAvatarOrBannerUpdateArgs = {
	avatarUploadToken?: InputMaybe<Scalars['String']>;
	bannerUploadToken?: InputMaybe<Scalars['String']>;
	organizationId: Scalars['ID'];
};

export type MutationOrganizationBusinessLineCreateArgs = {
	businessLineCreate: BusinessLineUpdate;
	organizationId: Scalars['ID'];
};

export type MutationOrganizationBusinessLineDeleteArgs = {
	lineId: Scalars['ID'];
	organizationId: Scalars['ID'];
};

export type MutationOrganizationBusinessLineUpdateArgs = {
	businessLineCreate: BusinessLineUpdate;
	lineId: Scalars['ID'];
	organizationId: Scalars['ID'];
};

export type MutationOrganizationCommitFileArgs = {
	orgId: Scalars['String'];
	request: FileUploadCommitRequest;
};

export type MutationOrganizationCreateArgs = {
	organizationCreation: OrganizationCreation;
};

export type MutationOrganizationCreateAssociateNotificationArgs = {
	organizationId: Scalars['String'];
	request: OrganizationNotificationRequest;
};

export type MutationOrganizationDeleteFileInstanceArgs = {
	orgId: Scalars['String'];
	request: FileDeleteRequest;
};

export type MutationOrganizationDeleteFilesArgs = {
	orgId: Scalars['String'];
	request: FileDeleteRequest;
};

export type MutationOrganizationProfileUpdateArgs = {
	organizationId: Scalars['ID'];
	organizationProfileUpdate: OrganizationProfileUpdate;
};

export type MutationOrganizationRepresentativeDeleteArgs = {
	organizationId: Scalars['ID'];
	representativeId: Scalars['ID'];
};

export type MutationOrganizationRepresentativeUpsertArgs = {
	organizationId: Scalars['ID'];
	representativeId?: InputMaybe<Scalars['ID']>;
	update: RepresentativeUpdate;
};

export type MutationOrganizationRoleCreateArgs = {
	organizationId: Scalars['ID'];
	role: OrganizationRoleUpdate;
};

export type MutationOrganizationRoleDeleteArgs = {
	organizationId: Scalars['ID'];
	roleId: Scalars['ID'];
};

export type MutationOrganizationRoleUpdateArgs = {
	organizationId: Scalars['ID'];
	role: OrganizationRoleUpdate;
	roleId: Scalars['ID'];
};

export type MutationOrganizationSetPrivateArgs = {
	orgId: Scalars['ID'];
	private: Scalars['Boolean'];
};

export type MutationPatchOrgConfigurationSettingsArgs = {
	orgId: Scalars['ID'];
	patch: OrgDashboardConfigurationPatch;
};

export type MutationPatchUserDashboardConfigurationSettingsArgs = {
	patch: UserDashboardConfigurationPatch;
	userId: Scalars['ID'];
};

export type MutationPromptClippingRequestArgs = {
	request: PromptClippingRequest;
	userId: Scalars['ID'];
};

export type MutationResetMfaArgs = {
	userId: Scalars['ID'];
};

export type MutationSeedOrgOrAncestorArgs = {
	orgToSeedId: Scalars['ID'];
	sourceOrgId: Scalars['ID'];
};

export type MutationSetChildArgs = {
	childOrgId: Scalars['ID'];
	parentOrgId: Scalars['ID'];
};

export type MutationSocialOrganizationAcceptConnectionArgs = {
	connectionId: Scalars['ID'];
	organizationId: Scalars['ID'];
};

export type MutationSocialOrganizationCancelConnectionRequestArgs = {
	connectionId: Scalars['ID'];
	organizationId: Scalars['ID'];
};

export type MutationSocialOrganizationDisconnectArgs = {
	connectionId: Scalars['ID'];
	organizationId: Scalars['ID'];
};

export type MutationSocialOrganizationIgnoreConnectionArgs = {
	connectionId: Scalars['ID'];
	organizationId: Scalars['ID'];
};

export type MutationSocialOrganizationRequestConnectionArgs = {
	organizationId: Scalars['ID'];
	userOrOrganizationId: Scalars['ID'];
};

export type MutationSocialUserAcceptConnectionArgs = {
	connectionId: Scalars['ID'];
	userId: Scalars['ID'];
};

export type MutationSocialUserCancelConnectionRequestArgs = {
	connectionId: Scalars['ID'];
	userId: Scalars['ID'];
};

export type MutationSocialUserDisconnectArgs = {
	connectionId: Scalars['ID'];
	userId: Scalars['ID'];
};

export type MutationSocialUserFollowArgs = {
	userId: Scalars['ID'];
	userOrOrganizationId: Scalars['ID'];
};

export type MutationSocialUserIgnoreConnectionArgs = {
	connectionId: Scalars['ID'];
	userId: Scalars['ID'];
};

export type MutationSocialUserRequestConnectionArgs = {
	userId: Scalars['ID'];
	userOrOrganizationId: Scalars['ID'];
};

export type MutationSocialUserUnfollowArgs = {
	userId: Scalars['ID'];
	userOrOrganizationId: Scalars['ID'];
};

export type MutationStoreCognitoDeviceArgs = {
	storeCognitoDeviceRequest: StoreCognitoDeviceRequest;
};

export type MutationUpdateOrganizationTeamMembersArgs = {
	organizationId: Scalars['ID'];
	update: TeamMembersUpdate;
};

export type MutationUpdatePromptClippingArgs = {
	clippingId: Scalars['ID'];
	request: PromptClippingRequest;
	userId: Scalars['ID'];
};

export type MutationUserAccountActivationUpdateArgs = {
	operation: ActivationOperation;
	userId: Scalars['ID'];
};

export type MutationUserAccountEmergencyContactDeleteArgs = {
	userId: Scalars['ID'];
};

export type MutationUserAccountEmergencyContactUpdateArgs = {
	emergencyContactUpdate: AccountEmergencyContactUpdate;
	userId: Scalars['ID'];
};

export type MutationUserAccountLocationAddressAddOrUpdateArgs = {
	locationAddressId?: InputMaybe<Scalars['String']>;
	update: LocationAddressUpdate;
	userId: Scalars['ID'];
};

export type MutationUserAccountLocationAddressDeleteArgs = {
	locationAddressId: Scalars['ID'];
	userId: Scalars['ID'];
};

export type MutationUserAccountPrivacyUpdateArgs = {
	isPrivate: Scalars['Boolean'];
	userId: Scalars['ID'];
};

export type MutationUserAccountQuickLinkCreateArgs = {
	request: QuickLinkCreate;
	userId: Scalars['ID'];
};

export type MutationUserAccountQuickLinkDeleteArgs = {
	quickLinkId: Scalars['ID'];
	userId: Scalars['ID'];
};

export type MutationUserAccountQuickLinkUpdateArgs = {
	quickLinkId: Scalars['ID'];
	request: QuickLinkPatch;
	userId: Scalars['ID'];
};

export type MutationUserAccountUpdateArgs = {
	accountUpdate: UserAccountUpdate;
	userId: Scalars['ID'];
};

export type MutationUserAvatarOrBannerUpdateArgs = {
	avatarUploadToken?: InputMaybe<Scalars['String']>;
	bannerUploadToken?: InputMaybe<Scalars['String']>;
	userId: Scalars['ID'];
};

export type MutationUserProfileCanAdjusterLicensingDeleteArgs = {
	userId: Scalars['ID'];
};

export type MutationUserProfileCanAdjusterLicensingSetManualArgs = {
	canadianLicensing: CanAdjusterLicensingInput;
	userId: Scalars['ID'];
};

export type MutationUserProfileEducationAddArgs = {
	update: UserEducationUpdate;
	userId: Scalars['ID'];
};

export type MutationUserProfileEducationDeleteArgs = {
	userEducationId: Scalars['ID'];
	userId: Scalars['ID'];
};

export type MutationUserProfileEducationUpdateArgs = {
	update: UserEducationUpdate;
	userEducationId: Scalars['ID'];
	userId: Scalars['ID'];
};

export type MutationUserProfileExperienceCompanyAndPositionCreateArgs = {
	request: UserExperienceCompanyAndPositionCreation;
	userId: Scalars['ID'];
};

export type MutationUserProfileExperienceCompanyDeleteArgs = {
	userExperienceCompanyId: Scalars['ID'];
	userId: Scalars['ID'];
};

export type MutationUserProfileExperienceCompanyUpdateArgs = {
	request: UserExperienceCompanyUpdate;
	userExperienceCompanyId: Scalars['ID'];
	userId: Scalars['ID'];
};

export type MutationUserProfileExperiencePositionCreateArgs = {
	request: UserExperiencePositionUpdate;
	userExperienceCompanyId: Scalars['ID'];
	userId: Scalars['ID'];
};

export type MutationUserProfileExperiencePositionDeleteArgs = {
	userExperienceCompanyId: Scalars['ID'];
	userExperiencePositionId: Scalars['ID'];
	userId: Scalars['ID'];
};

export type MutationUserProfileExperiencePositionUpdateArgs = {
	request: UserExperiencePositionUpdate;
	userExperienceCompanyId: Scalars['ID'];
	userExperiencePositionId: Scalars['ID'];
	userId: Scalars['ID'];
};

export type MutationUserProfileSkillAddArgs = {
	request: UserSkillCreate;
	userId: Scalars['ID'];
};

export type MutationUserProfileSkillDeleteArgs = {
	userId: Scalars['ID'];
	userSkillId: Scalars['ID'];
};

export type MutationUserProfileSkillSetDisplayOrderArgs = {
	displayOrders: Array<UserSkillDisplayOrder>;
	userId: Scalars['ID'];
};

export type MutationUserProfileSkillsEndorseArgs = {
	endorse: Scalars['Boolean'];
	userId: Scalars['ID'];
	userSkillId: Scalars['ID'];
};

export type MutationUserProfileSkillsEndorsementVisibilityArgs = {
	endorsementId: Scalars['String'];
	userId: Scalars['ID'];
	userSkillId: Scalars['ID'];
	visible: Scalars['Boolean'];
};

export type MutationUserProfileSkillsUpdateArgs = {
	request: UserSkillPlacesUsedUpdateRequest;
	userId: Scalars['ID'];
	userSkillId: Scalars['ID'];
};

export type MutationUserProfileUpdateArgs = {
	update: UserProfileUpdate;
	userId: Scalars['ID'];
};

export type MutationUserProfileUsaAdjusterLicensingDeleteArgs = {
	userId: Scalars['ID'];
};

export type MutationUserProfileUsaAdjusterLicensingSetManualArgs = {
	usLicensing: UsaAdjusterLicensingInput;
	userId: Scalars['ID'];
};

export type MutationUserProfileUsaAdjusterLicensingSetNiprArgs = {
	npnNumber: Scalars['Float'];
	userId: Scalars['ID'];
};

export type MutationUserRegistrationArgs = {
	registration: UserRegistration;
	userId: Scalars['ID'];
};

export type MutationUserSiteRoleAddArgs = {
	siteRoleUpdate: SiteRoleUpdate;
};

export type MutationUserSiteRoleDeleteArgs = {
	id: Scalars['String'];
};

export type MutationUserSiteRoleUpdateArgs = {
	id: Scalars['String'];
	siteRoleUpdate: SiteRoleUpdate;
};

export type MutationUserSiteUserCreateSystemNotificationArgs = {
	request: SystemNotificationRequest;
};

export type MutationUserSiteUserInvitationDeleteArgs = {
	invitationId: Scalars['String'];
};

export type MutationUserSiteUserInvitationResendArgs = {
	invitationId: Scalars['String'];
};

export type MutationUserSiteUserInvitationSendArgs = {
	siteUserInvitationRequest: SiteUserInvitationRequest;
};

export type MutationUserSiteUserUpdateArgs = {
	siteUserId: Scalars['ID'];
	update: SiteUserUpdate;
};

export type NewConversationRequest = {
	attachmentUploadTokens?: InputMaybe<Array<Scalars['String']>>;
	messageText: Scalars['String'];
	participants: Array<Participant>;
	requestedMessageId?: InputMaybe<Scalars['ID']>;
	subject: Scalars['String'];
};

export type NewMessageRequest = {
	attachmentUploadTokens?: InputMaybe<Array<Scalars['String']>>;
	messageText: Scalars['String'];
	requestedMessageId?: InputMaybe<Scalars['ID']>;
};

export type NewParticipantOptions = {
	entityId: Scalars['String'];
	entityType: ParticipantEntityType;
	historyShareFromUtc?: InputMaybe<Scalars['Date']>;
	historyType: ParticipantHistoryType;
};

export type Notification = {
	__typename?: 'Notification';
	associatedUrl?: Maybe<Scalars['String']>;
	createdUtc: Scalars['Date'];
	displayText: Scalars['String'];
	id: Scalars['ID'];
	readCount: Scalars['Float'];
	recipientUserId: Scalars['String'];
	relatedEntityEmblem?: Maybe<Emblem>;
	relatedEntityId: Scalars['String'];
	relatedEntityType: RelatedEntityType;
	state: NotificationState;
	targetEntityId: Scalars['String'];
	type: NotificationType;
	unreadCount: Scalars['Float'];
};

export type NotificationDetail = {
	__typename?: 'NotificationDetail';
	createdUtc: Scalars['Date'];
	emailPlainTextBody: Scalars['String'];
	emailSubject: Scalars['String'];
	id: Scalars['String'];
	inAppText: Scalars['String'];
	notificationType: NotificationType;
	numUsersFailed: Scalars['Float'];
	numUsersSending: Scalars['Float'];
	numUsersSent: Scalars['Float'];
	smsText: Scalars['String'];
};

export type NotificationPagingResponse = {
	__typename?: 'NotificationPagingResponse';
	items: Array<Notification>;
	totalCount: Scalars['Int'];
	totalUnseenCount: Scalars['Int'];
};

export type NotificationSetting = {
	__typename?: 'NotificationSetting';
	email: Scalars['Boolean'];
	emailDigestDaily: Scalars['Boolean'];
	emailDigestHourly: Scalars['Boolean'];
	inApp: Scalars['Boolean'];
	notificationType: NotificationType;
	sms: Scalars['Boolean'];
};

export type NotificationSettingDefault = {
	__typename?: 'NotificationSettingDefault';
	allowedTransportsList: Array<AllowedTransports>;
	description: Scalars['String'];
	displayName: Scalars['String'];
	displayOrder: Scalars['Float'];
	email: Scalars['Boolean'];
	emailDigestDaily: Scalars['Boolean'];
	emailDigestHourly: Scalars['Boolean'];
	groupName: Scalars['String'];
	inApp: Scalars['Boolean'];
	notificationType: NotificationType;
	requiresAtLeastOne: Scalars['Boolean'];
	sms: Scalars['Boolean'];
};

export type NotificationSettingDefaultGroupResponse = {
	__typename?: 'NotificationSettingDefaultGroupResponse';
	groupName: Scalars['String'];
	items: Array<NotificationSettingDefault>;
};

export type NotificationSettingInput = {
	email: Scalars['Boolean'];
	emailDigestDaily: Scalars['Boolean'];
	emailDigestHourly: Scalars['Boolean'];
	inApp: Scalars['Boolean'];
	notificationType: NotificationType;
	sms: Scalars['Boolean'];
};

export type NotificationSettings = {
	__typename?: 'NotificationSettings';
	settings: Array<NotificationSetting>;
};

export type NotificationSettingsInput = {
	settings: Array<NotificationSettingInput>;
};

export enum NotificationState {
	New = 'New',
	Read = 'Read',
	Seen = 'Seen',
}

export type NotificationSummary = {
	__typename?: 'NotificationSummary';
	createdUtc: Scalars['Date'];
	emailPlainTextBody: Scalars['String'];
	emailSubject: Scalars['String'];
	id: Scalars['String'];
	inAppText: Scalars['String'];
	notificationType: NotificationType;
	smsText: Scalars['String'];
};

export enum NotificationType {
	AssociateInvite = 'AssociateInvite',
	AssociateNotification = 'AssociateNotification',
	BillingUpdate = 'BillingUpdate',
	ConnectionAccepted = 'ConnectionAccepted',
	ConnectionRequested = 'ConnectionRequested',
	MessageReceived = 'MessageReceived',
	SiteUserInvitation = 'SiteUserInvitation',
	SystemNotification = 'SystemNotification',
}

export type OperationPermission = {
	__typename?: 'OperationPermission';
	operationType: Scalars['String'];
	permissionKey: Scalars['String'];
};

export type OrgBadgeCountResponse = {
	__typename?: 'OrgBadgeCountResponse';
	conversationsBadgeCount: Scalars['Float'];
	inboundRequestsBadgeCount: Scalars['Float'];
};

export type OrgDashboardConfigurationPatch = {
	primaryColor: Scalars['String'];
	secondaryColor: Scalars['String'];
	subTitle: Scalars['String'];
	title: Scalars['String'];
};

export type OrgDashboardConfigurationResponse = {
	__typename?: 'OrgDashboardConfigurationResponse';
	id: Scalars['ID'];
	primaryColor: Scalars['String'];
	secondaryColor: Scalars['String'];
	subTitle: Scalars['String'];
	title: Scalars['String'];
};

export type OrgSearchResult = {
	__typename?: 'OrgSearchResult';
	deactivated?: Maybe<Scalars['Boolean']>;
	displayLocation?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	legalName?: Maybe<Scalars['String']>;
	organizationEmblem?: Maybe<Emblem>;
	systemEmailAddress?: Maybe<Scalars['String']>;
};

export type OrganizationAboutUpdate = {
	about?: InputMaybe<Scalars['String']>;
	displayPhoneNumber?: InputMaybe<PhoneNumberInput>;
	industry?: InputMaybe<Scalars['String']>;
	numberOfEmployees?: InputMaybe<Scalars['String']>;
	websiteUrl?: InputMaybe<Scalars['String']>;
	yearFounded?: InputMaybe<Scalars['Float']>;
};

export type OrganizationAccount = {
	__typename?: 'OrganizationAccount';
	avatarFile?: Maybe<File>;
	bannerFile?: Maybe<File>;
	deactivated: Scalars['Boolean'];
	displayName: Scalars['String'];
	handle: Scalars['String'];
	id: Scalars['ID'];
	lastUpdatedUtc: Scalars['Date'];
	legalName: Scalars['String'];
	mainPhoneNumber: PhoneNumber;
	private: Scalars['Boolean'];
	quickLinks?: Maybe<Array<QuickLink>>;
	systemEmailAddress: Scalars['String'];
};

export type OrganizationAccountPagingResponse = {
	__typename?: 'OrganizationAccountPagingResponse';
	items: Array<OrganizationAccount>;
	totalCount: Scalars['Int'];
};

export type OrganizationAccountUpdate = {
	avatarUploadToken?: InputMaybe<Scalars['String']>;
	bannerUploadToken?: InputMaybe<Scalars['String']>;
	displayName: Scalars['String'];
	handle: Scalars['String'];
	legalName: Scalars['String'];
	mainPhoneNumber: PhoneNumberInput;
	systemEmailAddress: Scalars['String'];
};

export type OrganizationAncestor = {
	__typename?: 'OrganizationAncestor';
	ancestorLevel: Scalars['Int'];
	emblem?: Maybe<Emblem>;
	organizationId: Scalars['ID'];
};

export type OrganizationAncestorsResponse = {
	__typename?: 'OrganizationAncestorsResponse';
	ancestorToSeedId?: Maybe<Scalars['ID']>;
	ancestors?: Maybe<Array<OrganizationAncestor>>;
	emblem: Emblem;
	organizationId: Scalars['ID'];
};

export type OrganizationBillingOptions = {
	__typename?: 'OrganizationBillingOptions';
	billChildrenForUsage: Scalars['Boolean'];
	chargeAutomatically: Scalars['Boolean'];
	createInvoicesAsDrafts: Scalars['Boolean'];
	notBillable: Scalars['Boolean'];
};

export type OrganizationBillingOptionsInput = {
	billChildrenForUsage: Scalars['Boolean'];
	chargeAutomatically: Scalars['Boolean'];
	createInvoicesAsDrafts: Scalars['Boolean'];
	notBillable: Scalars['Boolean'];
};

export type OrganizationBillingSummary = {
	__typename?: 'OrganizationBillingSummary';
	amountBilled: Scalars['Float'];
	balanceDue: Scalars['Float'];
	organizationHandle?: Maybe<Scalars['String']>;
	organizationName?: Maybe<Scalars['String']>;
	requiresAttention: Scalars['Boolean'];
	status: InvoiceStatus;
	stripeCustomerUrl?: Maybe<Scalars['String']>;
};

export type OrganizationBillingSummaryResponse = {
	__typename?: 'OrganizationBillingSummaryResponse';
	invoiceSummaries: Array<OrganizationBillingSummary>;
	totalAmountBilled: Scalars['Float'];
	totalBalanceDue: Scalars['Float'];
};

export type OrganizationCreateAndLinkRequest = {
	acid: Scalars['Float'];
	displayName: Scalars['String'];
	legalName: Scalars['String'];
	mailingAddress: AddressInput;
	mainPhoneNumber: PhoneNumberInput;
	systemEmailAddress: Scalars['String'];
	systemId: Scalars['Float'];
};

export type OrganizationCreation = {
	displayName: Scalars['String'];
	legalName: Scalars['String'];
	mailingAddress: AddressInput;
	mainPhoneNumber: PhoneNumberInput;
	systemEmailAddress: Scalars['String'];
};

export type OrganizationCreationResponse = {
	__typename?: 'OrganizationCreationResponse';
	handle: Scalars['String'];
};

export type OrganizationImmediateFamilyResponse = {
	__typename?: 'OrganizationImmediateFamilyResponse';
	childEmblems?: Maybe<Array<Emblem>>;
	childOrganizationIds?: Maybe<Array<Scalars['ID']>>;
	emblem: Emblem;
	organizationId: Scalars['ID'];
	parentEmblem?: Maybe<Emblem>;
	parentOrganizationId?: Maybe<Scalars['ID']>;
};

export type OrganizationLinkRequest = {
	acid: Scalars['Float'];
	systemId: Scalars['Float'];
};

export enum OrganizationNotificationAudience {
	Administrators = 'Administrators',
	AllAssociates = 'AllAssociates',
	None = 'None',
}

export type OrganizationNotificationRequest = {
	audience: Array<OrganizationNotificationAudience>;
	emailHtmlBody: Scalars['String'];
	emailSubject: Scalars['String'];
	inAppText: Scalars['String'];
	smsSendAsBulk: Scalars['Boolean'];
	smsText: Scalars['String'];
};

export type OrganizationProfile = {
	__typename?: 'OrganizationProfile';
	about?: Maybe<Scalars['String']>;
	affiliatedOrganizationConnections: Array<Connection>;
	avatarFile?: Maybe<File>;
	bannerFile?: Maybe<File>;
	businessLines?: Maybe<Array<BusinessLine>>;
	callouts?: Maybe<Array<Callout>>;
	connection?: Maybe<Connection>;
	conversationInsuranceProfileLink?: Maybe<Scalars['String']>;
	deactivated: Scalars['Boolean'];
	displayLocation?: Maybe<Scalars['String']>;
	displayName: Scalars['String'];
	displayPhoneNumber?: Maybe<PhoneNumber>;
	facebookProfileLink?: Maybe<Scalars['String']>;
	following?: Maybe<Scalars['Boolean']>;
	id: Scalars['ID'];
	industry?: Maybe<Industry>;
	linkedinProfileLink?: Maybe<Scalars['String']>;
	numberOfEmployees?: Maybe<EmployeeCount>;
	primaryAddress?: Maybe<Address>;
	tagline?: Maybe<Scalars['String']>;
	websiteUrl?: Maybe<Scalars['String']>;
	xProfileLink?: Maybe<Scalars['String']>;
	yearFounded?: Maybe<Scalars['Float']>;
};

export type OrganizationProfileUpdate = {
	callouts?: InputMaybe<Array<CalloutUpdate>>;
	conversationInsuranceProfileLink?: InputMaybe<Scalars['String']>;
	displayName: Scalars['String'];
	facebookProfileLink?: InputMaybe<Scalars['String']>;
	linkedinProfileLink?: InputMaybe<Scalars['String']>;
	tagline?: InputMaybe<Scalars['String']>;
	xProfileLink?: InputMaybe<Scalars['String']>;
};

export type OrganizationRepresentativePagingResponse = {
	__typename?: 'OrganizationRepresentativePagingResponse';
	items: Array<Representative>;
	totalCount: Scalars['Int'];
};

export type OrganizationRole = {
	__typename?: 'OrganizationRole';
	associateCount: Scalars['Int'];
	category: OrganizationRoleCategory;
	id: Scalars['ID'];
	isAdminRole: Scalars['Boolean'];
	name: Scalars['String'];
	organizationId: Scalars['String'];
	permissionKeys: Array<Scalars['String']>;
};

export type OrganizationRoleCategory = {
	__typename?: 'OrganizationRoleCategory';
	id: Scalars['ID'];
	name: Scalars['String'];
};

export type OrganizationRolePagingResponse = {
	__typename?: 'OrganizationRolePagingResponse';
	items: Array<OrganizationRole>;
	totalCount: Scalars['Int'];
};

export type OrganizationRoleUpdate = {
	categoryId: Scalars['String'];
	name: Scalars['String'];
	permissionKeys: Array<Scalars['String']>;
};

export type PagingResponseOfAssociateOrInvitation = {
	__typename?: 'PagingResponseOfAssociateOrInvitation';
	items: Array<AssociateOrInvitation>;
	totalCount: Scalars['Int'];
};

export type PagingResponseOfConnection = {
	__typename?: 'PagingResponseOfConnection';
	items: Array<Connection>;
	totalCount: Scalars['Float'];
};

export type PagingResponseOfFollow = {
	__typename?: 'PagingResponseOfFollow';
	items: Array<Follow>;
	totalCount: Scalars['Float'];
};

export type PagingResponseOfJournalEntry = {
	__typename?: 'PagingResponseOfJournalEntry';
	items: Array<JournalEntry>;
	totalCount: Scalars['Float'];
};

export type PagingResponseOfLocationAddress = {
	__typename?: 'PagingResponseOfLocationAddress';
	items: Array<LocationAddress>;
	totalCount: Scalars['Float'];
};

export type PagingResponseOfNotificationSummary = {
	__typename?: 'PagingResponseOfNotificationSummary';
	items: Array<NotificationSummary>;
	totalCount: Scalars['Float'];
};

/** Response object for getting a paging list of chat messages. */
export type PagingResponseOfUserChatMessage = {
	__typename?: 'PagingResponseOfUserChatMessage';
	items: Array<UserChatMessage>;
	totalCount: Scalars['Int'];
};

export type ParentEntity = {
	__typename?: 'ParentEntity';
	entityCurrentValue?: Maybe<Scalars['String']>;
	entityId: Scalars['String'];
	entityName?: Maybe<Scalars['String']>;
};

export type Participant = {
	entityId: Scalars['String'];
	entityType: ParticipantEntityType;
};

export enum ParticipantEntityType {
	LegacyClaim = 'LegacyClaim',
	Organization = 'Organization',
	System = 'System',
	Unknown = 'Unknown',
	User = 'User',
}

export enum ParticipantHistoryType {
	All = 'All',
	None = 'None',
	Partial = 'Partial',
}

export type ParticipantLabel = {
	__typename?: 'ParticipantLabel';
	id: Scalars['ID'];
	name: Scalars['String'];
	type: LabelType;
};

export type PeopleSearchResult = {
	__typename?: 'PeopleSearchResult';
	affiliation?: Maybe<Scalars['String']>;
	avatarFile?: Maybe<File>;
	cellPhone?: Maybe<PhoneNumber>;
	companyName?: Maybe<Scalars['String']>;
	emailAddress: Scalars['String'];
	firstName: Scalars['String'];
	handle?: Maybe<Scalars['String']>;
	homePhone?: Maybe<PhoneNumber>;
	lastName: Scalars['String'];
	secondaryEmailAddress?: Maybe<Scalars['String']>;
	siteRole?: Maybe<Scalars['String']>;
	siteUserId?: Maybe<Scalars['String']>;
	siteUserInvitationId?: Maybe<Scalars['String']>;
	userActive?: Maybe<Scalars['Boolean']>;
	userId?: Maybe<Scalars['String']>;
};

export type PermissionCategories = {
	__typename?: 'PermissionCategories';
	categories: Array<PermissionCategory>;
};

export type PermissionCategory = {
	__typename?: 'PermissionCategory';
	categoryType: Scalars['String'];
	description: Scalars['String'];
	displayOrder: Scalars['Int'];
	name: Scalars['String'];
	permissionGroups: Array<PermissionGroup>;
};

export type PermissionGroup = {
	__typename?: 'PermissionGroup';
	description: Scalars['String'];
	displayOrder: Scalars['Int'];
	name: Scalars['String'];
	permissions: Array<OperationPermission>;
};

export type PersonName = {
	__typename?: 'PersonName';
	firstName?: Maybe<Scalars['String']>;
	lastName?: Maybe<Scalars['String']>;
	middleName?: Maybe<Scalars['String']>;
	suffix?: Maybe<Scalars['String']>;
};

export type PersonNameInput = {
	firstName: Scalars['String'];
	lastName: Scalars['String'];
	middleName?: InputMaybe<Scalars['String']>;
	suffix?: InputMaybe<Scalars['String']>;
};

export type PhoneNumber = {
	__typename?: 'PhoneNumber';
	countryCode: Scalars['String'];
	number: Scalars['String'];
};

export type PhoneNumberInput = {
	countryCode: Scalars['String'];
	number: Scalars['String'];
};

export type PolicyCategory = {
	__typename?: 'PolicyCategory';
	code: Scalars['String'];
	id: Scalars['String'];
	name: Scalars['String'];
	policyTypes: Array<PolicyType>;
};

export type PolicyType = {
	__typename?: 'PolicyType';
	code: Scalars['String'];
	id: Scalars['String'];
	lossTypes: Array<LossType>;
	name: Scalars['String'];
};

export type PricingModelValidationResults = {
	__typename?: 'PricingModelValidationResults';
	errors: Array<Scalars['String']>;
};

/** Request object for creating or updating a prompt clipping. */
export type PromptClippingRequest = {
	/** The content of the clipping. */
	content: Scalars['String'];
	/** The title of the clipping. */
	title: Scalars['String'];
};

/** An individual prompt clipping details. */
export type PromptClippingResponse = {
	__typename?: 'PromptClippingResponse';
	/** The content of the clipping. */
	content?: Maybe<Scalars['String']>;
	/** The Id of the prompt clipping. */
	id: Scalars['ID'];
	/** The title of the clipping. */
	title?: Maybe<Scalars['String']>;
};

/** List of a users prompt clippings. */
export type PromptClippingsResponse = {
	__typename?: 'PromptClippingsResponse';
	/** An array of prompt clippings. */
	clippings?: Maybe<Array<PromptClippingResponse>>;
};

export type PropertyValidationRule = {
	__typename?: 'PropertyValidationRule';
	rule: Scalars['String'];
	values: Array<KeyValuePair>;
};

export type PropertyValidationRules = {
	__typename?: 'PropertyValidationRules';
	property: Scalars['ID'];
	rules: Array<PropertyValidationRule>;
	shape?: Maybe<ValidationRules>;
	type: Scalars['String'];
};

export type Query = {
	__typename?: 'Query';
	account: UserAccount;
	adjusterLicensingSummary: AdjusterLicensingSummaries;
	affiliatedOrganizations: Array<Emblem>;
	allCoverages: Coverages;
	allUserOrgPermissions: Array<UserOrgPermissions>;
	appSettings: AppSettings;
	associateInvitations: AssociateInvitationsResponse;
	associatesOrInvitations: PagingResponseOfAssociateOrInvitation;
	billingAdHocBillingItemsGet: AdHocBillingItemsResponse;
	billingBillingHierarchyValidationResultsGet: BillingHierarchyValidationResults;
	billingCustomerDashboardUrlGet: UrlResponse;
	billingCustomerSummariesGet: OrganizationBillingSummaryResponse;
	billingJournalsForOrganizationGet: PagingResponseOfJournalEntry;
	billingJournalsGet: PagingResponseOfJournalEntry;
	billingOrganizationBillingInfoGet: BillingInfoResponse;
	billingOrganizationBillingInvoicesGet: InvoiceResponse;
	billingOrganizationOptionsGet: OrganizationBillingOptions;
	billingOrganizationPortalUrlGet: UrlResponse;
	billingPreviewInvoiceGet: InvoiceDataResponse;
	billingPricingModelValidationResultsGet: PricingModelValidationResults;
	billingProductsGet: GetProductsResponse;
	billingSiteUserPortalUrlGet: UrlResponse;
	billingStripeUrlsGet: StripeUrlsResponse;
	canAdjusterLicensing: CanAdjusterLicensingOutput;
	canLicenseTypes: Array<CanLicenseTypeLookup>;
	/** Gets a chat conversation by id. */
	chatConversationGet: UserChatMessageResponse;
	/** Gets all the saved conversations for the calling user. */
	chatConversationGetAllSaved: SavedConversationsResponse;
	/** Gets a users latest chat conversation. */
	chatConversationGetLatest: LatestChatConversationResponse;
	/** Gets a saved chat conversation. */
	chatConversationGetSaved: UserChatMessageResponse;
	/** Gets the users custom instructions. */
	chatCustomInstructionsGet: CustomInstructions;
	/** Gets the last message of all the conversations for a specific user. */
	chatLastMessagesByUser: RelevantChatMessagesResponse;
	cognitoDevices: CognitoDevicesResponse;
	conversationGet: Conversation;
	conversationLabelSummaries: LabelsResponse;
	conversationParticipantGet: ConversationParticipant;
	conversationSearch: ConversationSummariesResponse;
	conversationSummariesForLabel: ConversationSummariesResponse;
	conversationsWithParticipants: ListResponseOfConversationSummary;
	countries: Array<Country>;
	country: Country;
	countryAddressFieldLookups: Array<AddressFieldLookup>;
	coverageLookup: Coverages;
	emblem: Emblem;
	entity: HistoryLogResponse;
	environment: Environment;
	/** Gets a list of user messages for the Evox Review page. */
	evoxReview: PagingResponseOfUserChatMessage;
	folder: FolderInformation;
	folderReferenceList: FolderReferencesList;
	geocode: GeocodeResponse;
	getAssociatesToBeSeededCount: AssociatesToBeSeededCountResponse;
	getCognitoUser: CognitoUserResponse;
	getFileInformation: FileInformation;
	getFileInstanceDownloadUrl: FileDownloadInformation;
	getFileInstances: FileInstanceResponse;
	getOrgBadgeCounts: OrgBadgeCountResponse;
	getOrgConfigurationSettings: OrgDashboardConfigurationResponse;
	getOrganizationAncestors: OrganizationAncestorsResponse;
	getOrganizationImmediateFamily: OrganizationImmediateFamilyResponse;
	getOrganizations: OrganizationAccountPagingResponse;
	getRecommendedLogoUrl: RecommendedLogoUrlResponse;
	getUploadUrl: FileUploadResponse;
	getUserBadgeCounts: UserBadgeCountResponse;
	handleSearch?: Maybe<EntitySearch>;
	jobIndustries: Array<JobIndustry>;
	jobSpecialties: Array<JobSpecialty>;
	jobTypes: Array<JobType>;
	languages: Array<Language>;
	legacyAdjustmentCompanyInfo: AdjustmentCompanyInfo;
	legacyCanCallerLink: CanCallerLinkResult;
	legacyClaimSummaries: LegacyClaimSummariesResult;
	legacyDatabases: Array<LegacyDatabase>;
	legacyInvitationProcess: LegacyInvitationInfoResponse;
	linkedLegacyAdjustmentCompanyInfo: LinkedAdjustmentCompanyInfo;
	loginInformation: UserLogin;
	notificationSettingDefaults: Array<NotificationSettingDefaultGroupResponse>;
	notificationSettingForUser: NotificationSettings;
	notifications: NotificationPagingResponse;
	orgOrDescendentIsLinked: IsLinkedResponse;
	orgQuickLinks: Array<QuickLink>;
	orgSystemSearch: Array<OrgSearchResult>;
	organizationAccount: OrganizationAccount;
	organizationAccountGetConversationInsurance: ConversationInsuranceResponse;
	organizationConnectionsOrRequests: PagingResponseOfConnection;
	organizationFollowers: PagingResponseOfFollow;
	organizationLocationAddresses: PagingResponseOfLocationAddress;
	organizationNotificationDetails: NotificationDetail;
	organizationNotifications: PagingResponseOfNotificationSummary;
	organizationProfile: OrganizationProfile;
	organizationRepresentatives: OrganizationRepresentativePagingResponse;
	organizationTeamMembers: ListResponseOfEmblem;
	participantExactHandleSearch: Array<QuickSearchEntity>;
	participantSearch: Array<QuickSearchEntity>;
	peopleSearch: Array<PeopleSearchResult>;
	permissionCategories: PermissionCategories;
	profile: UserProfile;
	profilePrivacies: Array<UserProfileEffectivePrivacy>;
	profilePrivacy: UserProfileEffectivePrivacy;
	/** Gets a list of the users prompt clippings. */
	promptClippingsGet: PromptClippingsResponse;
	quickLinks: Array<QuickLink>;
	quickSearch: Array<QuickSearchEntity>;
	role: OrganizationRole;
	roleCategories: Array<OrganizationRoleCategory>;
	roles: OrganizationRolePagingResponse;
	rootFolderContents: FolderContents;
	searchFolders: FolderContents;
	sitePermissionCategories: PermissionCategories;
	siteRole: SiteRole;
	siteRoleCategories: Array<SiteRoleCategory>;
	siteRoles: SiteRolePagingResponse;
	siteUser: SiteUser;
	siteUserEmblems: EmblemPagingResponse;
	skills: Array<Skill>;
	systemNotificationDetails: NotificationDetail;
	systemNotifications: PagingResponseOfNotificationSummary;
	usaAdjusterLicensing: UsaAdjusterLicensingOutput;
	usaLicenseClasses: Array<UsaLicenseClassLookup>;
	usaLinesOfAuthority: Array<UsaLicenseClassLookup>;
	usaNiprAdjusterLookup: Array<UsaNiprAdjusterLookup>;
	userConnections: PagingResponseOfConnection;
	userConnectionsOrRequests: PagingResponseOfConnection;
	userDashboardConfigurationSettings: UserDashboardConfigurationResponse;
	userFollowers: PagingResponseOfFollow;
	userFollows: PagingResponseOfFollow;
	userLegacyAdjustmentCompanies: LegacyUserAdjustmentCompanies;
	userLegacyAdjustmentCompaniesWithMetrics: LegacyUserAdjustmentCompaniesWithMetrics;
	userLegacyAdjustmentCompaniesWithMetricsBySystemAndAcid: LegacyUserAdjustmentCompaniesWithMetrics;
	userLocationAddresses: PagingResponseOfLocationAddress;
	userOrgPermissions: UserOrgPermissions;
	userProfileEducationGet: UserEducationResponse;
	userProfileExperienceGet: UserExperienceResponse;
	userProfileSkillEndorsementsGet: UserSkillEndorsementResponse;
	userProfileSkillPlacesUsedGet: UserSkillPlacesUsedResponse;
	userProfileSkillsGet: UserSkillsResponse;
	validationRules: ValidationRules;
	versionNumber: Scalars['String'];
	workTypes: Array<WorkType>;
	zendeskAuth: ZendeskAuthResponse;
};

export type QueryAccountArgs = {
	userId: Scalars['ID'];
};

export type QueryAdjusterLicensingSummaryArgs = {
	userId: Scalars['ID'];
};

export type QueryAffiliatedOrganizationsArgs = {
	userId: Scalars['ID'];
};

export type QueryAllCoveragesArgs = {
	countryId: Scalars['ID'];
};

export type QueryAssociatesOrInvitationsArgs = {
	includeActive: Scalars['Boolean'];
	includeDeclined: Scalars['Boolean'];
	includeInactive: Scalars['Boolean'];
	includeInvited: Scalars['Boolean'];
	offset: Scalars['Float'];
	organizationId: Scalars['ID'];
	pageSize: Scalars['Float'];
	searchFor: Scalars['String'];
	sortDirection: Scalars['String'];
	sortedBy: Scalars['String'];
};

export type QueryBillingAdHocBillingItemsGetArgs = {
	organizationId: Scalars['String'];
};

export type QueryBillingCustomerDashboardUrlGetArgs = {
	organizationId: Scalars['String'];
};

export type QueryBillingJournalsForOrganizationGetArgs = {
	organizationId: Scalars['String'];
	request: JournalSearchRequest;
};

export type QueryBillingJournalsGetArgs = {
	request: JournalSearchRequest;
};

export type QueryBillingOrganizationBillingInfoGetArgs = {
	organizationId: Scalars['String'];
};

export type QueryBillingOrganizationBillingInvoicesGetArgs = {
	numDays: Scalars['Int'];
	organizationId: Scalars['String'];
};

export type QueryBillingOrganizationOptionsGetArgs = {
	organizationId: Scalars['String'];
};

export type QueryBillingOrganizationPortalUrlGetArgs = {
	organizationId: Scalars['String'];
	returnUrl: Scalars['String'];
};

export type QueryBillingPreviewInvoiceGetArgs = {
	generateDraftInvoice: Scalars['Boolean'];
	invoiceDate: Scalars['Date'];
	organizationId: Scalars['String'];
	usage?: InputMaybe<BillingUsage>;
};

export type QueryBillingSiteUserPortalUrlGetArgs = {
	organizationId: Scalars['String'];
	returnUrl: Scalars['String'];
};

export type QueryCanAdjusterLicensingArgs = {
	userId: Scalars['ID'];
};

export type QueryCanLicenseTypesArgs = {
	province?: InputMaybe<Scalars['String']>;
};

export type QueryChatConversationGetArgs = {
	conversationId: Scalars['ID'];
};

export type QueryChatConversationGetAllSavedArgs = {
	userId: Scalars['ID'];
};

export type QueryChatConversationGetLatestArgs = {
	userId: Scalars['ID'];
};

export type QueryChatConversationGetSavedArgs = {
	conversationId: Scalars['ID'];
	userId: Scalars['ID'];
};

export type QueryChatCustomInstructionsGetArgs = {
	userId: Scalars['ID'];
};

export type QueryChatLastMessagesByUserArgs = {
	conversationTypeFilter?: InputMaybe<Array<ConversationTypeFilter>>;
	userId: Scalars['ID'];
};

export type QueryCognitoDevicesArgs = {
	userId: Scalars['ID'];
};

export type QueryConversationGetArgs = {
	conversationId: Scalars['String'];
	entityId: Scalars['String'];
	entityType: ConversationApiEntityType;
	metadata?: InputMaybe<Array<KeyValuePairInput>>;
};

export type QueryConversationLabelSummariesArgs = {
	entityId: Scalars['String'];
	entityType: ConversationApiEntityType;
	metadata?: InputMaybe<Array<KeyValuePairInput>>;
};

export type QueryConversationParticipantGetArgs = {
	conversationId: Scalars['String'];
	userId: Scalars['String'];
};

export type QueryConversationSearchArgs = {
	entityId: Scalars['String'];
	entityType: ConversationApiEntityType;
	labelId: Scalars['String'];
	metadata?: InputMaybe<Array<KeyValuePairInput>>;
	offset: Scalars['Int'];
	pageSize: Scalars['Int'];
	searchText: Scalars['String'];
};

export type QueryConversationSummariesForLabelArgs = {
	entityId: Scalars['String'];
	entityType: ConversationApiEntityType;
	labelId: Scalars['String'];
	metadata?: InputMaybe<Array<KeyValuePairInput>>;
	offset: Scalars['Int'];
	pageSize: Scalars['Int'];
};

export type QueryConversationsWithParticipantsArgs = {
	entityId: Scalars['String'];
	entityIds: Array<Scalars['String']>;
	entityType: ConversationApiEntityType;
	metadata?: InputMaybe<Array<KeyValuePairInput>>;
};

export type QueryCountryArgs = {
	id: Scalars['String'];
};

export type QueryCountryAddressFieldLookupsArgs = {
	id: Scalars['String'];
};

export type QueryCoverageLookupArgs = {
	countryId: Scalars['String'];
	lossTypeId: Scalars['String'];
	policyCategoryId: Scalars['String'];
	policyTypeId: Scalars['String'];
};

export type QueryEmblemArgs = {
	entityId: Scalars['ID'];
	entityType: EmblemEntityType;
};

export type QueryEntityArgs = {
	entityId: Scalars['String'];
	historyLogId?: InputMaybe<Scalars['String']>;
};

export type QueryEvoxReviewArgs = {
	chatMessageRating?: InputMaybe<ChatMessageRating>;
	conversationTypeFilter?: InputMaybe<Array<ConversationTypeFilter>>;
	offset?: InputMaybe<Scalars['Float']>;
	pageSize?: InputMaybe<Scalars['Float']>;
	sortDirection?: InputMaybe<SortDirection>;
};

export type QueryFolderArgs = {
	entityId: Scalars['String'];
	entityType: FoldersApiEntityType;
	folderId: Scalars['String'];
};

export type QueryFolderReferenceListArgs = {
	entityId: Scalars['String'];
	entityType: FoldersApiEntityType;
};

export type QueryGeocodeArgs = {
	address: AddressInput;
};

export type QueryGetAssociatesToBeSeededCountArgs = {
	orgId: Scalars['ID'];
};

export type QueryGetCognitoUserArgs = {
	userId: Scalars['ID'];
};

export type QueryGetFileInformationArgs = {
	fileId: Scalars['String'];
};

export type QueryGetFileInstanceDownloadUrlArgs = {
	fileInstanceId: Scalars['String'];
	inline?: InputMaybe<Scalars['Boolean']>;
	sasUrlOnly?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetFileInstancesArgs = {
	fileId: Scalars['String'];
};

export type QueryGetOrgBadgeCountsArgs = {
	hasMessagesRead: Scalars['Boolean'];
	hasSocialRead: Scalars['Boolean'];
	organizationId: Scalars['ID'];
};

export type QueryGetOrgConfigurationSettingsArgs = {
	orgId: Scalars['ID'];
};

export type QueryGetOrganizationAncestorsArgs = {
	onlyAssociateCreate?: InputMaybe<Scalars['Boolean']>;
	orgId: Scalars['ID'];
};

export type QueryGetOrganizationImmediateFamilyArgs = {
	orgId: Scalars['ID'];
};

export type QueryGetOrganizationsArgs = {
	offset: Scalars['Float'];
	pageSize: Scalars['Float'];
	searchFor: Scalars['String'];
	sortDirection: SortDirection;
	sortedBy: Scalars['String'];
};

export type QueryGetRecommendedLogoUrlArgs = {
	websiteUrl: Scalars['String'];
};

export type QueryGetUploadUrlArgs = {
	request: FileUploadRequest;
};

export type QueryGetUserBadgeCountsArgs = {
	userId: Scalars['ID'];
};

export type QueryHandleSearchArgs = {
	handle: Scalars['String'];
};

export type QueryJobIndustriesArgs = {
	countryId: Scalars['ID'];
};

export type QueryJobSpecialtiesArgs = {
	countryId: Scalars['ID'];
	industryId: Scalars['ID'];
};

export type QueryJobTypesArgs = {
	countryId: Scalars['ID'];
	industryId: Scalars['ID'];
	specialtyId: Scalars['ID'];
};

export type QueryLegacyAdjustmentCompanyInfoArgs = {
	acid: Scalars['Int'];
	systemId: Scalars['Int'];
};

export type QueryLegacyCanCallerLinkArgs = {
	acid: Scalars['Int'];
	systemId: Scalars['Int'];
};

export type QueryLegacyClaimSummariesArgs = {
	count: Scalars['Int'];
	legacySystemId: Scalars['Int'];
	legacyUserId: Scalars['Int'];
	roleType: ClaimSummaryRoleType;
	subset: ClaimSummarySubset;
};

export type QueryLegacyInvitationProcessArgs = {
	invitationId: Scalars['ID'];
};

export type QueryLinkedLegacyAdjustmentCompanyInfoArgs = {
	orgId: Scalars['ID'];
};

export type QueryNotificationSettingForUserArgs = {
	userId: Scalars['ID'];
};

export type QueryNotificationsArgs = {
	limit: Scalars['Int'];
	markAllAsSeen: Scalars['Boolean'];
	offset: Scalars['Int'];
	types?: InputMaybe<Array<NotificationType>>;
};

export type QueryOrgOrDescendentIsLinkedArgs = {
	orgId: Scalars['ID'];
};

export type QueryOrgQuickLinksArgs = {
	orgId: Scalars['ID'];
};

export type QueryOrgSystemSearchArgs = {
	activationStatus?: InputMaybe<ActivationStatus>;
	location?: InputMaybe<Scalars['String']>;
	searchText: Scalars['String'];
};

export type QueryOrganizationAccountArgs = {
	organizationId: Scalars['ID'];
};

export type QueryOrganizationAccountGetConversationInsuranceArgs = {
	organizationId: Scalars['ID'];
};

export type QueryOrganizationConnectionsOrRequestsArgs = {
	markInboundAsSeen?: InputMaybe<Scalars['Boolean']>;
	offset: Scalars['Float'];
	organizationId: Scalars['ID'];
	pageSize: Scalars['Float'];
	pageType: ConnectionPageType;
	searchFor: Scalars['String'];
	sortDirection: SortDirection;
	sortedBy: ConnectionSortBy;
};

export type QueryOrganizationFollowersArgs = {
	offset: Scalars['Float'];
	organizationId: Scalars['ID'];
	pageSize: Scalars['Float'];
	searchFor: Scalars['String'];
	sortDirection: Scalars['String'];
	sortedBy: Scalars['String'];
};

export type QueryOrganizationLocationAddressesArgs = {
	offset: Scalars['Float'];
	organizationId: Scalars['ID'];
	pageSize: Scalars['Float'];
	searchFor: Scalars['String'];
	sortDirection: SortDirection;
};

export type QueryOrganizationNotificationDetailsArgs = {
	notificationId: Scalars['ID'];
	organizationId: Scalars['ID'];
};

export type QueryOrganizationNotificationsArgs = {
	offset: Scalars['Int'];
	organizationId: Scalars['ID'];
	pageSize: Scalars['Int'];
	searchText: Scalars['String'];
};

export type QueryOrganizationProfileArgs = {
	organizationId: Scalars['ID'];
};

export type QueryOrganizationRepresentativesArgs = {
	offset: Scalars['Float'];
	organizationId: Scalars['ID'];
	pageSize: Scalars['Float'];
	searchFor: Scalars['String'];
	sortDirection: SortDirection;
};

export type QueryOrganizationTeamMembersArgs = {
	organizationId: Scalars['ID'];
};

export type QueryParticipantExactHandleSearchArgs = {
	entityId: Scalars['String'];
	handle?: InputMaybe<Scalars['String']>;
};

export type QueryParticipantSearchArgs = {
	entityId: Scalars['String'];
	entityType: EntitySearchType;
	limit: Scalars['Float'];
	query?: InputMaybe<Scalars['String']>;
};

export type QueryPeopleSearchArgs = {
	activationStatus?: InputMaybe<ActivationStatus>;
	affiliation?: InputMaybe<Scalars['String']>;
	companyName?: InputMaybe<Scalars['String']>;
	searchText: Scalars['String'];
	userType: UserType;
};

export type QueryProfileArgs = {
	userId: Scalars['ID'];
};

export type QueryProfilePrivaciesArgs = {
	userId: Scalars['ID'];
};

export type QueryProfilePrivacyArgs = {
	type: Scalars['String'];
	userId: Scalars['ID'];
};

export type QueryPromptClippingsGetArgs = {
	userId: Scalars['ID'];
};

export type QueryQuickLinksArgs = {
	userId: Scalars['ID'];
};

export type QueryQuickSearchArgs = {
	entitySearchMode?: InputMaybe<EntitySearchMode>;
	limit: Scalars['Float'];
	query: Scalars['String'];
};

export type QueryRoleArgs = {
	organizationId: Scalars['ID'];
	roleId: Scalars['ID'];
};

export type QueryRolesArgs = {
	offset?: InputMaybe<Scalars['Float']>;
	organizationId: Scalars['ID'];
	pageSize?: InputMaybe<Scalars['Float']>;
	sortDirection?: InputMaybe<Scalars['String']>;
	sortedBy?: InputMaybe<Scalars['String']>;
};

export type QueryRootFolderContentsArgs = {
	entityId: Scalars['String'];
	entityType: FoldersApiEntityType;
};

export type QuerySearchFoldersArgs = {
	entityId: Scalars['String'];
	entityType: FoldersApiEntityType;
	request: SearchFoldersRequest;
};

export type QuerySiteRoleArgs = {
	id: Scalars['String'];
};

export type QuerySiteRolesArgs = {
	offset?: InputMaybe<Scalars['Float']>;
	pageSize?: InputMaybe<Scalars['Float']>;
	searchFor?: InputMaybe<Scalars['String']>;
	sortDirection?: InputMaybe<SortDirection>;
	sortedBy?: InputMaybe<Scalars['String']>;
};

export type QuerySiteUserArgs = {
	id: Scalars['ID'];
};

export type QuerySiteUserEmblemsArgs = {
	offset: Scalars['Float'];
	pageSize: Scalars['Float'];
	searchFor: Scalars['String'];
	sortDirection: SortDirection;
	sortedBy: Scalars['String'];
};

export type QuerySkillsArgs = {
	skillIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type QuerySystemNotificationDetailsArgs = {
	notificationId: Scalars['ID'];
};

export type QuerySystemNotificationsArgs = {
	offset: Scalars['Int'];
	pageSize: Scalars['Int'];
	searchText: Scalars['String'];
};

export type QueryUsaAdjusterLicensingArgs = {
	userId: Scalars['ID'];
};

export type QueryUsaLicenseClassesArgs = {
	state: Scalars['String'];
};

export type QueryUsaLinesOfAuthorityArgs = {
	state: Scalars['String'];
};

export type QueryUsaNiprAdjusterLookupArgs = {
	birthDate: Scalars['Date'];
	firstName?: InputMaybe<Scalars['String']>;
	lastName?: InputMaybe<Scalars['String']>;
	middleName?: InputMaybe<Scalars['String']>;
	npnNumber?: InputMaybe<Scalars['Int']>;
	ssNumber?: InputMaybe<Scalars['String']>;
};

export type QueryUserConnectionsArgs = {
	offset: Scalars['Float'];
	pageSize: Scalars['Float'];
	searchFor: Scalars['String'];
	sortDirection: SortDirection;
	sortedBy: ConnectionSortBy;
	userId: Scalars['ID'];
};

export type QueryUserConnectionsOrRequestsArgs = {
	markInboundAsSeen?: InputMaybe<Scalars['Boolean']>;
	offset: Scalars['Float'];
	pageSize: Scalars['Float'];
	pageType: ConnectionPageType;
	searchFor: Scalars['String'];
	sortDirection: SortDirection;
	sortedBy: ConnectionSortBy;
	userId: Scalars['ID'];
};

export type QueryUserDashboardConfigurationSettingsArgs = {
	userId: Scalars['ID'];
};

export type QueryUserFollowersArgs = {
	offset: Scalars['Float'];
	pageSize: Scalars['Float'];
	searchFor: Scalars['String'];
	sortDirection: Scalars['String'];
	sortedBy: Scalars['String'];
	userId: Scalars['ID'];
};

export type QueryUserFollowsArgs = {
	offset: Scalars['Float'];
	pageSize: Scalars['Float'];
	searchFor: Scalars['String'];
	sortDirection: Scalars['String'];
	sortedBy: Scalars['String'];
	userId: Scalars['ID'];
};

export type QueryUserLegacyAdjustmentCompaniesWithMetricsBySystemAndAcidArgs = {
	acid: Scalars['Int'];
	systemId: Scalars['Int'];
};

export type QueryUserLocationAddressesArgs = {
	offset: Scalars['Float'];
	pageSize: Scalars['Float'];
	searchFor: Scalars['String'];
	sortDirection: SortDirection;
	userId: Scalars['ID'];
};

export type QueryUserOrgPermissionsArgs = {
	orgId: Scalars['ID'];
};

export type QueryUserProfileEducationGetArgs = {
	userId: Scalars['ID'];
};

export type QueryUserProfileExperienceGetArgs = {
	userId: Scalars['ID'];
};

export type QueryUserProfileSkillEndorsementsGetArgs = {
	userId: Scalars['ID'];
	userSkillId: Scalars['ID'];
};

export type QueryUserProfileSkillPlacesUsedGetArgs = {
	userId: Scalars['ID'];
	userSkillId: Scalars['ID'];
};

export type QueryUserProfileSkillsGetArgs = {
	userId: Scalars['ID'];
};

export type QueryValidationRulesArgs = {
	typeName: Scalars['String'];
};

export type QuickLink = {
	__typename?: 'QuickLink';
	iconFile?: Maybe<FileInformation>;
	iconFileId?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	title: Scalars['String'];
	url: Scalars['String'];
};

export type QuickLinkCreate = {
	iconUploadToken?: InputMaybe<Scalars['String']>;
	title: Scalars['String'];
	url: Scalars['String'];
};

export type QuickLinkPatch = {
	clearIconFlag: Scalars['Boolean'];
	iconUploadToken?: InputMaybe<Scalars['String']>;
	title: Scalars['String'];
	url: Scalars['String'];
};

export type QuickSearchEntity = {
	__typename?: 'QuickSearchEntity';
	avatarFileId?: Maybe<Scalars['String']>;
	displayName: Scalars['String'];
	displayPicture?: Maybe<File>;
	entityType: EntitySearchType;
	handle?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	location?: Maybe<Scalars['String']>;
	relevance: Scalars['Int'];
};

export type RecommendedLogoUrlResponse = {
	__typename?: 'RecommendedLogoUrlResponse';
	logoUrl?: Maybe<Scalars['String']>;
};

export enum RelatedEntityType {
	Billing = 'Billing',
	LegacyClaim = 'LegacyClaim',
	Message = 'Message',
	None = 'None',
	Organization = 'Organization',
	SiteUserInvitation = 'SiteUserInvitation',
	User = 'User',
}

/** Response object for getting a list of chat messages. */
export type RelevantChatMessagesResponse = {
	__typename?: 'RelevantChatMessagesResponse';
	/** An array of chat message responses to a request. */
	relevantChatMessages: Array<UserChatMessage>;
};

export type Representative = {
	__typename?: 'Representative';
	cellPhoneNumber: PhoneNumber;
	emailAddress: Scalars['String'];
	id: Scalars['ID'];
	name: PersonName;
	organizationId: Scalars['String'];
	representativeType: Array<RepresentativeType>;
	title: Scalars['String'];
	workPhoneNumber?: Maybe<PhoneNumber>;
};

export enum RepresentativeType {
	Billing = 'Billing',
	Operational = 'Operational',
	Primary = 'Primary',
	Technical = 'Technical',
}

export type RepresentativeUpdate = {
	cellPhoneNumber: PhoneNumberInput;
	emailAddress: Scalars['String'];
	name: PersonNameInput;
	representativeType: Array<RepresentativeType>;
	title: Scalars['String'];
	workPhoneNumber?: InputMaybe<PhoneNumberInput>;
};

/** Request object for saving or updating a chat conversation. */
export type SaveConversationRequest = {
	/** The name of the saved conversation. */
	conversationName: Scalars['String'];
};

/** Represents a saved chat conversation. */
export type SavedConversation = {
	__typename?: 'SavedConversation';
	/** The conversation ID. */
	conversationId: Scalars['ID'];
	/** The saved name of the conversation. */
	conversationName: Scalars['String'];
};

/** Response object for getting a users save chat conversations. */
export type SavedConversationsResponse = {
	__typename?: 'SavedConversationsResponse';
	/** The saved conversations. */
	savedConversations: Array<SavedConversation>;
};

export type SearchFoldersRequest = {
	caseSensitive: Scalars['Boolean'];
	currentInstanceOnly: Scalars['Boolean'];
	searchType: FolderSearchType;
	searchValue: Scalars['String'];
};

export type SiteRole = {
	__typename?: 'SiteRole';
	id: Scalars['ID'];
	isAdminRole?: Maybe<Scalars['Boolean']>;
	lastModifiedUtc?: Maybe<Scalars['Date']>;
	name: Scalars['String'];
	permissionKeys?: Maybe<Array<Scalars['String']>>;
	siteRoleCategory?: Maybe<SiteRoleCategory>;
	userCount?: Maybe<Scalars['Int']>;
};

export type SiteRoleCategory = {
	__typename?: 'SiteRoleCategory';
	id: Scalars['ID'];
	name: Scalars['String'];
};

export type SiteRolePagingResponse = {
	__typename?: 'SiteRolePagingResponse';
	items: Array<SiteRole>;
	totalCount: Scalars['Int'];
};

export type SiteRoleUpdate = {
	name: Scalars['String'];
	permissionKeys: Array<Scalars['String']>;
	siteRoleCategoryId: Scalars['String'];
};

export type SiteUser = {
	__typename?: 'SiteUser';
	affiliation?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	profileLastUpdatedUtc?: Maybe<Scalars['Date']>;
	roles?: Maybe<Array<SiteRole>>;
};

export type SiteUserInvitation = {
	__typename?: 'SiteUserInvitation';
	affiliation?: Maybe<Scalars['String']>;
	emailAddress?: Maybe<Scalars['String']>;
	firstName?: Maybe<Scalars['String']>;
	invitationId: Scalars['ID'];
	invitedBySiteUserId?: Maybe<Scalars['String']>;
	invitedDateTimeUtc?: Maybe<Scalars['Date']>;
	lastName?: Maybe<Scalars['String']>;
	role?: Maybe<SiteRole>;
	roleId?: Maybe<Scalars['String']>;
};

export type SiteUserInvitationRequest = {
	affiliation: Scalars['String'];
	firstName: Scalars['String'];
	invitedEmailAddress: Scalars['String'];
	lastName: Scalars['String'];
	roleId: Scalars['String'];
};

export type SiteUserUpdate = {
	affiliation: Scalars['String'];
	siteRoleIds: Array<Scalars['String']>;
};

export type Skill = {
	__typename?: 'Skill';
	id: Scalars['String'];
	name: Scalars['String'];
};

export enum SortDirection {
	Ascending = 'Ascending',
	Descending = 'Descending',
}

export type StoreCognitoDeviceRequest = {
	deviceKey: Scalars['String'];
};

export type StripePriceInfo = {
	__typename?: 'StripePriceInfo';
	billingScheme?: Maybe<Scalars['String']>;
	description?: Maybe<Scalars['String']>;
	effectiveDate: Scalars['Date'];
	interval?: Maybe<Scalars['String']>;
	price: Scalars['Float'];
	priceId: Scalars['String'];
	productId: Scalars['String'];
	type?: Maybe<Scalars['String']>;
};

export type StripeProductInfo = {
	__typename?: 'StripeProductInfo';
	description?: Maybe<Scalars['String']>;
	name: Scalars['String'];
	prices: Array<StripePriceInfo>;
	productId: Scalars['String'];
};

export type StripeUrlsResponse = {
	__typename?: 'StripeUrlsResponse';
	customerDashboardUrl: Scalars['String'];
	invoiceDashboardUrl: Scalars['String'];
	productDashboardUrl: Scalars['String'];
};

export enum SystemFileType {
	EvoxAttachment = 'EvoxAttachment',
	MessageAttachment = 'MessageAttachment',
	OrganizationAvatar = 'OrganizationAvatar',
	OrganizationBanner = 'OrganizationBanner',
	OrganizationCommon = 'OrganizationCommon',
	OrganizationQuickLinkIcon = 'OrganizationQuickLinkIcon',
	UserAvatar = 'UserAvatar',
	UserBanner = 'UserBanner',
	UserQuickLinkIcon = 'UserQuickLinkIcon',
}

export enum SystemNotificationAudience {
	None = 'None',
	OrgAdmins = 'OrgAdmins',
	SocialUsers = 'SocialUsers',
}

export type SystemNotificationRequest = {
	audience: Array<SystemNotificationAudience>;
	emailHtmlBody: Scalars['String'];
	emailSubject: Scalars['String'];
	inAppText: Scalars['String'];
	inAppUrl?: InputMaybe<Scalars['String']>;
	smsSendAsBulk: Scalars['Boolean'];
	smsText: Scalars['String'];
};

export type TeamMemberUpdate = {
	displayPosition: Scalars['Int'];
	userId: Scalars['ID'];
};

export type TeamMembersUpdate = {
	teamMemberUpdates: Array<TeamMemberUpdate>;
};

export type UpdateConversationRequest = {
	subject: Scalars['String'];
};

export type UpsertFolderRequest = {
	name: Scalars['String'];
	parentFolderId?: InputMaybe<Scalars['String']>;
};

export type UrlResponse = {
	__typename?: 'UrlResponse';
	url: Scalars['String'];
};

export type UsaAdjusterLicensingInput = {
	birthDate: Scalars['Date'];
	firstName: Scalars['String'];
	lastName: Scalars['String'];
	middleName?: InputMaybe<Scalars['String']>;
	npn: Scalars['Int'];
	statesLicensing: Array<UsaStateAdjusterLicensingInput>;
	suffix?: InputMaybe<Scalars['String']>;
};

export type UsaAdjusterLicensingOutput = {
	__typename?: 'UsaAdjusterLicensingOutput';
	birthDate?: Maybe<Scalars['Date']>;
	firstName?: Maybe<Scalars['String']>;
	lastName?: Maybe<Scalars['String']>;
	middleName?: Maybe<Scalars['String']>;
	npn: Scalars['Int'];
	statesLicensing: Array<UsaStateAdjusterLicensingOutput>;
	suffix?: Maybe<Scalars['String']>;
};

export type UsaLicenseClassLookup = {
	__typename?: 'UsaLicenseClassLookup';
	code: Scalars['Int'];
	id: Scalars['String'];
	name: Scalars['String'];
	stateId: Scalars['String'];
};

export type UsaLineOfAuthorityLookup = {
	__typename?: 'UsaLineOfAuthorityLookup';
	code: Scalars['Int'];
	id: Scalars['String'];
	name: Scalars['String'];
	stateId: Scalars['String'];
};

export type UsaNiprAdjusterLookup = {
	__typename?: 'UsaNiprAdjusterLookup';
	birthDate?: Maybe<Scalars['Date']>;
	name: Scalars['String'];
	npn: Scalars['Int'];
	stateOfResidence?: Maybe<Scalars['String']>;
};

export type UsaStateAdjusterAppointmentInput = {
	companyName: Scalars['String'];
	lineOfAuthorityId?: InputMaybe<Scalars['String']>;
	renewalDate?: InputMaybe<Scalars['Date']>;
	status: Scalars['String'];
};

export type UsaStateAdjusterAppointmentOutput = {
	__typename?: 'UsaStateAdjusterAppointmentOutput';
	companyName: Scalars['String'];
	lineOfAuthorityId?: Maybe<Scalars['String']>;
	renewalDate?: Maybe<Scalars['Date']>;
	status: Scalars['String'];
};

export type UsaStateAdjusterLicenseInput = {
	active: Scalars['Boolean'];
	expirationDate?: InputMaybe<Scalars['Date']>;
	licenseClassId: Scalars['String'];
	number: Scalars['String'];
	originalIssueDate?: InputMaybe<Scalars['Date']>;
};

export type UsaStateAdjusterLicenseOutput = {
	__typename?: 'UsaStateAdjusterLicenseOutput';
	active: Scalars['Boolean'];
	expirationDate?: Maybe<Scalars['Date']>;
	licenseClassId: Scalars['String'];
	number: Scalars['String'];
	originalIssueDate?: Maybe<Scalars['Date']>;
};

export type UsaStateAdjusterLicensingInput = {
	fullNameAlias?: InputMaybe<Scalars['String']>;
	stateAddressFieldLookupId: Scalars['String'];
	stateAppointments: Array<UsaStateAdjusterAppointmentInput>;
	stateLicenses: Array<UsaStateAdjusterLicenseInput>;
	stateRegulatoryActions: Array<UsaStateAdjusterRegulatoryActionInput>;
};

export type UsaStateAdjusterLicensingOutput = {
	__typename?: 'UsaStateAdjusterLicensingOutput';
	fullNameAlias?: Maybe<Scalars['String']>;
	stateAddressFieldLookupId: Scalars['String'];
	stateAppointments: Array<UsaStateAdjusterAppointmentOutput>;
	stateLicenses: Array<UsaStateAdjusterLicenseOutput>;
	stateRegulatoryActions: Array<UsaStateAdjusterRegulatoryActionOutput>;
};

export type UsaStateAdjusterRegulatoryActionInput = {
	actionDate: Scalars['Date'];
	disposition: Scalars['String'];
};

export type UsaStateAdjusterRegulatoryActionOutput = {
	__typename?: 'UsaStateAdjusterRegulatoryActionOutput';
	actionDate: Scalars['Date'];
	disposition: Scalars['String'];
};

export type UserAccount = {
	__typename?: 'UserAccount';
	avatarFile?: Maybe<File>;
	birthDate?: Maybe<Scalars['Date']>;
	cellPhone?: Maybe<PhoneNumber>;
	deactivated?: Maybe<Scalars['Boolean']>;
	emailAddress: Scalars['String'];
	emergencyContact?: Maybe<AccountEmergencyContact>;
	handle: Scalars['String'];
	homePhone?: Maybe<PhoneNumber>;
	id: Scalars['ID'];
	languageIds?: Maybe<Array<Scalars['String']>>;
	lastUpdatedUtc?: Maybe<Scalars['Date']>;
	name?: Maybe<PersonName>;
	private: Scalars['Boolean'];
	quickLinks?: Maybe<Array<QuickLink>>;
	secondaryEmailAddress?: Maybe<Scalars['String']>;
	siteUserId?: Maybe<Scalars['String']>;
};

export type UserAccountUpdate = {
	avatarUploadToken?: InputMaybe<Scalars['String']>;
	birthDate?: InputMaybe<Scalars['Date']>;
	cellPhone: PhoneNumberInput;
	handle: Scalars['String'];
	homePhone?: InputMaybe<PhoneNumberInput>;
	languageIds: Array<Scalars['String']>;
	name: PersonNameInput;
	secondaryEmailAddress?: InputMaybe<Scalars['String']>;
};

export type UserBadgeCountResponse = {
	__typename?: 'UserBadgeCountResponse';
	associateInvitationsBadgeCount: Scalars['Float'];
	conversationsBadgeCount: Scalars['Float'];
	inboundRequestsBadgeCount: Scalars['Float'];
	notificationsBadgeCount: Scalars['Float'];
};

/** Represents a file associated with a message. */
export type UserChatFile = {
	__typename?: 'UserChatFile';
	/** The ID of the file. */
	chatFileId: Scalars['ID'];
	/** The display name of the file. */
	name: Scalars['String'];
};

/** Represents a footnote associated with a message. */
export type UserChatFootnote = {
	__typename?: 'UserChatFootnote';
	/** The ID of the footnote. */
	chatFootnoteId: Scalars['ID'];
	/** The file display name for the footnote. */
	fileName?: Maybe<Scalars['String']>;
	/** The text snippet for the footnote. */
	snippet?: Maybe<Scalars['String']>;
	/** The url for the footnote. */
	url?: Maybe<Scalars['String']>;
	/** The display name for the url. */
	urlDisplayName?: Maybe<Scalars['String']>;
};

/** Represents a single message in a conversation. */
export type UserChatMessage = {
	__typename?: 'UserChatMessage';
	/** The id of the conversation this message belongs to. */
	chatConversationId: Scalars['ID'];
	/** The ID of the message. */
	chatMessageId: Scalars['ID'];
	/** The message content. */
	content?: Maybe<Scalars['String']>;
	/** The datetime of the message. */
	dateTimeUtc: Scalars['Date'];
	/** An array of files associated with this message. */
	files?: Maybe<Array<UserChatFile>>;
	/** An array of footnotes associated with this message. */
	footnotes?: Maybe<Array<UserChatFootnote>>;
	/** The function call arguments. */
	functionCallArguments?: Maybe<Scalars['String']>;
	/** The function call name. */
	functionCallName?: Maybe<Scalars['String']>;
	/** The name of the function that generated the content if role = FunctionResultsForUser. */
	functionName?: Maybe<Scalars['String']>;
	/** The rating of this message. */
	rating?: Maybe<ChatMessageRating>;
	/** The message creators role. */
	role: ChatMessageRole;
	/** The sequence of this message, used to properly sort messages in a conversation. */
	sequence: Scalars['Float'];
};

/** Chat message file request. */
export type UserChatMessageFileRequest = {
	/** The ID of the file. */
	fileId: Scalars['ID'];
	/** The upload token of the file. */
	fileUploadToken: Scalars['String'];
};

/** Chat message request. */
export type UserChatMessageRequest = {
	/** An array of file attachments to associate to the message. */
	attachments?: InputMaybe<Array<UserChatMessageFileRequest>>;
	/** The caller local timezone offset in minutes. */
	callerTimezoneOffsetMinutes?: InputMaybe<Scalars['Float']>;
	/** The user's message, i.e. 'Find claims for insured Bennett.' */
	content: Scalars['String'];
	/** The conversation ID, when continuing an existing conversation. Pass null to start a new conversation. */
	conversationId: Scalars['ID'];
};

/** Chat message response. */
export type UserChatMessageResponse = {
	__typename?: 'UserChatMessageResponse';
	/** The ID of the chat conversation. */
	chatConversationId: Scalars['ID'];
	/** The name for the conversation, if saved. */
	chatConversationName?: Maybe<Scalars['String']>;
	/** The source of the conversation. */
	chatConversationSource: ChatConversationSource;
	/** The type of the conversation. */
	chatConversationType: ChatConversationType;
	/** An array of chat message responses to a request. */
	chatMessages?: Maybe<Array<UserChatMessage>>;
	/** The number of cumulative tokens for this conversation */
	cumulativeTokens?: Maybe<Scalars['Float']>;
	/** A serialized JSON string of metadata about the conversation. */
	metadata?: Maybe<Scalars['String']>;
	/** The OpenAI thread Id for this conversation */
	openAiThreadId?: Maybe<Scalars['String']>;
	/** The id of the user this conversations belongs to. */
	userId: Scalars['ID'];
};

export type UserDashboardConfigurationPatch = {
	primaryColor: Scalars['String'];
	secondaryColor: Scalars['String'];
	subTitle: Scalars['String'];
	title: Scalars['String'];
};

export type UserDashboardConfigurationResponse = {
	__typename?: 'UserDashboardConfigurationResponse';
	id: Scalars['String'];
	primaryColor?: Maybe<Scalars['String']>;
	secondaryColor?: Maybe<Scalars['String']>;
	subTitle?: Maybe<Scalars['String']>;
	title?: Maybe<Scalars['String']>;
};

export type UserEducation = {
	__typename?: 'UserEducation';
	degree?: Maybe<Scalars['String']>;
	description?: Maybe<Scalars['String']>;
	endMonth?: Maybe<Scalars['Float']>;
	endYear?: Maybe<Scalars['Float']>;
	field?: Maybe<Scalars['String']>;
	id: Scalars['String'];
	logoUrl?: Maybe<Scalars['String']>;
	organizationEmblem?: Maybe<Emblem>;
	organizationId?: Maybe<Scalars['String']>;
	school?: Maybe<Scalars['String']>;
	skillIds: Array<Scalars['String']>;
	startMonth?: Maybe<Scalars['Float']>;
	startYear?: Maybe<Scalars['Float']>;
	websiteUrl?: Maybe<Scalars['String']>;
};

export type UserEducationResponse = {
	__typename?: 'UserEducationResponse';
	education: Array<UserEducation>;
};

export type UserEducationUpdate = {
	degree?: InputMaybe<Scalars['String']>;
	description?: InputMaybe<Scalars['String']>;
	endMonth?: InputMaybe<Scalars['Float']>;
	endYear?: InputMaybe<Scalars['Float']>;
	field?: InputMaybe<Scalars['String']>;
	logoUrl?: InputMaybe<Scalars['String']>;
	organizationId?: InputMaybe<Scalars['String']>;
	school?: InputMaybe<Scalars['String']>;
	startMonth?: InputMaybe<Scalars['Float']>;
	startYear?: InputMaybe<Scalars['Float']>;
	websiteUrl?: InputMaybe<Scalars['String']>;
};

export type UserExperienceCompany = {
	__typename?: 'UserExperienceCompany';
	id: Scalars['ID'];
	logoUrl?: Maybe<Scalars['String']>;
	name?: Maybe<Scalars['String']>;
	organizationId?: Maybe<Scalars['String']>;
	positions: Array<UserExperiencePosition>;
	skillIds: Array<Scalars['String']>;
	userId: Scalars['String'];
	websiteUrl?: Maybe<Scalars['String']>;
};

export type UserExperienceCompanyAndPositionCreation = {
	companyRequest: UserExperienceCompanyUpdate;
	positionRequest: UserExperiencePositionUpdate;
};

export type UserExperienceCompanyUpdate = {
	logoUrl?: InputMaybe<Scalars['String']>;
	name?: InputMaybe<Scalars['String']>;
	organizationId?: InputMaybe<Scalars['String']>;
	websiteUrl?: InputMaybe<Scalars['String']>;
};

export type UserExperiencePosition = {
	__typename?: 'UserExperiencePosition';
	description?: Maybe<Scalars['String']>;
	employmentType: EmploymentType;
	endMonth?: Maybe<Scalars['Float']>;
	endYear?: Maybe<Scalars['Float']>;
	id: Scalars['ID'];
	jobTitle: Scalars['String'];
	location: Scalars['String'];
	locationType: EmploymentLocationType;
	startMonth: Scalars['Float'];
	startYear: Scalars['Float'];
	userExperienceCompanyId: Scalars['String'];
};

export type UserExperiencePositionUpdate = {
	description?: InputMaybe<Scalars['String']>;
	employmentType: EmploymentType;
	endMonth?: InputMaybe<Scalars['Float']>;
	endYear?: InputMaybe<Scalars['Float']>;
	jobTitle: Scalars['String'];
	location: Scalars['String'];
	locationType: EmploymentLocationType;
	startMonth: Scalars['Float'];
	startYear: Scalars['Float'];
};

export type UserExperienceResponse = {
	__typename?: 'UserExperienceResponse';
	companies: Array<UserExperienceCompany>;
};

export type UserExtraData = {
	__typename?: 'UserExtraData';
	companyName?: Maybe<Scalars['String']>;
	companyTitle?: Maybe<Scalars['String']>;
	fullName?: Maybe<Scalars['String']>;
	initials?: Maybe<Scalars['String']>;
	isSiteUser: Scalars['Boolean'];
	siteUserId?: Maybe<Scalars['ID']>;
	siteUserRole?: Maybe<Scalars['ID']>;
};

export type UserLogin = {
	__typename?: 'UserLogin';
	displayName?: Maybe<Scalars['String']>;
	emailAddress: Scalars['String'];
	isSiteAdmin?: Maybe<Scalars['Boolean']>;
	registered: Scalars['Boolean'];
	siteUserId?: Maybe<Scalars['ID']>;
	siteUserInvitation?: Maybe<SiteUserInvitation>;
	standardUserId?: Maybe<Scalars['ID']>;
	userId: Scalars['ID'];
};

export type UserOrgPermissions = {
	__typename?: 'UserOrgPermissions';
	isOrganizationAdmin?: Maybe<Scalars['Boolean']>;
	organizationId: Scalars['ID'];
	permissions?: Maybe<Array<Scalars['String']>>;
};

export type UserProfile = {
	__typename?: 'UserProfile';
	about?: Maybe<Scalars['String']>;
	adjusterLicenses?: Maybe<Array<AdjusterLicensingSummary>>;
	affiliatedOrganizationConnections: Array<Connection>;
	avatarFile?: Maybe<File>;
	backgroundCheckDate?: Maybe<Scalars['Date']>;
	companyName?: Maybe<Scalars['String']>;
	companyTitle?: Maybe<Scalars['String']>;
	connection?: Maybe<Connection>;
	conversationInsuranceProfileLink?: Maybe<Scalars['String']>;
	displayLocation?: Maybe<Scalars['String']>;
	displayTemporaryLocation?: Maybe<DisplayTemporaryLocation>;
	drugTestDate?: Maybe<Scalars['Date']>;
	facebookProfileLink?: Maybe<Scalars['String']>;
	following?: Maybe<Scalars['Boolean']>;
	id: Scalars['ID'];
	jobFunctions?: Maybe<Array<JobFunctions>>;
	languageIds?: Maybe<Array<Scalars['String']>>;
	linkedinProfileLink?: Maybe<Scalars['String']>;
	name: PersonName;
	private: Scalars['Boolean'];
	workCodes: Array<Scalars['String']>;
	xProfileLink?: Maybe<Scalars['String']>;
	xactimateAddresses?: Maybe<Array<XactimateAddress>>;
};

export type UserProfileEffectivePrivacy = {
	__typename?: 'UserProfileEffectivePrivacy';
	aspect: Scalars['String'];
	mayAccess: Scalars['Boolean'];
	targetUserId: Scalars['String'];
};

export type UserProfileUpdate = {
	about?: InputMaybe<Scalars['String']>;
	avatarUploadToken?: InputMaybe<Scalars['String']>;
	backgroundCheckDate?: InputMaybe<Scalars['Date']>;
	companyName?: InputMaybe<Scalars['String']>;
	companyTitle?: InputMaybe<Scalars['String']>;
	conversationInsuranceProfileLink?: InputMaybe<Scalars['String']>;
	drugTestDate?: InputMaybe<Scalars['Date']>;
	facebookProfileLink?: InputMaybe<Scalars['String']>;
	jobFunctions?: InputMaybe<Array<JobFunctions>>;
	languageIds: Array<Scalars['String']>;
	linkedinProfileLink?: InputMaybe<Scalars['String']>;
	name: PersonNameInput;
	workCodes: Array<Scalars['String']>;
	xProfileLink?: InputMaybe<Scalars['String']>;
	xactimateAddresses?: InputMaybe<Array<XactimateAddressUpdate>>;
};

export type UserRegistration = {
	birthDate?: InputMaybe<Scalars['Date']>;
	cellPhone: PhoneNumberInput;
	languageIds: Array<Scalars['String']>;
	name: PersonNameInput;
	primaryAddress: AddressInput;
};

export type UserSkill = {
	__typename?: 'UserSkill';
	displayOrder: Scalars['Float'];
	endorsedByCurrentUser: Scalars['Boolean'];
	endorsementCount: Scalars['Float'];
	id: Scalars['String'];
	skill: Skill;
	skillId: Scalars['String'];
};

export type UserSkillCreate = {
	displayOrder: Scalars['Float'];
	placesUsed: Array<UserSkillPlaceUsedCreate>;
	skillId: Scalars['String'];
};

export type UserSkillDisplayOrder = {
	displayOrder: Scalars['Float'];
	userSkillId: Scalars['String'];
};

export type UserSkillEndorsement = {
	__typename?: 'UserSkillEndorsement';
	endorsedOnUtc: Scalars['Date'];
	endorsingUserId: Scalars['String'];
	id: Scalars['String'];
	showOnProfile: Scalars['Boolean'];
	userSkillId: Scalars['String'];
};

export type UserSkillEndorsementResponse = {
	__typename?: 'UserSkillEndorsementResponse';
	endorsements: Array<UserSkillEndorsement>;
};

export type UserSkillPlaceUsed = {
	__typename?: 'UserSkillPlaceUsed';
	id: Scalars['String'];
	userEducation?: Maybe<UserEducation>;
	userEducationId?: Maybe<Scalars['String']>;
	userExperienceCompany?: Maybe<UserExperienceCompany>;
	userExperienceId?: Maybe<Scalars['String']>;
	userId: Scalars['String'];
};

export type UserSkillPlaceUsedCreate = {
	userEducationId?: InputMaybe<Scalars['String']>;
	userExperienceId?: InputMaybe<Scalars['String']>;
};

export type UserSkillPlacesUsedResponse = {
	__typename?: 'UserSkillPlacesUsedResponse';
	placesUsed: Array<UserSkillPlaceUsed>;
};

export type UserSkillPlacesUsedUpdateRequest = {
	placesUsed: Array<UserSkillPlaceUsedCreate>;
};

export type UserSkillsResponse = {
	__typename?: 'UserSkillsResponse';
	skills: Array<UserSkill>;
};

export enum UserType {
	SiteUser = 'SiteUser',
	SiteUserInvitation = 'SiteUserInvitation',
	SocialUser = 'SocialUser',
}

export type ValidationRules = {
	__typename?: 'ValidationRules';
	properties: Array<PropertyValidationRules>;
	typeName: Scalars['ID'];
};

export type Viewport = {
	__typename?: 'Viewport';
	northeast: Location;
	southwest: Location;
};

export type VirusScanResult = {
	blobName?: InputMaybe<Scalars['String']>;
	container?: InputMaybe<Scalars['String']>;
	rawResult?: InputMaybe<Scalars['String']>;
	status: Scalars['String'];
};

export enum VirusStatus {
	Clean = 'Clean',
	Infected = 'Infected',
	Pending = 'Pending',
	ScanFailed = 'ScanFailed',
	TooLargeToScan = 'TooLargeToScan',
}

export type WorkType = {
	__typename?: 'WorkType';
	displayName: Scalars['String'];
	workCode: Scalars['ID'];
};

export type XactimateAddress = {
	__typename?: 'XactimateAddress';
	xactNetAddress: Scalars['String'];
	xactimateVersion: Scalars['String'];
};

export type XactimateAddressUpdate = {
	xactNetAddress: Scalars['String'];
	xactimateVersion: Scalars['String'];
};

export type ZendeskAuthResponse = {
	__typename?: 'ZendeskAuthResponse';
	jwt?: Maybe<Scalars['String']>;
	url: Scalars['String'];
};
