import { Close, MoreHoriz } from '@mui/icons-material';
import {
	Box,
	Dialog,
	Divider,
	Drawer,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	MenuList,
	Stack,
	Typography,
} from '@mui/material';
import { FileInstanceInformation } from 'middleware-types';
import { useRef } from 'react';
import { ExtensionIcon } from 'utils/fileUtils';
import { theme } from 'utils/theme';
import { useIsMobile } from 'utils/useScreenSize';
import { useFileOptions } from '../hooks/use-file-options';
import { AccessLevelModal } from '../modals/access-level-modal';
import { CopyFileModal } from '../modals/copy-file-modal';
import { DeleteFileInstanceModal } from '../modals/delete-file-instance-modal';
import { DeleteFilesModal } from '../modals/delete-file-modal';
import { FileDetailsDrawer } from '../modals/file-details-drawer';
import { MakeCurrentInstanceModal } from '../modals/make-current-instance-modal';
import { MetadataModal } from '../modals/metadata-modal';
import { MobileVersionHistoryModal } from '../modals/mobile-version-history-modal';
import { MoveFilesModal } from '../modals/move-modal';
import { RenameFileModal } from '../modals/rename-file-modal';
import { VersionHistoryModal } from '../modals/version-history-modal';
import { useVersionHistoryContext } from './version-history-provider';

interface FileMenuButtonProps {
	file: FileInstanceInformation;
	menuOpen: boolean;
	setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FileMenuButton = ({ file, menuOpen, setMenuOpen }: FileMenuButtonProps) => {
	const { isVersionHistory } = useVersionHistoryContext();
	const isMobile = useIsMobile();

	const buttonRef = useRef<HTMLButtonElement>(null);

	const {
		items,
		renameOpen,
		setRenameOpen,
		copyOpen,
		setCopyOpen,
		moveOpen,
		setMoveOpen,
		privacyOpen,
		setPrivacyOpen,
		makeCurrentOpen,
		setMakeCurrentOpen,
		versionHistoryOpen,
		setVersionHistoryOpen,
		detailsOpen,
		setDetailsOpen,
		metadataOpen,
		setMetadataOpen,
		deleteOpen,
		setDeleteOpen,
		deleteInstanceOpen,
		setDeleteInstanceOpen,
	} = useFileOptions(file);

	if (items.length === 0) return <Box height={40} />;

	return (
		<Box
			onMouseDown={(e) => e.stopPropagation()}
			onClick={(e) => e.stopPropagation()}
			onTouchStart={(e) => e.stopPropagation()}>
			{/** menu button */}
			<IconButton onClick={() => setMenuOpen(true)} ref={buttonRef}>
				<MoreHoriz />
			</IconButton>
			{/** menus */}
			{isMobile ? (
				<>
					<Drawer
						open={menuOpen}
						anchor="bottom"
						onClose={() => setMenuOpen(false)}
						onClick={() => setMenuOpen(false)}>
						<Stack px={2} py={1.5} direction="row" alignItems="center" spacing={1}>
							<ExtensionIcon filename={file.fileName} sx={{ color: 'neutral.500' }} />
							<Typography variant="h3" flex={1} noWrap>
								{file.fileName}
								{isVersionHistory && ` (v${file.versionNumber})`}
							</Typography>
							<IconButton onClick={() => setMenuOpen(false)}>
								<Close />
							</IconButton>
						</Stack>
						<Divider />
						<MenuList>
							{items.map((item, index) => [
								index > 0 && items[index - 1].section !== item.section && (
									<Divider key={`${index}.divider`} />
								),
								<MenuItem key={index} onClick={item.onClick} dense={false}>
									<ListItemIcon>
										<item.icon />
									</ListItemIcon>
									<ListItemText>{item.label}</ListItemText>
								</MenuItem>,
							])}
						</MenuList>
					</Drawer>
					{/** rename modal */}
					<Drawer open={renameOpen} anchor="bottom">
						<RenameFileModal file={file} onClose={() => setRenameOpen(false)} />
					</Drawer>
					{/** copy modal */}
					<Drawer open={copyOpen} anchor="bottom">
						<CopyFileModal file={file} onClose={() => setCopyOpen(false)} />
					</Drawer>
					{/** move modal */}
					<Drawer open={moveOpen} anchor="bottom">
						<MoveFilesModal files={[file]} onClose={() => setMoveOpen(false)} />
					</Drawer>
					{/** access level modal */}
					<Drawer open={privacyOpen} anchor="bottom">
						<AccessLevelModal files={[file]} onClose={() => setPrivacyOpen(false)} />
					</Drawer>
					{/** make current modal */}
					<Drawer open={makeCurrentOpen} anchor="bottom">
						<MakeCurrentInstanceModal
							file={file}
							onClose={() => setMakeCurrentOpen(false)}
						/>
					</Drawer>
					{/** version history modal */}
					<Dialog
						open={versionHistoryOpen}
						fullScreen
						sx={{ zIndex: theme.zIndex.drawer }}>
						<MobileVersionHistoryModal
							file={file}
							onClose={() => setVersionHistoryOpen(false)}
						/>
					</Dialog>
					{/** details modal */}
					<Drawer open={detailsOpen} anchor="bottom">
						<FileDetailsDrawer file={file} onClose={() => setDetailsOpen(false)} />
					</Drawer>
					{/** metadata modal */}
					<Drawer open={metadataOpen} anchor="bottom">
						<MetadataModal file={file} onClose={() => setMetadataOpen(false)} />
					</Drawer>
					{/** delete modal */}
					<Drawer open={deleteOpen} anchor="bottom">
						<DeleteFilesModal files={[file]} onClose={() => setDeleteOpen(false)} />
					</Drawer>
					{/** delete instance modal */}
					<Drawer open={deleteInstanceOpen} anchor="bottom">
						<DeleteFileInstanceModal
							file={file}
							onClose={() => setDeleteInstanceOpen(false)}
						/>
					</Drawer>
				</>
			) : (
				<>
					<Menu
						open={menuOpen}
						anchorEl={buttonRef.current}
						onClose={() => setMenuOpen(false)}
						onClick={() => setMenuOpen(false)}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
						transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
						{items.map((item, index) => [
							index > 0 && items[index - 1].section !== item.section && (
								<Divider key={`${index}.divider`} />
							),
							<MenuItem key={index} onClick={item.onClick}>
								<ListItemIcon>
									<item.icon />
								</ListItemIcon>
								<ListItemText>{item.label}</ListItemText>
							</MenuItem>,
						])}
					</Menu>
					{/** rename modal */}
					<Dialog open={renameOpen} fullWidth maxWidth="sm">
						<RenameFileModal file={file} onClose={() => setRenameOpen(false)} />
					</Dialog>
					{/** copy modal */}
					<Dialog open={copyOpen} fullWidth maxWidth="sm">
						<CopyFileModal file={file} onClose={() => setCopyOpen(false)} />
					</Dialog>
					{/** move modal */}
					<Dialog open={moveOpen} fullWidth maxWidth="sm">
						<MoveFilesModal files={[file]} onClose={() => setMoveOpen(false)} />
					</Dialog>
					{/** access level modal */}
					<Dialog open={privacyOpen} fullWidth maxWidth="sm">
						<AccessLevelModal files={[file]} onClose={() => setPrivacyOpen(false)} />
					</Dialog>
					{/** make current modal */}
					<Dialog open={makeCurrentOpen} fullWidth maxWidth="sm">
						<MakeCurrentInstanceModal
							file={file}
							onClose={() => setMakeCurrentOpen(false)}
						/>
					</Dialog>
					{/** version history modal */}
					<Dialog
						open={versionHistoryOpen}
						fullWidth
						maxWidth="xxl"
						// this is to stop file drop events from propagating to the main table
						slotProps={{
							root: {
								onDragEnter: (e) => e.stopPropagation(),
								onDragOver: (e) => e.stopPropagation(),
								onDragLeave: (e) => e.stopPropagation(),
							},
						}}>
						<VersionHistoryModal
							file={file}
							onClose={() => setVersionHistoryOpen(false)}
						/>
					</Dialog>
					{/** metadata modal */}
					<Dialog open={metadataOpen} fullWidth maxWidth="sm">
						<MetadataModal file={file} onClose={() => setMetadataOpen(false)} />
					</Dialog>
					{/** delete modal */}
					<Dialog open={deleteOpen} fullWidth maxWidth="sm">
						<DeleteFilesModal files={[file]} onClose={() => setDeleteOpen(false)} />
					</Dialog>
					{/** delete instance modal */}
					<Dialog open={deleteInstanceOpen} fullWidth maxWidth="sm">
						<DeleteFileInstanceModal
							file={file}
							onClose={() => setDeleteInstanceOpen(false)}
						/>
					</Dialog>
				</>
			)}
		</Box>
	);
};
