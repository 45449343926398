import { gql } from '@apollo/client';
import { KeyboardArrowDown } from '@mui/icons-material';
import {
	ButtonBase,
	FormHelperText,
	InputBaseComponentProps,
	Menu,
	MenuItem,
	Stack,
	TextField,
} from '@mui/material';
import { SxProps } from '@mui/system';
import { useField, useFormikContext } from 'formik';
import parsePhoneNumberFromString, { AsYouType, isPossiblePhoneNumber } from 'libphonenumber-js';
import { PhoneNumber, PhoneNumberInput } from 'middleware-types';
import React, { useCallback, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { theme } from 'utils/theme';

export const PHONE_FIELDS = gql`
	fragment PhoneFields on PhoneNumber {
		countryCode
		number
	}
`;

/**
 * PhoneNumberField is a phone number input with a country code.
 *
 * @param {PhoneNumberFieldProps} props
 * @returns
 */

type PhoneNumberFieldProps = {
	name: string;
	label: string | undefined;
	disabled?: boolean;
	required?: boolean;
	sx?: SxProps;
};

export const PhoneNumberField = ({
	name,
	label,
	disabled,
	required,
	sx,
}: PhoneNumberFieldProps) => {
	const { isSubmitting } = useFormikContext();
	const [field, meta, helpers] = useField<Partial<PhoneNumberInput>>({
		name: name,
		onReset: () => reset(),
		validate: (value: Partial<PhoneNumberInput>) => {
			if (
				(value?.number ||
					(phoneNumber?.length && phoneNumber?.length < 14 && phoneNumber?.length > 0)) &&
				!isPossiblePhoneNumber(`+${value?.countryCode}${value?.number}`, {
					defaultCallingCode: field.value.countryCode,
				})
			) {
				return 'Invalid Phone Number';
			}
			if (required === true && (!value?.number || !value?.countryCode)) {
				return 'Required Field';
			}
		},
	});

	const [phoneNumber, setPhoneNumber] = useState<string | undefined>(
		meta.initialValue?.number
			? `+${meta.initialValue?.countryCode}${meta.initialValue?.number}`
			: ''
	);

	const reset = useCallback(() => {
		setPhoneNumber(
			meta.initialValue?.number
				? `+${meta.initialValue.countryCode}${meta.initialValue.number}`
				: ''
		);
	}, [meta.initialValue]);

	const onPhoneNumberChange = (value: string | undefined) => {
		setPhoneNumber(value);
		try {
			const asYouType = new AsYouType({
				defaultCallingCode: field.value.countryCode,
			});
			asYouType.input(value ?? '');
			const num = asYouType.getNumber();

			field.onChange({
				target: {
					name: field.name,
					value: {
						countryCode: num?.countryCallingCode ?? '',
						number: num?.nationalNumber ?? '',
					},
				},
			});
		} catch (error: any /*ParseError*/) {
			if (error.message.indexOf('Unknown country') !== 0) {
				throw error;
			}
		}
	};

	return (
		<>
			<PhoneInput
				name={name}
				label={label}
				value={phoneNumber}
				customOnBlur={() => {
					helpers.setTouched(true);
					field.onBlur;
				}}
				onChange={(val) => {
					onPhoneNumberChange(val);
				}}
				defaultCountry={CountryCodeToLabel(meta.initialValue?.countryCode) ?? 'US'}
				countries={['US', 'GB']}
				countryOptionsOrder={['US', 'GB']}
				inputComponent={PhoneInputComponent}
				addInternationalOption={false}
				disabled={disabled || isSubmitting}
				meta={meta}
				sx={sx}
				required={required}
				countrySelectComponent={CountrySelect}
			/>
			<FormHelperText
				error
				sx={{
					paddingLeft: 7,
				}}>
				{meta.touched
					? typeof meta.error === 'object'
						? meta.error['number'] ?? meta.error['countryCode']
						: meta.error
					: undefined}
			</FormHelperText>
		</>
	);
};

const CountryCodeToLabel = (countryCode?: string | undefined) => {
	switch (countryCode) {
		case '1':
			return 'US';
		case '44':
			return 'GB';
		default:
			return undefined;
	}
};

const PhoneInputComponent = React.forwardRef<HTMLInputElement, InputBaseComponentProps>(
	(props, ref) => {
		const {
			name,
			label,
			value,
			onChange,
			onFocus,
			onBlur,
			customOnBlur,
			meta,
			sx,
			required,
			disabled,
			...rest
		} = props;

		return (
			<TextField
				name={name}
				label={label}
				value={value}
				error={meta.touched && !!meta.error}
				required={required}
				onBlur={customOnBlur}
				disabled={disabled}
				onChange={onChange}
				onFocus={onFocus}
				inputProps={{
					...rest,
				}}
				inputRef={ref}
				sx={sx}
			/>
		);
	}
);

PhoneInputComponent.displayName = 'PhoneInputComponent';

interface CountrySelectProps {
	value?: string;
	options: { value?: string; label?: string }[];
	onChange: (value: string | undefined) => void;
	disabled?: boolean;
	readOnly?: boolean;
	iconComponent: React.FC<{ country?: string; label: string }>;
}

const CountrySelect = ({
	value,
	options,
	onChange,
	disabled,
	readOnly,
	iconComponent: Icon,
}: CountrySelectProps) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const label = options.find((option) => option.value === value)?.label ?? '';

	return (
		<>
			<ButtonBase
				sx={{ py: 1, mr: 0.5 }}
				disableRipple
				disabled={disabled || readOnly}
				onClick={(e) => setAnchorEl(e.currentTarget)}>
				<Stack
					direction="row"
					alignItems="center"
					spacing={0.25}
					sx={{
						'& .PhoneInputCountryIcon': {
							border: `1px solid ${theme.palette.neutral[500]}`,
						},
					}}>
					<Icon country={value} label={label} />
					<KeyboardArrowDown fontSize="small" />
				</Stack>
			</ButtonBase>
			<Menu
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				onClick={() => setAnchorEl(null)}>
				{options.map((option, index) => (
					<MenuItem
						key={index}
						selected={option.value === value}
						onClick={() => onChange(option.value)}>
						{option.label}
					</MenuItem>
				))}
			</Menu>
		</>
	);
};

/**
 * Renders out a phone number using the country specific number format.
 *
 * @param {{ number: PhoneNumber}} props
 * @return {*}
 */
export const PhoneNumberText = (props: { number: PhoneNumber }) => {
	let parsedNumber = '';
	try {
		parsedNumber =
			parsePhoneNumberFromString(
				`+${props.number.countryCode}${props.number.number}`
			)?.formatInternational() ?? '';
	} catch (error: any /*ParseError*/) {
		if (error.message.indexOf('Unknown country') !== 0) {
			throw error;
		}
		parsedNumber = 'Invalid Phone Number';
	}

	return <>{parsedNumber}</>;
};
