import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragHandle } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { UserSkill } from 'middleware-types';
import { SkillListItem } from './skill-list-item';

interface SortableSkillProps {
	skill: UserSkill;
	userId: string;
	index: number;
}

export const SortableSkill = ({ skill, userId, index }: SortableSkillProps) => {
	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
		id: skill.id,
	});

	const style = {
		transform: CSS.Translate.toString(transform),
		transition,
	};

	return (
		<Stack
			{...attributes}
			ref={setNodeRef}
			style={style}
			direction="row"
			alignItems="flex-start"
			spacing={0.5}>
			<Box {...listeners} pt={0.45} sx={{ cursor: 'move' }}>
				<DragHandle sx={{ color: 'neutral.500' }} />
			</Box>
			<SkillListItem skill={skill} userId={userId} index={index} isSortable />
		</Stack>
	);
};
