import { Close, FolderOutlined, MoreHoriz } from '@mui/icons-material';
import {
	Box,
	Dialog,
	Divider,
	Drawer,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	MenuList,
	Stack,
	Typography,
} from '@mui/material';
import { FolderReference } from 'middleware-types';
import { useRef } from 'react';
import { useIsMobile } from 'utils/useScreenSize';
import { useFolderOptions } from '../hooks/use-folder-options';
import { CreateUpdateFolderModal } from '../modals/create-update-folder-modal';
import { DeleteFolderModal } from '../modals/delete-folder-modal';

interface FolderMenuButtonProps {
	folder: FolderReference;
	menuOpen: boolean;
	setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FolderMenuButton = ({ folder, menuOpen, setMenuOpen }: FolderMenuButtonProps) => {
	const isMobile = useIsMobile();

	const buttonRef = useRef<HTMLButtonElement>(null);

	const { items, renameOpen, setRenameOpen, deleteOpen, setDeleteOpen } = useFolderOptions();

	if (items.length === 0) return <Box height={40} />;

	return (
		<Box
			onMouseDown={(e) => e.stopPropagation()}
			onClick={(e) => e.stopPropagation()}
			onTouchStart={(e) => e.stopPropagation()}>
			{/** menu button */}
			<IconButton onClick={() => setMenuOpen(true)} ref={buttonRef}>
				<MoreHoriz />
			</IconButton>
			{/** menus */}
			{isMobile ? (
				<>
					<Drawer
						open={menuOpen}
						anchor="bottom"
						onClose={() => setMenuOpen(false)}
						onClick={() => setMenuOpen(false)}>
						<Stack px={2} py={1.5} direction="row" alignItems="center" spacing={1}>
							<FolderOutlined sx={{ color: 'neutral.500' }} />
							<Typography variant="h3" flex={1}>
								{folder.name}
							</Typography>
							<IconButton onClick={() => setMenuOpen(false)}>
								<Close />
							</IconButton>
						</Stack>
						<Divider />
						<MenuList>
							{items.map((item, index) => (
								<MenuItem key={index} onClick={item.onClick} dense={false}>
									<ListItemIcon>
										<item.icon />
									</ListItemIcon>
									<ListItemText>{item.label}</ListItemText>
								</MenuItem>
							))}
						</MenuList>
					</Drawer>
					{/** rename modal */}
					<Drawer open={renameOpen} anchor="bottom">
						<CreateUpdateFolderModal
							folder={folder}
							onClose={() => setRenameOpen(false)}
						/>
					</Drawer>
					{/** delete modal */}
					<Drawer open={deleteOpen} anchor="bottom">
						<DeleteFolderModal folder={folder} onClose={() => setDeleteOpen(false)} />
					</Drawer>
				</>
			) : (
				<>
					<Menu
						open={menuOpen}
						anchorEl={buttonRef.current}
						onClose={() => setMenuOpen(false)}
						onClick={() => setMenuOpen(false)}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
						transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
						{items.map((item, index) => (
							<MenuItem key={index} onClick={item.onClick}>
								<ListItemIcon>
									<item.icon />
								</ListItemIcon>
								<ListItemText>{item.label}</ListItemText>
							</MenuItem>
						))}
					</Menu>
					{/** rename modal */}
					<Dialog open={renameOpen} fullWidth maxWidth="sm">
						<CreateUpdateFolderModal
							folder={folder}
							onClose={() => setRenameOpen(false)}
						/>
					</Dialog>
					{/** delete modal */}
					<Dialog open={deleteOpen} fullWidth maxWidth="sm">
						<DeleteFolderModal folder={folder} onClose={() => setDeleteOpen(false)} />
					</Dialog>
				</>
			)}
		</Box>
	);
};
