import { LogoutOutlined, PasswordOutlined } from '@mui/icons-material';
import {
	Card,
	CardContent,
	CardHeader,
	Divider,
	IconButton,
	Stack,
	Typography,
} from '@mui/material';
import { Auth } from 'aws-amplify';
import { useGlobalSignOut } from 'components/pages/user/account/security/hooks';
import { ConfirmationModalContent } from 'components/ui/modals/confirmation-modal-content';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { useState } from 'react';
import { useSession } from 'utils/session';

interface SessionSettingsCardProps {
	userId: string;
	canEdit: boolean;
}

export const SessionSettingsCard = ({ userId, canEdit }: SessionSettingsCardProps) => {
	const { user } = useSession();
	const { globalSignOut } = useGlobalSignOut();
	const [modalOpen, setModalOpen] = useState(false);

	return (
		<Card>
			<CardHeader title="Session Settings" />
			<Divider />
			<CardContent>
				<Stack direction="row" alignItems="center" spacing={{ xs: 2, sm: 3 }}>
					<PasswordOutlined fontSize="small" />
					<Typography variant="h5" flex={1}>
						Sign Out Of All Devices
					</Typography>
					{canEdit && (
						<IconButton onClick={() => setModalOpen(true)}>
							<LogoutOutlined />
						</IconButton>
					)}
				</Stack>
			</CardContent>
			{/** sign out modal */}
			<ModalOrDrawer open={modalOpen}>
				<ConfirmationModalContent
					variant="destructive"
					title="Sign Out Of All Devices"
					primaryText="Are you sure?"
					secondaryText={
						user.userId == userId
							? `Do you really want to log out of all devices?`
							: `Do you really want to log this user out of all of their devices?`
					}
					onSubmit={async () => {
						if (user.userId !== userId) {
							return await globalSignOut(userId);
						}
						await Auth.signOut({ global: true });
						window.location.href = '/auth/login';
						return false;
					}}
					onClose={() => setModalOpen(false)}
					confirmText="Sign Out"
				/>
			</ModalOrDrawer>
		</Card>
	);
};
