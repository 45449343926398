import { gql } from '@apollo/client';
import { Mutation, MutationCommitFileArgs } from 'middleware-types';
import { client } from 'utils/apollo';
import { addNewFileInstance } from '../../utils/cache-helpers';
import { FILE_INFO_FIELDS } from '../../utils/fragments.graphql';
import { handleNoResponse, responseHasErrors } from 'utils/errors';

const COMMIT_FILE_INSTANCE = gql`
	${FILE_INFO_FIELDS}
	mutation CommitFileInstance(
		$entityType: FoldersApiEntityType!
		$entityId: String!
		$request: FileUploadCommitRequest!
	) {
		commitFile(entityType: $entityType, entityId: $entityId, request: $request) {
			...FileInfoFields
		}
	}
`;

export const commitFileInstance = async (variables: MutationCommitFileArgs) =>
	await client
		.mutate<Pick<Mutation, 'commitFile'>, MutationCommitFileArgs>({
			mutation: COMMIT_FILE_INSTANCE,
			variables,
			update: (cache, { data }) => {
				if (!data) return;
				addNewFileInstance({ cache, file: data.commitFile });
			},
		})
		.then((res) => {
			if (responseHasErrors(res.errors)) {
				throw res.errors;
			}

			return true;
		})
		.catch(() => {
			handleNoResponse({});
			return false;
		});
