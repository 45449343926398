import { AddOutlined, DeleteOutlined, EditOutlined, MoreHorizRounded } from '@mui/icons-material';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { UserExperienceCompany } from 'middleware-types';
import { useState } from 'react';
import { Permission } from 'utils/permissions';
import { useAnonymousSession } from 'utils/session';
import { useSiteUser } from 'utils/useSiteUser';
import { UserExperienceDeleteCompanyModal } from './user-experience-delete-company-modal';

/**
 * Props for the context menu.
 *
 * @interface UserExperienceCompanyContextMenuProps
 */
interface UserExperienceCompanyContextMenuProps {
	showAddPositionForm: () => void;
	showUpdateCompanyForm: () => void;
	company: UserExperienceCompany;
}

/**
 * The Row Action.
 *
 * @enum {number}
 */
enum RowAction {
	AddPosition,
	UpdateCompany,
	DeleteCompany,
}

export const UserExperienceCompanyContextMenu = (
	props: UserExperienceCompanyContextMenuProps
): React.JSX.Element => {
	const { user } = useAnonymousSession();
	const { hasPermission } = useSiteUser();
	const [showDeleteCompanyModal, setShowDeleteCompanyModal] = useState(false);
	const [anchorEl, setAnchorEl] = useState<Element | null>(null);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void =>
		setAnchorEl(event.currentTarget);

	const onAction = (action: RowAction): void => {
		switch (action) {
			case RowAction.AddPosition:
				return props.showAddPositionForm();
			case RowAction.UpdateCompany: {
				return props.showUpdateCompanyForm();
			}
			case RowAction.DeleteCompany: {
				return setShowDeleteCompanyModal(true);
			}
		}
	};

	/**
	 * The actions possible for a context menu.
	 *  @type {*} */
	const tableActions = [
		{
			permissions: [Permission.SocialUser_Profile_U],
			action: RowAction.AddPosition,
			text: 'Add Role',
			icon: <AddOutlined />,
		},
		{
			permissions: [Permission.SocialUser_Profile_U],
			action: RowAction.UpdateCompany,
			text: 'Update Company',
			icon: <EditOutlined />,
		},
		{
			permissions: [Permission.SocialUser_Profile_U],
			action: RowAction.DeleteCompany,
			text: 'Delete Company',
			icon: <DeleteOutlined />,
		},
	];

	const handleRowAction = (action: RowAction): void => {
		setAnchorEl(null);
		if (onAction) {
			onAction(action);
		}
	};

	let menu = tableActions
		.filter(
			(ta) =>
				(user && !user.siteUserId && props.company.userId == user.userId) ||
				hasPermission(ta.permissions ?? [])
		)
		.map((ta, k) => {
			return (
				<MenuItem
					key={k}
					onClick={
						ta.action !== undefined ? (): void => handleRowAction(ta.action) : undefined
					}>
					<ListItemIcon>{ta.icon}</ListItemIcon>
					<ListItemText>{ta.text}</ListItemText>
				</MenuItem>
			);
		});

	return (
		<>
			{menu.length > 0 && (
				<IconButton
					aria-label="more"
					aria-controls="long-menu"
					aria-haspopup="true"
					onClick={handleClick}>
					<MoreHorizRounded />
				</IconButton>
			)}
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={(): void => setAnchorEl(null)}>
				{menu}
			</Menu>
			{props.company.userId && props.company.id && (
				<ModalOrDrawer open={showDeleteCompanyModal}>
					<UserExperienceDeleteCompanyModal
						userId={props.company.userId}
						onClose={() => setShowDeleteCompanyModal(false)}
						name={props.company.name}
						companyId={props.company.id}
						organizationId={props.company.organizationId}
					/>
				</ModalOrDrawer>
			)}
		</>
	);
};
