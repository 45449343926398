import {
	AssignmentOutlined,
	DeleteOutlined,
	MoreHorizOutlined,
	Subject,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Button,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Skeleton,
	Typography,
} from '@mui/material';
import {
	useCanAdjusterLicensingManualMutation,
	useDeleteCanAdjusterLicensesMutation,
} from 'components/pages/user/profile/licensing/can/hooks';
import { Alert } from 'components/ui/alert';
import { TextField } from 'components/ui/fields';
import { ConfirmationModalContent } from 'components/ui/modals/confirmation-modal-content';
import { ModalButtonsContainer } from 'components/ui/modals/modal-buttons-container';
import { ModalContent } from 'components/ui/modals/modal-content';
import { ModalHeader } from 'components/ui/modals/modal-header';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { ModalContentProps } from 'components/ui/modals/modal-types';
import { useToast } from 'components/ui/toast';
import { Formik } from 'formik';
import { AdjusterLicensingSummary } from 'middleware-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { UserProfileAspectType, usePrivacy } from 'utils/usePrivacy';
import { useValidation } from 'utils/useValidation';

interface AddCanManualModalProps extends ModalContentProps {
	userId: string;
}

export const AddCanManualModal = ({ userId, onClose }: AddCanManualModalProps) => {
	const toast = useToast();
	const navigate = useNavigate();
	const validation = useValidation('CanAdjusterLicensing');
	const mutation = useCanAdjusterLicensingManualMutation(userId);

	type FormValues = {
		ciprNumber: string;
	};

	const onSubmit = async (values: FormValues) => {
		await mutation
			.updateLicenses({
				...values,
				ciprNumber: values.ciprNumber ?? 0,
				provincesLicensing: [],
			})
			.then((success) => {
				if (!success) return;
				toast.push('Successfully added licensing data.', {
					variant: 'success',
				});
				onClose();
				navigate(`license/can`);
			});
	};

	return (
		<Formik<FormValues>
			initialValues={{
				ciprNumber: '',
			}}
			validationSchema={validation.schema}
			onSubmit={onSubmit}>
			{(fprops) => (
				<>
					<ModalHeader title="Add Canada Licensing Information" onClose={onClose} />
					<ModalContent>
						{mutation.error && <Alert error={mutation.error} />}
						{validation.loading ? (
							<Skeleton variant="rectangular" height={30} />
						) : (
							<TextField label="CIPR Number" required name="ciprNumber" fullWidth />
						)}
					</ModalContent>
					<ModalButtonsContainer>
						<Button variant="outlined" disabled={fprops.isSubmitting} onClick={onClose}>
							Cancel
						</Button>
						<LoadingButton
							color="primary"
							variant="contained"
							loading={fprops.isSubmitting}
							disabled={validation.loading || !fprops.isValid || !fprops.dirty}
							onClick={() => fprops.submitForm()}>
							Add Licenses
						</LoadingButton>
					</ModalButtonsContainer>
				</>
			)}
		</Formik>
	);
};

/**
 * CanContextOptions - Context menu options for a USA license.
 */
export const CanContextOptions = ({ summary }: { summary: AdjusterLicensingSummary }) => {
	const toast = useToast();
	const navigate = useNavigate();
	const { hasAccess } = usePrivacy(summary.userId);

	const { deleteLicenses } = useDeleteCanAdjusterLicensesMutation(summary.userId);
	const onDelete = async () =>
		await deleteLicenses()
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Successfully deleted licensing data.', {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [quickViewOpen, setQuickViewOpen] = useState(false);
	const [deleteOpen, setDeleteOpen] = useState(false);

	// If you don't have access to any licensing features, don't show the context menu at all.
	if (
		!hasAccess(UserProfileAspectType.ViewLicensingDetails, UserProfileAspectType.AlterLicensing)
	)
		return <></>;

	return (
		<>
			<IconButton onClick={(ev) => setAnchorEl(ev.currentTarget)}>
				<MoreHorizOutlined />
			</IconButton>
			<Menu
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				onClick={() => setAnchorEl(null)}>
				{hasAccess(UserProfileAspectType.ViewLicensingDetails) && (
					<MenuItem onClick={() => setQuickViewOpen(true)}>
						<ListItemIcon>
							<Subject />
						</ListItemIcon>
						<ListItemText>Quick View</ListItemText>
					</MenuItem>
				)}
				{hasAccess(
					UserProfileAspectType.ViewLicensingDetails,
					UserProfileAspectType.AlterLicensing
				) && (
					<MenuItem onClick={() => navigate(`license/can`)}>
						<ListItemIcon>
							<AssignmentOutlined />
						</ListItemIcon>
						<ListItemText>License Details</ListItemText>
					</MenuItem>
				)}
				{hasAccess(UserProfileAspectType.AlterLicensing) && (
					<MenuItem onClick={() => setDeleteOpen(true)}>
						<ListItemIcon>
							<DeleteOutlined />
						</ListItemIcon>
						<ListItemText>Delete Country</ListItemText>
					</MenuItem>
				)}
			</Menu>
			{/** quick view modal */}
			<ModalOrDrawer open={quickViewOpen} dialogProps={{ maxWidth: 'lg' }}>
				<ModalHeader
					title="Quick View: Licensing"
					onClose={() => setQuickViewOpen(false)}
				/>
				<ModalContent>
					<Typography component="pre" fontFamily="monospace">
						{summary.adjusterLicensingText}
					</Typography>
				</ModalContent>
				<ModalButtonsContainer>
					<Button
						variant="contained"
						color="primary"
						onClick={() => setQuickViewOpen(false)}>
						Close
					</Button>
				</ModalButtonsContainer>
			</ModalOrDrawer>
			{/** delete modal */}
			<ModalOrDrawer open={deleteOpen}>
				<ConfirmationModalContent
					variant="destructive"
					primaryText="Delete Canada licensing?"
					secondaryText="Do you really want to delete the licensing data for this country? This process cannot be undone."
					onSubmit={onDelete}
					onClose={() => setDeleteOpen(false)}
				/>
			</ModalOrDrawer>
		</>
	);
};
