import { DeleteOutlined, EditOutlined, Loop, MoreHoriz } from '@mui/icons-material';
import {
	Dialog,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Stack,
	TableCell,
	TableRow,
	Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { AdHocBillingItem } from 'middleware-types';
import { useState } from 'react';
import { formatUSD, zoneDateOnly } from 'utils/utils';
import { useBillingProducts } from './hooks/use-billing-products';
import { AddUpdateLineItemModal } from './modals/add-update-line-item-modal';
import { DeleteLineItemModal } from './modals/delete-line-item-modal';

interface AdHocItemRowProps {
	item: AdHocBillingItem;
	canUpdate: boolean;
}

export const AdHocItemRow = ({ item, canUpdate }: AdHocItemRowProps) => {
	const { products } = useBillingProducts();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [editModalOpen, setEditModalOpen] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	const product = products.find((p) => p.productId === item.stripeProductId);

	const description = item.description || product?.name;
	const unitPrice = item.stripePriceId
		? product?.prices.find((p) => p.priceId === item.stripePriceId)?.price
		: item.unitPrice === null || item.unitPrice === undefined
		? undefined
		: item.unitPrice / 100;

	return (
		<>
			<TableRow>
				<TableCell size="small">
					<Stack spacing={-0.25}>
						<Stack direction="row" alignItems="center" gap={1}>
							{description}
							{item.recurring && <Loop sx={{ color: 'neutral.500' }} />}
						</Stack>
						{item.serviceDate && (
							<Typography variant="caption">
								Date of Service:{' '}
								{format(zoneDateOnly(item.serviceDate), 'MM/dd/yyyy')}
							</Typography>
						)}
					</Stack>
				</TableCell>
				<TableCell align="right">
					{unitPrice === undefined ? '--' : formatUSD(unitPrice)}
				</TableCell>
				<TableCell align="right">{item.quantity}</TableCell>
				<TableCell padding="checkbox">
					{canUpdate && (
						<IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
							<MoreHoriz />
						</IconButton>
					)}
				</TableCell>
			</TableRow>
			{/** menu */}
			<Menu
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				onClick={() => setAnchorEl(null)}>
				<MenuItem onClick={() => setEditModalOpen(true)}>
					<ListItemIcon>
						<EditOutlined />
					</ListItemIcon>
					<ListItemText>Edit Item</ListItemText>
				</MenuItem>
				<MenuItem onClick={() => setDeleteModalOpen(true)}>
					<ListItemIcon>
						<DeleteOutlined />
					</ListItemIcon>
					<ListItemText>Delete Item</ListItemText>
				</MenuItem>
			</Menu>
			{/** edit modal */}
			<Dialog open={editModalOpen} fullWidth maxWidth="sm">
				<AddUpdateLineItemModal item={item} onClose={() => setEditModalOpen(false)} />
			</Dialog>
			{/** delete modal */}
			<Dialog open={deleteModalOpen} fullWidth maxWidth="sm">
				<DeleteLineItemModal item={item} onClose={() => setDeleteModalOpen(false)} />
			</Dialog>
		</>
	);
};
