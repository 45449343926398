import { GetAppOutlined, SearchOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Radio,
	Skeleton,
	Typography,
} from '@mui/material';
import {
	useExistingLicenseQuery,
	useLookupNIPRQuery,
	useLookupNIPRQueryValidation,
	useUsaAdjusterLicensingNIPRMutation,
} from 'components/pages/user/profile/licensing/usa/hooks';
import { Alert } from 'components/ui/alert';
import { DatePickerField, NumberField, TextField } from 'components/ui/fields';
import { ModalButtonsContainer } from 'components/ui/modals/modal-buttons-container';
import { ModalContent } from 'components/ui/modals/modal-content';
import { ModalHeader } from 'components/ui/modals/modal-header';
import { ModalContentProps } from 'components/ui/modals/modal-types';
import { useToast } from 'components/ui/toast';
import { format } from 'date-fns';
import { Formik } from 'formik';
import { QueryUsaNiprAdjusterLookupArgs } from 'middleware-types';
import { useEffect, useState } from 'react';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { zoneDateOnly } from 'utils/utils';

interface NpnModalProps extends ModalContentProps {
	userId: string;
}

export const NpnModal = ({ userId, onClose }: NpnModalProps) => {
	const { validationSchema, loading } = useLookupNIPRQueryValidation();
	const existingLicensedUser = useExistingLicenseQuery(userId);

	const initialValues: QueryUsaNiprAdjusterLookupArgs = {
		npnNumber: existingLicensedUser.npn,
		firstName: existingLicensedUser.npn ? '' : existingLicensedUser.name?.firstName ?? '',
		lastName: existingLicensedUser.npn ? '' : existingLicensedUser.name?.lastName ?? '',
		birthDate: existingLicensedUser.birthDate
			? zoneDateOnly(existingLicensedUser.birthDate)
			: null,
	};

	const [mode, setMode] = useState<'form' | 'display'>('form');

	if (loading || existingLicensedUser.loading)
		return (
			<>
				<ModalHeader title="Locate your NIPR Profile" onClose={onClose} />
				<ModalContent>
					{[...Array(4)].map((_, index) => (
						<Skeleton key={index} variant="rounded" height={40} />
					))}
				</ModalContent>
			</>
		);

	return (
		<Formik<QueryUsaNiprAdjusterLookupArgs>
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={async () => setMode('display')}
			enableReinitialize>
			{({ values, submitForm, isValid, dirty }) =>
				mode === 'form' ? (
					<FindNpnModal
						onClose={onClose}
						submitForm={submitForm}
						canSubmitForm={isValid && dirty}
					/>
				) : (
					<DisplayNpnModal
						userId={userId}
						values={values}
						onBack={() => setMode('form')}
						onClose={onClose}
					/>
				)
			}
		</Formik>
	);
};

interface FindNpnModalProps extends ModalContentProps {
	submitForm: () => void;
	canSubmitForm: boolean;
}

const FindNpnModal = ({ submitForm, canSubmitForm, onClose }: FindNpnModalProps) => {
	return (
		<>
			<ModalHeader title="Locate your NIPR Profile" onClose={onClose} />
			<ModalContent>
				<Box>
					<Grid container spacing={1} columnSpacing={2}>
						<Grid item>
							<Typography variant="h5">
								Please provide your Date of Birth and National Producer Number. If
								you do not have your NPN, you must provide your First and Last name
								instead.
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<DatePickerField label="Date of Birth" name="birthDate" required />
						</Grid>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						<Grid item xs={12} md={12}>
							<NumberField
								label="National Producer Number"
								name="npnNumber"
								integerOnly
								positiveNumber
								maxLength={9}
							/>
						</Grid>
						<Grid item xs={12}>
							<Divider>OR</Divider>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField label="First Name" type="text" name="firstName" />
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField label="Last Name" type="text" name="lastName" />
						</Grid>
					</Grid>
				</Box>
			</ModalContent>
			<ModalButtonsContainer>
				<Button variant="outlined" onClick={onClose}>
					Cancel
				</Button>
				<Button
					variant="contained"
					color="primary"
					startIcon={<SearchOutlined />}
					disabled={!canSubmitForm}
					onClick={submitForm}>
					Find
				</Button>
			</ModalButtonsContainer>
		</>
	);
};

interface DisplayNpnModalProps extends ModalContentProps {
	userId: string;
	values: QueryUsaNiprAdjusterLookupArgs;
	onBack: () => void;
}

const DisplayNpnModal = ({ userId, values, onBack, onClose }: DisplayNpnModalProps) => {
	const toast = useToast();
	const { updateLicenses, loading: mutLoading } = useUsaAdjusterLicensingNIPRMutation(userId);
	const { results, loading, error } = useLookupNIPRQuery({
		...values,
		birthDate: values.birthDate ? format(values.birthDate, 'yyyy-MM-dd') : '',
	});
	const [selectedNpn, setSelectedNpn] = useState<number | undefined>(
		results.length > 0 ? results[0].npn : undefined
	);

	const onSubmit = async () => {
		if (!selectedNpn) return;
		await updateLicenses(selectedNpn)
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Successfully imported NIPR data', { variant: 'success' });
				onClose();
			})
			.catch(() => handleNoResponse({ toast }));
	};

	useEffect(() => {
		setSelectedNpn(results.length > 0 ? results[0].npn : undefined);
	}, [results]);

	return (
		<>
			<ModalHeader title="Import your Licensing Information from NIPR" onClose={onClose} />
			<ModalContent>
				{error && <Alert error={error} />}
				{loading ? (
					[...Array(4)].map((_, index) => (
						<Skeleton key={index} variant="rounded" height={40} />
					))
				) : results && results.length > 0 ? (
					<List>
						{results.map((item) => (
							<ListItem key={item.npn} button>
								<ListItemIcon>
									<Radio
										value={item.npn}
										color="primary"
										checked={item.npn === selectedNpn}
										onClick={() => setSelectedNpn(item.npn)}
									/>
								</ListItemIcon>
								<ListItemText
									primary={item.npn}
									secondary={
										<>
											<strong>{item.name}</strong>
											<br />
											{`Date of Birth: ${format(
												zoneDateOnly(item.birthDate),
												'MM/dd/yyyy'
											)}`}
											<br />
											{item.stateOfResidence &&
												`State of Residence: ${item.stateOfResidence}`}
										</>
									}
								/>
							</ListItem>
						))}
					</List>
				) : (
					<Typography variant="h6">
						There are no entities that match your search requirements.
					</Typography>
				)}
			</ModalContent>
			<ModalButtonsContainer>
				<Button variant="outlined" onClick={onBack}>
					Back
				</Button>
				{results.length > 0 && (
					<LoadingButton
						variant="contained"
						color="primary"
						loading={mutLoading}
						startIcon={<GetAppOutlined />}
						onClick={onSubmit}
						disabled={selectedNpn === undefined}>
						Import
					</LoadingButton>
				)}
			</ModalButtonsContainer>
		</>
	);
};
